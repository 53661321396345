import React from 'react';
import Store from '../../Common/Store'
import styled from 'styled-components';
import { FaTimes, FaPlusCircle, FaLock } from 'react-icons/fa';
import { IoIosArrowForward, IoIosLock } from 'react-icons/io';
import General from './General';
//import ConflictTypes from './ConflictTypes'
import CommitteeRoles from './CommitteeRoles';
import Reminders from './Reminders';
import APICall from '../../Common/APICall.js';
import { Link } from 'react-router-dom';
import Email from './Email';
import AlertBox from '../Common/AlertBox';
import CSLLoaderNonPromise from '../Common/CSLLoaderNonPromise';
import Reload from '../../Common/Reload.js';

const ReviewBodyContainer = styled.div`
    padding-top: 25px;
    width: 100%;
`;
const ReviewLeftContainer = styled.div`
    float: left;
    padding-right: 20px;
    width: 20%;
    box-sizing: border-box;
`;
const ReviewRightContainer = styled.div`
    float: right;
    padding-left: 20px;
    width: 80%;
    box-sizing: border-box;
`;
const ReviewTab = styled.div`
    background-color: #ffffff;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    padding: 10px 20px;
    cursor: pointer;
`;
const CloseBtn = styled.div`
    float: right;
    font-size: 20px;
    color: #9c9c9c;
    cursor: pointer;
    padding-top: 10px;
    padding-right: 5px;
`;
const FooterButtonsContainer = styled.div`
	text-align: right;
	padding-top: 25px;
	width: 70%;
`;
const CancelBtn = styled.button`
	padding: 10px 20px;
	background-color: #ffffff;
	border: 1px solid #ffffff;
	border-radius: 2px;
	color: #333333;
	cursor: pointer;
`;
const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
`;
const ErrorBar = styled.div`
    background-color: #F6DBDB;
    color: #DE8D8E;
    font-weight: 600;
    padding: 10px 20px;
`;
const ReviewTab_inactive = styled.div`
    background-color: #ffffff;
    color: #dedede;
    padding: 10px 20px;
`;
const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin-right: 3px;
    float: left;
`;

class ModuleConfigHOC extends React.Component {
    state = {
        active_section_id: "general",
        dirty: false,
        permissions: {},
        module_config: null,
        all_company_users: null,
        changed_user_roles: {},
        requester: "",
        client_conflict_types: null,
        standard_Type: null,
        conflict_types_json: {},
        ready: false,
        client_conflict_types_filter: null,
        importStandardTypeButton: true,
        committee_roles: [],
        alert_param: null,
        permission_changed: false,
        is_loader: false,
        committeesaccess: [],
    };
    constructor(props) {
        super(props);
        if (Store.getStoreData('role') === 'admin_manager') {
            const postData = { command: "list_company_and_users" };
            const api = new APICall();
            api.command(postData, this.getInitialPermission);
        }
    }

    getInitialPermission = (result) => {
        let requester = this.props.match ? this.props.match.params.requester : "";
        console.log("requester:", requester);
        console.log("result", result);

        let contactlist = localStorage.getItem("contactlist")!==null ? JSON.parse(localStorage.getItem("contactlist")) : {};
        let user_roles = contactlist.result.user_roles['committees'];
        let cls = contactlist.result.contactlist;

        let all_company_users = [];
        for(let key in cls){
            if(cls[key].IsActive === true && cls[key].IsDeleted === false){
                let cu = {};
                cu['email'] = cls[key].EmailAddress;
                cu['user_id'] = parseInt(key);
                cu['name'] = cls[key].ContactName;
                cu['role'] = key in user_roles ? user_roles[key] : 'no_access';
                // cu['company_id'] = 3111;
                all_company_users.push(cu)
            }
        }
        const permissions = {
            user_roles: user_roles,
            roles: result.roles,
            company_users: all_company_users

        };








        // const permissions = {
        //     user_roles: result.user_roles,
        //     gc_companies: result.gc_companies,
        //     roles: result.roles,
        //     company_users: result.company_users
        // };
        let module_configs = JSON.parse(result.moduleconfig.config_json);
        console.log("module_configs,", all_company_users, JSON.parse(JSON.stringify(result.company_users)));
        let importStandardTypeButton = module_configs.general.standard_type_enable;
        // const all_company_users = JSON.parse(JSON.stringify(result.company_users));
        let committee_roles_temp = module_configs.committee_roles;
        let committee_roles = [];
        committee_roles_temp.forEach((item) => {
            item.status = (typeof item.status !== 'undefined') ? item.status : 'Active';
            committee_roles.push(item);
        })
        this.setState({
            permissions, all_company_users, module_config: module_configs, requester, conflict_types_json: result.client_conflict_types, client_conflict_types: result.client_conflict_types, client_conflict_types_filter: result.client_conflict_types, standard_Type: result.standard_Type,
            importStandardTypeButton: importStandardTypeButton, committee_roles: committee_roles, ready: true , committeesaccess: result.committee_email_access,
        });
    }

    returnSectionPre = (section) => (event) => {
        event.preventDefault();
        if(this.state.permission_changed && section !== 'permissions'){
			let message = 'Your changes have not been saved. Are you sure you want to continue?';
			let alert_param = {
				title: 'Alert', message: message, ok_text: 'Ok', confirm: true,
				alertHandler: this.submitAlertHandler, stack: {'change_section': true , section: section}
			};
			this.setState({ alert_param: alert_param });
        }else{
			this.returnSection(section);
        }
    }

    returnSection = (section) => {
        //event.preventDefault();
        if (section === 'permissions') {
            const postData = { command: "list_company_and_users" };
            const api = new APICall();
            api.command(postData, this.getInitialPermission);
        }
        this.setState({ active_section_id: section });
    }

    updateGeneral = (obj) => {
        let { module_config, dirty } = this.state;
        console.log("old_config", this.state);
        const old_config = { ...module_config };
        // console.log("old_config", old_config);
        module_config.general = obj;
        const new_config = { ...module_config };
        const postData = { command: "save_module_config", module_config: module_config };
        const api = new APICall();
        api.command(postData, this.afterUpdateEmailRemainder);
        // let {module_config, dirty} = this.state;
        // const old_config = {...module_config};
        // module_config.general = obj;
        // const new_config = {...module_config};
        // if (JSON.stringify(old_config) !== JSON.stringify(new_config)) {
        // 	dirty = true;
        // }
        // this.setState({module_config});
    }


    changeActiveInactive = (obj) => {
        let { module_config } = this.state;
        module_config.general.reason_active_inactive = obj;
        const postData = { command: "save_module_config", module_config: module_config };
        const api = new APICall();
        api.command(postData, this.afterUpdateEmailRemainder);
    }

    saveReasonValue = (obj) => {
        console.log("object", obj);
        let { module_config } = this.state;
        module_config.reason_removal.push(obj)
        const postData = { command: "save_module_config", module_config: module_config };
        const api = new APICall();
        api.command(postData, this.afteraddReasonValue);
    }

    afteraddReasonValue = (result) => {
        console.log(result);
        // alert("Reason for removal added successfully")
        // this.setState({ module_config: result.result });
        let message = 'Reminder Update Successfully'
        let alert_param = {
            title: 'Success', message: message, ok_text: 'Ok', confirm: false,
            alertHandler: this.updateRem, stack: {}
        }
        this.setState({ alert_param: alert_param })
    }

    updateReason = (arr) => {
        console.log("object", arr);
        let { module_config } = this.state;
        module_config.reason_removal = arr;
        const postData = { command: "save_module_config", module_config: module_config };
        const api = new APICall();
        api.command(postData, this.afterUpdatereasonValue);
    }
    afterUpdatereasonValue = (result) => {
        console.log(result);
        alert("Reason for removal updated successfully")
        this.setState({ module_config: result.result });
    }

    deleteReasonValue = (arr) => {
        console.log("object", arr);
        let { module_config } = this.state;
        module_config.reason_removal = arr;
        const postData = { command: "save_module_config", module_config: module_config };
        const api = new APICall();
        api.command(postData, this.afterDeletereasonValue);
    }
    afterDeletereasonValue = (result) => {
        console.log(result);
        alert("Reason for removal deleted successfully")
        this.setState({ module_config: result.result });
    }



    afterPermissionUpdate = (result) => {
        console.log("HHHH,",result);
        let message = 'Permissions updated successfully'
        let alert_param = {
            title: 'Success', message: message, ok_text: 'Ok', confirm: false,
            alertHandler: this.submitReminder, stack: {}
        };

		let cl  = JSON.parse(localStorage.getItem('contactlist'));
		let contactlist_assoc = cl.result.contactlist;
		let user_roles = cl.result.user_roles.committees;
		let updated_permissions = {};
		let updated_user_roles = {};
		for(let r of result.result){
			updated_permissions[r.id.toString()] = r.name;
		}
		for(let u in user_roles){
			updated_user_roles[u] = (u in updated_permissions) ? updated_permissions[u] : user_roles[u];
		}
		for(let u in updated_permissions){
			if(!(u in user_roles)){
				updated_user_roles[u] = updated_permissions[u];
			}
		}
		//cl.result.user_roles.committees = updated_user_roles;
		Reload.RestoreStorage(updated_user_roles); // add this line

        this.setState({ alert_param: alert_param , permission_changed: false , is_loader: false});
    }

    updateCommitteeRoles = (obj) => {
        let { module_config } = this.state;
        module_config.committee_roles = obj;
        console.log("modulecong", module_config)
        console.log("moduleCommitteeRoles", obj)
        const postData = { command: "save_module_config", module_config: module_config };
        const api = new APICall();
        api.command(postData, this.afterUpdateCommitteeRoles);
    }

    afterUpdateCommitteeRoles = (result) => {
        console.log(result);
        let module_config = result.result;
        let committee_roles_temp = module_config.committee_roles;
        let committee_roles = [];
        committee_roles_temp.forEach((item) => {
            item.status = (typeof item.status !== 'undefined') ? item.status : 'Active';
            committee_roles.push(item);
        })
        this.setState({ module_config: result.result, committee_roles: committee_roles });
    }

    updateEmail = (obj) => {
        let { module_config } = this.state;
        module_config.email = obj;
        console.log("modulecong", module_config)
        console.log("moduleemail", obj)
        const postData = { command: "save_module_config", module_config: module_config };
        const api = new APICall();
        api.command(postData, this.afterUpdateEmailRemainder);
    }
    updateCommitteeEmailAccess = (obj) => {
        const postData = { command: "update_committee_email_access", email_id: obj.email_id, committees: obj.committees };
        const api = new APICall();
        api.command(postData, this.afterUpdateEmailAccess);
    }
    afterUpdateEmailAccess = (result) => {
        console.log("afterUpdateEmailAccess",result);
        this.setState({ committeesaccess: result });
    }
    updateEmailRemainder = (obj) => {
        let { module_config, dirty } = this.state;
        console.log("old_config", this.state);
        const old_config = { ...module_config };
        // console.log("old_config", old_config);
        module_config.general = obj;
        const new_config = { ...module_config };
        const postData = { command: "save_module_config", module_config: module_config };
        const api = new APICall();
        api.command(postData, this.afterUpdateEmailRemainder);
    }

    afterUpdateEmailRemainder = (result) => {
        console.log(result);
        this.setState({ module_config: result.result });
    }

    updatePermissionsInState = (obj, single_obj) => {
        console.log("single_obj:", single_obj);
        let changed_user_roles = JSON.parse(JSON.stringify(this.state.changed_user_roles));
        changed_user_roles[single_obj.user_id] = single_obj.role;
        console.log("changed_user_roles:", changed_user_roles);
        this.setState({ permissions: obj, changed_user_roles , permission_changed: true});
    }
    updatePermissionsInDb = () => {
        let changed_user_roles = JSON.parse(JSON.stringify(this.state.changed_user_roles));
		this.setState({ is_loader: true});

		setTimeout(() => {
			const api = new APICall();
			let postData = { command: "save_user_role", changed_user_roles: changed_user_roles };
			api.command(postData, this.afterPermissionUpdate);
		}, 20);

        //this.setState({ permissions: obj, changed_user_roles });
        // console.log("Updated Permission:", obj);
    }

    searchUsers = (search_string) => {
        if (search_string === "") {
            let permissions = JSON.parse(JSON.stringify(this.state.permissions));
            permissions.company_users = this.state.all_company_users;
            this.setState({ permissions });
        } else {
            let all_company_users = JSON.parse(JSON.stringify(this.state.all_company_users));
            let filtered_company_users = [];
            all_company_users.forEach((user) => {
                if (user.name.toLowerCase().includes(search_string.toLowerCase()) || user.role.includes(search_string.toLowerCase())) {
                    filtered_company_users.push(user);
                }
            })
            let permissions = JSON.parse(JSON.stringify(this.state.permissions));
            permissions.company_users = filtered_company_users;
            // console.log("in search:", permissions.company_users);
            this.setState({ permissions });
        }
    }


    changeAllRoles = (role) => {
        let permissions = JSON.parse(JSON.stringify(this.state.permissions));
        if (role !== "0") {
            permissions.company_users.forEach((user) => {
                user.role = role;
            })
        }
        let changed_user_roles = {};
        permissions.company_users.forEach((user) => {
            changed_user_roles[user.user_id] = user.role;
        })
        // console.log("change all roles:", permissions.company_users);
        this.setState({ permissions, changed_user_roles , permission_changed: true});
    }

    updateModuleconfig = (event) => {
        event.preventDefault();
        let { module_config, changed_user_roles } = this.state;
        // console.log("saving:", changed_user_roles);
        module_config.general.standard_type_enable = this.state.importStandardTypeButton;
        console.log("modulecong", module_config)
        const api = new APICall();
        let postData = { command: "save_module_config", module_config: module_config };
        api.command(postData, this.afterUpdate);

    }

    closePagePre = (event) => {
        event.preventDefault();
        if(this.state.permission_changed){
			let message = 'Your changes have not been saved. Are you sure you want to continue?';
			let alert_param = {
				title: 'Alert', message: message, ok_text: 'Ok', confirm: true,
				alertHandler: this.submitAlertHandler, stack: {}
			};
			this.setState({ alert_param: alert_param });
        }else{
			this.closePage();
        }
    }
    submitAlertHandler = (result, stack) => {
		console.log("submitAlertHandler result,",result);
		this.setState({alert_param: null});
		if(result){
			if('change_section' in stack && stack.section !== 'permissions'){
				this.returnSection(stack.section);
			}else{
				this.closePage();
			}

		}

    }

    closePage = () => {
        let url = "/";
        window.location.replace(url);
    }

    updateReminder = (obj) => {
        let { module_config } = this.state;
        module_config.reminders = obj;
        const new_config = { ...module_config };
        const postData = { command: "save_module_config", module_config: module_config };
        const api = new APICall();
		let that = this;
        api.command(postData, function callback(result){that.afterUpdate(result,'reminders')});
    }

    afterUpdate = (result,flag) => {
        //if(flag === 'reminders')return;
        let message = 'Configuration changed successfully'
        let alert_param = {
            title: 'Success', message: message, ok_text: 'Ok', confirm: false,
            alertHandler: this.submitReminder, stack: {}
        }
        this.setState({ alert_param: alert_param })

    }

    submitReminder = (result, stack) => {
        this.setState({ alert_param: null })
        // window.location.reload();
    }


    exportToCSV = (event) => {
        event.preventDefault();
        var cur_table_data = Store.getStoreData('table_cur_data');
        console.log("cur_table_data", cur_table_data)
        var cur_table_arr = [];
        let headerCSV = { type: "CONFLICT TYPE", status: "STATUS", id: "1234" }
        cur_table_data.unshift(headerCSV);
        for (let i = 0; i < cur_table_data.length; i++) {
            var container = [];
            container = Object.values(cur_table_data[i])
            if (container.length > 0);
            //   container.shift();
            container.pop()

            cur_table_arr.push(container)
        }
        console.log("cur_table_arr", cur_table_arr)
        let csvContent = "data:text/csv;charset=utf-8,"
            + cur_table_arr.map(e => e.join(", ")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "Conflict_Types.csv");
        document.body.appendChild(link);
        link.click();
    }

    render() {

        if (Store.getStoreData('role') !== 'admin_manager') {
            return (
                <ErrorBar>
                    <div style={{ float: "left" }}>You are not authorised to access this page. If you think this is in error, please contact your administrator.</div>
                    <div style={{ float: "right", fontSize: "16px", cursor: "pointer" }} onClick={this.closePagePre}>x</div>
                    <div style={{ clear: "both" }}></div>
                </ErrorBar>
            );
        }
        if (this.state.module_config === null) {
            return (<div>Loading...</div>);
        }
        console.log("ModuleConfigHOC state:", this.state)
        return (
            <div style={{ padding: "10px 10px 20px 10px" }}>
                <CloseBtn onClick={this.closePagePre}><FaTimes /></CloseBtn>
                <div style={{ clear: "both" }}></div>
				{
					(() => {
						if (this.state.is_loader) {
							return <div><CSLLoaderNonPromise /></div>
						}

					})()
				}
                <ReviewBodyContainer>
                    <ReviewLeftContainer style={{ position: "sticky", top: "2px" }}>
                        <ReviewTab onClick={this.returnSectionPre("general")} style={{ marginTop: "2px" }}>
                            {
                                (() => {
                                    if (this.state.active_section_id === "general") {
                                        return (<div style={{ float: "left", fontWeight: "600" }}>General</div>);
                                    } else {
                                        return (<div style={{ float: "left" }}>General</div>);
                                    }
                                })()
                            }
                            <div style={{ float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px" }}><IoIosArrowForward /></div>
                            <div style={{ clear: "both" }}></div>
                        </ReviewTab>
                        <ReviewTab onClick={this.returnSectionPre("email")} style={{ marginTop: "2px" }}>
                            {
                                (() => {
                                    if (this.state.active_section_id === "email") {
                                        return (<div style={{ float: "left", fontWeight: "600" }}>Email</div>);
                                    } else {
                                        return (<div style={{ float: "left" }}>Email</div>);
                                    }
                                })()
                            }
                            <div style={{ float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px" }}><IoIosArrowForward /></div>
                            <div style={{ clear: "both" }}></div>
                        </ReviewTab>
                        <ReviewTab onClick={this.returnSectionPre("reminders")} style={{ marginTop: "2px" }}>
                            {
                                (() => {
                                    if (this.state.active_section_id === "reminders") {
                                        return (<div style={{ float: "left", fontWeight: "600" }}>Reminders</div>);
                                    } else {
                                        return (<div style={{ float: "left" }}>Reminders</div>);
                                    }
                                })()
                            }
                            <div style={{ float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px" }}><IoIosArrowForward /></div>
                            <div style={{ clear: "both" }}></div>
                        </ReviewTab>
                        <ReviewTab onClick={this.returnSectionPre("committeeroles")} style={{ marginTop: "2px" }}>
                            {
                                (() => {
                                    if (this.state.active_section_id === "committeeroles") {
                                        return (<div style={{ float: "left", fontWeight: "600" }}>Committee Roles</div>);
                                    } else {
                                        return (<div style={{ float: "left" }}>Committee Roles</div>);
                                    }
                                })()
                            }
                            <div style={{ float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px" }}><IoIosArrowForward /></div>
                            <div style={{ clear: "both" }}></div>
                        </ReviewTab>
                    </ReviewLeftContainer>
                    <ReviewRightContainer>
                        {
                            (() => {
                                if (this.state.active_section_id === "general") {
                                    return (<General general={JSON.parse(JSON.stringify(this.state.module_config.general))} updateGeneral={this.updateGeneral} changeActiveInactive={this.changeActiveInactive} />);
                                }
                                if (this.state.active_section_id === "email") {
                                    return (<Email email={JSON.parse(JSON.stringify(this.state.module_config.email))} variables={this.state.module_config.variables} general={JSON.parse(JSON.stringify(this.state.module_config.general))} updateGeneralSave={this.updateEmailRemainder} updateEmail={this.updateEmail} updateCommitteeEmailAccess={this.updateCommitteeEmailAccess} committeesaccess={this.state.committeesaccess}/>);
                                }
                                if (this.state.active_section_id === "reminders") {
                                    return (<Reminders reminders={JSON.parse(JSON.stringify(this.state.module_config.reminders))} updateReminder={this.updateReminder} />);
                                }
                                if (this.state.active_section_id === "committeeroles") {
                                    return (<CommitteeRoles committeeroles={this.state.committee_roles} updateCommitteeRoles={this.updateCommitteeRoles} />);
                                }

                            })()
                        }
                        {
                            (() => {

                                if (this.state.active_section_id !== "email" && this.state.active_section_id !== "general" &&  this.state.active_section_id !== "reminders" ) {
                                    return (
                                        <FooterButtonsContainer>
                                            <CancelBtn onClick={this.closePagePre}>Cancel</CancelBtn>
											{
												(() => {
														if(this.state.active_section_id === "permissions"){
															return(<SaveBtn onClick={this.updatePermissionsInDb}>Save</SaveBtn>);
														}else{
															return(<SaveBtn onClick={this.updateModuleconfig}>Save</SaveBtn>);
														}

												})()
											}
                                        </FooterButtonsContainer>
                                    )
                                }
                            })()
                        }


                    </ReviewRightContainer>
                    <div style={{ clear: "both" }}></div>
                </ReviewBodyContainer>
                <AlertBox alertParam={this.state.alert_param} />
            </div>
        );
    }
}

export default ModuleConfigHOC;
