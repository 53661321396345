import React, { Component } from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import { MdClose } from 'react-icons/md';

const ReactModalHeader = styled.div`
    padding: 10px 15px;
    background: linear-gradient(to bottom, #04ADA8 0%, #04ADA8 100%);
    color:#fff;
    border-radius: 4px 4px 0 0;
`;
const ReactFormGroup = styled.div`
    margin-bottom: 15px;
`;
const ModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 36%;
    left: 12%;
    height: 220px;
    background: #ffffff;
    border: 1px solid #868484;
    z-index: 1005;
    position: absolute;
    margin: 0 20%;
    border-radius: 6px;
    top: 39vh;
`;
const CloseDiv = styled.div`
    box-sizing: border-box;
    cursor:pointer;
`;
const ConfirmationMessage = styled.div`
    padding: 15px;
    background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 0%, #FFFFFF 100%, #FFFFFF 100%);
    box-sizing: border-box;
    box-shadow: inset 0 1px 1px #FFFFFF;
    border: 1px solid grey;
    border-radius: 4px;
    margin-bottom: 24px;
	font-weight: 600;
	color: #000000
`;
const YesButton = styled.button`
    color: #fff;
    background: linear-gradient(to top, #04ADA8 0%, #04ADA8 100%);
    box-shadow: inset 0px 1px 1px 0px #04ADA8;
    width: 116px;
    padding: 6px 16px;
    border: 1px solid #04ADA8;
    border-radius:3px;
    margin-right:10px;
`;
const NoButton = styled.button`
    color: #000000;
    background: linear-gradient(to top, #FFFFFF 0%, #FFFFFF 100%);
    box-shadow: inset 0px 1px 1px 0px #FFFFFF;
    width: 116px;
    padding: 6px 16px;
    border: 1px solid grey;
    border-radius:3px;
    margin-right:10px;
`;
class ConfirmationBox extends Component {

    closeConfirmationModal = () =>{
        //Store.updateStore('ConfirmationBoxVisibility', false);
		this.props.closeDialog();
    }
    closeParentModal = () => {
		//this.closeConfirmationModal();
		//this.closeCommitteeModal();
		//this.closeMeetingModal();
		this.props.closeParentModal();
        
    }

	closeCommitteeModal = () => {
		Store.updateStore('OverlayHeight', null);
        Store.updateStore('modal_committee_Edit', false);
        Store.updateStore('modal_overlay_committee_Edit', null);
        Store.updateStore('modal_committee_Edit_CommitteeID', 0);
		
    }

	closeMeetingModal = () => {
		Store.updateStore('meetingDataEditMode',null);
        Store.updateStore('MeetingOverlay', null);
        Store.updateStore('MeetingVisibility', false);
		
    }
    
    render(){
        return(
            <ModalContainer>
                
                <ReactModalHeader>
                    <div style={{ float: "left" }}>Cancel</div>
                    <div style={{ float: "right" }}><CloseDiv onClick={this.closeConfirmationModal}><MdClose /></CloseDiv></div>
                    <div style={{ clear: "both" }}></div>
                </ReactModalHeader>
                <div style={{padding:"15px"}}>
                    <ConfirmationMessage>
                        <p>Are you sure you want to exit? All data will be lost.</p>
                    </ConfirmationMessage>
                    <hr style={{ border: "1px solid #dbdbdb", width: "253px" }}></hr>
                    <div style={{textAlign:"center"}}>
                        <YesButton onClick={this.closeParentModal}>Yes</YesButton>
                        <NoButton onClick={this.closeConfirmationModal}>No</NoButton>
                    </div>
                </div>
            </ModalContainer>
        )
    }
}

export default ConfirmationBox;