import React from 'react';
import styled from 'styled-components';
//import ExcelJS from 'exceljs';
import Store from '../Common/Store.js';
import APICall from '../Common/APICall.js';
import moment from 'moment';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';



const RunExportButton = styled.button`
    color: #fff;
    box-shadow: inset 0px 1px 1px 0px #1A3552;
	margin-left: 30px;
    width: 156px;
    padding: 6px 16px;
    border: 1px solid #1A3552;
    border-radius:3px;
    background:#1A3552;
`;
const metingschedule = [
    { id: "Scheduled", display: "Scheduled", value: "Scheduled", selected: true },
    { id: "Cancelled", display: "Cancelled", value: "Cancelled", selected: false },
    { id: "Completed", display: "Completed", value: "Completed", selected: false }
];
function stringToDate(_date,_format,_delimiter)
{
            var formatLowerCase=_format.toLowerCase();
            var formatItems=formatLowerCase.split(_delimiter);
            var dateItems=_date.split(_delimiter);
            var monthIndex=formatItems.indexOf("mm");
            var dayIndex=formatItems.indexOf("dd");
            var yearIndex=formatItems.indexOf("yyyy");
            var month=parseInt(dateItems[monthIndex]);
            month-=1;
            var formatedDate = new Date(dateItems[yearIndex],month,dateItems[dayIndex]);
            return formatedDate;
}
function download(filename, text) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text));
  //element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  //element.setAttribute('href', 'data:vnd.ms-excel;charset=utf-8,' );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
class RunExport extends React.Component{
	constructor(props) {
        super(props);
        this.downloadFile = this
            .downloadFile
            .bind(this);
    }
	componentDidMount() {
		//console.log("I am in RunExport componentDidMount ==>"+JSON.stringify(this.props.passFlag));
	}
	componentDidUpdate(){
		//console.log("I am in RunExport componentDidUpdate ==>"+JSON.stringify(this.props.passFlag));
	}
	getFormattedDate = (input_date) => {
		var date = new Date(input_date);
		var month = (date.getMonth() +1) < 10 ? '0'+(date.getMonth() +1) : (date.getMonth() +1);
		var dt = (date.getDate() < 10 ) ? '0'+(date.getDate()) : (date.getDate());
		return dt +'/'+ month + '/'+date.getFullYear();
	}
	downloadFile = (event) => {
		event.preventDefault();

        const url = Store.getStoreData('api_url');

        if(this.props.passFlag === 'one'){
			/*
			let postData = { command: "getCommitteeWithCount", data:{ employee_id: 0} };

			let httpVerb = { method: "POST", mode: "cors", headers: { "Content-Type": "application/x-www-form-urlencoded" }, body: "jsondata=" + JSON.stringify(postData) };
			fetch(url, httpVerb)
				.then((res) => res.json())
				.then(result => {
	                console.log("one result==>",result);
					let committee_meeting_date_hash = {};
					let committee_meeting_count_hash = {};
					let temp_date_hash = {};
					result.committees.map(committee => {
						committee_meeting_date_hash[committee.PK_CommitteeID] = '';
						temp_date_hash[committee.PK_CommitteeID] = committee.PK_CommitteeID;
					})
                    //result.committees.committee_meeting_date_hash(meeting_date => {
                    for (var key in result.committee_meeting_date_hash) {
						if(key.toString() === temp_date_hash[key].toString()){//if any meeting date exists
							if(new Date(result.committee_meeting_date_hash[key]) >= new Date()){
								committee_meeting_date_hash[key] = this.getFormattedDate(new Date(result.committee_meeting_date_hash[key]));
							}

						}
					}
                    for (var key in result.meeting_count_hash) {
						committee_meeting_count_hash[key] = result.meeting_count_hash[key];
					}
					//console.log(committee_meeting_date_hash);
					var csv = 'Committee Name,Legal Entity,Description,Members Count,Members Detail,Third Party Members,Notes Count,Meeting Count,Next Scdeduled Meeting\n';
					result.committees.map(committee => {
						//console.log(JSON.parse(committee.Json_Data).committeename);
						//result.committees.length > 0 ? result.committees[0].companyName : '';
						let committeeName = JSON.parse(committee.Json_Data).committeename.replace(/\n|\r\n/g, " ; ");
						let companyName = committee.companyName.replace(/\n|\r\n/g, " ; ");
						let perposeofcommitee = JSON.parse(committee.Json_Data).perposeofcommitee.replace(/\n|\r\n/g, " ; ");
						let next_scheduled_meeting_date = committee_meeting_date_hash[committee.PK_CommitteeID] ;

						let committeemembers = [];
						let thirdpartyembers = [];
						JSON.parse(committee.Json_Data).committeemembers.forEach((item,index) => {
							if(typeof item.employeeid !== 'undefined'){
								committeemembers.push(item.employeename +'(' + item.committeerolename + ')');
							}

						});
						let committeemembers_joined = '"' + committeemembers.join(',') + '"';

						JSON.parse(committee.Json_Data).thirdpartyembers.forEach((item,index) => {
							if(typeof item.thirdpartyembername !== 'undefined' && item.thirdpartyembername.trim() !== ''){
								thirdpartyembers.push(item.thirdpartyembername +'(' + item.thirdpartyembercompany + ')');
							}

						});
						let thirdpartyembers_joined = '"' + thirdpartyembers.join(',') + '"';

						csv += '"' + committeeName +'","' + companyName + '","' + perposeofcommitee + '",' +committeemembers.length+','+committeemembers_joined+','+thirdpartyembers_joined+','+committee.notecount+','+committee_meeting_count_hash[committee.PK_CommitteeID]+ ',' + next_scheduled_meeting_date + '\n';
					});
					download("committee_report.csv",csv);

					//return result;
				});
			*/
			//let postData = { command: "getCommitteeWithCount", data:{ employee_id: 0} };
			//let api = new APICall();
			//api.command(postData, this.processHandleCommitteeCount);
		}

		//alert("in RunExport");
		//download("helloworld.txt","This is the content of my file");
		let company_name = Store.getStoreData('company_name');
		let companies_assoc = Store.getStoreData('companies_assoc');
		let committee_with_count = Store.getStoreData('committee_with_count');
		let committee_meeting_date_hash = committee_with_count.committee_meeting_date_hash;
		let meeting_count_hash = committee_with_count.meeting_count_hash;
		let committee_meeting_count_hash = {};
		for (var key in meeting_count_hash) {
			committee_meeting_count_hash[key] = meeting_count_hash[key];
		}

		let records = [];
		let fileName = 'committee_report';

		for(let committee of committee_with_count.committees){
			committee.gc_id = ('gc_id' in JSON.parse(committee.Json_Data)) ? JSON.parse(committee.Json_Data).gc_id : '0';

			let committeeName = JSON.parse(committee.Json_Data).committeename;
			let companyName = (committee.gc_id === '0') ? company_name: companies_assoc[committee.gc_id].company_name;
			if(committee.gc_id.toString() !== '0' && companies_assoc[committee.gc_id].is_active === 0){
				companyName = companyName +" (Inactive)";
			}
			let purposeofcommitee = JSON.parse(committee.Json_Data).perposeofcommitee;
			let next_scheduled_meeting_date = (committee.PK_CommitteeID in committee_meeting_date_hash) ? moment(committee_meeting_date_hash[committee.PK_CommitteeID]).format("DD/MM/YYYY") : 'NA' ;
			let committeemembers = [];
			let thirdpartyembers = [];
			JSON.parse(committee.Json_Data).committeemembers.forEach((item,index) => {
				if(typeof item.employeeid !== 'undefined'){
					committeemembers.push(item.employeename +'(' + item.committeerolename + ')');
				}

			});
			let committeemembers_joined = committeemembers.join(',');

			JSON.parse(committee.Json_Data).thirdpartyembers.forEach((item,index) => {
				if(typeof item.thirdpartyembername !== 'undefined' && item.thirdpartyembername.trim() !== ''){
					thirdpartyembers.push(item.thirdpartyembername +'(' + item.thirdpartyembercompany + ')');
				}

			});
			let thirdpartyembers_joined = thirdpartyembers.join(',');
			let elm ={
				'Committee Name' : committeeName,
				'Legal Entity' : companyName,
				'Description' : purposeofcommitee,
				'Members Count' : committeemembers.length,
				'Members Detail' : committeemembers_joined,
				'Third Party Members' : thirdpartyembers,
				'Meeting Count' : committee_meeting_count_hash[committee.PK_CommitteeID],
				'Next Scdeduled Meeting' : next_scheduled_meeting_date,
				//'Notes Count' : `${day}-${month}-${year}`,
			};
			records.push(elm);
		}

        let wb = {SheetNames:[], Sheets:{}};
        const ws1 = XLSX.utils.json_to_sheet(records);
        wb.SheetNames.push("COMMITTEES");
		wb.Sheets["COMMITTEES"] = ws1;

        const format1 = "DD-MM-YYYY HH:mm:ss"
        let tm = moment().format(format1);

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + '-' + tm + fileExtension);

	}


    render(){
        return(
            <RunExportButton onClick={this.downloadFile}>Run Export</RunExportButton>
        );
    }
}
export default RunExport;
