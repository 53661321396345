import React from 'react';
import {FaTimes, FaTrashAlt, FaQuestionCircle} from 'react-icons/fa';
import styled from 'styled-components';
import AlertBox from '../Common/AlertBox';
import CSLTable from '../Common/CSLTable';
import APICall from '../../Common/APICall.js';
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";


const CancelBtn = styled.button`
	background-color: #ffffff;
	border: 1px solid #EBEBEB;
	border-radius: 4px;
	padding: 10px 20px;
	cursor: pointer;
`;
const SubmitBtn = styled.button`
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 4px;
	padding: 10px 30px;
	cursor: pointer;
	color: #ffffff;
`;
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 12px;
    font-weight: 600;
    height: 80px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151;  
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 74%;
    position: absolute;
    top: 38px;
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 2px;
`;
const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: rgb(243, 247, 251);
    padding: 10px;
    border-radius: 4px;
`;

class EmailConfigModal extends React.Component
{
	state = {alert_param: null, email_id: null, committees: []};

	componentDidMount()
	{
		const postData = { command: "committeeList" };
		const api = new APICall();
		api.command(postData, this.getInitialCommittees);
		
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			this.setState({email_id: this.props.email_id});
		}
	}
    getInitialCommittees = (result) => {
		console.log("getInitialCommittees,",result);
		let committees = [];
		let committeesaccess = this.props.committeesaccess;
		let ca_access = [];
		for(let c of committeesaccess){
			if(this.props.email_id === c.email_id){
				ca_access[c.committee_id.toString()] = (c.is_enabled.toString() === '1') ? true : false;
			}
		}
		for(let r of result){
			let json_obj = JSON.parse(r.Json_Data);
			let status = (r.PK_CommitteeID.toString() in ca_access) ? ca_access[r.PK_CommitteeID.toString()] : false ;
			committees.push({committee_id: r.PK_CommitteeID, name: json_obj.committeename, enabled: status});
		}
		this.setState({committees,email_id: this.props.email_id});
	}
	closeModal = (event) => {
		event.preventDefault();
		this.props.closeModal();
	}

	modifyEmailAccess = (event) => {
		event.preventDefault();
        let {committees} = this.state;
        //console.log("content",content)
		this.props.modifyEmailsAccess({committees: committees, email_id: this.state.email_id});
	}


	tableData = () => {
		let tabledata = this.state.committees;

		//tabledata = this.filter_table_data(tabledata);
		console.log("tabledata 1",tabledata,this.state.email_id);
        let ret = {data: [], columns: []};
        ret.columns =[
                        {Header: 'Committee', accessor: 'committee_name', minWidth: 120, headerStyle: {textAlign: 'left'}}, 
						{'Header' : 'Enabled', Cell: row => (
							<div>
								   {
									   (()=> {
										   if (row.original.enabled) {
											   return(<div style={{cursor: "pointer", fontSize:"21px", textAlign: "center"}} onClick={this.handlenaCommitteeIncluded(row.original.id)}><IoIosCheckboxOutline /></div>);
										   } else {
											   return(<div  style={{cursor: "pointer", fontSize:"21px", textAlign: "center"}} onClick={this.handlenaCommitteeIncluded(row.original.id)}><IoIosSquareOutline /></div>);
										   }
									   })()
								   }
							</div>
						 ), headerStyle:{textAlign: 'center'}},
						];
        tabledata.forEach((item) => {
			
        	let elem = {
				id: item.committee_id, 
				committee_name: item.name, 
				enabled: item.enabled, 
			};
        	ret.data.push(elem);
        })
        return ret;
	}
	handlenaCommitteeIncluded = (committee_id) => (event) => {
		//console.log("content:", committee_id);
		let {committees} = this.state;
		let committees_arr = [];
		committees.forEach((item) => {
			if (item.committee_id.toString() === committee_id.toString()) {
				item.enabled = item.enabled ? false : true; 
				//console.log("matched", item);
			}
			committees_arr.push(item);
		})
		//console.log("committees_arr:", committees_arr);
		this.setState({committees: committees_arr });
	}
	render()
	{
		console.log("Committee Selection state,",this.state);
		//if (this.state.content === null) {
		//	return (<div>Loading...</div>);
		//}

		return (
			<MRModalContainer>
                <MRModalHeader>
                    <MRModalHeaderText>Committee Selection</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{ clear: "both" }}></div>
                    <MRModalSubHeaderText></MRModalSubHeaderText>
                    <div style={{ clear: "both" }} ref={el => { this.el = el; }}></div>
                </MRModalHeader>
				<MRModalBody>
					<div style={{float: "left", marginLeft: "2px", height: "50px", fontSize: "15px", backgroundColor: "#FFFFFF" , width: "100%", paddingTop: "8px", border: "1px solid #ffffff", borderRadius: "3px", boxShadow: "0 0 4px #c4c4c4"}}>On the table below, un(check) the boxes to choose which Committees should receive this email notification. </div>
					<div style={{clear: "both", marginTop: "20px"}}></div>
					<div style={{marginTop: "20px", float: "left", marginLeft: "2px", height: "50px", fontSize: "20px", backgroundColor: "#04ADA8" , width: "100%", paddingTop: "8px", border: "1px solid #ffffff", borderRadius: "3px", boxShadow: "0 0 4px #c4c4c4", color: "#FFFFFF"}}>&nbsp;Email Notifications </div>

					<div style={{clear: "both"}}></div>
					<CSLTable add={false} processData={this.tableData} headerText={''} tableRows="10" refreshCallback={this.refreshState} />
					<div style={{width: "100%", height: "2px", backgroundColor: "#EBEBEB", marginTop: "20px"}}></div>

					<div style={{width: "100%", height: "2px", backgroundColor: "#EBEBEB", marginTop: "20px"}}></div>
					<div style={{textAlign: "right", marginTop: "20px"}}>
						<CancelBtn onClick={this.closeModal}>Cancel</CancelBtn>
						<SubmitBtn style={{marginLeft: "15px"}} onClick={this.modifyEmailAccess}>Submit</SubmitBtn>
					</div>
				</MRModalBody>
				<AlertBox alertParam={this.state.alert_param} />
			</MRModalContainer>	
		);
	}
}

export default EmailConfigModal;