import React from 'react';
import styled from 'styled-components';
import Store from '../../../Common/Store.js';
import Utils from '../../../Common/Utils.js';
import Iframe from 'react-iframe'
import moment from 'moment'
import * as jsPDF from 'jspdf'


const marginX = 12;
const marginY = 12;
const pageTop = marginY
const maxX = 210 - (2 * marginX);
const maxY = 297 - (2 * marginY);
const levelVertGap = 8;
const levelHorzGap = 15;
const connectorLineOffset = 5;
const boxHeight = 45;
const boxWidth = 70;
const boxHeaderHeight = 6;
const boxBodyHeight = 22;
const boxFooterHeight = 8;
const footerPillWidth = (boxWidth / 4) - 4;
const footerPillGap = 2;
const boxRadius = 2;
const pageHeaderHeight = 10;
const effectivePageHeightP = Math.floor(maxY / (boxHeight + levelVertGap)) * (boxHeight + levelVertGap);
const effectivePageHeightL = Math.floor(maxX / (boxHeight + levelVertGap)) * (boxHeight + levelVertGap);
const connectorLineWidth = 1.25;
const pageHeaderLineWidth = 1;
const dottedLineLength = 8;

const PdfContainer = styled.div`
	position: absolute;
	width: calc(100vw - 325px);

	z-index: 1011;
    background-color: white;
`;
const FootButton = styled.div`
	padding: 10px 15px;
    float: right;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;

`;

class PdfGenerator extends React.Component
{
	state = {pdfURL: null, ready: false, gstruct: null, table_headers: null}

	reportPDF = null;
	pageWidth = maxX;
	pageHeight = maxY;
	currentY = pageTop;
	currentX = marginX;
	titleHeight = 10
	titleFontHeight = 8
	lineHeight = 9
	tableLineHeight = 4.6
	inLineHeight = 6
	tabWidth = 30
	indentWidth = 5
	cells = {}
	rows = []
	groupHeader = null
	pageBottom = marginY + maxY



	constructor(props) {
    	super(props);
    	this.scrollDiv = React.createRef();
  	}

  	initPage = () => {
  		this.currentX = marginX
  		this.currentY = pageTop
  	}

	componentDidMount()
	{
		this.raw_data = Store.getStoreData("pdf_data")
		this.gstruct = Store.getStoreData("gstruct")
		this.table_headers = Store.getStoreData("table_headers")
		this.preview_data = Store.getStoreData("preview_data")
		this.selections_gathered = Store.getStoreData("selections_gathered")
		this.loggedin_user = Store.getStoreData('loggedin_user_info')
		let new_sg = []
		if(this.selections_gathered !== null) {
			for(let sg of this.selections_gathered) {
				// // console.log('selections svvv', sg)
				if(!(sg.type === 'multiple_select' && sg.selection.length === 0) && !(sg.type !== 'multiple_select' && sg.selection === 'Choose')) {
					new_sg.push(sg)
				}
			}
			this.selections_gathered = new_sg.length > 0 ? new_sg : null
		}

		this.fields = {}
		this.groupby_fields = {}
		for(let th of this.raw_data.table_headers) {
			this.fields[th.accessor] = th
		}
		for(let th of this.raw_data.groupby.selections) {
			this.groupby_fields[th.accessor] = th
		}
		this.measureCellWidths()
		// console.log('this.preview_data', this.preview_data)
		this.flattenTree(this.preview_data)
		this.drawFirstPage()
		this.processRows()
		console.log('after processRows this.rows', this.rows)
		this.drawTable()
		
		// this.prepareData(this.data, raw_data, this.gstruct, 0)

		// // console.log('PdfGenerator this.preview_data', this.preview_data)
		// // console.log('PdfGenerator this.table_headers', this.table_headers)
		// console.log('PdfGenerator this.raw_data', this.raw_data)
		// // console.log('PdfGenerator this.selections_gathered', this.selections_gathered)
		// // console.log('PdfGenerator this.loggedin_user', this.loggedin_user)
		// console.log('PdfGenerator this.fields', this.fields)
		// // console.log('PdfGenerator this.rows', this.rows)
		// console.log('PdfGenerator this.cells', this.cells)
		// // console.log('PdfGenerator this.groupHeader', this.groupHeader)
		
		
		
		let blob = this.reportPDF.output('blob')
		let binaryData = [];
		binaryData.push(blob);
		let pdfURL = window.URL.createObjectURL(new Blob(binaryData, {type: "application/pdf"}));
		// // console.log('PdfGenerator this.scrollDiv', this.scrollDiv)
		
		this.setState({ready: true, pdfURL: pdfURL})
	}

	processRows = () => {
		let new_rows = []
		this.recording_header = false
		for(let r of this.rows) {
			if(!r._is_header){
				if(this.recording_header) {
					new_rows.push(JSON.parse(JSON.stringify(this.currentHeader)))
					this.recording_header = false
				}
				new_rows.push(r)
			} else {
				if(!this.recording_header) {
					this.currentHeader = {_is_header: true, _cells: [r]}
					this.recording_header = true
				} else {
					this.currentHeader._cells.push(r)
				}
			}
		}
		this.rows = JSON.parse(JSON.stringify(new_rows))
		for(let r of this.rows) {
			this.measureRow(r)
		}
		for(let r of this.rows) {
			if(r._is_header) {
				for(let c of r._cells) {
					if(this.groupHeader === null)this.groupHeader = []
					this.groupHeader.push(Utils.copyObject(c))
				}
				break
			}
		}
	}

	getCellTextWidth = (text) => {
		this.reportPDF.setFontSize(11)
		return this.reportPDF.getTextWidth(text)
	}

	splitCellContent = (line, width) => {
		let words = line.toString().split(' ')
		let lines = ['']
		let cur_line = 0
		let cur_left = 0
		for(let w of words) {
			let text_width = this.getCellTextWidth(w + ' ')
			if(cur_left + text_width > width) {
				lines.push(w + ' ')
				cur_line++
				cur_left = 0
			} else {
				cur_left += text_width
				lines[cur_line] += w + ' '
			}
		}
		return lines
	}

	measureCellHeight = (cell, cell_content) => {
		if(this.getCellTextWidth(cell) <= cell_content._width){cell_content._content = [cell.toString()]}
		else {cell_content._content = this.splitCellContent(cell, cell_content._width)}
		return this.tableLineHeight * cell_content._content.length
	}

	measureCells = (cells, cell_content) => {
		// // console.log('pdfGenerator this.cells', this.cells)
		// // console.log('pdfGenerator cells', cell_content)
		let row_height = this.inLineHeight
		for(let h in cells) {
			if(h in this.cells) {
				cell_content[h] = {}
				cell_content[h]._width = this.cells[h].width * this.pageWidth
				cell_content[h]._content = []
				let cell_height = this.measureCellHeight(cells[h], cell_content[h])
				row_height = cell_height > row_height ? cell_height : row_height
			}
		}
		return row_height
	}

	measureRow = (row) => {
		if(row._is_header) {
			row._height = this.tableLineHeight * row._cells.length
		} else {
			row._cell_content = {}
			row._height = this.measureCells(row._cells, row._cell_content)
		}
	}

	flattenTree = (bucket) => {
		// console.log('bucket', bucket)
		if('fieldname' in bucket) {
			let row = {}
			row._is_header = true
			row._level = bucket.level
			row._label = ''
			row._right_label = ''
			row._label = bucket.fieldvalue
			row._right_label = bucket.fieldname
			this.rows.push(row)
		}
		if(!bucket.is_grouping) {
			for(let r of bucket.data_rows) {
				let row = {}
				row._is_header = false
				row._level = bucket.level
				row._label = ''
				row._right_label = ''
				row._cells = r
				this.rows.push(row)
			}
		}
		// console.log('this.rows', this.rows)
		
		for(let b of bucket.buckets) {
			this.flattenTree(b)
		}
	}

	measureCellWidths = () => {
		this.cells = {}
		let total_width = 0
		for(let th of this.raw_data.table_headers) {
			if(!(th.accessor in this.groupby_fields)){
				total_width += this.fields[th.accessor].width
				this.cells[th.accessor] = th
			}
		}
		for(let th in this.cells) {
			this.cells[th].width = this.cells[th].width / total_width
		}
	}

	drawFirstPage = () => {
		this.reportPDF = new jsPDF({
									 orientation: 'portrait',
									 unit: 'mm',
									 format: 'a4',
									 putOnlyUsedFonts:true
									});

		this.drawTitleSection()
	}

	outText = (x, y, text, style) => {
		this.reportPDF.setFontStyle(style)
		this.reportPDF.text(text, x, y)
		let ret = this.reportPDF.getTextWidth(text)
		this.reportPDF.setFontStyle('normal')
		return ret
	}

	drawTableHeader = () => {
		this.currentY += this.lineHeight
		for(let t of this.table_headers) {
			if(t.accessor in this.cells) {
				let cell = this.cells[t.accessor]
				if(cell.width === 0)continue
				// // console.log('drawTableHeader cell, t', cell, t)
				// // console.log('drawTableHeader this.table_headers', this.table_headers)
				this.reportPDF.setDrawColor('#D9D9D9');
				this.reportPDF.setTextColor('#2C2F2B');
				this.reportPDF.setFontStyle('bold')
				this.reportPDF.setLineWidth(0.0);
				this.reportPDF.setFillColor('#D9D9D9');
				this.reportPDF.setFontSize(10);
				// // console.log('drawTableHeader params', this.currentX, this.currentY, this.pageWidth * cell.width, this.lineHeight)
				this.reportPDF.roundedRect(this.currentX, this.currentY, this.pageWidth * cell.width, this.lineHeight, 0, 0, "DF");
				this.reportPDF.text(cell.nickname,this.currentX + 2, this.currentY + 6)
				this.currentX += this.pageWidth * cell.width
			}
		}
		this.currentY += this.lineHeight
	}

	drawTableRow = (r, new_page) => {
		this.currentX = marginX
		this.reportPDF.setTextColor('#1E3E62')
		this.reportPDF.setFontStyle('normal')
		this.reportPDF.setFontSize(8);
		this.reportPDF.setDrawColor('#FFFFFF');
		this.reportPDF.setLineWidth(1.5);
		this.reportPDF.setFillColor('#FFFFFF');
		// // console.log('drawTableHeader params', this.currentX, this.currentY)
		this.reportPDF.roundedRect(this.currentX, this.currentY, this.pageWidth, r._height, 0, 0, "DF");
		// console.log('pdfGenerator row', r)
		// console.log('pdfGenerator this.groupby_fields', this.groupby_fields)
		// console.log('pdfGenerator this.cells', this.cells)
		if(r._is_header && new_page) {
			let page_header = {_cells: this.groupHeader}
			this.drawGroupHeader(page_header)
			return
		}
		for(let t of this.table_headers) {
			if(!(t.accessor in r._cell_content) && !(t.accessor in this.groupby_fields)) {
				r._cell_content[t.accessor] = {}
				r._cell_content[t.accessor]._content = ['N/A']
				r._cell_content[t.accessor]._width = this.pageWidth * this.cells[t.accessor].width
			}
			if(t.accessor in r._cell_content) {
				let cell = r._cell_content[t.accessor]
				if(cell._width === 0)continue
				this.reportPDF.setDrawColor('#ffffff');
				this.reportPDF.setTextColor('#1E3E62');
				this.reportPDF.setLineWidth(0.2);
				this.reportPDF.setFillColor('#FFFFFF');
				this.reportPDF.setFontSize(8);
				// // console.log('drawTableHeader params', this.currentX, this.currentY, this.pageWidth * cell._width, r._height)
				this.reportPDF.roundedRect(this.currentX, this.currentY, cell._width, r._height, 0, 0, "DF");
				let iter = 0
				for(let c of cell._content) {
					this.reportPDF.text(c,this.currentX, this.currentY + iter*this.tableLineHeight + 4)
					iter++
				}
				this.currentX += cell._width
			}
		}
		// this.outText(this.currentX + 3, this.currentY + 5,'normal row', 'normal')
		this.currentY += r._height + 1.2
	}

	addNewPage = (r) => {
		this.reportPDF.addPage('a4','portrait');
		this.currentY = marginY
		this.currentX = marginX
		this.drawTableHeader()
		let page_header = {_cells: this.groupHeader}
		if(r._is_header) {
			// page_header[r._level - 1] = Utils.copyObject(r)
			console.log('_is_header and new page r, page_header', JSON.parse(JSON.stringify(r)), JSON.parse(JSON.stringify(page_header)))

			for(let c of r._cells) {
				page_header._cells[c._level - 1] = Utils.copyObject(c)
			}
			// page_header = r
		}
		this.drawGroupHeader(page_header, true)
	}

	drawTable = () => {
		this.drawTableHeader()
		let new_page = false
		for(let r of this.rows) {

			if(this.currentY + r._height > this.pageBottom) {
				this.addNewPage(r)
				new_page = true
			}
			// console.log('pdfGeneratorGrouping row, r, new_page', r, new_page)
			if(r._is_header){
				if(!new_page){
					// console.log('pdfGeneratorGrouping groupheader inside page r', r)
					this.drawGroupHeader(r, new_page)
				}
				
			} else {
				this.drawTableRow(r, new_page)
			}
			new_page = false
		}
	}

	drawGroupHeader = (r, new_page) => {
		// this.currentY += this.lineHeight
		// console.log('pdfGeneratorGrouping this.groupHeader, r', this.groupHeader, r)
		if(this.groupHeader === null)return
		for(let c of r._cells) {
			this.groupHeader[c._level - 1] = Utils.copyObject(c)
			this.currentX = marginX
			this.reportPDF.setTextColor('#2C2F2B')
			this.reportPDF.setFontStyle('bold')
			this.reportPDF.setFontSize(10);
			this.reportPDF.setDrawColor('#ffffff');
			this.reportPDF.setFillColor('#EEF0F1');

			// if(!new_page){
				this.reportPDF.roundedRect(this.currentX, this.currentY, this.pageWidth, this.inLineHeight, 0, 0, "DF");
				this.outText(this.currentX + (c._level - 1) * this.indentWidth + .5, this.currentY + 4,c._label, 'bold')
				let right_label = 'Grouped by: ' + c._right_label
				let right_text_width = this.reportPDF.getTextWidth(right_label)
				this.outText(this.pageWidth + marginX - right_text_width - 4, this.currentY + 4,right_label, 'bold')
				this.currentY += this.inLineHeight
			// }
		}
	}

	drawTitleSection = () => {
		// this.reportPDF.
		let contact_name = this.loggedin_user.ContactName
		let today = moment()
		let date = today.format('DD-MM-YYYY')
		let time = today.format('hh:mm a')
		let reporter_string = 'This report was produced by ' + contact_name + ' at ' + time + ' on ' + date
		// // console.log('pdfGenerator reporter_string', reporter_string)
		this.reportPDF.setDrawColor('#ffffff');
		this.reportPDF.setTextColor('#FDFDFD');
		this.reportPDF.setLineWidth(0.25);
		this.reportPDF.setFillColor('#37ADA7');
		this.reportPDF.setFontSize(18);
		this.reportPDF.roundedRect(this.currentX, this.currentY, this.pageWidth, this.titleHeight, 2, 2, "DF");
		this.reportPDF.setFontStyle('bold')
		this.reportPDF.text(this.raw_data.module.display + ' Report',this.currentX + 2, this.currentY + 7);
		this.reportPDF.setFontStyle('normal')
		this.currentY += this.titleHeight + this.lineHeight
		this.reportPDF.setFontSize(12);
		this.reportPDF.setTextColor('#1E3E62');
		this.currentX += this.outText(this.currentX, this.currentY,'This report was produced by ', 'normal')
		this.currentX += this.outText(this.currentX, this.currentY,' ' + contact_name + ' ', 'bold')
		this.currentX += this.outText(this.currentX, this.currentY,'at', 'normal')
		this.currentX += this.outText(this.currentX, this.currentY,' ' + time + ' ', 'bold')
		this.currentX += this.outText(this.currentX, this.currentY,'on', 'normal')
		this.currentX += this.outText(this.currentX, this.currentY,' ' + date + '.', 'bold')
		this.currentX = marginX
		this.currentY += this.lineHeight
		this.selections_gathered = []
		for(let fid in this.props.filters) {
			let f = this.props.filters[fid]
			if(Object.keys(f.selections).length > 0) {
				this.selections_gathered.push(f)
			}
		}
		// console.log('pdfGeneratorTitle this.selections_gathered', this.selections_gathered)
		let filter_header = this.selections_gathered.length === 0 ? 'No filters were applied.' : 'The following filters were applied:'
		this.currentX += this.outText(this.currentX, this.currentY,filter_header, 'normal')
		this.currentX = marginX
		this.currentY += this.selections_gathered.length === 0 ? this.lineHeight : this.inLineHeight
		let filter_text = ''
		let iter = 0
		let sort_fields = {}
		let groupby_fields = {}
		for(let sb of this.raw_data.sortby.fields) {
			sort_fields[sb.accessor] = sb.nickname
		}
		for(let gb of this.raw_data.groupby.fields) {
			groupby_fields[gb.accessor] = gb.nickname
		}
		// console.log('pdfGeneratorTitle this.raw_data.groupby', this.raw_data.groupby)
		if(this.selections_gathered.length > 0){
			this.reportPDF.setFontSize(10);
			for(let sg of this.selections_gathered) {
				filter_text = sg.nickname + ': '
				this.outText(this.currentX + this.indentWidth, this.currentY,filter_text, 'normal')
				this.currentX += this.tabWidth + 3.5
				filter_text = ''
				let i = 0
				if(sg.type === 'multi' || sg.type === 'single' ) {
					for(let sel in sg.selections) {
						filter_text = sel + (i < Object.keys(sg.selections).length - 1 ? ', ' : '')
						i++
						let text_width = this.reportPDF.getTextWidth(filter_text)
						if(this.currentX + text_width > this.pageWidth) {
							this.currentY += this.inLineHeight
							this.currentX = this.tabWidth + marginX
						}
						this.currentX += this.outText(this.currentX, this.currentY,filter_text, 'normal')
					}
				} else if(sg.type === 'date_range') {
					filter_text = ` From ${sg.selections.from_date} To ${sg.selections.to_date}`
					this.currentX += this.outText(this.currentX, this.currentY,filter_text, 'normal')
				} else {
					filter_text += sg.selections.text
					this.currentX += this.outText(this.currentX, this.currentY,filter_text, 'normal')
				}
				// this.currentX += this.outText(this.currentX, this.currentY,filter_text, 'normal')
				this.currentX = marginX
				this.currentY += iter < this.selections_gathered.length - 1 ? this.inLineHeight : this.lineHeight
				iter++
			}
			this.reportPDF.setFontSize(12);
		}

		filter_header = this.raw_data.groupby.selections.length === 0 ? 'The report was not grouped by any fields.' : 'The report was grouped by:'
		this.outText(this.currentX, this.currentY,filter_header, 'normal')
		this.currentX = this.raw_data.groupby.selections.length === 0 ? marginX : marginX+this.indentWidth
		this.currentY += this.raw_data.groupby.selections.length > 0 ? this.inLineHeight : this.lineHeight
		iter = 0
		this.reportPDF.setFontSize(10);
		for(let s of this.raw_data.groupby.selections) {
			filter_text = groupby_fields[s.accessor] + ' in ' + (s.sort_order === 'asc' ? 'Ascending order.' : 'Descending order.')
			this.outText(this.currentX, this.currentY,filter_text, 'normal')
			this.currentY += iter < this.raw_data.groupby.selections.length - 1 ? this.inLineHeight : this.lineHeight
			this.currentX = iter < this.raw_data.groupby.selections.length - 1 ? marginX + this.indentWidth : marginX
			iter++
		}
		this.reportPDF.setFontSize(12);
		
		
		let active_pills = []
		/*if(this.props.pills.All.is_active === 1) {
			active_pills.push('All Statuses')
		} else {
			for(let pil in this.props.pills) {
				if(this.props.pills[pil].is_active === 1)active_pills.push(this.props.pills[pil].display)
			}
		}*/

		if(active_pills.length > 0) {
			this.reportPDF.setFontSize(12);
			this.outText(this.currentX, this.currentY,'The Report includes the following statuses:', 'normal')
			this.currentY += this.inLineHeight
			for(let s of active_pills) {
				this.reportPDF.setFontSize(10);
				this.outText(this.currentX + this.indentWidth, this.currentY,s, 'normal')
				this.currentY += this.inLineHeight
			}
		} else {
			this.reportPDF.setFontSize(12);
			this.outText(this.currentX, this.currentY,'The Report does not include any statuses.', 'normal')
			this.currentY += this.inLineHeight
		}
		// this.currentY += this.lineHeight
		this.reportPDF.setFontSize(12);
		
		
		let field_name = this.raw_data.sortby.selected.accessor !== 'none' ? sort_fields[this.raw_data.sortby.selected.accessor] : ''
		let sort_order = this.raw_data.sortby.selected.order === 'Ascending' ? 'Ascending' : 'Descending'
		filter_header = this.raw_data.sortby.selected.accessor === 'none' ? `The report rows were not sorted.` : `The report rows were sorted by ${field_name} in ${sort_order} order.`
		this.outText(this.currentX, this.currentY,filter_header, 'normal')
	}

	render()
	{
		if(!this.state.ready)return(<div></div>)

		return (
			<PdfContainer ref={this.scrollDiv} style={{position: 'fixed', top: 64}}>
				<div style={{backgroundColor: 'white'}}>
					<FootButton style={{backgroundColor: "#04ADA8", color: "#ffffff", marginRight: "10px"}} onClick={this.props.toggleGenerate}>Close</FootButton>
				</div>
				<Iframe url={this.state.pdfURL} width="100%" height="800px" type="application/pdf" />
			</PdfContainer>
		);
	}

	// scrollToMyRef = () => window.scrollTo(0, this.scrollDiv.current.offsetTop)  
	scrollToMyRef = () => this.scrollDiv.current.scrollIntoView({ behavior: 'smooth' });
}

export default PdfGenerator;

				// <button onClick={() => this.props.toggleGenerate()}>Close</button>