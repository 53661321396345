import React from 'react';
import styled from 'styled-components';
import { TiArrowSortedDown } from "react-icons/ti";
import { FaEye } from "react-icons/fa";
import { FaPen } from "react-icons/fa";
import Store from '../../Common/Store';
import MeetingListTable from '../../Components/MeetingList/Meetings';
// import MeetingListTable from '../../Components/MeetingList/MeetingListTable';

const ScheduleMeetingButton = styled.button`
    background-color: #04ADA8;
    color: #ffffff; 
    border-radius: 5px;
    border: 1px solid #04ADA8;
    padding: 3px 18px;
    background-image: linear-gradient(to bottom, #04ADA8 50%, #04ADA8);
    float:right;
    clear:both;
`;
const ViewDiv = styled.div`
    box-sizing: border-box;
    cursor:pointer;
`;
const MRModalDivider = styled.div`
    background-color: #EDF1F1;
    height:1px;
    margin-top: 15px;
    margin-bottom: 15px;
	width: 100%;
`;
class MeetingList extends React.Component {

    constructor(props) {
        super(props);

        this.state={

        }
    }



	AddModal = (modal_name) => {
        this.props.AddModal(modal_name);  
	}
    render() {
		console.log("in MeetingList render==>",this.props.filterSearchJson);
		let time = new Date().getTime();
        return (
            <div style={{ marginTop: "2px" }}>
                <MeetingListTable prop_task_id={this.props.prop_task_id} prop_meeting_id={this.props.prop_meeting_id} filterSearchJson={this.props.filterSearchJson} passMeetingTabRefreshInstruction={this.props.passMeetingTabRefreshInstruction} passCommitteIdForAdvancedSearch={this.props.passCommitteIdForAdvancedSearch} AddModal={this.AddModal} />
            </div>
        );
    }
}

export default MeetingList;