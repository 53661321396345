import React, { useState } from 'react';
import styled from 'styled-components';
import { FiEdit, FiChevronUp, FiChevronDown , FiChevronRight, FiChevronLeft , FiUndo } from "react-icons/fi";
import { FaUndo } from "react-icons/fa";
import Store from '../../Common/Store'
//import CSLLoader from '../Common/CSLLoader';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: option => option.title,
});

const useStyles = makeStyles((theme) => ({
  label: {
    display: 'block',
  },
  input: {
    width: 200,
	fontFamily: 'Montserrat,sans-serif',
  },
  listbox: {
    width: 300,
    margin: 0,
    padding: 3,
    zIndex: 1500,
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    maxHeight: 400,
    border: '1px solid rgba(0,0,0,.25)',
    '& li[data-focus="true"]': {
      backgroundColor: '#4a8df6',
      color: 'white',
      cursor: 'pointer',
    },
    '& li:active': {
      backgroundColor: '#2977f5',
      color: 'white',
    },
  },
  optionclass:{
	  padding: 6
  }
}));




const GCPanelBody = styled.div`
  background-color: rgb(232, 240, 247);
  width: calc(100% - 450px);
  padding: 0px 0px 0px 0px;
  margin-top: 10px;
  margin-left: 5px;
  float: left;
`;
const GCPanelArrow = styled.div`
  background-color: rgb(232, 240, 247);
  width: 20px;
  padding: 0px 0px 0px 0px;
  margin-top: 5px;
  float: left;
  cursor: pointer;
`;

const GCPillsContainer = styled.div`
  background-color: rgb(232, 240, 247);
  width: calc(100% - 420px);
  height: 20px;
  padding: 0px 0px 0px 0px;
  margin-top: 2px;
  float: left;
  overflow: hidden;
  white-space: nowrap;
`;

const ParentPill = styled.div`
	width: 120px;
	padding: 0px 5px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	float: left;
	font-weight: 400;
	font-size: 17px;
	color: #7ACAC9;
	cursor: pointer;
`
const ParentPillActive = styled.div`
	width: 120px;
	padding: 0px 5px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	float: left;
	font-weight: 700;
	font-size: 17px;
	color: #435870;
`

const GCPill = styled.div`
	width: 120px;
	padding: 0px 5px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	float: left;
	font-weight: 400;
	font-size: 15px;
	color: #7ACAC9;
	cursor: pointer;
`
const GCPillActive = styled.div`
	width: 120px;
	padding: 0px 5px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	float: left;
	font-weight: 700;
	font-size: 15px;
	color: #435870;
	cursor: pointer;
`
////
const RadioBlock = styled.div`
	/*margin-top: 10px;*/
`;
const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;

function UseAutocomplete(props) {
  
  const [values, setValues] = useState();
  const classes = useStyles();
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: 'use-autocomplete-companies',
    options: props.companies,
    getOptionLabel: (option) => option.name,
	onChange: props.onChange,
	inputValue : props.inputValue,
	// filterOptions: {filterOptions}
  });

  return (
    <div style={{float: 'left', width: 180 }}>
      <div {...getRootProps()}>
		  {/*<label className={classes.label} {...getInputLabelProps()}>
          useAutocomplete
        </label>
		  */}
        <input className={classes.input} {...getInputProps()} placeholder={props.placeholder}/>
      </div>
      {groupedOptions.length > 0 ? (
        <ul className={classes.listbox} {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li className={classes.optionclass} {...getOptionProps({ option, index })}>{option.name}</li>
          ))}
        </ul>
      ) : null}
    </div>
  );
}

class GCPanel extends React.Component {
	state = {company: null, gc_list: null, current_gcid: 0, selected_id: 0, offset: 0 , filtered_gc_list_assoc : {} , gc_companies : [] , company_display_option: "highlight" , auto_complete_input_value: ''}

	setComponentState = (gc_companies) => {
		let gc_list = [];
    let company = {};
		let filtered_gc_list_assoc = this.state.filtered_gc_list_assoc;
		let parent_company_name = Store.getStoreData('company_name');
		let comittee_gcs = Store.getStoreData('comittee_gcs')
		gc_list.push({company_name: parent_company_name, name: parent_company_name, id: 0, is_gc: false });
		filtered_gc_list_assoc['0'] = ('0' in filtered_gc_list_assoc) ? filtered_gc_list_assoc['0'] : 0;
		for(let gc in gc_companies) {
			if(gc_companies[gc].is_active === 0){
				if(gc_companies[gc].id in comittee_gcs){
					filtered_gc_list_assoc[gc_companies[gc].id] = (gc_companies[gc].id in filtered_gc_list_assoc) ? filtered_gc_list_assoc[gc_companies[gc].id] : 0;
					gc_list.push({company_name: gc_companies[gc].company_name, name: gc_companies[gc].company_name, id: gc_companies[gc].id, is_gc: true });
				}
			}else{
				filtered_gc_list_assoc[gc_companies[gc].id] = (gc_companies[gc].id in filtered_gc_list_assoc) ? filtered_gc_list_assoc[gc_companies[gc].id] : 0;
				gc_list.push({company_name: gc_companies[gc].company_name, name: gc_companies[gc].company_name, id: gc_companies[gc].id, is_gc: true });
			}

		}

		

        //let filtered_gc_list_assoc = gc_list;
        console.log('GCPanel company, current_gcid', gc_companies,  comittee_gcs)
        this.setState({gc_companies , company, gc_list, filtered_gc_list_assoc , current_gcid: this.props.currentGCid, offset: 0 })
	}

	componentDidMount = () => {
		let gc_companies = Store.getStoreData('companies_assoc');
		/*let filtered_gc_list_assoc = this.state.filtered_gc_list_assoc;
		let gc_list = [];
		for(let gc in gc_companies) {
			filtered_gc_list_assoc[gc_companies[gc].id] = 1;
			gc_list.push({company_name: gc_companies[gc].company_name, name: gc_companies[gc].company_name, id: gc_companies[gc].id, is_gc: true });
		}
		filtered_gc_list_assoc['0'] = 1;
		this.setState({gc_companies , gc_list, filtered_gc_list_assoc });
		*/
		this.setComponentState(gc_companies);
	
	}

	componentDidUpdate = (prevprops) => {
		if(this.props === prevprops)return
        let gc_companies = this.state.gc_companies;
		this.setComponentState(gc_companies);
	}

    nameCompare = (a,b) => {
        return a.name.localeCompare(b.name)
    }
	switchRadioDisplayOption = (key , val) => (event) => {
		event.preventDefault();
		//let module_configs = this.props.module_configs;
		//let {general} = this.props.module_configs;
		//general[key] = val;
		//module_configs.general =general

        
		/*let filtered_gc_list_assoc = {};
		let original_gc_list = (typeof this.state.original_gc_list !== 'undefined') ? this.state.original_gc_list : this.state.gc_list;
        original_gc_list.map((gc, index) => {
			filtered_gc_list_assoc[gc.id] = 0;
		});*/

		this.props.changeGC({filtered_gc_list_assoc : this.state.filtered_gc_list_assoc, company_display_option: val});
		this.setState({company_display_option: val});
	}
    onSearchCompany = (event, values) => {
    	event.preventDefault()
    	//console.log('onSearchCompany values', values)
    	let selected_id = values === null ? 0 : values.id
        let original_gc_list = this.state.gc_list;
		let updated_gc_list = [];
		let auto_complete_input_value = '';
		let filtered_gc_list_assoc = this.state.filtered_gc_list_assoc;
        if(values !== null){
			for(let gc of original_gc_list) {
				if(gc.id === values.id){
					updated_gc_list.push(gc);
					break;
				}
			}			
        }else{
			updated_gc_list = original_gc_list;
        }
		updated_gc_list.map((gc, index) => {
			if(values !== null && parseInt(gc.id) === parseInt(values.id)) {
				filtered_gc_list_assoc[gc.id] = (filtered_gc_list_assoc[gc.id] + 1) % 2;
				auto_complete_input_value = gc.company_name;
			}else{
				filtered_gc_list_assoc[gc.id] = 0;
			}
		});

    	this.props.changeGC({filtered_gc_list_assoc : filtered_gc_list_assoc, company_display_option: this.state.company_display_option});
    	this.setState({gc_list : updated_gc_list , original_gc_list : original_gc_list , offset: 0 , filtered_gc_list_assoc ,auto_complete_input_value: auto_complete_input_value})
    }

    changeGC = (gc_id) => {
    	
		let updated_gcs = [];
		let filtered_gc_list_assoc = this.state.filtered_gc_list_assoc;
		//console.log("filtered_gc_list_assoc=>",filtered_gc_list_assoc);
		this.state.gc_list.map((gc, index) => {
			if(parseInt(gc.id) === parseInt(gc_id)) {
				filtered_gc_list_assoc[gc.id] = (filtered_gc_list_assoc[gc.id] + 1) % 2;
			}
		});
		this.props.changeGC({filtered_gc_list_assoc: filtered_gc_list_assoc , company_display_option: this.state.company_display_option});
    	this.setState({current_gcid: gc_id , filtered_gc_list_assoc : filtered_gc_list_assoc })
    }

    undoGC  = (event) => {
		event.preventDefault();

		//console.log("in here undoGC");
		let filtered_gc_list_assoc = {};
		let original_gc_list = (typeof this.state.original_gc_list !== 'undefined') ? this.state.original_gc_list : this.state.gc_list;
        original_gc_list.map((gc, index) => {
			filtered_gc_list_assoc[gc.id] = 0;
		});
		this.props.changeGC({filtered_gc_list_assoc: filtered_gc_list_assoc , company_display_option: 'undo_gcs'});
		this.setState({gc_list : original_gc_list , filtered_gc_list_assoc : filtered_gc_list_assoc,auto_complete_input_value: ''});
    }

    scrollPanel = (dir) => {
    	let len = this.state.gc_list.length
    	let offset = this.state.offset
    	switch(dir) {
    		case 'left': if(offset - 2 >= 0){
    						offset -= 2;
    					} else {
    						offset = 0
    					}
    					break;
    		case 'right': if(offset + 2 < len){
    						offset += 2;
    					} else {
    						offset = len - 2
    					}
    					break;
    	}
    	this.setState({offset})
    }

	render() {
		console.log('GCPanel state', this.state)
		if(this.state.company === null || !this.props.display)return <div></div>
		let radioTop = 0;
		return (
			<div>

			<GCPanelBody>
			{
				(() => {
					//if(this.state.current_gcid === 0) return <ParentPillActive title={this.state.company.company_name}>{this.state.company.company_name}</ParentPillActive>
					//else return <ParentPill onClick={() => this.changeGC(0)} title={this.state.company.company_name}>{this.state.company.company_name}</ParentPill>
				})()
			}
			

			<GCPanelArrow onClick={() => this.scrollPanel('left')}><FiChevronLeft /></GCPanelArrow>
			<GCPillsContainer>
			{
				this.state.gc_list.map((gc, index) => {
					if((this.state.selected_id === 0 || gc.id === this.state.selected_id) && index >= this.state.offset) {
						let unselect_str = 'click again to unselect ' + gc.name;
						if(this.state.filtered_gc_list_assoc[gc.id] === 1) return <GCPillActive key={index} title={unselect_str} onClick={() => this.changeGC(gc.id)}>{gc.name}</GCPillActive>
						else return <GCPill key={index} title={gc.name} onClick={() => this.changeGC(gc.id)}>{gc.name}</GCPill>
					}
					
				})
			}
			</GCPillsContainer>
			<GCPanelArrow onClick={() => this.scrollPanel('right')}><FiChevronRight /></GCPanelArrow>
			<div style={{marginTop: "3px"}}>
				<UseAutocomplete  companies={this.state.gc_list} onChange={this.onSearchCompany} placeholder="Search Companies" inputValue={this.state.auto_complete_input_value}/>
			</div>
			{
				(() => {
					let selected_gcs = [];
					Object.entries(this.state.filtered_gc_list_assoc).forEach(([key, value]) => {
						if(value === 1) selected_gcs.push(key);
					})
                    if(selected_gcs.length > 0){
						radioTop = -19;
						return (
							<div>
							<FaUndo style={{backgroundColor: "rgb(232, 240, 247)" , cursor: "pointer", marginLeft : "32px" , marginTop : "3px" }} onClick={this.undoGC} title="undo all clicked companies" />
							</div>
						)
                    }						
				})()
			}
			<div style={{marginTop: radioTop , float: "right"  }}>
			{
				(() => {
					if (Store.getStoreData('parent_has_group_companies') === true) {
						if (this.state.company_display_option === 'highlight') {
							return (
								<RadioBlock>
									<SelectedRadio id="radio_highlight" title="Highlight committees related to chosen companies">Highlight</SelectedRadio>
									<UnselectedRadio id="radio_select" onClick={this.switchRadioDisplayOption('company_display_option' , 'select')} title="Only show committees for chosen companies">Select</UnselectedRadio>
								</RadioBlock>
							);
						} else if(this.state.company_display_option === 'select') {
							return (
								<RadioBlock>
									<UnselectedRadio onClick={this.switchRadioDisplayOption('company_display_option' , 'highlight')} title="Highlight committees related to chosen companies">Highlight</UnselectedRadio>
									<SelectedRadio title="Only show committees for chosen companies">Select</SelectedRadio>
								</RadioBlock>
							);
						}
					}
				})()
			}
			</div>


			<div style={{clear: 'both'}} />
		</GCPanelBody>

		</div>
		)
	}
}

export default GCPanel;