import React from 'react';
import styled from 'styled-components';
import CSLTable from '../Common/CSLTable';
import { FaEdit,FaPencilAlt, FaTimes,FaPlusCircle } from "react-icons/fa";
import RemovalConfigModal from './RemovalConfigModal';
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const GeneralContainer = styled.div`
	width: 90%;
`;
const GeneralInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const GeneralLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 20px;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
const SearchInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
`;
const EmailContainer = styled.div`
	width: 100%;
`;
const EmailInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 18px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
class General extends React.Component
{
	/*state = {general: null,reason_for_removal: null,reason_for_removal_search: null, 
		show_reason_for_removal_array: null, show_removal_config_modal: false,curid: 0,content: null,
		showActiveCheckbox: false, reasonActiveArrayAll: null,reasonInactiveArrayAll: null};*/

	state = {general: null, general : null , chair_is_a_required_field : true};

	componentDidMount()
	{		
		this.setState({general: this.props.general});
			//showActiveCheckbox: this.props.general.reason_active_inactive,
			//reason_for_removal: this.props.reason_for_removal,
			//show_reason_for_removal_array: reasonValue,
			//reason_for_removal_search: this.props.reason_for_removal,
			//reasonActiveArrayAll: reasonActiveArrayAll,
			//reasonInactiveArrayAll: reasonInactiveArrayAll});
		//console.log("reason_for_removal===>",this.props.reason_for_removal);
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {

			this.setState({general: this.props.general});
				//showActiveCheckbox: this.props.general.reason_active_inactive,
				//reason_for_removal: this.props.reason_for_removal,
				//show_reason_for_removal_array: reasonValue,
				//reason_for_removal_search: this.props.reason_for_removal,
				//reasonActiveArrayAll: reasonActiveArrayAll,
				//reasonInactiveArrayAll: reasonInactiveArrayAll});
			//console.log("reason_for_removal===>",this.props.reason_for_removal);
			// this.setState({general: this.props.general,reason_for_removal: this.props.reason_for_removal, reason_for_removal_search: this.props.reason_for_removal});
		}
	}

	switchRadio = (key) => (event) => {
		event.preventDefault();
		let {general} = this.state;
		general[key] = general[key] ? false : true;
		this.props.updateGeneral(general);
	}



	openModal = (id) => (event) => {
		event.preventDefault();     
		console.log("id",id); 
		const content = this.state.reason_for_removal.find((item) => {
			return item.reasonID === id;
		})
		this.setState({content,curid: id,show_reason_config_modal: true});        
	}
	
	closeModal = () => {		
		this.setState({show_reason_config_modal: false})
	}


	handleActiveInactive = (value) => (event) => {
        //event.preventDefault();
        console.log("value",value)  
		let data = value === 1 ? true : false;
		this.props.changeActiveInactive(data);		       
    }

	render()
	{
		if (this.state.general === null) {
			return (<div>Loading...</div>);
		}

		return (
			<div>
			{
				(() => {
					if (this.state.show_reason_config_modal) {
						return (<RemovalConfigModal closeModal={this.closeModal} curid={this.state.curid} content={JSON.parse(JSON.stringify(this.state.content))} updateReasonValue={this.modifyReason} saveReasonValue={this.saveReasonDate}/>);
					}
				})()
			}
			<GeneralContainer>
				<GeneralInnerContainer>
					<GeneralLabel>Is a Chair required for each committee?</GeneralLabel>
					{
						(() => {
							if (this.state.general.chair_is_a_required_field) {
								return (
									<RadioBlock>
										<SelectedRadio>Yes</SelectedRadio>
										<UnselectedRadio onClick={this.switchRadio('chair_is_a_required_field')}>No</UnselectedRadio>
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio onClick={this.switchRadio('chair_is_a_required_field')}>Yes</UnselectedRadio>
										<SelectedRadio>No</SelectedRadio>
									</RadioBlock>
								);
							}
						})()
					}
					<GeneralLabel>Allow meetings to be completed with outstanding tasks?</GeneralLabel>
					{
						(() => {
							if (this.state.general.allow_completion_of_meeting_where_tasks_are_outstanding) {
								return (
									<RadioBlock>
										<SelectedRadio>Yes</SelectedRadio>
										<UnselectedRadio onClick={this.switchRadio('allow_completion_of_meeting_where_tasks_are_outstanding')}>No</UnselectedRadio>
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio onClick={this.switchRadio('allow_completion_of_meeting_where_tasks_are_outstanding')}>Yes</UnselectedRadio>
										<SelectedRadio>No</SelectedRadio>
									</RadioBlock>
								);
							}
						})()
					}
					<GeneralLabel>Carry over follow-up tasks/actions to the next scheduled meeting?</GeneralLabel>
					{
						(() => {
							if (this.state.general.carry_over_tasks_to_next_scheduled_meeting) {
								return (
									<RadioBlock>
										<SelectedRadio>Yes</SelectedRadio>
										<UnselectedRadio onClick={this.switchRadio('carry_over_tasks_to_next_scheduled_meeting')}>No</UnselectedRadio>
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio onClick={this.switchRadio('carry_over_tasks_to_next_scheduled_meeting')}>Yes</UnselectedRadio>
										<SelectedRadio>No</SelectedRadio>
									</RadioBlock>
								);
							}
						})()
					}
					<GeneralLabel>Track member responses to meeting invites</GeneralLabel>
					{
						(() => {
							if (this.state.general.track_member_responses_to_meeting_invitees) {
								return (
									<RadioBlock>
										<SelectedRadio>Yes</SelectedRadio>
										<UnselectedRadio onClick={this.switchRadio('track_member_responses_to_meeting_invitees')}>No</UnselectedRadio>
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio onClick={this.switchRadio('track_member_responses_to_meeting_invitees')}>Yes</UnselectedRadio>
										<SelectedRadio>No</SelectedRadio>
									</RadioBlock>
								);
							}
						})()
					}						
				</GeneralInnerContainer>

				{/*
					(() => {
						if (this.state.general.require_reason_for_removal) {
							return(
							<div>
								<div>
									<SearchInput style={{width: "98.5%",marginTop: "15px"}} placeholder="Search..." onChange={this.searchValue} />
								</div>
								<MRModalLabel>Show Inactive?</MRModalLabel>
								{
									(() => {
										if(!this.state.showActiveCheckbox){
											return(<div  style={{cursor: "pointer", fontSize:"21px",display: "inline-block",marginLeft: "5px"}} onClick={this.handleActiveInactive(1)} ><IoIosSquareOutline /></div>)
										}else{
											return(<div  style={{cursor: "pointer", fontSize:"21px",display: "inline-block",marginLeft: "5px"}} onClick={this.handleActiveInactive(0)} ><IoIosCheckboxOutline /></div>)
										}
									})()
								}
								<EmailContainer>
									<EmailInnerContainer>
										<div style={{display: "flow-root"}}>
											<div style={{float: "left"}}>
												<EmailBoldLabel style={{display: "inline-block"}}>Configure Options</EmailBoldLabel>
											</div>
											<div onClick={this.addReasonConfigModal} style={{float: "right", cursor: "pointer",fontSize: "17px",color: "#04ADA8"}}>
												<FaPlusCircle />
											</div>	
										</div>									
										<CSLTable add={false} processData={this.tableData} headerText={''} tableRows="8" refreshCallback={this.refreshState} />
									</EmailInnerContainer>
								</EmailContainer>
							</div>
							);
						}
					})()
				*/}				
			</GeneralContainer>
			</div>
		);
	}
}

export default General;