import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Store from '../../Common/Store.js';
import APICall from '../../Common/APICall.js';
import { FaAngleRight, FaTrash } from 'react-icons/fa';
import { FaTimes, FaPlusCircle } from 'react-icons/fa';
import { FaEye, FaPen } from "react-icons/fa";
import { MdDelete } from 'react-icons/md';
import Utils from '../../Common/Utils.js';
import CSLTable from '../Common/CSLTable';
import AddCommitteeMembers from '../Tabs/AddCommitteeMembers';
import AddActions from '../Tabs/AddActions';
import CommitteeUtils from '../Common/CommitteeUtils.js';
import AlertBox from '../Common/AlertBox.js';
import ExcelGenerator from '../EXCEL/ExcelGenerator.js';
import CSLLoaderNonPromise from '../Common/CSLLoaderNonPromise';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';


const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';



const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    width: 94%;
    left: 3%;
`;

const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 22px;
    color: #a0a0a0;
    cursor: pointer;
`;

const MRModalInput = styled.input`
    height: 26px;
    width: 84%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    margin-top: 15px;
    font-family: Montserrat,sans-serif
`;

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
const CatBtn = styled.button`
    border-radius: 4px;
    padding: 13px 37px;
    color: #ffffff;
    cursor: pointer;
`;
const RiskSelect = styled.select`
    margin-top: 15px;
    box-sizing: border-box;
    height: 35px;
    width: 86%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
	font-weight: bold;
`;
const RiskBtn = styled.button`
  background-color: #213E61;
  border: 1px solid #213E61;
  border-radius: 4px;
  padding: 9px 37px;
  color: #ffffff;
  cursor: pointer;
`;

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1200px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  z-index: 1002;
  opacity: 0.8;
`;
const ViewDiv = styled.div`
    box-sizing: border-box;
    cursor:pointer;
    position:relative;
    margin-right:3px;
`;

class MeetingTaskListModal extends React.Component {

	state = {
		committe_name: "",
		meeting_date: null,
		alert_param: null,
		tasks: [],
		open_action_modal: false,
		action_extra_json: {},
		action_task_id: 0,
		download_excel: false,
		xls_data: {},
	};

	componentDidMount() {


		const meetingTasksListTaskListHash = Store.getStoreData("MeetingTasksListTaskListHash");
		const meetingTasksListMeetingId = Store.getStoreData("MeetingTasksListMeetingId");
		const meetingTasksListCommitteeName = Store.getStoreData("MeetingTasksListCommitteeName");
		const meetingTasksListTaskRefId = Store.getStoreData("MeetingTasksListTaskRefId");
		const meetingTasksListMeetingDate = Store.getStoreData("MeetingTasksListMeetingDate");

		//Store.updateStore('ViewCommitteeTasksListVisibility' , true);

		let taskData = meetingTasksListTaskListHash[meetingTasksListTaskRefId];
		let contact_list = Store.getStoreData('contact_list');
		console.log("actions==>", taskData);
		let actions = [];
		for (var j = 0; j < taskData.length; j++) {
			let completed_date = moment.unix(taskData[j].completed_date);
			let task_json = JSON.parse(taskData[j].task_json);
			let status = 'Incomplete';
			if (taskData[j].cur_lane === 'COMP_COMMITTEES_COMPLETED' || taskData[j].cur_lane === 'COMP_COMMITTEES_COMPLETE') { status = 'Complete'; }
			let elem = {

				'task_name': task_json.object_data.headerinfo.action_json.action_title,
				'task_description': task_json.object_data.headerinfo.action_json.action_description,
				'assigned_to': contact_list[task_json.object_data.headerinfo.action_json.action_assignto],
				'assigned_to_id': task_json.object_data.headerinfo.action_json.action_assignto,
				'due_date': task_json.object_data.headerinfo.action_json.due_date,
				'action_note_undertaken': ('action_note_undertaken' in task_json.object_data.headerinfo.action_json) ? task_json.object_data.headerinfo.action_json.action_note_undertaken : '',
				'status': status,
				'task_id': taskData[j].id,
				'cur_lane': taskData[j].cur_lane,
				'completed_date': (status === 'Incomplete') ? '-' : completed_date.format("DD/MM/YYYY"),

			};
			actions.push(elem);
		}

		this.setState({ meeting_date: meetingTasksListMeetingDate, committe_name: meetingTasksListCommitteeName, tasks: taskData, actions: actions });
	}



	closeModal = (event) => {
		event.preventDefault();
		this.props.closeModal();
	}

	processTableData = () => {
		let contact_list = Store.getStoreData('contact_list');
		let role = Store.getStoreData('role');
		let loggedin_user_info = Store.getStoreData('loggedin_user_info');
		//console.log("loggedin_user_info=>",loggedin_user_info);

		let actions = this.state.actions;
		console.log("actions==>", actions);


		let ret = { data: [], columns: [], raw_data: [], row_filters: {} };

		ret.columns = [
			{ Header: '#', accessor: 'counter', minWidth: 20, headerStyle: { textAlign: 'left' } },
			{ Header: 'Task Name', accessor: 'task_name', minWidth: 100, headerStyle: { textAlign: 'left' } },
			{ Header: 'Assigned To', accessor: 'assigned_to', minWidth: 100, headerStyle: { textAlign: 'left' } },
			{
				Header: 'Due Date', accessor: 'due_date', minWidth: 100, headerStyle: { textAlign: 'left' }, sortMethod: (a, b) => {

					if (a === b) {
						return 0;
					}
					let aReverse = 0;
					let bReverse = 0;
					let adue = a.split("/");
					aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
					let bbdue = b.split("/");
					bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
					return aReverse > bReverse ? 1 : -1;

				}
			},
			{
				Header: 'Status', accessor: 'status', minWidth: 50, headerStyle: { textAlign: 'left' }, Cell: row => (
					<div style={{ textAlign: "left" }}>
						{
							(() => {
								return (<div onClick={this.openActionModal(row.original.task_id)}>{row.original.status}</div>);

							})()
						}
					</div>
				)
			},
			{ Header: 'Completed Date', id: 'completed_date', accessor: 'completed_date', minWidth: 100, headerStyle: { textAlign: 'left' } },
			{
				Header: 'Action', minWidth: 50, headerStyle: { textAlign: 'center' }, Cell: row => (
					<div style={{ textAlign: "center" }}>
						{
							(() => {
								if (row.original.status === "Incomplete") {
									if ((role === 'team' && row.original.assigned_to_id.toString() === loggedin_user_info.ID.toString()) || (role === 'admin_manager' || role === 'governance_manager')) {
										return (<div style={{ float: "left", marginLeft: "60px" }} onClick={this.openActionModal(row.original.task_id, false)}><FaPen style={{ cursor: "pointer" }} title="edit this task" /></div>);
									} else {
										return (<div style={{ float: "left", marginLeft: "60px" }} onClick={this.openActionModal(row.original.task_id, true)}><FaEye style={{ cursor: "pointer" }} title="view this task" /></div>);
									}
								} else {
									return (<div style={{ float: "left", marginLeft: "60px" }} onClick={this.openActionModal(row.original.task_id, true)}><FaEye style={{ cursor: "pointer" }} title="view this task" /></div>);
								}


							})()
						}
						{
							(() => {
								if (row.original.cur_lane !== 'COMP_COMMITTEES_COMPLETE') {
									if (role === 'admin_manager' || role === 'governance_manager') {
										return (
											<div style={{ float: "right", marginRight: "30px" }}>
												<ViewDiv style={{ curser: "pointer" }} onClick={this.deleteConfirm(row.original.task_id)} title="delete this follow-up task" style={{ color: "#1A3552" }}><MdDelete /></ViewDiv>
											</div>
										);
									}

								}

							})()
						}
					</div>
				)
			}
		];



		for (var j = 0; j < actions.length; j++) {
			//let counter = j+1;
			let counter = actions.length - j;
			let status = 'Incomplete';
			let dueg_date_moment_unix = moment(actions[j].due_date).unix();
			let due_date_obj = new Date(actions[j].due_date);

			let due_day = due_date_obj.getDate() < 10 ? "0" + due_date_obj.getDate().toString() : due_date_obj.getDate();
			let due_month = due_date_obj.getMonth() + 1 < 10 ? `0${(due_date_obj.getMonth() + 1).toString()}` : due_date_obj.getMonth() + 1;
			let due_year = due_date_obj.getFullYear();
			let due_date_string = `${due_day}/${due_month}/${due_year}`;
			//console.log("due_date_string,",due_date_string);
			if (actions[j].cur_lane === 'COMP_COMMITTEES_COMPLETED' || actions[j].cur_lane === 'COMP_COMMITTEES_COMPLETE') { status = 'Complete'; }
			let elem = {
				'index': j,
				'counter': counter,
				'task_name': actions[j].task_name,
				'assigned_to': actions[j].assigned_to,
				'assigned_to_id': actions[j].assigned_to_id,
				'due_date': due_date_string,
				'status': status,
				'task_id': actions[j].task_id,
				'completed_date': actions[j].completed_date,
				'cur_lane': actions[j].cur_lane,
				'action_note_undertaken': actions[j].action_note_undertaken,
				'sort_field': dueg_date_moment_unix.toString(),

			};
			ret.data.push(elem);
			ret.raw_data.push(elem);
		}
		ret.data.sort(this.dueDateCompare);

		return ret;
	}
	dueDateCompare = (a, b) => {
		return b.sort_field.localeCompare(a.sort_field);
	}
	deleteConfirm = (task_id) => (event) => {
		event.preventDefault();

		let alert_param = {
			title: 'Warning', message: 'Are you sure you want to delete this follow-up task?', ok_text: 'Yes', cancel_text: 'No', confirm: true,
			alertHandler: this.deleteProcessor, stack: { task_id: task_id }
		}
		this.setState({ alert_param: alert_param })

	}
	deleteProcessor = (result, stack) => {
		if (!result || stack.prevent) {
			this.setState({ alert_param: null })
			return
		}
		let task_id = stack.task_id;
		this.setState({ is_loader: true })
		//console.log("deleteProcessor task_id,",task_id,this.state.actions,parseInt(task_id));
		if (parseInt(task_id).toString() === 'NaN') {
			let actions = [];
			for (let a of this.state.actions) {
				if (a.task_id.toString() === task_id.toString()) continue;
				actions.push(a);
			}
			this.setState({ actions, alert_param: null, is_loader: false });
		} else {
			let postData = { command: "delete_followup_task", task_id: task_id };
			let api = new APICall();
			api.command(postData, this.processDeleteAction);
			this.setState({ alert_param: null });
		}
		//return;

	}
	processDeleteAction = (result) => {
		console.log("processDeleteAction result,", result);
		let actions = [];
		for (let a of this.state.actions) {
			if (a.task_id.toString() === result.task_id.toString()) continue;
			actions.push(a);
		}
		this.setState({ actions, is_loader: false })
	}

	openActionModal = (task_id, view_mode) => (event) => {
		event.preventDefault();
		//alert(task_id);
		/*
		this.setState({ open_action_modal : true },()=>{
			let actions = [];
			let tasks_list = this.state.tasks;
			for(let i=0;i<tasks_list.length;i++){
				let task_json = JSON.parse(tasks_list[i].task_json);
				let action_json = task_json.object_data.headerinfo.action_json;
				action_json.cur_lane = tasks_list[i].cur_lane;
				action_json.task_id = tasks_list[i].id;
				//console.log("action_json==>",action_json);
				actions.push(action_json);
			}
			//console.log("actions==>",actions);
			let jsonobj = {};
			for(let i=0;i<actions.length;i++){
				if(parseInt(actions[i].task_id) === parseInt(task_id)){
					jsonobj = JSON.parse(JSON.stringify(actions[i]));
					jsonobj.showModal = true ;
					//jsonobj.view_mode = (jsonobj.cur_lane === 'COMP_COMMITTEES_COMPLETE') ? true : false ;				
					jsonobj.view_mode = view_mode ;				
				}
			}
			
			//console.log("action_extra_json==>",jsonobj);

			//Store.updateStore('ViewCommitteeTasksListVisibility' , true);
			Store.updateStore('Committee_Name_For_Task' , Store.getStoreData('MeetingTasksListCommitteeName'));
			Store.updateStore('Meeting_Date_For_Task' , Store.getStoreData('MeetingTasksListMeetingDate'));

			this.setState({ action_extra_json : jsonobj , action_task_id : task_id});			
		});*/
		this.setState({ is_loader: true });
		//return;
		let postData = { "command": "get_task", "task_id": task_id };
		let api = new APICall();
		api.command(postData, (result) => this.openTaskModal(result, view_mode));

	}

	openTaskModal = (result, view_mode) => {
		console.log("result,", result);
		let task_json = JSON.parse(result.result.task.parenttask.task_json);
		let action_json = task_json.object_data.headerinfo.action_json;
		let task_id = result.result.task.parenttask.id;
		let bin_files = task_json.object_data.bin_files;
		let _view_mode = (result.result.task.parenttask.cur_lane !== 'COMP_COMMITTEES_COMPLETE') ? view_mode : true;
		let action_extra_json = { action_title: action_json.action_title, action_description: action_json.action_description, action_assignto: action_json.action_assignto, due_date: action_json.due_date, action_note_undertaken: action_json.action_note_undertaken, followupBinFiles: bin_files, showModal: true, view_mode: _view_mode, task_id: task_id, cur_lane: result.result.task.parenttask.cur_lane, roll_over_task_ref_id: action_json.roll_over_task_ref_id };

		let committee_id = action_json.committee_id;
		let committee_name_assoc = Store.getStoreData('committee_name_assoc');
		let committee_name = committee_name_assoc[committee_id];
		Store.updateStore('Committee_Name_For_Task', committee_name);
		Store.updateStore('Meeting_Date_For_Task', this.state.meeting_date);
		this.setState({ action_extra_json, open_action_modal: true, is_loader: false });
		//return;
	}

	actionModalOpen = (jsonObj) => {
		console.log("actionModalOpen jsonObj,",jsonObj);
		let contact_list = Store.getStoreData('contact_list');
		if ('task_id' in jsonObj && 'task_status' in jsonObj) {
			let actions = JSON.parse(JSON.stringify(this.state.actions));
			for (let i = 0; i < actions.length; i++) {
				if (actions[i].task_id === jsonObj.task_id && jsonObj.task_status === 'complete') {
					actions[i].cur_lane = 'COMP_COMMITTEES_COMPLETE';
					actions[i].action_note_undertaken = jsonObj.action_note_undertaken;
					actions[i].task_description = jsonObj.actionDescription_value;
					let completed_date = moment();
					actions[i].completed_date = completed_date.format("DD/MM/YYYY");
				}
				if (actions[i].task_id === jsonObj.task_id && jsonObj.task_status === 'incomplete') {
					actions[i].cur_lane = 'COMP_COMMITTEES_SAVED';
					actions[i].task_name = jsonObj.subject;
					actions[i].assigned_to_id = jsonObj.assignedTo;
					actions[i].task_description = jsonObj.actionDescription_value;
					actions[i].assigned_to = contact_list[jsonObj.assignedTo];
					actions[i].due_date = jsonObj.due_date;
					//actions[i].action_note_undertaken = jsonObj.action_note_undertaken;
				}
			}
			this.setState({ actionModalOpen: jsonObj.status, action_extra_json: {}, actions: actions, open_action_modal: false });
		} else {
			this.setState({ actionModalOpen: jsonObj.status, action_extra_json: {}, open_action_modal: false });
		}
	}

	exportToExcel = () => {
		//event.preventDefault();
		let committeeUtils = new CommitteeUtils();
		let members = [];
		let contact_list = Store.getStoreData('contact_list');
		let fileName = 'meeting_tasks_list';
		let actions = this.state.actions;
		//console.log("actions excel==>",actions);
		let dt = {
			file_name: "meeting_tasks_list",
			cols: ['Assigned To', 'Task Name', 'Task Description', 'Status', 'Due Date', 'Completed Date', 'Completion Text'],
			data: [
			]
		}

		for (let j = 0; j < actions.length; j++) {
			//var task_json = JSON.parse(taskData[j].task_json);
			let status = 'Incomplete';
			if (actions[j].cur_lane === 'COMP_COMMITTEES_COMPLETED' || actions[j].cur_lane === 'COMP_COMMITTEES_COMPLETE') { status = 'Complete'; }
			/*let elm = {

						'Assigned To': actions[j].assigned_to,
						'Task Title': actions[j].task_name,
						'Task Description': actions[j].task_description,
						'Status': status,
						'Due Date': this.getFormattedDate(actions[j].due_date),
						'Completed Date': actions[j].completed_date,
											 
					};*/
			//members.push(elm);
			let arr = [actions[j].assigned_to, actions[j].task_name, committeeUtils.changeStringToAtob(actions[j].task_description), status, this.getFormattedDate(actions[j].due_date), actions[j].completed_date, actions[j].action_note_undertaken];
			dt.data.push(arr);
		}

		if (dt.data.length > 0) {
			/*const format1 = "DD-MM-YYYY HH:mm:ss"
			let tm = moment().format(format1);

			const ws = XLSX.utils.json_to_sheet(members);
			const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
			const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
			const data = new Blob([excelBuffer], {type: fileType});
			FileSaver.saveAs(data, fileName + '-' + tm + fileExtension);
			*/
			let format1 = "DD-MM-YYYY HH:mm:ss"
			let tm = moment().format(format1);
			let file_name = fileName + '-' + tm;
			dt.file_name = file_name;
			this.setState({ download_excel: true, is_loader: false, xls_data: dt });
		}
	}
	toggleExcel = () => {
		this.setState({ download_excel: false });
	}
	getFormattedDate = (input_date) => {
		var date = new Date(input_date);
		var month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
		var dt = (date.getDate() < 10) ? '0' + (date.getDate()) : (date.getDate());
		return dt + '/' + month + '/' + date.getFullYear();
	}


	render() {
		console.log("state:", this.state);
		if (this.state.meeting_date === null) {
			return <div>Loading...</div>
		}
		let committeeid = Store.getStoreData('MeetingTasksListCommitteeId');
		return (
			<div>
				<MRModalContainer>
					{
						(() => {
							if (this.state.is_loader) {
								return <div><CSLLoaderNonPromise /></div>
							}

						})()
					}
					<div style={{ height: "80px", width: "100%", borderLeft: "14px solid #2AACA5", boxSizing: "border-box" }}>
						<div style={{ float: "left", fontSize: "16px", fontWeight: "600", padding: "28px" }}>Tasks <br /> <div style={{ float: "left", fontSize: "14px", fontWeight: "400" }}> {this.state.committe_name} Meeting - {this.getFormattedDate(this.state.meeting_date)}</div> </div>
						<MRModalHeaderCloseBtn onClick={this.closeModal} style={{ padding: "28px" }}><FaTimes /></MRModalHeaderCloseBtn>
						<div style={{ clear: "both" }}></div>
					</div>
					<div style={{ width: 'calc(100%-150px)' }}>
						<OverviewInfoBody>
							<CSLTable add={false} processData={this.processTableData} headerText={'Tasks'} tableRows={10} />
						</OverviewInfoBody>
					</div>



					{
						(() => {
							if (this.state.open_action_modal) {
								return (
									<div >
										<InactiveOverlay /><AddActions overviewBgColor={'#f7f7f7'} actionModalOpen={this.actionModalOpen} refreshAddActionValue={this.refreshAddActionValue} curLane={'test'} action_extra_json={this.state.action_extra_json} tasks_list={this.state.tasks} prop_task_id={'0'} committeeId={committeeid} />
									</div>
								);
							}
						})()
					}
					{
						(() => {
							if (this.state.download_excel) {
								return (
									<div>
										<InactiveOverlay />
										<div style={{ position: 'fixed', top: '140px', width: 'calc(100%-100px)', height: 'calc(100vh-180px)', zIndex: 1001, border: "solid 3px", borderColor: "red" }}>


											<ExcelGenerator toggleExcel={this.toggleExcel} xls_data={this.state.xls_data} />
										</div>
									</div>
								);
							}
						})()
					}

					<div style={{ padding: "15px 25px 15px 0px" }}>
						<div style={{ float: "left", marginLeft: "40px" }} >  <RiskBtn onClick={this.exportToExcel} >Export</RiskBtn></div>
						<CatBtn style={{ backgroundColor: "#ffffff", border: "1px solid #F0F1F1", color: "black", float: "right" }} onClick={this.closeModal}>Cancel</CatBtn>
						<div style={{ clear: "both" }}></div>
					</div>
				</MRModalContainer>
				<AlertBox alertParam={this.state.alert_param} />
			</div>
		);
	}
}

export default MeetingTaskListModal;