import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import APICall from '../../Common/APICall.js';
import { MdClose } from 'react-icons/md';
import CommitteePersonelTable from './CommitteePersonelTable';
import { saveAs } from 'file-saver';
import ReactPaginate from 'react-paginate';
import { FaTimes } from "react-icons/fa";
import AddCommitteeMembers from './AddCommitteeMembers';
import AmendMeeting from './AmendMeeting';
import CSLTable from '../Common/CSLTable';
import Dropzone from '../Common/Dropzone.js';
import CSLLoader from '../Common/CSLLoader';
import '../Common/Dropzone.css';
import * as Style from '../Common/StyledComponents';



const ReactModalHeader = styled.div`
    padding: 10px 15px;
    background: linear-gradient(to bottom, #04ADA8 0%, #04ADA8 100%);
    color:#fff;
`;
const CloseDiv = styled.div`
    box-sizing: border-box;
    cursor:pointer;
`;
const ModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 40%;
    height: 690px;
    background: #ffffff;
    border: 1px solid #868484;
    z-index: 1000;
    position: absolute;
    margin: 0 20%;
    border-radius: 6px;
`;
const RiskCommittee = styled.div`
    padding: 15px;
    background: linear-gradient(180deg, rgba(232, 232, 232, 1) 0%, rgba(232, 232, 232, 1) 0%, rgba(245, 245, 245, 1) 100%, rgba(245, 245, 245, 1) 100%);
    box-sizing: border-box;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    margin-bottom: 24px;
`;
const ViewTermsOfreferenceFileButton = styled.button`
    background-color: #115d9c;
    color: #ffffff; 
    border-radius: 5px;
    border: 1px solid #115d9c;
    padding: 3px 18px;
    background-image: linear-gradient(to bottom, #3e7bb4 50%, #115d9c);
    margin: 10px 0;
`;
const AddNoteButton = styled.button`
    background-color: #115d9c;
    color: #ffffff; 
    border-radius: 5px;
    border: 1px solid #115d9c;
    padding: 3px 18px;
    background-image: linear-gradient(to bottom, #3e7bb4 50%, #115d9c);
    margin: 10px 0;
`;
const MeetingHistory = styled.div`
    padding: 15px;
    background: rgb(233,233,233);
    box-sizing: border-box;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    margin-bottom: 24px;
    margin-top: 30px;
`;
const NoteHistory = styled.div`
    padding: 15px;
    /*background: rgb(233,233,233);*/
    box-sizing: border-box;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    margin-bottom: 24px;
`;
const THR = styled.tr`
    background: rgb(242,242,242);
`;
const TDR = styled.tr`
    background: rgb(249,249,249);
`;
const ViewDiv = styled.div`
    box-sizing: border-box;
    cursor:pointer;
    margin-right:3px;
`;
const UploadReferenceFile = styled.div`
    padding-left: 10px;
    padding-bottom: 10px;
    background: #f9f9f9;
    margin: 13px 0;
    color: black;
`;
const ViewMeeting = styled.a `
    cursor:pointer;
    color:#04ADA8;
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;

/*  */
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 12px;
    font-weight: 600;
    height: 80px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151;  
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: rgb(243, 247, 251);
    padding: 10px;
    border-radius: 4px;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 94%;
    position: absolute;
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 50px;
    top: 40px;
`;

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1800px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 1000;
  opacity: 0.8;
`;


class CommitteeDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            committee: [],
            meetingList: [],
            isLoaded: true,
            selectedFile: [],
            dropzoneFiles: [],
            notes: [],
			pageCount : 0,
			offset: 0,
			data: [],
			elements: [],
			perPage: 5,
			currentPage: 0,
			view: null,
			ready: false,
			committeename: '',
			committeemembers: [],
			perposeofcommitee: '',

        }
    }

	setElementsForCurrentPage = () => {
		let elements = this.state.data
					  .slice(this.state.offset, this.state.offset + this.state.perPage);

		this.setState({ meetingList: elements });
    }

	handlePageClick = data => {
		let selected = data.selected;
		let offset = Math.ceil(selected * this.state.perPage);

		this.setState({ offset: offset }, () => {
		    this.setElementsForCurrentPage();
		});
    }


    closeViewModal = () => {
        this.props.closeModal();
    }
    closeView = () => {
    	this.setState({view:null})
    }
	createMemberRows1 = (members) => {
		console.log("members==>"+JSON.stringify(members));
		let table = [];
		let flag = 0;
		for (let i = 0; i < members.length; i++){
				let re = /(chair*)/i;
				let found = members[i].committeerolename.match( re );
				if(found !== null){
					table.push(<p><b>{members[i].committeerolename} :</b> &nbsp;&nbsp;{members[i].employeename}</p>);
					flag = 1;
					break;
				}

		}
		if(flag === 0){
			table.push(<p><b>Chair :</b> &nbsp;&nbsp;None Allocated</p>);
		}
        return table;
	}

	createNotesList = (notes) => {
		//console.log("meetings==>"+JSON.stringify(meetings));
		let table = [];
		for (let i = 0; i < notes.length; i++){
			//console.log("employeename==>"+this.state.committeemembers[i].employeename+"<=employeename");
			//if(typeof jsonobj.label !='undefined' && jsonobj.label!=""){
				let children = [];
				//table.push(<p>{members[i].committeerolename}&nbsp;&nbsp;{members[i].employeename}</p>);
				children.push(<td style={{ width: "40%", padding: "10px" }}>{notes[i].title}</td>);
				children.push(<td style={{ width: "30%" }}>{notes[i].addedBy}</td>);
				children.push(<td style={{ width: "30%" }}>{notes[i].addedDate}</td>);
				//children.push(<td style={{ width: "29%" }} ><ViewDiv onClick={() => openAmendMeetingModal(meetings[i].PK_CommitteeMettingID)} >View1</ViewDiv></td>);
				//children.push(<td><ReactFormGroup>{this.handleAttendeeDurationDropDown(jsonobj.value)}</ReactFormGroup></td>);
				//children.push(<td>{this.handleGuestAttendedDropDown(jsonobj.value)}</td>);

				table.push(<tr key={i}>{children}</tr>);

				//this.state.guestmembers.push({'employeeid':jsonobj.value,'employeename':jsonobj.label , 'duration':'Part' , 'attended' : 'Yes' });
			//}


		}
        return table;
	}
    downloadAttachment = (file_id, file_name) => {
        console.log("file_id==>" , file_id);
        let that = this;
        let postData = { command: "downloadFile", data: { file_id: file_id } };
        let api = new APICall();
        api.command(postData, function callback(result) { that.downloadFileToBrowser(file_name, result) });
    }
    downloadFileToBrowser = (file_name, result) => {
		console.log("result,",file_name, result);
        let bin_file = result.base64filedata.bin_file;
        let fileName = result.base64filedata.name;
        let dnld = bin_file.replace(/ /g, '+');
        // console.log("binary file before download:", dnld);
        var binaryString = window.atob(dnld);
        // console.log('binaryString', binaryString);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        var blob = new Blob([bytes]);
        saveAs(blob, fileName);
    }
	downloadFileToBrowser_obsolate = (bin_file, fileName) => {
        let dnld = bin_file.replace(/ /g, '+');
        // console.log("binary file before download:", dnld);
        var binaryString = window.atob(dnld);
        // console.log('binaryString', binaryString);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        var blob = new Blob([bytes]);
        saveAs(blob, fileName);
	}
	downloadAttachment_obsolate = (file_id,file_name) => {
		//console.log("arrayindex==>"+arrayindex);
		console.log("file_id==>"+file_id);
		//console.log("file_name==>"+file_name);
		//this.state.selectedFile[i].file_id,this.state.selectedFile[i].file_name
		//let that = this;

		const url = Store.getStoreData('api_url');
		let postData = { command: "downloadFile", data: {  file_id : file_id  } };

		let httpVerb = { method: "POST", mode: "cors", headers: { "Content-Type": "application/x-www-form-urlencoded" }, body: "jsondata=" + JSON.stringify(postData) };
		fetch(url, httpVerb)
			.then((res) => res.json())
			.then(result => {
			   //console.log("file_name=>"+file_name);
			   //console.log("MM=>"+JSON.stringify(result.base64filedata.bin_file));
			   this.downloadFileToBrowser(result.base64filedata.bin_file,result.base64filedata.name);

			});
	}
	createFileRows = (fileData) => {
		let table = [];
		//let children = [];
		if(fileData.length > 0){
			for (let i = 0; i < fileData.length; i++){
				//console.log("employeename==>"+this.state.committeemembers[i].employeename+"<=employeename");
				//if(typeof this.state.committeemembers[i].employeename !='undefined' && this.state.committeemembers[i].employeename!=""){
					let children = [];
					children.push(<div style={{float:"left" , width: "330px"}}><p><a onClick={()=>this.downloadAttachment(fileData[i].file_id,fileData[i].file_name)} style={{cursor: 'pointer'}}>{fileData[i].file_name}</a></p></div>);
					//children.push(<div style={{float:"right"}}><MdClose style={{color: "#979797"}} onClick={()=>this.deleteAttachment(i)}/></div>);
					//children.push(<div style={{clear:"both"}}></div>);
					//children.push(<td>{this.handleAttendeeAttendedDropDown(this.state.committeemembers[i].employeeid)}</td>);

					table.push(<div>{children}</div>);
				//}


			}
		}else{
					let children = [];
					children.push(<div style={{float:"left" , width: "330px"}}><p>No File Uploaded</p></div>);
					//children.push(<div style={{float:"right"}}><MdClose style={{color: "#979797"}} onClick={()=>this.deleteAttachment(i)}/></div>);
					//children.push(<div style={{clear:"both"}}></div>);
					//children.push(<td>{this.handleAttendeeAttendedDropDown(this.state.committeemembers[i].employeeid)}</td>);

					table.push(<div>{children}</div>);
		}


        return table;
	}



    openAmendMeetingModal = (meetingid) => (event) => {
        event.preventDefault();
		/*this.setState({ ready: false });
		let postData = { command: "getMeetingByID" , data: {  meetingid: meetingid } };
		let api = new APICall();
		api.command(postData, this.processOpenAmendMeetingModalData);*/
		Store.updateStore('Meeting_view_meeting_id', meetingid);
		this.processOpenAmendMeetingModalData();
    }
	processOpenAmendMeetingModalData = () => {
		//console.log("WW,",result);
		//Store.updateStore('meetingDataViewMode', result);
		Store.updateStore('AmendMeetingOverlay', `${document.body.scrollHeight.toString()}px`);
		Store.updateStore('AmendMeetingVisibility', true);
		this.setState({view: 'viewmeeting' , ready : true});


	}
    componentDidMount() {

        let committeeid = Store.getStoreData('modal_committee_Details_CommitteeID')


		this.setState({
			ready: false,
		});

		let postData = { command: "getCommitteeDetailsData" , data: { committeeid: committeeid } } ;
		let api = new APICall();
		api.command(postData, this.processIndexData);

        this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    }
	processIndexData = (result) => {
		//console.log("processIndexData result,",result);
		let arr = [];
		let version_hash = {};
		Store.updateStore('committeedetails_committeemembers',JSON.parse(result.committee_by_id.Json_Data).committeemembers)
		let notes = typeof JSON.parse(result.committee_by_id.Json_Data).notes !== 'undefined' ? JSON.parse(result.committee_by_id.Json_Data).notes : [];
		let ReferenceFiles  = (typeof JSON.parse(result.committee_by_id.Json_Data).ReferenceFiles !== 'undefined') ? JSON.parse(result.committee_by_id.Json_Data).ReferenceFiles : [];
		console.log("ReferenceFiles=>",ReferenceFiles);
		let dropzoneFiles = [];
		for(let i=0;i<ReferenceFiles.length;i++){
			dropzoneFiles.push({uid : ReferenceFiles[i].file_id , name: ReferenceFiles[i].file_name});

		}
		result.meetinglist_by_committeeid.map(r => {
			let task_ref_id = JSON.parse(r.Json_Data).task_ref_id;
			version_hash[task_ref_id] = r.PK_CommitteeMettingID;
		});
		//console.log("version_hash,",version_hash);
		for(let i=0;i<result.meetinglist_by_committeeid.length;i++){
			if(result.meetinglist_by_committeeid[i].PK_CommitteeMettingID === version_hash[JSON.parse(result.meetinglist_by_committeeid[i].Json_Data).task_ref_id]){
				arr.push({PK_CommitteeMettingID: result.meetinglist_by_committeeid[i].PK_CommitteeMettingID, Json_Data:JSON.parse(result.meetinglist_by_committeeid[i].Json_Data)});
			}

		}

        let committeename = JSON.parse(result.committee_by_id.Json_Data).committeename;
        let committeemembers = JSON.parse(result.committee_by_id.Json_Data).committeemembers;
        let perposeofcommitee = JSON.parse(result.committee_by_id.Json_Data).perposeofcommitee;
		//console.log("RRR,",committeemembers);
		let employee_assoc = Store.getStoreData('employee_assoc');
		let u_c_members = [];
		for(let c of committeemembers){
			//c.employeename = (!employee_assoc[c.employeeid.toString()].is_active) ? employee_assoc[c.employeeid.toString()].name+' (Inactive)': employee_assoc[c.employeeid.toString()].name;
			if(!employee_assoc[c.employeeid.toString()].is_active)continue;
			u_c_members.push(c);
		}

		this.setState({
			isLoaded: true,
			ready: true,
			committee: result.committee_by_id,
			selectedFile: ReferenceFiles,
			dropzoneFiles: dropzoneFiles,
			notes: notes,
			//meetingList: arr.slice(this.state.offset, this.state.perPage),
			meetingList: arr,
			data: arr ,
			pageCount: Math.ceil(arr.length / this.state.perPage),
			committeename: committeename,
			committeemembers: u_c_members,
			perposeofcommitee: perposeofcommitee,
		})

	}
    processMeetingHistoryData = () => {

        let ret = {data: [], columns: []};
        let i = 0;

		ret.columns =[{Header: 'Date', accessor: 'meeting_date', width: '30%', headerStyle: {textAlign: 'left'},sortMethod: (a, b) => {

						if (a === b) {
                            return 0;
						}
						let aReverse = 0;
						let bReverse = 0;
						let adue = a.split("/");
						aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
						let bbdue = b.split("/");
						bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                            return aReverse > bReverse ? 1 : -1;

						} },
			          //{Header: 'Agenda', accessor: 'agenda', width: '30%', headerStyle: {textAlign: 'left'}},
			          {Header: 'Status', accessor: 'schedule', width: '20%', headerStyle: {textAlign: 'left'}},
                      {'Header' : '', Cell: row => (
                        <div>
	                        {
	                            (() => {
									return (
										   <ViewMeeting onClick={this.openAmendMeetingModal(row.original.meeting_id)} >View</ViewMeeting>
										)
	                            })()
	                        }


                        </div>
                      ), width: '20%', headerStyle: {textAlign: 'left'}},

		             ];

		let meetingList = this.state.meetingList;
		console.log("meetingList,",meetingList);

		for (let i = 0; i < meetingList.length; i++){
			let elem = {'index' : i+1,
						'meeting_id' : meetingList[i].PK_CommitteeMettingID,
						'meeting_date' : meetingList[i].Json_Data.meetingdate,
						'agenda' : meetingList[i].Json_Data.meetingagenda,
						'schedule': meetingList[i].Json_Data.meetingschedule ,


					   };
			ret.data.push(elem);


		}


        return ret;
    }

	downloadAttachment_old = (file_id,file_name) => {
		let that = this;
		const url = Store.getStoreData('api_url');
		let postData = { command: "downloadFile", data: {  file_id : file_id  } };

		let httpVerb = { method: "POST", mode: "cors", headers: { "Content-Type": "application/x-www-form-urlencoded" }, body: "jsondata=" + JSON.stringify(postData) };
		fetch(url, httpVerb)
			.then((res) => res.json())
			.then(result => {
			   that.downloadFileToBrowser(result.base64filedata.bin_file,result.base64filedata.name);

			});
	}
	downloadFileToBrowser_old = (bin_file, fileName) => {
        let dnld = bin_file.replace(/ /g, '+');
        // console.log("binary file before download:", dnld);
        var binaryString = window.atob(dnld);
        // console.log('binaryString', binaryString);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        var blob = new Blob([bytes]);
        saveAs(blob, fileName);
	}

    render() {
		let paginationElement;
        //if (this.state.isLoaded == true) {

            return (
                <MRModalContainer>
					<MRModalHeader>
						<MRModalHeaderText>Committee Details</MRModalHeaderText>
						<MRModalHeaderCloseBtn onClick={this.closeViewModal}><FaTimes /></MRModalHeaderCloseBtn>
						<div style={{clear: "both"}}></div>
						<MRModalSubHeaderText></MRModalSubHeaderText>
						<div style={{clear: "both"}} ref={el => { this.el = el; }}></div>
					</MRModalHeader>
                    {
                    	(() => {
                    		if(this.state.view === "viewmeeting"){
                    			return (
									<div>
									<InactiveOverlay />
									<AmendMeeting closeModal={this.closeView}/>
									</div>
								);
                    		}

                    	})()
                    }
                    <MRModalBody>
                    <div style={{ padding: "15px", height: "650px", overflowY: "scroll", fontSize: "13px" }}>
						{
							(() => {
								if(!this.state.ready) {
									return  <div><CSLLoader /></div>
								}

							})()
						}
                        <RiskCommittee>
                            <div>
                                {<div style={{ float: "left" }}><p><b>{this.state.committeename}</b></p></div>}
                                <div style={{ clear: "both" }}></div>
                                <div style={{ float: "left" }}>{this.createMemberRows1(this.state.committeemembers)}</div>
                                <div style={{ clear: "both" }}></div>
                                <p style={{ margin: "12px 0" }}>
									<b>Purpose :</b> <br />
									<div style={{wordWrap: "break-word" , maxWidth: "80%"}}>
									{this.state.perposeofcommitee}
									</div>
                                </p>
                            </div>
                        </RiskCommittee>

                        <hr style={{ border: "1px solid #dbdbdb" }}></hr>

                        <p style={{marginBottom:"10px"}}><b>Committee Personnel</b></p>
                        <CommitteePersonelTable committeeMembers={this.state.committeemembers}/>

                        <div style={{clear: "both",marginTop: "25px"}}></div>
                        <p><b>Terms Of Reference</b></p>
						<div style={{width: "88%"}}>
							<div style={{padding: "10px"}}>
								<Style.ModalNameInputDiv >
									{
										(() => {
											return <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.dropzoneFiles} height={"200px"} onRemoveFile={this.filesRemoved} showFileButton={false} handleDownload={this.downloadAttachment}/>
										})()
									}
								</Style.ModalNameInputDiv>
							</div>
						</div>
                        <div style={{clear: "both",marginTop: "25px"}}></div>
                        <p style={{marginBottom:"10px"}}><b>Meeting History</b></p>
						<div>
							<AddCommitteeMembers showAddIcon={false} headerText={'Meeting History'}/>
							<OverviewInfoBody>
								 <CSLTable add={false} showPagination={false} processData={this.processMeetingHistoryData} refreshCallback={this.refreshCallback} tableRows={1000}/>
							</OverviewInfoBody>
						</div>



                    </div>
                    </MRModalBody>
					<MRModalFooter>
							<div style={{ textAlign: "right" , marginTop: "20px"}}>

							</div>
					</MRModalFooter>
                </MRModalContainer>
            );
        //}
        //else{
        //    return(
        //        <div>Loading</div>
        //    )
        //}

    }
}

export default CommitteeDetails;
