import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import APICall from '../../Common/APICall.js';
import { MdClose } from 'react-icons/md';
import { Container, Row, Col } from 'react-grid-system';
import { FaTimes } from "react-icons/fa";

const ReactModalHeader = styled.div`
    padding: 10px 15px;
    background: linear-gradient(to bottom, #04ADA8 0%, #04ADA8 100%);
    color:#fff;
`;
const ModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 30%;
    height: 420px;
    background: #ffffff;
    border: 1px solid #868484;
    z-index: 1001;
    position: absolute;
    margin: 0 20%;
    border-radius: 6px;
    top: 45%;
    left: 20%;
`;
const CloseDiv = styled.div`
    box-sizing: border-box;
    cursor:pointer;
`;
const Type = styled.p `
    text-align: center;
    font-weight: bold;
    border-color: #ccc;
    padding: 2px 4px;
    border-radius: 5px;
    
`;

/*  */
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 12px;
    font-weight: 600;
    height: 80px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151;  
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: rgb(243, 247, 251);
    padding: 10px;
    border-radius: 4px;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 50%;
    position: absolute;
    top: 38px;
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 100px;
`;

class EmployeeRoles extends React.Component{
    closeEmployeeRolesModal = () => {
        Store.updateStore('EmployeeRolesOverlay', null);
        Store.updateStore('EmployeeRolesVisibility', false);
        this.props.closeModal();
    }

    constructor(props){
        super(props);
        this.state = {
            roles : [
            ],
			directphone : "",
			email : "",
        }
    }

	componentDidMount(){


		let postData = { command: "getRolesByEmployeeID" , data:{employee_id:Store.getStoreData('Employee_Id')}};
        let api = new APICall();
		api.command(postData, this.processRoles);
		       /*
				const url = Store.getStoreData('api_url');

				//let postData = { command: "getRolesByEmployeeID", data: {  Store.getStore('Employee_Id') } };
				let postData = { command: "getRolesByEmployeeID", data: { employee_id:Store.getStoreData('Employee_Id') } };

				let httpVerb = { method: "POST", mode: "cors", headers: { "Content-Type": "application/x-www-form-urlencoded" }, body: "jsondata=" + JSON.stringify(postData) };

				fetch(url, httpVerb)
					.then((res) => res.json())
					.then(result => {
						//console.log('result==>'+JSON.stringify(JSON.parse(result.Json_Data).children));
						//Store.updateStore('EditMode', editmode);
						result.roles.map((role,index) => {
							//editmode: "true"
							//versionrows: this.createVersionRows(JSON.parse(result.Json_Data).children),
							//console.log("RoleType==>"+role.RoleType);
							let backGround = "linear-gradient(to bottom, "+role.RoleTypeGradient_FirstColorCode+" 0%, "+role.RoleTypeGradient_LastColorCode+" 100%)";
							rolesarr.push({type: role.RoleType, roleid: role.ClientRole_Code, title: role.ClientRole_Title,color:role.RoleType_TextColor,background: backGround});

						});
						this.setState({
							roles:rolesarr,
							directphone : result.contact.Direct,
							email : result.contact.EmailAddress,
						},()=>{
								Store.updateStore('Employee_Id',null);
								Store.updateStore('Employee_Name',null);
							});
					});
				*/
			//Store.updateStoreData('EditActionRecord',null);


	}
	processRoles = (result) => {
		let rolesarr = [];
        console.log("processRoles result,",result);
		result.roles.map((role,index) => {
			//editmode: "true"
			//versionrows: this.createVersionRows(JSON.parse(result.Json_Data).children),
			//console.log("RoleType==>"+role.RoleType);
			let backGround = "linear-gradient(to bottom, "+role.RoleTypeGradient_FirstColorCode+" 0%, "+role.RoleTypeGradient_LastColorCode+" 100%)";
			rolesarr.push({type: role.RoleType, roleid: role.ClientRole_Code, title: role.ClientRole_Title,color:role.RoleType_TextColor,background: backGround});

		});

		this.setState({
			roles:rolesarr,
			directphone : result.contact.Direct,
			email : result.contact.EmailAddress,
		},()=>{
			Store.updateStore('Employee_Id',null);
			Store.updateStore('Employee_Name',null);
		});
	}

    EmployeeRolesTable() {

        return this.state.roles.map((roles,index) => {
            const {type,roleid,title} = roles
            return (
                <tr key={index}>
                    <td>
                        <Type style={{background: roles.background,color: roles.color}}>{type}</Type>
                    </td>
                    <td>{roleid}</td>
                    <td>{title}</td>
                </tr>
            )
        })
    }

    render(){
        return(
            <MRModalContainer>
				<MRModalHeader>
					<MRModalHeaderText>{Store.getStoreData('Employee_Name')}</MRModalHeaderText>
					<MRModalHeaderCloseBtn onClick={this.closeEmployeeRolesModal}><FaTimes /></MRModalHeaderCloseBtn>
					<div style={{clear: "both"}}></div>
					<MRModalSubHeaderText></MRModalSubHeaderText>
					<div style={{clear: "both"}}></div>
				</MRModalHeader>
                <MRModalBody>
                <div style={{padding: "15px",height:"378px",overflowY:"scroll"}}>
                    <p><b>Assigned Roles</b></p>
                    <table style={{width:"100%",margin:"10px 0px"}}>
                        <thead>
                            <tr>
                                <th style={{width:"38%"}}>Type</th>
                                <th>Role ID</th>
                                <th>Title</th>
                            </tr>
                        </thead>
                        <tbody style={{fontSize:"11px"}}>
                            {this.EmployeeRolesTable()}
                        </tbody>
                    </table>
                    <hr style={{border: "1px solid #dbdbdb"}}></hr>
                    <p><b>Contact Details</b></p>
                    <div style={{fontSize:"13px"}}>
                    <Row style={{margin:"5px 0"}}>
                        <Col sm={4}>
                            <p><b>Direct</b></p>
                        </Col>
                        <Col sm={8}>
                            <p>{this.state.directphone}</p>
                        </Col>
                    </Row>
                    <Row style={{margin:"5px 0"}}>
                        <Col sm={4}>
                            <p><b>Email</b></p>
                        </Col>
                        <Col sm={8}>
                            <p>{this.state.email}</p>
                        </Col>
                    </Row>
                    </div>
                </div>
                </MRModalBody>
				<MRModalFooter>
						<div style={{ textAlign: "right" , marginTop: "20px"}}>

						</div>
				</MRModalFooter>
            </MRModalContainer>
        );
    }
}

export default EmployeeRoles;
