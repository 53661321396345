import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import Store from '../../Common/Store.js';
import moment from 'moment';

const ScheduleMeetingBox = styled.div`
    padding: 15px;
    background: linear-gradient(rgba(245, 245, 245, 1) 100%, rgba(245, 245, 245, 1) 100%);
    box-sizing: border-box;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    margin-bottom: 24px;
    font-size:12px;
`;
class ScheduleMeeting extends React.Component {
    openViewAgendaModal = () => {
        Store.updateStore('ViewAgendaOverlay', `${document.body.scrollHeight.toString()}px`);
        Store.updateStore('ViewAgendaVisibility', true);
        this.props.showagendaModal('viewagenda');
    }
    formatDate = (date) => {
        let day = date.getDate();
        let monthIndex = date.getMonth() + 1;
        let year = date.getFullYear();

        if (day < 10) {
            day = '0' + day;
        }
        if (monthIndex < 10) {
            monthIndex = '0' + monthIndex;
        }

        return day + '/' + monthIndex + '/' + year;
    }
    createAttendeeRows = (jsonData) => {
        let table = [];
        let fullarray = jsonData;
        let rowNumbers = Math.round(fullarray.length / 2);
        let modNumbers = fullarray.length % 2;
        //console.log("fullarray===>",fullarray);
		let employee_assoc = Store.getStoreData('employee_assoc');
		for(let f of fullarray){
			if(f.employeeid.toString() in employee_assoc && !employee_assoc[f.employeeid.toString()].is_active)f.employeename=f.employeename+' (Inactive)';
		}

        for (let i = 0; i < fullarray.length; i++) {
			//let employee_name = (!employee_assoc[fullarray[i].employeeid.toString()].is_active) ? fullarray[i].employeename+' (Inactive)': fullarray[i].employeename;
            let children = [];
            if (i % 2 == 0) {
                children.push(<td style={{ width: "35%" }}>{fullarray[i].employeename}</td>);
                children.push(<td style={{ width: "30%" }}>{fullarray[i].required}</td>);
                if (typeof fullarray[i + 1] !== 'undefined') {
                    children.push(<td style={{ width: "35%" }}>{fullarray[i + 1].employeename}</td>);
                    children.push(<td style={{ width: "30%" }}>{fullarray[i + 1].required}</td>);
                } else {
                    children.push(<td style={{ width: "35%" }}>&nbsp;</td>);
                    children.push(<td style={{ width: "30%" }}>&nbsp;</td>);
                }
            }

            table.push(<tr key={i}>{children}</tr>);
        }
		console.log("fullarray===>",table);

        return table;
    }
    render() {
        let meetingDataViewMode = Store.getStoreData('meetingDataViewMode');
        let contact_list = Store.getStoreData('contact_list');
        if (meetingDataViewMode === null) return <div></div>;
        let finalData = JSON.parse(meetingDataViewMode.Json_Data);
        let starttime = new Date(finalData.meetingstarttime);
		let organiser = contact_list[finalData.organiser_id.toString()];

        return (
            <div>
                <ScheduleMeetingBox>
                    <p style={{ marginBottom: "15px", fontSize: "15px" }}><b>Meeting Detail</b></p>

                    <div >
                        <div style={{ marginLeft: "1%", float: "left"}}>
                            <div >
                                <div style={{fontWeight: "bold" }}>Date</div>
                                <div >{finalData.meetingdate}</div>
                            </div>
                        </div>
                        <div style={{ marginLeft: "40%", float: "left"}}>
                            <div style={{width: "100px"}}>
                                <div style={{ fontWeight: "bold" }}>Organiser</div>
                                <div >{organiser}</div>
                            </div>
                        </div>
                        <div style={{ marginLeft: "40%", float: "left"}}>
                            <div >
                                <div style={{ fontWeight: "bold"}}>Agenda</div>
                                <div onClick={this.openViewAgendaModal}><a id="button_viewagendadoc" style={{ cursor: "pointer", fontWeight: "normal", color: "#04ADA8" }}>View</a></div>
                            </div>
                        </div>
                    </div>
					<div style={{clear: "both",marginTop: "25px"}}></div>
                    <div style={{paddingTop: "10px"}}>
                        <div style={{ marginLeft: "1%", float: "left"}}>
                            <div >
                                <div style={{ fontWeight: "bold" }}>Start Time</div>
                                <div >{moment(finalData.meetingstarttime).format("h:mm a")}</div>
                            </div>
                        </div>
                        <div style={{ marginLeft: "40%", float: "left"}}>
                            <div style={{width: "100px"}}>
                                <div style={{ fontWeight: "bold"}}>End Time</div>
                                <div >{moment(finalData.meetingendtime).format("h:mm a")}</div>
                            </div>
                        </div>
                        <div style={{ marginLeft: "40%", float: "left"}}>
                            <div >
                                <div style={{ fontWeight: "bold"}}>Location</div>
                                <div >{finalData.meetinglocation}</div>
                            </div>
                        </div>
                    </div>
					<div style={{clear: "both",marginTop: "25px"}}></div>
                    <p style={{paddingTop: "10px" ,marginLeft: "1%",}}><b>Invited</b></p>
                    <table className="metingtable" style={{ margin: "5px 11px" }}>
                        {this.createAttendeeRows(finalData.attendeemembers)}
                        {this.createAttendeeRows(finalData.guestmembers)}
                    </table>
                </ScheduleMeetingBox>
            </div>
        );
    }
}

export default ScheduleMeeting;