import React from 'react';
import styled from 'styled-components';
import { FaTimes,FaPlusCircle , FaEdit} from 'react-icons/fa';
import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils.js';
import APICall from '../../Common/APICall';
import * as Style from '../Common/StyledComponents';
import DatePicker from 'react-date-picker';
import { saveAs } from 'file-saver';
import { Container, Row, Col } from 'react-grid-system';
import { FaPlus } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';

const ReactFormGroup = styled.div`
    margin-bottom: 15px;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto;
    margin-top: 30px;    
`;
const ScoreContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;    
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 6px #a7a6a6;
    width: 96%;
    margin: 0px auto;
    padding: 15px;
    left: 15%;   
`

const ScoreHeaderContainer = styled.div`
    background-color: #36aba6;    
    height: auto;
    padding: 10px;
    color: #ffffff;
    border: 1px solid #36aba6;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
`;
const ScoreBodyContainer = styled.div`
    padding: 10px;
`;

const MRModalLabel = styled.div`
    color: #a0a0a0;
    font-weight: 600;    
    margin-bottom: 6px;
    margin-top: 10px;
`;
const HeaderButton = styled.div`
    float: right;
    font-size: 20px;
    cursor: pointer;
`;

const FCKTextarea = styled.textarea`
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    /*font-weight: bold;*/
	font-family: Montserrat,sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    float: right;
    cursor: pointer;
	font-family: Montserrat,sans-serif;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    padding: 0px 20px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
	font-family: Montserrat,sans-serif;
`;

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1002;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    width: 95%;   
    margin-bottom: 10px;
	margin-left: 7px;
	top: 25%;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px 20px 10px 20px;
	background-color: #04ADA8;
`;
const MRModalSemiHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    /*padding: 20px 20px 10px 20px;*/
	background-color: grey;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    /*color: #DEDEDE;*/
    color: #FFFFFF;
    font-weight: bold;
    font-size : 14px;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #FFFFFF;
    cursor: pointer;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalInput = styled.input`
    height: 28px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
	font-family: Montserrat,sans-serif;
`;
const RegModalContainertop = styled.div`
    display: grid;
    grid-template-columns: 10% calc(90% - 15px);
    grid-gap: 15px;    
`;
const RegModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 3px;
    font-weight: bold;
    /*color: #979797;*/
    color: #000000;
`;
const ActionSelect = styled.select`    
    display: inline-block;
    box-sizing: border-box;
    height: 35px;
    width: 92%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    margin-left: 14px;
	/*font-weight: bold;*/
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -10%;
  top: -80px;
  z-index: 1000;
  opacity: 0.8;
`;
const InactiveOverlay2 = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -10%;
  top: -80px;
  z-index: 1005;
  opacity: 0.8;
`;
const MRModalFooter = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    
    background-color: #EDF1F1;
    padding: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`;
const CSLDateCover = styled.div`
    margin-top: 0px;
    width: 250px;
    border-radius: 3px;
    height: 34px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0 0 4px #c4c4c4;
	font-weight: bold;
    `;

function GetSortOrder(prop) {    
    return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
}
class AddThirdPartyMembers extends React.Component
{
    state = { 
        showModal: false,
        
		IsActionTitleProvided : false,
		IsActionAssignedToProvided : false,
		IsActionProvided : false,
		is_form_modified : false,
		open_data_lost_warning_modal : false,
		
		committeeMembersPlusIconShow : true,
        thirdpartyembers: [],
        
    }

	componentDidMount()
    {
		//window.scrollTo(0, 0);
		//console.log("Store.getStoreData('contacts') in risk area modal==>"+JSON.stringify(Store.getStoreData('contacts')));
		
        if(typeof this.props.action_extra_json !== 'undefined'){
			this.setState({ thirdpartyembers : this.props.thirdpartyembers });
		}
        
    }

    componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			
			this.setState({ thirdpartyembers : this.props.thirdpartyembers });
		}
		
	}

    
    showAddAction = (index) => (event) => {
		//console.log("in show action");
        event.preventDefault();
        this.setState({ showModal: true} , ()=>{
			//this.props.actionModalOpen({'status' : true}); 
			this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
		});
    }
    closeAddActionModal = (event) => {
		event.preventDefault();
        
        this.setState({ showModal: false });
    }

	refreshState_old  = (val) => {
		if(val === null){
			this.setState({open_data_lost_warning_modal : false , showModal: false} , ()=>{this.props.actionModalOpen({'status' : false})});
		}else{
			this.setState({open_data_lost_warning_modal : false } , ()=>{this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});});
		}
	}

	
 






    submit = (event) => {
		event.preventDefault();
        this.setState({ showModal: false } , ()=>{this.props.thirdPartyMembersModalOpen({thirdpartyembers : this.state.thirdpartyembers})});
		//this.props.actionModalOpen({'status' : false , showModal: false , committeemembers : this.state.committeemembers })
    } 

    handleTPAddClick = event => {

        event.preventDefault()

        let tmparr = this.state.thirdpartyembers;
		let employeeid = Utils.genKey(10);
        tmparr.push({
            employeeid: employeeid,
            thirdpartyembername: "",
            thirdpartyembercompany: "",
            thirdpartyemberreason: ""
        })

        this.setState({ thirdpartyembers: tmparr })

    }

    handleTPAddNameChange = event => {
        event.preventDefault()
        var c = parseInt(event.target.name.split('-')[1])
        let tmparr = this.state.thirdpartyembers
        tmparr[c - 1].thirdpartyembername = event.target.value
        this.setState({ thirdpartyembers: tmparr })

    }
    handleTPAddCompanyChange = event => {
        event.preventDefault()
        var c = parseInt(event.target.name.split('-')[1])
        let tmparr = this.state.thirdpartyembers

        tmparr[c - 1].thirdpartyembercompany = event.target.value
        //console.log(tmparr)
        this.setState({ thirdpartyembers: tmparr })
        // console.log(c)
    }
    handleTPAddReasonChange = event => {
        event.preventDefault()
        var c = parseInt(event.target.name.split('-')[1])
        let tmparr = this.state.thirdpartyembers

        tmparr[c - 1].thirdpartyemberreason = event.target.value
        //console.log(tmparr)
        this.setState({ thirdpartyembers: tmparr })
        // console.log(c)
    }


    render() {
        return(<div>
            {
                (() => {
                    if(this.state.showModal) {
                        return(<div > 
                            <MRModalContainer>
                                <MRModalHeader>
									{
										(() => {
											return(
												<MRModalHeaderText>Add Third Party Members</MRModalHeaderText>
												)
										})()
									}		
                                    <MRModalHeaderCloseBtn onClick={this.closeAddActionModal}><FaTimes /></MRModalHeaderCloseBtn>
                                    <div style={{clear: "both"}}></div>
                                </MRModalHeader>

								<div style={{clear: "both"}} ></div>
                                <MRModalBody>

                                <div style={{clear: "both"}} ></div>
                        <Container>
                            {
                                this.state.thirdpartyembers.map((tm, index) => {
                                    if (index === this.state.thirdpartyembers.length - 1) {
                                        return (
                                            <Row key={index + 1}>
                                                <Col sm={4} style={{ paddingLeft: "0px" }}>
                                                    <ReactFormGroup>
                                                        <input className="react-form-control" placeholder="Full Name" name={`tpname-${index + 1}`} value={tm.thirdpartyembername} onChange={this.handleTPAddNameChange}></input>
                                                    </ReactFormGroup>
                                                </Col>
                                                <Col sm={4} style={{ paddingRight: "0px" }}>
                                                    <ReactFormGroup>
                                                        <input className="react-form-control" placeholder="Company" name={`tpcompany-${index + 1}`} value={tm.thirdpartyembercompany} onChange={this.handleTPAddCompanyChange}></input>
                                                    </ReactFormGroup>
                                                </Col>
                                                <Col sm={3} style={{ paddingRight: "0px" }}>
                                                    <ReactFormGroup>
                                                        <input className="react-form-control" placeholder="Reason" name={`tpreason-${index + 1}`} value={tm.thirdpartyemberreason} onChange={this.handleTPAddReasonChange}></input>
                                                    </ReactFormGroup>
                                                </Col>
                                                <Col sm={1}>
                                                    <FaPlus style={{ color: "#979797", top: "16px", position: "relative" }} onClick={this.handleTPAddClick} />
                                                </Col>
                                            </Row>
                                        )

                                    }
                                    else {
                                        return (
                                            <Row key={index + 1}>
                                                <Col sm={4} style={{ paddingLeft: "0px" }}>
                                                    <ReactFormGroup>
                                                        <input className="react-form-control" placeholder="Full Name" name={`tpname-${index + 1}`} value={tm.thirdpartyembername} onChange={this.handleTPAddNameChange}></input>
                                                    </ReactFormGroup>
                                                </Col>
                                                <Col sm={4} style={{ paddingRight: "0px" }}>
                                                    <ReactFormGroup>
                                                        <input className="react-form-control" placeholder="Company" name={`tpcompany-${index + 1}`} value={tm.thirdpartyembercompany} onChange={this.handleTPAddCompanyChange}></input>
                                                    </ReactFormGroup>
                                                </Col>
                                                <Col sm={3} style={{ paddingRight: "0px" }}>
                                                    <ReactFormGroup>
                                                        <input className="react-form-control" placeholder="Reason" name={`tpreason-${index + 1}`} value={tm.thirdpartyemberreason} onChange={this.handleTPAddReasonChange}></input>
                                                    </ReactFormGroup>
                                                </Col>
                                                <Col sm={1}>
                                                    <FaPlus style={{ color: "#979797", top: "16px", position: "relative" }} onClick={this.handleTPAddClick} />
                                                </Col>
                                            </Row>
                                        )
                                    }


                                })


                            }

                        </Container>



                                <div style={{clear: "both"}} ></div>


                                <div style={{clear: "both"}} ref={el => { this.el = el; }}></div>
                                </MRModalBody>
								<MRModalFooter>
                                <div style={{height: '50px', paddingRight: "31px", marginTop: "50px"}}>   
									{
										(() => {
											return <MRModalNextBtn  style={{backgroundColor: "#26ADA7", border: "1px solid #26ADA7" }} onClick={this.submit}>Submit</MRModalNextBtn>;
										})()
									}													
                                    
                                    <MRModalCancelBtn style={{backgroundColor: "#ffffff", border: "1px solid #ffffff", color: "black"}} onClick={this.closeAddActionModal}>Close</MRModalCancelBtn>
                                </div>
								</MRModalFooter>
                            </MRModalContainer>
                        </div>)
                    }
									
                })()
            }

            <OverviewInfoHeader>
                <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>{this.props.headerText}</div>
				{
					(() => {
						if(this.props.showAddIcon === true) {
							return <div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.showAddAction(-1)}><FaEdit /></div>
						}
					})()
				}
                <div style={{clear: "both"}}></div>
            </OverviewInfoHeader>
            
        </div>)
    }
}
export default AddThirdPartyMembers;