import React, { Component } from "react";
import "./Dropzone.css";
import styled from "styled-components";
import { FiDownload } from "react-icons/fi";
import { MdClear } from "react-icons/md";
import { saveAs } from "file-saver";
import UploadFile from './UploadFile';
import CSLProgressbar from './CSLProgressbar';
// import * as Style from "../Common/StyledComponents";
import AlertBox from "../Common/AlertBox.js";
var atob = require("atob");

const FileContainer = styled.div`
  width: 300px;
  display: flex;
`;
const FileInfoContainer = styled.div`
  margin-left: 10px;
  color: #969ea2;
  width: 220px;
  height: 70px;
`;
const FileTypeContainer = styled.div`
  width: 100px;
  min-height: 70px;
  height: 70px;
  background-color: #fff;
  color: #969ea2;
  font-size: 22px;
  text-align: center;
  padding-top: 22px;
  font-weight: 800;
  border: 1px solid #aa9ea2;
`;
const AttachmentButton = styled.button`
    padding: 10px;
    background-color: #37ADA7;
    border: 1px solid #37ADA7;
    border-radius: 4px;
    color: #ffffff;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    &:hover {
      background-color: #2e948f;
      border-color: #2e948f;
    }
`;

class Dropzone extends Component {
  state = {
    files: [],
    bin_files: [],
    files_to_show: [],
    addnew: true,
    alert_param: null,
    buttontxt: "Add an attachment",
    f_name_delete: '',
  };
  binFiles = [];
  numFiles = 0;
  prevNumFiles = 0;
  numFilesLoading = 0;

  constructor(props) {
    super(props);
    this.state = {
    hightlight: false,
    got_file: 0,
    files: [],
    files_to_show: [],
    isShowProgressBar:false,
    percentLoaded:0,
    alert_param: null
    };
    this.fileInputRef = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    //this.onDragOver = this.onDragOver.bind(this);
    //this.onDragLeave = this.onDragLeave.bind(this);
    //this.onDrop = this.onDrop.bind(this);

    this.uploadApi = new UploadFile();
  }

  uploadFilesFromChild = async (files)=>{
    let result = null;
    console.log('called from Parent');
    console.log('files array==>',files);
    // upload to temp storage
    await this.uploadApi.command(files, this.returnTempFilesInfo,this.updateStatus);
  }


  returnTempFilesInfo = (result) => {
    console.log('returnTempFilesInfo result', result)
    this.props.onUploadComplete(result);
  };


  updateStatus = (status)=>{
    const {loaded, total} = status;
    console.log('UploadProgress in Dropzone loaded, total', loaded, total)
  	let percentLoaded = Math.round((loaded / total) * 100);
		console.log('percentLoaded ===>',percentLoaded);
		if(percentLoaded>0 && percentLoaded<=99){
			//show progress bar
			this.setState({isShowProgressBar:true,percentLoaded:percentLoaded});
		}else{
			//hide progress bar
			let alert_param = { title: "Alert", message: "Your attachment(s) are being saved. This may take a few seconds.", no_button:true , ok_text: "OK", confirm: false, alertHandler: this.AlertremoveHandler, stack: { id: 0 } };
			this.setState({isShowProgressBar:false,percentLoaded:percentLoaded, alert_param});
		}
  }

	AlertremoveHandler = (result, stack) => {
	  if (!result || stack.prevent) {
	    this.setState({ alert_param: null });
	    return;
	  }
	  this.setState({ alert_param: null });
	}

  openFileDialog() {
    if (this.props.disabled) return;
    this.fileInputRef.current.click();
  }

  updateProgress = (evt) => {
    if (evt.lengthComputable) {
      var percentLoaded = Math.round((evt.loaded / evt.total) * 100);
      //console.log('percentLoaded',percentLoaded);
    }
  }

  readBinaryFile = (file) => {
    let reader = new FileReader();
    let that = this;
    that.binFiles.forEach((tfile,index) => {
      if(typeof tfile.uid === 'undefined'){
        that.binFiles.splice(index,1);
      }
    })
    reader.onprogress = this.updateProgress;
    reader.onload = (function(theFile) {
      return function(e) {
        // let processed_bin = e.target.result.replace(/\n/g,'');
        let fElement = {
          uid: that.genKey(10),
          name: theFile.name,
          bin_file: window.btoa(e.target.result).replace(/\n/g, ""),
        };
        that.binFiles.push(fElement);
        that.numFiles++;
      };
    })(file);

    reader.readAsBinaryString(file);
  }

  checkFilesUploading = () => {
    if(this.numFiles < this.prevNumFiles + this.numFilesLoading){
      setTimeout(this.checkFilesUploading, 500);
    } else {
	  let f_assoc = {};
	  let files_to_show = [];
      let files_to_show_p = JSON.parse(JSON.stringify(this.state.bin_files));
	  console.log("files_to_show",files_to_show_p);
      /*for(let tfile of files_to_show_p){
		  if(tfile.uid in f_assoc)continue;
		  files_to_show.push(tfile);
		  f_assoc[tfile.uid]=true;
		  //}

      }*/
	  //files_to_show = [...new Set(files_to_show_p)];
	  files_to_show = files_to_show_p;
	  console.log("files_to_show",files_to_show,this.binFiles);

	  this.binFiles.forEach((tfile) => {
		//for(let tfile of this.binFiles){
		  files_to_show.push(tfile);
	  });
	  let f = [];
      for(let tfile of files_to_show){
		  if(tfile.uid in f_assoc)continue;
		  f.push(tfile);
		  f_assoc[tfile.uid]=true;
      }
	  console.log("files_to_show",files_to_show,f);
      this.props.onFilesAdded(this.binFiles);
      this.setState({files_to_show:f});
    }
  }

  onFilesAdded(evt) {
    if (this.props.disabled) return;
    const files = evt.target.files;
    /*if (files.length > 1) {
      let alert_param = { title: "Alert", message: "Please select one document", ok_text: "OK", confirm: false, alertHandler: this.processCloseloading, stack: {} };
      this.setState({ alert_param: alert_param });
      return false;
    }*/

    let file_parts = files[0].name.split(/\.(?=[^\.]+$)/);

    if (file_parts[file_parts.length - 1] === "exe") {
      let alert_param = { title: "Alert", message: "Unsupported file type", ok_text: "OK", confirm: false, alertHandler: this.processCloseloading, stack: {} };
      this.setState({ alert_param: alert_param });
      return false;
    }

    let fileSize = files[0].size;
    // console.log("fileSize", fileSize)
    if(fileSize > 26214400){ /// more than 25 MB -> 25*1024*1024
      let alert_param = { title: "Alert", message: "This file has exceeded the maximum file upload size of 25mb. Please reduce the file size and try again.", ok_text: "OK", confirm: false, alertHandler: this.processCloseloading, stack: {} };
      this.setState({ alert_param: alert_param });
      return false;
    }

    let tempFiles = this.state.files;
    this.numFilesLoading = files.length;
    this.prevNumFiles = this.numFiles;
    setTimeout(this.checkFilesUploading, 500);

    for (var i = 0; i < files.length; i++) {
      tempFiles.push(files.item(i));
      this.readBinaryFile(files.item(i));
    }
  }

  processCloseloading = (result, stack) => {
    this.setState({ alert_param: null });
  };

  onDragOver(evt) {
    evt.preventDefault();

    if (this.props.disabled) return;

    this.setState({ hightlight: true });
  }

  onDragLeave() {
    this.setState({ hightlight: false });
  }

  onDrop(event) {
    event.preventDefault();

    if (this.props.disabled) return;

    const files = event.dataTransfer.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
    this.setState({ hightlight: false });
  }

  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  handleRemove = (uid,f_name) => {
	console.log("this.binFiles", this.binFiles,uid);
    let tempFiles = JSON.parse(JSON.stringify(this.state.files));
    let newtempFiles = [];
    this.binFiles.forEach((tfile) => {
      //if (tfile.uid !== uid && !('status' in tfile)) { ///unsaved files
      if (tfile.uid !== uid ) { ///unsaved files
		tfile.status='active';
        newtempFiles.push(tfile);
      }
      //if (tfile.uid === uid && 'status' in tfile) { ///already saved files
      //  tfile.status='deleted';
      //  newtempFiles.push(tfile);
      //}
    });
    this.binFiles = newtempFiles;
    // tempFiles.splice(index,1);
	let files_to_show = [];
    let files_to_show_p = JSON.parse(JSON.stringify(this.state.bin_files));
	console.log("files_to_show_p,",files_to_show_p,f_name);
	//let name = '';
	for(let f of files_to_show_p){
		if(f.uid === uid){f.status='deleted';}
		//if(f.name === f_name){f.status='deleted';}
		files_to_show.push(f);
	}
	let a = [];
	let a_assoc={};
	for(let i of [...files_to_show,...newtempFiles]){
		if(i.status==='deleted')continue;
		if(i.uid in a_assoc)continue;
		a_assoc[i.uid]=true;
		a.push(i);
	}
    console.log("this.binFiles", this.binFiles, files_to_show, newtempFiles,a);
    this.props.onFilesRemoved({files: [...files_to_show,...newtempFiles], file_name: f_name , uid: uid});
    this.setState({ files: newtempFiles, files_to_show:a , bin_files: a });
  };

  handleDownload = (uid) => {
    let dbinfile = this.state.files_to_show.find((item) => item.uid === uid);
    console.log(dbinfile);
	if(!('bin_file' in dbinfile)){
		this.props.handleDownload(dbinfile.uid,dbinfile.name);
	}else{
		let dnld = dbinfile.bin_file.replace(/ /g, "+");
		//console.log(dnld);

		let binaryString = window.atob(dnld);
		var binaryLen = binaryString.length;
		var bytes = new Uint8Array(binaryLen);
		for (var i = 0; i < binaryLen; i++) {
			var ascii = binaryString.charCodeAt(i);
			bytes[i] = ascii;
		}
		var blob = new Blob([bytes]);
		saveAs(blob, dbinfile.name);
	}

  };

  componentDidMount() {
    let existing_files = this.props.initFiles === null ? [] : JSON.parse(JSON.stringify(this.props.initFiles));
    existing_files.forEach((efile) => {
      efile.removable = false;
    })
    let addnew = true;
    if ("addnew" in this.props) {
      addnew = this.props.addnew;
    }
    let buttontxt = this.state.buttontxt;
    if ("buttontxt" in this.props) {
      buttontxt = this.props.buttontxt;
    }
    //console.log("addnew", addnew, this.props);
    this.setState({
      bin_files: existing_files,
      files_to_show: existing_files,
      files: [],
      addnew,
      buttontxt: buttontxt,
    });
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps !== this.props) {
  //     let existing_files = this.props.initFiles === null ? [] : JSON.parse(JSON.stringify(this.props.initFiles));
  //     existing_files.forEach((efile) => {
  //       efile.removable = false;
  //     })
  //     this.setState({bin_files: existing_files, files_to_show: existing_files});
  //   }
  // }

  genKey = (length) => {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  render() {
    console.log('isShowProgressBar===>',this.state.isShowProgressBar);
		console.log('this.state.percentLoaded===>',this.state.percentLoaded);
    return (
      <div>
        {
          (() => {
                  if(this.state.files_to_show.length > 0) {
                    return (
                            <div style={{width:'100%'}}>
                            {
                              this.state.files_to_show.map((f, index) => {
                                if(f.status !== 'deleted'){

									const file_parts = f.name.split(".");
									let ext = "PDF";
									switch(file_parts[file_parts.length - 1]) {
									  case 'jpeg':
									  case 'JPG':
									  case 'jpg' : ext = 'JPG';break;
									  case 'png' : ext = 'PNG'; break;
									  case 'docx' : ext = 'DOC'; break;
									  case 'doc' : ext = 'DOC'; break;
									  case 'msg' : ext = 'MSG'; break;
									  case 'txt' : ext = 'TXT'; break;
									  case 'ppt' : ext = 'PPT'; break;
									  case 'pptx' : ext = 'PPT'; break;
									  case 'xls' : ext = 'XLS'; break;
									  case 'xlsx' : ext = 'XLS'; break;
									  default: ext = 'PDF'; break;
									}
									let file_icon_var = ext === 'DOCX' ? 'DOC' : ext;
									file_icon_var = ext === 'XLSX' ? 'XLS' : ext;
									const file_icon = `/dropzoneImages/${ext}.png`;
									let name_width = "250px";
									let name_length = 100;
									if (!("removable" in f)) {
										name_width = "80%";
										name_length = 40;
									}
									let f_name = f.name.length > name_length ? f.name.substring(0, name_length) : f.name;
									return (
									  <div key={index} style={{width: "50%", float: "left", boxSizing: "border-box", padding: index === 0 || index%2 === 0 ? "10px 10px 10px 0px" : "10px 0px 10px 10px"}}>
										<div style={{boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"}}>
											<div style={{float: "left", padding: "10px", boxSizing: "border-box"}}>
											  <img src={file_icon} height="100px" width="auto" />
											</div>
											<div style={{float: "left", padding: "10px", boxSizing: "border-box"}}>
											  <div style={{whiteSpace: "nowrap" , width: name_width, overflow: "hidden",textOverflow: "ellipsis"}}><strong title={f.name} style={{color: '#000000'}}>{f_name}</strong><br /><br /></div>
											  <div style={{cursor: "pointer"}} onClick={() => this.handleDownload(f.uid)}><FiDownload /> Download</div>
											  {
												(() => {
												  if (!"removable" in f || this.props.do_remove === true) {
													return (<div style={{cursor: "pointer", marginTop: "10px"}} onClick={() => this.handleRemove(f.uid,f.name)}><MdClear /> Remove</div>);
												  }
												})()
											  }
											</div>
											<div style={{clear: "both"}}></div>
										</div>
									  </div>
									);
								}
                              })
                            }
                            <div style={{clear: "both"}}></div>
                            </div>
                      )
                  }

          })()
        }

        {
          (() => {
        if (this.state.isShowProgressBar===true) {
							      return (
							            <div>
							                <CSLProgressbar style={{ position: 'absolute' }} value={this.state.percentLoaded} />
							             </div>
							             );
                  }
          })()
        }


        <input
          ref={this.fileInputRef}
          className="FileInput"
          type="file"
          multiple
          accept=".pdf,.jpg,.jpeg,.png,.docx,.doc,.msg,.txt,.ppt,.pptx,.xls,.xlsx"
          onChange={this.onFilesAdded}
        />
        {
          (() => {
            if (this.state.addnew) {
              return (
                <div style={{paddingTop: "20px"}}>
                  <AttachmentButton onClick={this.openFileDialog}>{this.state.buttontxt}</AttachmentButton>
                </div>
              );
            }
          })()
        }
        <AlertBox alertParam={this.state.alert_param} />
      </div>
    );
  }
}

export default Dropzone;
