import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils.js';
import APICall from '../../Common/APICall.js';

const ModalInput = styled.input`
    height: 40px;
    width: calc(100% - 13px);
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;

class InputBox extends React.Component {
	state = {guest_name: "", key_value: ""};
	constructor(props) {
	    super(props);
	}

	componentDidMount = () => {
		this.setState({ready:true, guest_name: this.props.value, key_value : this.props.key_value})
	}
	


	handleChange = (event) => {
	    this.setState({ [event.target.name] : event.target.value })
	    console.log("key", this.state.key_value);
	    this.props.showInput(this.state.key_value, event.target.value);
	}
	render()
	{
		return(<ModalInput placeholder="Full Name" name="guest_name" value={this.state.guest_name} onChange={this.handleChange}  />)
	}
}
export default InputBox;