		let data = {table_headers: [], data: []};
        data.module = {id: 'committees', display: 'Committees & Governance'}
		data.table_headers = [
            {"accessor": "committee", "nickname": "Committee", type: 'alpha', width: 3},
            {"accessor": "organiser", "nickname": "Organiser", type: 'alpha', width: 2},
            {"accessor": "meetingdate", "nickname": "Meeting Date", type: 'date', width: 2},
            {"accessor": "status", "nickname": "Status", type: 'alpha', width: 2},
            {"accessor": "invited", "nickname": "Invited", type: 'alpha', width: 2},
            {"accessor": "attended", "nickname": "Attended", type: 'alpha', width: 2},
            {"accessor": "absent", "nickname": "Absent", type: 'alpha', width: 2},
            {"accessor": "month", "nickname": "Meeting Month", type: 'month', width: 0},
            {"accessor": "taskcount", "nickname": "Taks", type: 'alpha', width: 2},
        ];

        data.sortby = {
            fields: [
                {"accessor": "status", "nickname": "Status"},
                {"accessor": "committee", "nickname": "Committee"},
                {"accessor": "organiser", "nickname": "Organiser"},
                {"accessor": "invited", "nickname": "Invited"},
                {"accessor": "attended", "nickname": "Attended"},
                {"accessor": "absent", "nickname": "Absent"},
                {"accessor": "meetingdate", "nickname": "Meeting Date"},
                {"accessor": "month", "nickname": "Meeting Month"},
                {"accessor": "taskcount", "nickname": "Taks"}
            ],
            selected: {"accessor": "none", "order": "Descending"}
        };
        data.groupby = {
            fields: [
                {"accessor": "status", "nickname": "Status"},
                {"accessor": "committee", "nickname": "Committee"},
                {"accessor": "organiser", "nickname": "Organiser"},
                {"accessor": "invited", "nickname": "Invited"},
                {"accessor": "attended", "nickname": "Attended"},
                {"accessor": "absent", "nickname": "Absent"},
                {"accessor": "meetingdate", "nickname": "Meeting Date"},
                {"accessor": "month", "nickname": "Meeting Month"},
                {"accessor": "taskcount", "nickname": "Taks"}
            ],
            selections: []
        };

module.exports = data