import React from 'react';
import {FaTimes, FaTrashAlt, FaQuestionCircle} from 'react-icons/fa';
import styled from 'styled-components';
import AlertBox from '../Common/AlertBox';
import CSLTable from '../Common/CSLTable';
import APICall from '../../Common/APICall.js';
import Store from '../../Common/Store.js';
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";


const CancelBtn = styled.button`
	background-color: #ffffff;
	border: 1px solid #EBEBEB;
	border-radius: 4px;
	padding: 10px 20px;
	cursor: pointer;
`;
const SubmitBtn = styled.button`
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 4px;
	padding: 10px 30px;
	cursor: pointer;
	color: #ffffff;
`;
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 12px;
    font-weight: 600;
    height: 80px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151;  
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 74%;
    position: absolute;
    top: -68px;
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 82px;
`;
const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: rgb(243, 247, 251);
    padding: 10px;
    border-radius: 4px;
`;

class EmailConfigModal extends React.Component
{
	state = {alert_param: null, email_id: null, emails: []};

	componentDidMount()
	{
		let postData = { command: "get_committee_email_access_list" , committee_id: this.props.committee_id};
		let api = new APICall();
		api.command(postData, this.getInitialEmails);
		
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			this.setState({email_id: this.props.email_id});
		}
	}
    getInitialEmails = (result) => {
		let module_configs = Store.getStoreData('module_configs');
		let loggedin_user_info = Store.getStoreData('loggedin_user_info');
		
		let emails = [];
		let ca_access = [];
		for(let c of module_configs.email){
			ca_access[c.id] = c.name;
		}
		console.log("getInitialEmails,",result,module_configs,ca_access);
		for(let r of result){
			let enabled = true;
			if(loggedin_user_info.ID.toString() in JSON.parse(r.disabled_members)){
				enabled = false;
			}
			emails.push({email_id: r.email_id, name: ca_access[r.email_id], enabled: enabled, disabled_members: r.disabled_members});
		}
		this.setState({emails});
		
	}
	closeModal = (event) => {
		event.preventDefault();
		this.props.closeDialog();
	}

	modifyEmailAccess = (event) => {
		event.preventDefault();
        let {emails} = this.state;
		let u_emails = [];
		for(let e of emails){
			u_emails.push({email_id: e.email_id, disabled_members: e.disabled_members});
		}
		let postData = { command: "update_email_disabled_members" , committee_id: this.props.committee_id, emails: u_emails};
		let api = new APICall();
		api.command(postData, this.getUpdate);
	}
    getUpdate = (result) => {
		console.log(result);
		this.props.closeDialog();
	}

	tableData = () => {
		let emails = this.state.emails;

		//tabledata = this.filter_table_data(tabledata);
		console.log("tabledata 1",emails);
        let ret = {data: [], columns: []};
        ret.columns =[
                        {Header: 'Email', accessor: 'email_name', minWidth: 120, headerStyle: {textAlign: 'left'}}, 
						{'Header' : 'Email me', minWidth: 20, Cell: row => (
							<div>
								   {
									   (()=> {
										   if (row.original.enabled) {
											   return(<div style={{cursor: "pointer", fontSize:"21px", textAlign: "center"}} onClick={this.handlenaCommitteeIncluded(row.original.id)}><IoIosCheckboxOutline /></div>);
										   } else {
											   return(<div  style={{cursor: "pointer", fontSize:"21px", textAlign: "center"}} onClick={this.handlenaCommitteeIncluded(row.original.id)}><IoIosSquareOutline /></div>);
										   }
									   })()
								   }
							</div>
						 ), headerStyle:{textAlign: 'center'}},
						];
        emails.forEach((item) => {
			
        	let elem = {
				id: item.email_id, 
				email_name: item.name, 
				enabled: item.enabled, 
			};
        	ret.data.push(elem);
        })
        return ret;
	}
	handlenaCommitteeIncluded = (email_id) => (event) => {
		//console.log("content:", committee_id);
		let loggedin_user_info = Store.getStoreData('loggedin_user_info');
		let {emails} = this.state;
		let emails_arr = [];
		emails.forEach((item) => {
			if (item.email_id.toString() === email_id.toString()) {
				let enabled = item.enabled ? false : true;
				let disabled_members = JSON.parse(item.disabled_members);
				if(!enabled){
					disabled_members[loggedin_user_info.ID.toString()]=1;
				}else{
					delete disabled_members[loggedin_user_info.ID.toString()];
				}
				item.disabled_members = JSON.stringify(disabled_members);
				item.enabled = enabled;
			}
			emails_arr.push(item);
		})
		//console.log("committees_arr:", committees_arr);
		this.setState({emails: emails_arr });
	}
	render()
	{
		console.log("Committee Selection state,",this.state);
		//if (this.state.content === null) {
		//	return (<div>Loading...</div>);
		//}

		return (
			<MRModalContainer>
                <MRModalHeader>
                    <MRModalHeaderText>Notification Preferences</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{ clear: "both" }}></div>
                    <MRModalSubHeaderText></MRModalSubHeaderText>
                    <div style={{ clear: "both" }} ref={el => { this.el = el; }}></div>
                </MRModalHeader>
				<MRModalBody>
					<div style={{float: "left", marginLeft: "2px", height: "50px", fontSize: "15px", backgroundColor: "#FFFFFF" , width: "100%", paddingTop: "8px", border: "1px solid #ffffff", borderRadius: "3px", boxShadow: "0 0 4px #c4c4c4"}}>&nbsp;On the table below, un(check) the boxes to choose which notification emails you would like to receive when you are added to a Committee, or when changes are made to Meetings.  </div>
					<div style={{clear: "both", marginTop: "20px"}}></div>
					<div style={{marginTop: "20px", float: "left", marginLeft: "2px", height: "50px", fontSize: "20px", backgroundColor: "#04ADA8" , width: "100%", paddingTop: "8px", border: "1px solid #ffffff", borderRadius: "3px", boxShadow: "0 0 4px #c4c4c4", color: "#FFFFFF"}}>&nbsp;Email Notifications </div>

					<div style={{clear: "both"}}></div>
					<CSLTable add={false} processData={this.tableData} headerText={''} tableRows="10" refreshCallback={this.refreshState} />
					<div style={{width: "100%", height: "2px", backgroundColor: "#EBEBEB", marginTop: "20px"}}></div>

					<div style={{width: "100%", height: "2px", backgroundColor: "#EBEBEB", marginTop: "20px"}}></div>
					<div style={{textAlign: "right", marginTop: "20px"}}>
						<CancelBtn onClick={this.closeModal}>Cancel</CancelBtn>
						<SubmitBtn style={{marginLeft: "15px"}} onClick={this.modifyEmailAccess}>Submit</SubmitBtn>
					</div>
				</MRModalBody>
				<AlertBox alertParam={this.state.alert_param} />
			</MRModalContainer>	
		);
	}
}

export default EmailConfigModal;