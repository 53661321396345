import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import APICall from '../../Common/APICall.js';
import ViewVersionsList from '../../Components/ScheduleMeetingModal/ViewVersionsList';

const ViewVersion = styled.div`
    padding: 15px;
    background: linear-gradient(rgba(245, 245, 245, 1) 100%, rgba(245, 245, 245, 1) 100%);
    box-sizing: border-box;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    border: 1px solid #04ADA8;
    border-radius: 4px;
    margin-bottom: 24px;
    font-size:12px;
`;
const ErrorNote = styled.div`
    padding: 15px;
    background: rgb(239,214,214);
    background: linear-gradient(rgb(240,217,217,1) 60%, rgba(231, 196, 196, 1) 100%);
    box-sizing: border-box;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    border: 1px solid rgb(196,128,125);
    border-radius: 4px;
    margin-bottom: 24px;
    font-size:12px;
    color:rgb(179,90,88);
`;
const AnchorText = styled.a`
    text-decoration:underline;
    cursor:pointer;
	color: #04ADA8;
`;
class ViewVersions extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            viewversionlist: false,
            currentversion: "",
            rootmeetingid: "",
        };
    }
	componentDidMount(){
		this.setComponentState();

	}
	setComponentState = () =>{
		let meetingDataEditMode = Store.getStoreData('meetingDataEditMode');
		if(meetingDataEditMode!==null){
			let postData = { command: "getMeetingListByMeetingId", data: {  meetingid: meetingDataEditMode.PK_CommitteeMettingID } };
			let api = new APICall();
			api.command(postData, this.processViewVersions);
		}
	}
    processViewVersions = (result) => {
		//console.log("processViewVersions,",result);
		let meetingDataEditMode = Store.getStoreData('meetingDataEditMode');
		let version = 1;
		for (let i = 0; i < result.length; i++)
		{
			if(result[i].PK_CommitteeMettingID === meetingDataEditMode.PK_CommitteeMettingID){
				version = i + 1;
			}
		}
		this.setState({
			//editmode: "true"
			currentversion: version,
			rootmeetingid: JSON.parse(meetingDataEditMode.Json_Data).rootmeetingid,
			currentmeetingid: meetingDataEditMode.PK_CommitteeMettingID,

		});
    }
    componentDidUpdate(prevProps)
    {
        if (prevProps !== this.props) {
			this.setComponentState();
        }
    }
	componentDidMount_obsolate(){
		//console.log('In addnote componentdidmounts');
		let meetingDataEditMode = Store.getStoreData('meetingDataEditMode');
		//console.log('editActionRecord==>'+editActionRecord);
		//console.log('setNoteEditRecord==>'+JSON.stringify(this.props.setNoteEditRecord));
		if(meetingDataEditMode!=null){
			const url = Store.getStoreData('api_url');
			let postData = { command: "getMeetingListByMeetingId", data: {  meetingid: meetingDataEditMode.PK_CommitteeMettingID } };
			let httpVerb = { method: "POST", mode: "cors", headers: { "Content-Type": "application/x-www-form-urlencoded" }, body: "jsondata=" + JSON.stringify(postData) };

			fetch(url, httpVerb)
				.then((res) => res.json())
				.then(result => {
				    let version = 1;
                    for (let i = 0; i < result.length; i++)
                    {
						if(result[i].PK_CommitteeMettingID === meetingDataEditMode.PK_CommitteeMettingID){
							version = i + 1;
						}
                    }
					this.setState({
						//editmode: "true"
						currentversion: version,
						rootmeetingid: JSON.parse(meetingDataEditMode.Json_Data).rootmeetingid,
						currentmeetingid: meetingDataEditMode.PK_CommitteeMettingID,

					});
				});



			//Store.updateStoreData('EditActionRecord',null);
		}

	}
    componentDidUpdate_obsolate(prevProps)
    {
        if (prevProps !== this.props) {
			let meetingDataEditMode = Store.getStoreData('meetingDataEditMode');
			//console.log('editActionRecord==>'+editActionRecord);
			//console.log('setNoteEditRecord==>'+JSON.stringify(this.props.setNoteEditRecord));
			if(meetingDataEditMode!=null){
				const url = Store.getStoreData('api_url');


				//let postData = { command: "getMeetingByID", data: {  meetingid: meetingId } };
				let postData = { command: "getMeetingListByMeetingId", data: {  meetingid: meetingDataEditMode.PK_CommitteeMettingID } };

				let httpVerb = { method: "POST", mode: "cors", headers: { "Content-Type": "application/x-www-form-urlencoded" }, body: "jsondata=" + JSON.stringify(postData) };

				fetch(url, httpVerb)
					.then((res) => res.json())
					.then(result => {
						let version = 1;
						for (let i = 0; i < result.length; i++)
						{
							if(result[i].PK_CommitteeMettingID === meetingDataEditMode.PK_CommitteeMettingID){
								version = i + 1;
							}
						}
						this.setState({
							//editmode: "true"
							currentversion: version,
							rootmeetingid: JSON.parse(meetingDataEditMode.Json_Data).rootmeetingid,
							currentmeetingid: meetingDataEditMode.PK_CommitteeMettingID,

						});
					});



				//Store.updateStoreData('EditActionRecord',null);
			}
        }
    }
    callBackFromChild = (childData) => {
		//console.log("in openViewVersionListModal=>");
        //Store.updateStore('ViewMeetingVersionListOverlay', `${document.body.scrollHeight.toString()}px`);
        //Store.updateStore('ViewMeetingVersionListVisibility', true);
		if(childData.closeversionlistmodal){
			this.setState({
				viewversionlist: false
			});
		}

    }
    openViewVersionListModal = () => {
		//console.log("in openViewVersionListModal=>");
        //Store.updateStore('ViewMeetingVersionListOverlay', `${document.body.scrollHeight.toString()}px`);
        //Store.updateStore('ViewMeetingVersionListVisibility', true);
        this.setState({
            viewversionlist: true
        });
    }
    render(){
        if(Store.getStoreData('EditMode')){

			//console.log("in openViewVersionListModal=>"+JSON.stringify(meetingDataEditMode));
            return(
                <div>
                    <div style={{marginTop:"10px"}}>
                        <ViewVersion>
                            <p>You are viewing <b>V{this.state.currentversion}</b>. <AnchorText onClick={this.openViewVersionListModal}>View all versions</AnchorText></p>
                        </ViewVersion>
				        {this.state.viewversionlist ? <ViewVersionsList rootMeetingId={this.state.rootmeetingid} currentMeetingId={this.state.currentmeetingid} callBackFromChild={this.callBackFromChild}/> : null}
                    </div>


                </div>
            );
        } else {
            return(
                <div></div>
            );
        }
    }
}

export default ViewVersions;
