import React from 'react';
import AddCommitee from './AddCommitee';
import CommiteeList from './CommiteeList';
import Store from '../../Common/Store.js';
import moment from 'moment';


class CommiteeTab extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            refresh_time : 0,
            totalcommitteecount: '00',
            showInactiveCommittee: false,
            committeeFilterByCompany: {},
            filtered_gc_list_assoc: {},
            company_display_option: 'highlight',
            ready: false,
        }
    }

	componentDidMount = () => {
		let companies_assoc = Store.getStoreData('companies_assoc');
		let parent_has_group_companies = Store.getStoreData('parent_has_group_companies');
		let filtered_gc_list_assoc = this.state.filtered_gc_list_assoc;
		if(parent_has_group_companies){
			for(let gc in companies_assoc) {
				//filtered_gc_list_assoc[companies_assoc[gc].id] = (companies_assoc[gc].id in filtered_gc_list_assoc) ? 1 : 0;
				filtered_gc_list_assoc[companies_assoc[gc].id.toString()] = 1;
			}
			//filtered_gc_list_assoc['0'] = ('0' in filtered_gc_list_assoc) ? 1 : 0;			
			filtered_gc_list_assoc['0'] = 1;			
		}else{
			filtered_gc_list_assoc['0'] = 1;
		}
		this.setState({ filtered_gc_list_assoc , ready: true});
	}

    getTotalCommiteeCount = (childData) => {
		if(childData.committeecount < 10){
			childData.committeecount = '0' + childData.committeecount.toString() 
		}
		this.setState({ totalcommitteecount: childData.committeecount });
    }
    passCommitteIdForAdvancedSearch = (childData) => {
        this.props.getCommitteIdForAdvancedSearch(childData);
    }
    getInactiveResponse = (childData) => {
		this.setState({ showInactiveCommittee: childData.showInactiveCommittee });
    }
    committeeFilterByCompany = (childData) => {
		this.setState({ committeeFilterByCompany: childData });
    }
    openEditModal = () => {
        this.props.openEditModal();
    }
	AddModal = (modal_name) => {
        this.props.AddModal(modal_name);  
	}
	refreshCommitteeTree = () => {
        this.setState({ refresh_time: (new Date().getTime()+2) });  
	}
	changeGC = (jsonObj) => {
		console.log("changeGC filtered_gc_list_assoc,",jsonObj);
		let filtered_gc_list_assoc = jsonObj.filtered_gc_list_assoc;
		let updated_filtered_gc_list_assoc = {};
		let committeeFilterByCompany = this.state.committeeFilterByCompany;
		if(jsonObj.company_display_option === 'undo_gcs'){
			committeeFilterByCompany = {};
			for(let fa in filtered_gc_list_assoc){
				updated_filtered_gc_list_assoc[fa] = 1;
			}
			//jsonObj.filtered_gc_list_assoc = filtered_gc_list_assoc;
			//jsonObj.company_display_option = 'highlight';
		}else{
			let is_gc_selected = false;
			for(let fa in filtered_gc_list_assoc){
				if(filtered_gc_list_assoc[fa] === 1){
					committeeFilterByCompany.gc_id = fa;
					is_gc_selected = true;
				}
				updated_filtered_gc_list_assoc[fa] = filtered_gc_list_assoc[fa];
			}
			if(!is_gc_selected){
				committeeFilterByCompany = {};
				for(let fa in filtered_gc_list_assoc){
					updated_filtered_gc_list_assoc[fa] = 1;
				}
				//jsonObj.filtered_gc_list_assoc = filtered_gc_list_assoc;
			}
			
		}

		this.setState({ filtered_gc_list_assoc: updated_filtered_gc_list_assoc , company_display_option: jsonObj.company_display_option , committeeFilterByCompany});
	}
	updateDisplayOption = (jsonObj) => {
		console.log("changeGC filtered_gc_list_assoc,",jsonObj);
		let filtered_gc_list_assoc = jsonObj.filtered_gc_list_assoc;
		let committeeFilterByCompany = this.state.committeeFilterByCompany;
		for(let fa in jsonObj.filtered_gc_list_assoc){
			if(filtered_gc_list_assoc[fa] === 1)committeeFilterByCompany.gc_id = fa;
		}
		this.setState({ filtered_gc_list_assoc: jsonObj.filtered_gc_list_assoc , company_display_option: jsonObj.company_display_option , committeeFilterByCompany});
	}
    render(){
		let passCommitteeTabRefreshInstruction = this.props.passCommitteeTabRefreshInstruction;

		if('refresh_time' in passCommitteeTabRefreshInstruction){
			if(parseInt(this.state.refresh_time) > parseInt(passCommitteeTabRefreshInstruction.refresh_time)){
				passCommitteeTabRefreshInstruction.refresh_time = this.state.refresh_time;
				Store.updateStore('committee_deleted', true);
			}
		}else{
			passCommitteeTabRefreshInstruction = {'committee_updated' : true , refresh_time : (new Date().getTime()+2)}
		}
        if(!this.state.ready)return <div></div>;
        return(
            <div id="Committees" className="item active" >
              <div >
                <div className="panel-body" style={{backgroundColor : "#F5F9FF"}}>
                    <AddCommitee  addcommitteepermissions={this.props.addcommitteepermissions} getInactiveResponse={this.getInactiveResponse} committeeFilterByCompany={this.committeeFilterByCompany} changeGC={this.changeGC} updateDisplayOption={this.updateDisplayOption}/>
                    <CommiteeList getCommitteIdForAdvancedSearch={this.passCommitteIdForAdvancedSearch} getTotalCommiteeCount={this.getTotalCommiteeCount} showInactiveCommittee={this.state.showInactiveCommittee} passCommitteeTabRefreshInstruction={passCommitteeTabRefreshInstruction} committeeFilterByCompany={this.state.committeeFilterByCompany} filtered_gc_list_assoc={this.state.filtered_gc_list_assoc} company_display_option={this.state.company_display_option} openEditModal={this.openEditModal} AddModal={this.AddModal} refreshCommitteeTree={this.refreshCommitteeTree}/>
                </div>
              </div>
            </div>
        );
    }
}

export default CommiteeTab;