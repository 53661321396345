import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';

const ViewVersion = styled.div`
    padding: 15px;
    background: linear-gradient(rgba(245, 245, 245, 1) 100%, rgba(245, 245, 245, 1) 100%);
    box-sizing: border-box;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    margin-bottom: 24px;
    font-size:12px;
`;
const ErrorNote = styled.div`
    padding: 15px;
    background: rgb(239,214,214);
    background: linear-gradient(rgb(240,217,217,1) 60%, rgba(231, 196, 196, 1) 100%);
    box-sizing: border-box;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    border: 1px solid rgb(196,128,125);
    border-radius: 4px;
    margin-bottom: 24px;
    font-size:12px;
    color:rgb(179,90,88);
`;
const AnchorText = styled.a`
    text-decoration:underline;
    cursor:pointer;
`;
class ViewVersions extends React.Component{
    render(){
        if(Store.getStoreData('EditMode')){
            return(
                <div>
                    <div>
                        <ErrorNote>
                            <p><b>Note: </b>
                                Any changes made will be saved as a new version. 
                                Version history can be viewed at the bottom of this form.
                            </p>
                        </ErrorNote>
                    </div>
                </div>
            );
        } else {
            return(
                <div></div>
            );  
        }   
    }
}

export default ViewVersions;