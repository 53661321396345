import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import MeetingList from './MeetingList'
import { Container, Row, Col } from 'react-grid-system';
import AdvanceFilterBox from './AdvanceFilterBox';
import {IoIosArrowDown, IoIosArrowUp, IoIosTrash} from 'react-icons/io';

const ReactFormGroup = styled.div`
    margin-bottom: 15px;
`;
const GoButton = styled.button`
    padding: 2px 24px;
    left: -2px;
    position: relative;
    background: #04ADA8;
    color: #ffffff !important;
    /*box-shadow: inset 0 1px 0 rgba(255,255,255,0.2), 0 1px 2px rgba(0,0,0,0.05);*/
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    border-radius: 0 4px 4px 0;
    font-weight: bold;
`;
const AdvanceFilterButton = styled.button`
    background-color: #04ADA8;
    color: #ffffff; 
    border-radius: 5px;
    border: 1px solid #04ADA8;
    padding: 3px 18px;
    background-image: linear-gradient(to bottom, #04ADA8 50%, #04ADA8);
    float:right;
`;

const MeetingDateSelectionSection = styled.div`
    padding: 15px;
    /*background: linear-gradient(180deg, rgba(232, 232, 232, 1) 0%, rgba(232, 232, 232, 1) 0%, rgba(245, 245, 245, 1) 100%, rgba(245, 245, 245, 1) 100%);*/
    background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 0%, #FFFFFF 100%, #FFFFFF 100%);
    box-sizing: border-box;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    margin-bottom: 24px;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #ffffff;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;    
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;  
	margin-bottom: 20px;
`;

class MeetingTab extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          open: false,
          executeMeetingListWithFilterSearch: false,
          filtersearchjson: {}
        }
      }

    render(){
        return(
           <MeetingList prop_task_id={this.props.prop_task_id} prop_meeting_id={this.props.prop_meeting_id} />
        );
    }
}

export default MeetingTab;