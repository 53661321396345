import React from 'react';
import './App.css';
import styled from 'styled-components';
import Store from './Common/Store.js';
import APICall from './Common/APICall.js';
import APIContact from './Common/APIContact.js';
import Utils from './Common/Utils.js';
import ComDecom from './Common/ComDecom.js';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import CSLLoader from './Components/Common/CSLLoader';
import Index from './Components/Index';
import ModuleConfigHOC from './Components/Moduleconfig/ModuleConfigHOC';
import CSLPageloader from './Components/Common/CSLPageloader';
import AlertBox from './Components/Common/AlertBox';
import CommitteeUtils from './Components/Common/CommitteeUtils';
import Reload from './Common/Reload.js';
import moment from 'moment';
import authService from 'mcc-front-aux/dist/authService';
import siteLayout from 'mcc-front-aux/dist/siteLayout'

const last_action_time = moment().unix();

class AppContent extends React.Component {
	ready_for_proceed = false;
	state = {
		ready: false,
		module_configs: {},
		alert_param: null,
	};

	constructor(props) {
		super(props);
		Store.updateStore('module_name', 'committees');
		Utils.setEnv('committees');
		// this.callContact();
		// this.processContactData();
	}
	callContact() {
		if (!Store.getStoreData('is_admin')) {
			let windowUrl = window.location.href;
			if (windowUrl.indexOf('register') === -1) {
				let postData = { command: "contactlist" };
				let api = new APIContact();
				api.command(postData, this.processContactData);
			}
		}
	}
	processContactData = (result) => {
		/*console.log("processContactData,",result);
		let comdecom = new ComDecom();
		let cl = result.result['contactlist'];
		if('fields' in cl && 'rows' in cl)cl = comdecom.deCompress(result.result['contactlist'], true, 'ID');
		//result.result['contactlist'] = cl;
		let user_roles = result.result.user_roles.committees;
		*/
		let cl = JSON.parse(localStorage.getItem('contactlist'));
		let contactlist_assoc = cl.result.contactlist;
		let user_roles = cl.result.user_roles.committees;
		let contact_list = {};
		let listcontacts = [];
		let contactrole = [];
		let company_users = {};
		Object.keys(contactlist_assoc).forEach((uid) => {
			//if (uid in user_roles && contactlist_assoc[uid].IsActive === true && contactlist_assoc[uid].IsDeleted === false) {
				contact_list[uid.toString()] = (contactlist_assoc[uid].IsActive===false) ? contactlist_assoc[uid.toString()].ContactName+' (Inactive)': contactlist_assoc[uid.toString()].ContactName;
				listcontacts.push({ ID: parseInt(uid), ContactName: contactlist_assoc[uid.toString()].ContactName, IsActive: contactlist_assoc[uid].IsActive, IsDeleted: contactlist_assoc[uid].IsDeleted });
				company_users[uid.toString()] = { ID: parseInt(uid), ContactName: contactlist_assoc[uid.toString()].ContactName, email: contactlist_assoc[uid.toString()].EmailAddress, role: user_roles[uid.toString()] , IsActive: contactlist_assoc[uid].IsActive, IsDeleted: contactlist_assoc[uid].IsDeleted};
				contactrole.push({ user_id: parseInt(uid), role: user_roles[uid.toString()], IsActive: contactlist_assoc[uid].IsActive, IsDeleted: contactlist_assoc[uid].IsDeleted });
			//}

		});

		Store.updateStore('contacts', listcontacts);
		Store.updateStore('contact_list', contact_list);
		//Store.updateStore('users', company_users);
		console.log("listcontacts,",listcontacts);
		Store.updateStore('contactrole', contactrole);
		console.log("contactrole,",contactrole);
		Store.updateStore('company_users', company_users);
		let contact_role_list = contactrole;
		let contact_role_hash = {};
		for (let i = 0; i < contact_role_list.length; i++) {
			contact_role_hash[contact_role_list[i].user_id] = contact_role_list[i].role;
		}
		Store.updateStore('contact_role_hash', contact_role_hash);


	}

	componentDidMount() {
		this.processContactData();
		this.callApi();
	}

	callApi() {
		console.log("calloggedin_user_infolApi,");
		let postData = { command: "index", data: { employee_id: 0, include_inactive_committee: 'yes' } };
		let api = new APICall();
		api.command(postData, this.processIndex);
	}

	processIndex = (result) => {
		console.log("result,", result);
		//return;
		let comdecom = new ComDecom();
		let committeeUtils = new CommitteeUtils();
		if (result.error_code === 0) {
			if (result.role === "no_access") {
				Store.updateStore('role', result.role);
				let alert_param = {
					title: 'No Access', message: 'You are not authorised to access this module. If you think this is in error, please contact your administrator.', ok_text: 'Ok', confirm: false,
					alertHandler: this.alertHandler, stack: {}
				};
				this.setState({ ready: true, alert_param: alert_param });
				//return false;
			} else {
				let parent_has_group_companies = false;
				let module_configs = {};
				let tasks = [];
				let followup_tasks = [];
				let permissions = {};
				permissions['admin_manager'] = { 'module_config': true, 'add_committee': true, 'edit_committee': true, 'delete_committee': true, 'view_committee_structure': true, 'view_committee_details': true, 'add_meeting': true, 'edit_meeting': true, 'view_meeting': true, 'delete_meeting': true, 'add_followup_task_in_meeting': true, 'view_followup_task_in_meeting': true, 'edit_followup_task_in_meeting': true };
				permissions['governance_manager'] = { 'module_config': false, 'add_committee': true, 'edit_committee': true, 'delete_committee': false, 'view_committee_structure': true, 'view_committee_details': true, 'add_meeting': true, 'edit_meeting': true, 'view_meeting': true, 'delete_meeting': false, 'add_followup_task_in_meeting': true, 'view_followup_task_in_meeting': true, 'edit_followup_task_in_meeting': true };
				permissions['team'] = { 'module_config': false, 'add_committee': false, 'edit_committee': false, 'delete_committee': false, 'view_committee_structure': true, 'view_committee_details': true, 'add_meeting': false, 'edit_meeting': false, 'view_meeting': true, 'delete_meeting': false, 'add_followup_task_in_meeting': true, 'view_followup_task_in_meeting': true, 'edit_followup_task_in_meeting': false };
				permissions['no_access'] = { 'module_config': false, 'add_committee': false, 'edit_committee': false, 'delete_committee': false, 'view_committee_structure': false, 'view_committee_details': false, 'add_meeting': false, 'edit_meeting': false, 'view_meeting': false, 'delete_meeting': false, 'add_followup_task_in_meeting': false, 'view_followup_task_in_meeting': false, 'edit_followup_task_in_meeting': false };
				Store.updateStore('permissions', permissions[result.role]);

				let assignments = 'assignments' in result.result ? result.result.assignments : {}
				let user_assignments = 'user_assignments' in result.result ? result.result.user_assignments : {}
				Store.updateStore('role', result.role);
				Store.updateStore('assignments', assignments);
				Store.updateStore('user_assignments', user_assignments);
				Store.updateStore('parent_has_group_companies', result.result.parent_has_group_companies);
				Store.updateStore('loggedin_user_info', result.result.loggedin_user_info);
				let companies = 'companies' in result.result ? result.result.companies : {};
				Store.updateStore('companies', companies);
				let companies_assoc = 'companies_assoc' in result.result ? result.result.companies_assoc : {};
				for (let c_id in companies_assoc) {
					let include = false
					if ('companies' in assignments && c_id in assignments.companies) {
						include = true
					} else {
						if ('tags_config' in companies_assoc[c_id]) {
							for (let t_id in companies_assoc[c_id].tags_config) {
								for (let v_id in companies_assoc[c_id].tags_config[t_id]) {
									if ('tags' in assignments && t_id in assignments.tags && v_id in assignments.tags[t_id]) include = true
								}
							}
						}

					}
					if (!include && result.role !== 'admin_manager') delete companies_assoc[c_id];
				}
				Store.updateStore('companies_assoc', companies_assoc);
				let tag_values = 'tag_values' in result.result ? result.result.tag_values : {};
				for (let t_id in tag_values) {
					if ('tags' in assignments && !(t_id in assignments.tags)) {
						delete tag_values[t_id]
					} else {
						for (let v_id in tag_values[t_id].values) {
							if ('tags' in assignments && !(v_id in assignments.tags[t_id])) delete tag_values[t_id].values[v_id]
						}
					}
				}
				Store.updateStore('tag_values', tag_values);
				let company_name = 'company_name' in result.result ? result.result.company_name : 'N/A';
				Store.updateStore('company_name', company_name);
				let company_users = Store.getStoreData('company_users');
				let employee_list_pre = comdecom.deCompress(result.result.employee_list);
				for(let e of employee_list_pre){
					if(company_users[e.contact_id.toString()].IsActive===false)e.employeename=e.employeename+' (Inactive)';
				}
				Store.updateStore('employee_list', employee_list_pre);
				Store.updateStore('rsvp_list', comdecom.deCompress(result.result.rsvp_list));
				let employee_list = comdecom.deCompress(result.result.employee_list)

				if(employee_list.findIndex(e => e.contact_id === result.result.loggedin_user_info.ID) === -1) {
					let alert_param = {
						title: 'No Access', message: 'You are not authorised to access this module. If you think this is in error, please contact your administrator.', ok_text: 'Ok', confirm: false,
						alertHandler: this.alertHandler, stack: {}
					};
					this.setState({ ready: true, alert_param: alert_param });
					return
				}

				committeeUtils.map_employee_with_contact(employee_list);
				let committee_input_json = { committees: comdecom.deCompress(result.result.committeeWithCount), meetings: comdecom.deCompress(result.result.meetings_list), tasks_list: comdecom.deCompress(result.result.tasks_list) };
				let committeeWithCount_json = committeeUtils.convertToCommitteesWithCount(committee_input_json);
				Store.updateStore('committee_with_count', committeeWithCount_json);
				Store.updateStore('meeting_records', committee_input_json);
				Store.updateStore('raw_meetings_list', comdecom.deCompress(result.result.meetings_list));
				Store.updateStore('followup_tasks_list', comdecom.deCompress(result.result.tasks_list));

				let governance = result.result.governance;
				Store.updateStore('governance', governance);

				let filtered_committees = [];
				console.log('companies_assoc', companies_assoc)
				let comittee_gcs = {};

				for (let gc of governance.committees) {
					if (result.role !== 'admin_manager') {
						let association = 'association' in gc.json_data ? gc.json_data.association : 'parent'
						switch (association) {
							case 'gc': if (gc.json_data.gc_id in companies_assoc) filtered_committees.push(gc); break;
							case 'grouping': if (gc.json_data.grouping_id in assignments.tags && gc.json_data.grouping_value_id in assignments.tags[gc.json_data.grouping_id]) filtered_committees.push(gc); break;
							default: filtered_committees.push(gc);
						}
					} else {
						filtered_committees.push(gc);
					}
					if ('gc_id' in gc.json_data && gc.json_data.gc_id !== "0") comittee_gcs[gc.json_data.gc_id.toString()] = 1

				}
				console.log('governance.committees', comittee_gcs);
				for (let g of filtered_committees) {
					g.parent_id = g.json_data.parent_id === '' ? '0' : g.json_data.parent_id;
					g.id = g.id;
					g.show = g.is_active === true ? true : false;
					g.gc_id = ('gc_id' in g.json_data) ? g.json_data.gc_id : '0';
					g.combined_gcs = [];
				}

				let is_member_of_committee_hash = {};
				for (let committee of committeeWithCount_json.committees) {
					is_member_of_committee_hash[committee.PK_CommitteeID.toString()] = committee.is_member_of_this_committee ? true : false;
				}

				if (result.result.parent_has_group_companies && result.role === 'governance_manager') {
					for (let p of filtered_committees) {
						if (user_assignments[result.result.loggedin_user_info.ID]['companies'][p.gc_id] !== 1) is_member_of_committee_hash[p.id.toString()] = false;
						if (p.gc_id.toString() === '0') is_member_of_committee_hash[p.id.toString()] = true;
					}
				}
				for (let p of filtered_committees) {
					p.is_member_of_committee = is_member_of_committee_hash[p.id.toString()];
				}
				console.log("filtered_committees,", filtered_committees);
				let gov_committees = committeeUtils.convertListToTree(filtered_committees);
				console.log("gov_committees,", gov_committees);
				let u_children = committeeUtils.setCommitteeCombinedGcsTopLevel(gov_committees.children);
				gov_committees.children = u_children;
				//console.log("gov_committees,",gov_committees);
				Store.updateStore('gov_committees', gov_committees);
				Store.updateStore('filtered_committees', filtered_committees);
				Store.updateStore('members', governance.employee_hash);


				Store.updateStore('is_member_of_committee_hash', is_member_of_committee_hash);

				module_configs = JSON.parse(result.result.module_configs.config_json);
				Store.updateStore('module_configs', module_configs);
				Store.updateStore('comittee_gcs', comittee_gcs);
				this.setState({ ready: true, module_configs: module_configs });
			}
		} else {
			let alert_param = {
				title: 'System Error', message: result.error_msg, ok_text: 'Ok', confirm: false,
				alertHandler: this.alertHandler, stack: { id: 0 }
			}
			this.setState({ ready: true, alert_param: alert_param });
		}
		this.ready_for_proceed = true;
	}

	alertHandler_obsolate = (result, stack) => {
		this.setState({ alert_param: null })
		localStorage.removeItem('committees-index');
		window.location = '/';
	}
	alertHandler = (result, stack) => {
		// let url = Store.getStoreData('is_admin') ? "/ConsultantSummary.aspx" : "/select/ServiceBasedLanding";
		// window.location = url;
		this.setState({ alert_param: null })
		localStorage.removeItem('committees-index');
		Reload.ReloadPage();
	}

	render() {
		console.log("App js state,", this.state);
		if (!this.state.task_loading) {
			if (Store.getStoreData('committees-index') === null) {
				return <div><CSLPageloader /></div>
			}
		}
		if (!this.state.ready && !this.ready_for_proceed) {
			return <div><CSLLoader /></div>
		}
		this.ready_for_proceed = false;
		if (this.state.alert_param !== null) {
			return (
				<div>
					<AlertBox alertParam={this.state.alert_param} />
				</div>
			);
		} else {
			return (
				<Router>
					<Switch>

						<Route path="/moduleconfig" exact>
							<ModuleConfigHOC />
						</Route>
						<Route path="/" component={Index} exact />
						<Route path="/:taskId" component={Index} exact />
						<Route path="/meeting/:meetingId" component={Index} exact />
						<Route path="/moduleconfig" exact>
							<ModuleConfigHOC />
						</Route>
					</Switch>
				</Router>
			);
		}
	}

}

class App extends React.Component {
	state = { ready: false }

	setComponentState = async () => {
		console.log('this.props', this.props)
		let is_debug = false;
		let api_url = process.env.REACT_APP_API_URL;
		Store.updateStore('is_debug', is_debug)
		Store.updateStore('api_url', api_url)

		await authService.refresh()
		await siteLayout()
		this.setState({ready: true})
	}

	componentDidMount = () => {
		this.setComponentState()
	}

	componentDidUpdate = (prevprops) => {
		if (this.props === prevprops) return
		this.setComponentState()
	}

	render() {
		console.log("App state,", this.state);
		if (!this.state.ready) {
			return <div>Loading...</div>
		}

		// let appContent = <AppContent />
		//
		// return <ErrorBoundary><SiteLayout AppContent={appContent} /></ErrorBoundary>

		return <AppContent />
	}
}

export default App;
