import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils.js';
import Committee from './Committee.js';
// import RespoBox from './RespoBox.js';
// import RespoLine from './RespoLine.js';
import { FaExclamationCircle } from 'react-icons/fa';
import * as Style from '../Common/StyledComponents';


const HeaderDiv = styled.div`
    padding:9px;

    background-color: white;
`;


class GovmapReport extends React.Component {
	state = {
		ready: false,
		gov_committees: null,
		members: null,
		show_role_desc: false,
		root_user_id: '0',
		max_level: 100000
	}

	constructor(props) {
		super(props);
		this.initBBHt = 80
		this.bHeaderHt = 60
		this.bWidth = 550
		this.hGap = 100
		this.vGap = 30
		this.currentY = 0
		this.rolesPerLine = 6
		this.lineHeight = 15
		this.maxLevel = 0
	}

	setComponentState = () => {
		let gov_committees = Store.getStoreData('gov_committees');
		let loggedin_user_info = Store.getStoreData('loggedin_user_info');
		let employee_contact_assoc = Store.getStoreData('employee_contact_assoc');
		let current_employee_id = employee_contact_assoc[loggedin_user_info.ID].employee_id;

		console.log("gov_committees,",gov_committees,employee_contact_assoc,current_employee_id);
		//let members = Store.getStoreData('members')
        let is_member_of_committee_hash = Store.getStoreData('is_member_of_committee_hash');

		let filtered_gc_array = [];
		let filtered_gc_list_assoc = this.props.filtered_gc_list_assoc;
		for(let fa in filtered_gc_list_assoc){
			if(filtered_gc_list_assoc[fa]===1)filtered_gc_array.push(fa);
		}

		if(this.props.company_display_option === 'select'){
			let updated_children = [];
			for(let item of gov_committees.children) {
				let combined_gcs = item.combined_gcs;
				let containsAll = combined_gcs.filter(value => filtered_gc_array.includes(value.toString()));
				console.log("filtered_gc_array=>",filtered_gc_array,combined_gcs,containsAll,item.gc_id);
				if(containsAll.length > 0){
					updated_children.push(item);
				}
			}
			
			if(updated_children.length > 0){
				gov_committees.children = updated_children;
			}
		}

		this.currentY = 0;
		let index = 0;
		if(gov_committees !== null) {
			for(let gm of gov_committees.children) {
				this.measureGovmap(gm, 0, null , is_member_of_committee_hash,index,current_employee_id);
				index++;
			}
		}
		
		//console.log('committees is_member_of_committee_hash', Store.getStoreData('is_member_of_committee_hash'))

		this.setState({ready:true, gov_committees: gov_committees})
	}

	componentDidMount = () => {
		this.setComponentState();
	}

	componentDidUpdate = (prevprops) => {
		if(this.props === prevprops)return
        //console.log(this.props.committeeFilterByCompany);
		this.setComponentState();
	}

	measureGovmap = (committee, level, prev_r , is_member_of_committee_hash,index,current_employee_id) => {
		committee.show = (this.props.showInactiveCommittee) ? true : committee.show;
		if(committee.show){
			console.log('committee, level, prev_r', committee, level, prev_r,current_employee_id);
			let is_member_has_email_access = false;
			this.maxLevel = level > this.maxLevel ? level : this.maxLevel
			committee.name = committee.json_data.committeename
			committee.purpose = committee.json_data.perposeofcommitee
			committee.is_member_of_committee = is_member_of_committee_hash[committee.id]
			committee.members = committee.json_data.committeemembers;
			let employee = committee.json_data.committeemembers.find(e => e.employeeid.toString() === current_employee_id.toString());
			if(typeof employee !== 'undefined')is_member_has_email_access=true;
			committee.is_member_has_email_access = is_member_has_email_access;
			committee.alt_members = committee.json_data.thirdpartyembers;

			committee.level = level
			//committee.y = prev_r === null ? 0 : this.currentY
			committee.y = (prev_r === null && index === 0 ) ? 0 : this.currentY;
			//console.log('depcommittee.y, this.current_y, this.vGap', prev_r,committee.name , committee.y, this.currentY, this.vGap )
			let alt_length = 0
			for(let m of committee.alt_members) {
				if(m.thirdpartyembername !== '')alt_length++
			}
			committee.prev_y = prev_r === null ? 0 : prev_r.y
			committee.prev_height = prev_r === null ? 0 : prev_r.box_height
			committee.box_height = this.props.showRoles ? this.initBBHt + 4 + this.lineHeight * (committee.members.length + alt_length) : this.initBBHt
			// committee.box_height += 20
			this.currentY += committee.box_height
			for(let c of committee.children) {
				this.measureGovmap(c, level+1, committee , is_member_of_committee_hash,'',current_employee_id)
			}			
		}

		
	}

	mapFindById = (find_id, respomap) => {
        for(let m of respomap) {
        	if(find_id === m.id)return m
            let found_member = this.mapFindById(find_id, m.children);
            if(found_member !== null) return found_member;
        }
        return null;
    }
    passCommitteIdForAdvancedSearch = (childData) => {
        this.props.getCommitteIdForAdvancedSearch(childData);
    }
	AddModal = (modal_name) => {
        this.props.AddModal(modal_name);  
	}
	refreshCommitteeTree = () => {
        this.props.refreshCommitteeTree();  
	}
	render() {
		console.log('GovmapReport this.state in render,', this.state)
		console.log('GovmapReport props in render,', this.props)
		//console.log('GovmapReport this.props in render,', this.props)
		if(!this.state.ready)return (<div></div>)
		if(this.state.gov_committees === null)return (<div></div>);
        


		//console.log("filtered_gc_array=>",filtered_gc_array);
		return (<div>
					<HeaderDiv style={{backgroundColor : "#F5F9FF"}}>
						<div style={{position: 'relative', minHeight: this.currentY, marginLeft: 12 , backgroundColor : "#F5F9FF"}}>
						{
							this.state.gov_committees.children.map((item, i) => {
									if(item.show){
										return <Committee 
											key={i} 
											committee={item}
											horizontalGap={this.hGap}
											showRoles={this.props.showRoles}
											getCommitteIdForAdvancedSearch={this.passCommitteIdForAdvancedSearch}
											showInactiveCommittee={this.props.showInactiveCommittee}
											committeeFilterByCompany={this.props.committeeFilterByCompany}
											AddModal={this.AddModal}
											refreshCommitteeTree={this.refreshCommitteeTree}
											filtered_gc_list_assoc={this.props.filtered_gc_list_assoc}
											company_display_option={this.props.company_display_option}
										/>									
									}									

							})
						}
						</div>
					</HeaderDiv>
				</div>
			)
	}
}

export default GovmapReport;