import Store from "./Store.js";
/*global localStorage*/
class Reload {
    ReloadPage() {
      window.location = "/";
    }

  RestoreStorage(user_roles) {
    let module_name = Store.getStoreData('module_name');
    if(localStorage.getItem('site_layout')!==null){
      let site_layout = JSON.parse(localStorage.getItem('site_layout'));
      let use_storage = site_layout.result.use_storage;
      let exit_user_roles = use_storage.result['user_roles'][module_name];
      for(let k in user_roles){
        exit_user_roles[k] = user_roles[k]
      }
      use_storage.result['user_roles'][module_name] = exit_user_roles
      site_layout.result.use_storage = use_storage;
      localStorage.setItem("site_layout", JSON.stringify(site_layout))
    }
    if(localStorage.getItem('contactlist')!==null){
      let contactlist = JSON.parse(localStorage.getItem('contactlist'))
      let contact_user_roles = contactlist.result['user_roles'][module_name];
      for(let k in user_roles){
        contact_user_roles[k] = user_roles[k]
      }
      contactlist.result['user_roles'][module_name] = contact_user_roles;
      localStorage.setItem("contactlist", JSON.stringify(contactlist))
    }

  }
}

export default new Reload();
