import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import ReactModal from 'react-awesome-modal';
import { Container, Row, Col } from 'react-grid-system';
import { FaPlus } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import AddCommitteeModal from './AddCommitteeModal';
import GCPanel from '../Panel/GCPanel';
import onClickOutside from "react-onclickoutside";


const distinct = (value, index ,self) => {
	return self.indexOf(value) === index;
}

const AddCommiteeButton = styled.button`
    background-color: #04ADA8;
    color: #ffffff; 
    border-radius: 5px;
    border: 1px solid #04ADA8;
    padding: 3px 18px;
    position: relative;
    background-image: linear-gradient(to bottom, #04ADA8 50%, #04ADA8);
`;
const AddCommiteeLeftText = styled.div`
        float:left;
        color: #1A3552;
        font-weight: 600;
		margin-left: 30px;
`;
const ModalBox = styled.div`
    position: fixed;
    width: 40%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
  `;
const ModalBoxBody = styled.div`
 padding:10px;
  background: #fff;
`;
class AddCommitee extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            visible : false,
            show: false,
            isHideInactiveCommitteeMeetingChecked: false,
            show_company_dropdown: false,
            selected_company_in_dropdown: {gc_id : '-100' , company_name : 'All'}, // default
        }
      }

      handleClickOutside = (event) => {
        // event.preventDefault();
          if(this.state.show_company_dropdown) {
            this.setState({
                          show_company_dropdown : false
                      })
          }
      };

    
      openModal = () => {
        Store.updateStore('OverlayHeight', `${document.body.scrollHeight.toString()}px`);
        this.setState({
            visible : !this.state.visible,
            show : !this.state.show
        });
      }

      openDemoModal = () => {
        Store.updateStore('modal_overlay', `${document.body.scrollHeight.toString()}px`);
        Store.updateStore('modal_visibility', true);
      }

	  handleInactiveCommitteeChange = event => {
			//Disabled as prevents checkboxes being clicked
		  // event.preventDefault();
		  let isChecked = this.state.isHideInactiveCommitteeMeetingChecked ? false : true;
		  this.setState({isHideInactiveCommitteeMeetingChecked: isChecked})
		  this.props.getInactiveResponse({'showInactiveCommittee' : isChecked});
	  }
      
    
      closeModal = () => {
          this.setState({
              visible : false
          });
      }

      get_companies = (committees,company_arr) => {
            
			for(let gm of committees.children) {
				let gc_id = (typeof gm.json_data.gc_id === 'undefined') ? 0 : gm.json_data.gc_id;
				let exists = (typeof company_arr.find(company => company.gc_id === gc_id) === 'undefined' ? company_arr.push({company_id : gm.company_id , company_name : gm.company_name , gc_id : parseInt(gc_id) }) : company_arr) ;
				if(gm.children.length > 0){this.get_companies(gm,company_arr)}
				//company_arr = (!company_arr.includes(gm.company_id)) ? {company_id : }: ;
			}

			return company_arr;
      }
      showCompanyDropDown = (event)  => {
		  event.preventDefault();
          this.setState({
              show_company_dropdown : !this.state.show_company_dropdown,
          });

      }
	  hideCompanyDropdown = (event)  => {
		  event.preventDefault();
		  
		  let str = event.target.value;
		  //console.log("hideCompanyDropdown event.target=>"+str);
		  if(str !== '{item.gc_id}' && str !== '-100'){
              console.log("hideCompanyDropdown in here=>");
			  this.setState({
				  show_company_dropdown : false,
			  });			  
		  }



      }
      selectCompany = (gc_id , company_name)  => {
		  //event.preventDefault();
		  //console.log(gc_id);
          //this.setState({
          //    selected_company_in_dropdown : {'gc_id' : gc_id , 'company_name' : company_name},
          //})
          this.props.committeeFilterByCompany({'gc_id' : gc_id , 'company_name' : company_name});
      }
     
    
    
    render(){

        let committeecount = Store.getStoreData('filtered_committees').length

		let gov_committees = Store.getStoreData('gov_committees');
		//console.log("gov_committees=>",gov_committees);
        let parent_has_group_companies = Store.getStoreData('parent_has_group_companies');
		let companies = [];
		if(gov_committees !== null) {
			let tmp_companies = this.get_companies(gov_committees,companies);
            companies = tmp_companies.filter((tmp_companies, index, self) => index === self.findIndex((t) => (t.gc_id === tmp_companies.gc_id )))
		}
        //console.log("companies=>",companies);

		//return <div />;
        
        return(
            <div >
                <AddCommiteeLeftText><p>Total Committees {committeecount}</p></AddCommiteeLeftText>
				{
					(() => {
						if(parent_has_group_companies) return (
							<GCPanel changeGC={this.props.changeGC} currentGCid={this.props.current_gcid} display={true} updateDisplayOption={this.props.updateDisplayOption}/>
						);
					})()
				}
				<div style={{float: "right", marginTop : "10px" , marginRight : "40px" , color: "#1A3552" , fontWeight: "600"}}>
					Show Inactive Committees &nbsp;
					<input type="checkbox" id="checkbox_showinactivecommittees" checked={this.state.isHideInactiveCommitteeMeetingChecked} onClick={this.handleInactiveCommitteeChange}/>

				</div>

				{/*
					(() => {
						if(companies.length > 1){
							return (
								<div style={{float: "right", marginRight : "200px" , color: "#1A3552" , fontWeight: "600"}} onMouseDown={this.hideCompanyDropdown}>
									<div style={{float: "left"}}>Show :&nbsp;</div> 
									<div style={{backgroundColor: "#ffffff" , width : "250px", height : "20px" , float: "right"}} onClick={this.showCompanyDropDown} >
										<div style={{float: "left"}}>
											&nbsp;&nbsp;{this.state.selected_company_in_dropdown.company_name}
										</div>
										<div style={{float: "right"}}>
											<FiChevronDown onClick={this.showCompanyDropDown}/>
										</div>
									</div>
									{
										(() => {
											if(this.state.show_company_dropdown){
												let that = this;
												let companyList = companies.map(function(item , index){
													let inner_checked = (that.state.selected_company_in_dropdown.gc_id.toString() === item.gc_id.toString()) ? 'checked': '';
													return <div style={{padding: "0px 5px 10px 5px", fontWeight: "bold", cursor: "pointer"}} ><input class="radioclass" type="radio" name="box" value="{item.gc_id}" onClick={()=>that.selectCompany(item.gc_id , item.company_name)} checked={inner_checked} /> &nbsp;{item.company_name}</div>;
												});
												let all_checked = (that.state.selected_company_in_dropdown.gc_id.toString() === '-100') ? 'checked': '';
												companyList.unshift(<div style={{padding: "0px 5px 10px 5px", fontWeight: "bold", cursor: "pointer"}} ><input class="radioclass" type="radio" name="box"  value="-100" onClick={()=>that.selectCompany(-100, 'All')} checked={all_checked} /> &nbsp;All</div>);
												return(
													<div  style={{backgroundColor: "#ffffff", boxShadow: "rgb(167,166,166) 0px 0px 5px" , zIndex: "1001" , display : "block" , position: "absolute" , marginTop: "30px" , marginLeft : "50px" , height : "130px" , overflowY: "scroll"}}>
														{companyList}
													</div>
												);												
											}

										})()
									}

								</div>
							);
						}
					})()
				*/}

                <div style={{clear:"both"}}></div>
            </div>
        );
    }
}
export default onClickOutside(AddCommitee);