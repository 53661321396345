import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import APICall from '../../Common/APICall.js';
import { MdClose } from 'react-icons/md';
import { FaTimes } from "react-icons/fa";
import { saveAs } from 'file-saver';
import Dropzone from '../Common/Dropzone.js';
import '../Common/Dropzone.css';
import * as Style from '../Common/StyledComponents';

const ReactModalHeader = styled.div`
    padding: 10px 15px;
    background: linear-gradient(to bottom, #04ADA8 0%, #04ADA8 100%);
    color:#fff;
`;
const ModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 25%;
    height: 369px;
    background: #ffffff;
    border: 1px solid #868484;
    z-index: 1001;
    position: absolute;
    margin: 0 20%;
    border-radius: 6px;
    left: 20%;
    top: 60%;
`;
const CloseDiv = styled.div`
    box-sizing: border-box;
    cursor:pointer;
`;
const UploadAgendaDocumentFile = styled.div`
    padding: 10px;
    background: #f9f9f9;
    margin: 13px 0;
    color: black;
`;

/*  */
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 12px;
    font-weight: 600;
    height: 80px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151;  
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: rgb(243, 247, 251);
    padding: 10px;
    border-radius: 4px;
	min-height: 300px;
	max-height: 450px;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: 400px;
    width: 96%;
    position: absolute;
    top: -170px;
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 25px;
`;





class ViewCommitteeDescription extends React.Component{
    constructor(){
        super();
        this.state = {
             viewmode: true,
        }
    }

    componentDidMount() {
        this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
   }

    closeViewAgendaModal = () => {
        this.props.closeDialog();
    }

    render(){

        return(
            <MRModalContainer>
				<MRModalHeader>
					<MRModalHeaderText>{this.props.committee_name} Purpose </MRModalHeaderText>
					<MRModalHeaderCloseBtn onClick={this.closeViewAgendaModal}><FaTimes /></MRModalHeaderCloseBtn>
					<div style={{clear: "both"}}></div>
					<MRModalSubHeaderText></MRModalSubHeaderText>
					<div style={{clear: "both"}} ref={el => { this.el = el; }}></div>
				</MRModalHeader>
                <MRModalBody style={{overflowY: "scroll" ,maxWidth: "100%",wordWrap: "break-word" , maxHeight: "300px" }}>
				<div>{this.props.committee_desc}</div>
				<div style={{clear: "both",marginTop: "25px"}}></div>
				</MRModalBody>
				<MRModalFooter>
				</MRModalFooter>
            </MRModalContainer>
        );
    }
}

export default ViewCommitteeDescription;