import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import APICall from '../../Common/APICall.js';
import { MdClose } from 'react-icons/md';
import { FaTimes } from "react-icons/fa";
import { saveAs } from 'file-saver';
import Dropzone from '../Common/Dropzone.js';
import '../Common/Dropzone.css';
import * as Style from '../Common/StyledComponents';

const ReactModalHeader = styled.div`
    padding: 10px 15px;
    background: linear-gradient(to bottom, #04ADA8 0%, #04ADA8 100%);
    color:#fff;
`;
const ModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 25%;
    height: 369px;
    background: #ffffff;
    border: 1px solid #868484;
    z-index: 1001;
    position: absolute;
    margin: 0 20%;
    border-radius: 6px;
    left: 20%;
    top: 60%;
`;
const CloseDiv = styled.div`
    box-sizing: border-box;
    cursor:pointer;
`;
const UploadAgendaDocumentFile = styled.div`
    padding: 10px;
    background: #f9f9f9;
    margin: 13px 0;
    color: black;
`;

/*  */
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 12px;
    font-weight: 600;
    height: 80px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151;  
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: rgb(243, 247, 251);
    padding: 10px;
    border-radius: 4px;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 100%;
    position: absolute;
    top: -85px;
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: -25px !important;
`;





class ViewMiscDoc extends React.Component{
    constructor(){
        super();
        this.state = {
             viewmode: false,
        }
    }
	componentDidUpdate(){
		
	}

    closeModal = () => {
        //Store.updateStore('ViewMinutesOverlay', null);
        Store.updateStore('ViewMiscVisibility', false);
        this.props.closeDialog();
    }
	downloadFileToBrowser = (file_name,result) => {
		let bin_file = result.base64filedata.bin_file;
		let fileName = result.base64filedata.name;
        let dnld = bin_file.replace(/ /g, '+');
        // console.log("binary file before download:", dnld);
        var binaryString = window.atob(dnld);
        // console.log('binaryString', binaryString);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        var blob = new Blob([bytes]);
        saveAs(blob, fileName);
	}
	downloadAttachment = (file_id,file_name) => {
		let that = this;
		let postData = { command: "downloadFile", data: { file_id : file_id  }};
		let api = new APICall();
		api.command(postData, function callback(result){that.downloadFileToBrowser(file_name,result)});
	}
    render(){
		let meetingDataViewMode = Store.getStoreData('meetingDataViewMode');
	    let finalData = JSON.parse(meetingDataViewMode.Json_Data);
        //console.log("finalData", finalData);
		let misc_curBinFiles = [];
		let ac_assoc = {};

		for(let item of finalData.miscattachments){
			if('status' in item && item.status==='deleted')continue;
			if(!(item.file_name.toString() in ac_assoc)){
				misc_curBinFiles.push({uid : item.file_id , name : item.file_name});
				ac_assoc[item.file_name.toString()]=true;
			}
		}
        return(
            <MRModalContainer>
				<MRModalHeader>
					<MRModalHeaderText>Miscellaneous attachments</MRModalHeaderText>
					<MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
					<div style={{clear: "both"}}></div>
					<MRModalSubHeaderText></MRModalSubHeaderText>
					<div style={{clear: "both"}}></div>
				</MRModalHeader>
                <MRModalBody>
				<div style={{width: "88%"}}>
					<div style={{padding: "10px"}}>
						<Style.ModalNameInputDiv >
                        {
                            (() => {
                                if(finalData.miscattachments.length === 0) {
                                    return "No documentation has been uploaded for this meeting.";
                                }else{
                                    return <Dropzone onFilesAdded={this.filesLoaded} initFiles={misc_curBinFiles} height={"200px"} onRemoveFile={this.filesRemoved} showFileButton={false} handleDownload={this.downloadAttachment}/>
                                }
                            })()
                        }
						</Style.ModalNameInputDiv>
					</div>
				</div>
				</MRModalBody>
				<MRModalFooter>
						<div style={{ textAlign: "right" , marginTop: "20px"}}>

						</div>
				</MRModalFooter>
            </MRModalContainer>
        );
    }
}

export default ViewMiscDoc;