import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Store from '../../Common/Store.js';
import {FaAngleRight , FaTrash } from 'react-icons/fa';
import {FaTimes ,FaPlusCircle} from 'react-icons/fa';
import Utils from '../../Common/Utils.js';
import CSLTable from '../Common/CSLTable';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';


const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';



const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    width: 94%;
    left: 3%;
`;

const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 22px;
    color: #a0a0a0;
    cursor: pointer;
`;

const MRModalInput = styled.input`
    height: 26px;
    width: 84%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    margin-top: 15px;
    font-family: Montserrat,sans-serif
`;

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
const CatBtn = styled.button`
    border-radius: 4px;
    padding: 13px 37px;
    color: #ffffff;
    cursor: pointer;
`;
const RiskSelect = styled.select`
    margin-top: 15px;
    box-sizing: border-box;
    height: 35px;
    width: 86%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
	font-weight: bold;
`;
const RiskBtn = styled.button`
  background-color: #213E61;
  border: 1px solid #213E61;
  border-radius: 4px;
  padding: 9px 37px;
  color: #ffffff;
  cursor: pointer;
`;




class MeetingInviteesModal extends React.Component
{

    state = {
		     cur_tab: "invited",
		     committe_name: "", meeting_date: null, invited_members: [], attended_members: [], absent_members: [],
		    };

    componentDidMount() {

        const meetingInviteeMeetingList = Store.getStoreData("MeetingInviteeMeetingList");
        const meetingInviteeMeetingId = Store.getStoreData("MeetingInviteeMeetingId");
        const meetingInviteeFlag = Store.getStoreData("MeetingInviteeFlag");
        const meetingInviteeCommitteeName = Store.getStoreData("MeetingInviteeCommitteeName");
		let employee_assoc = Store.getStoreData('employee_assoc');

		//console.log("meetingInviteeMeetingId==>",meetingInviteeMeetingId);
		//console.log("meetingInviteeMeetingList==>",meetingInviteeMeetingList);

        let single = {};
        for (let element in meetingInviteeMeetingList) {
            // console.log("meetingInviteeMeetingList[element]", meetingInviteeMeetingList[element]);
            if(parseInt(meetingInviteeMeetingList[element].meetingid) === parseInt(meetingInviteeMeetingId)) {
                single = meetingInviteeMeetingList[element];
            }
        }

        let dtarr = single.Json_Data.meetingdate.split('/');

        let invites = [];
        let attends = [];
        let absents = [];

        for(let i=0;i<single.Json_Data.attendeemembers.length;i++){
            if(typeof single.Json_Data.attendeemembers[i].employeeid !== 'undefined'){
                single.Json_Data.attendeemembers[i].is_guest = 'No';
                invites.push(single.Json_Data.attendeemembers[i]);
            }
        }
        for(let i=0;i<single.Json_Data.guestmembers.length;i++){
            if(typeof single.Json_Data.guestmembers[i].employeeid !== 'undefined'){
                single.Json_Data.guestmembers[i].is_guest = 'Yes';
                single.Json_Data.guestmembers[i].committeerolename = '';
                invites.push(single.Json_Data.guestmembers[i]);
            }
        }
        for(let i=0;i<single.Json_Data.additionalguestmembers.length;i++){
            if(typeof single.Json_Data.additionalguestmembers[i].employeeid !== 'undefined' && single.Json_Data.additionalguestmembers[i].additionalguestname.trim() !== ''){
                single.Json_Data.additionalguestmembers[i].employeename = single.Json_Data.additionalguestmembers[i].additionalguestname;
                single.Json_Data.additionalguestmembers[i].committeerolename = '';
                single.Json_Data.additionalguestmembers[i].is_guest = 'Yes';
                invites.push(single.Json_Data.additionalguestmembers[i]);
            }
        }
        for(let ele of invites) {
			let employee_name = ele.employeename;
			if('employeeid' in ele && ele.employeeid.toString() in employee_assoc && !employee_assoc[ele.employeeid.toString()].is_active)employee_name=ele.employeename+' (Inactive)';
			ele.employeename = employee_name;
            if(ele.attended === "Yes") {
                attends.push(ele);
            }
            if(ele.attended === "No"){
                absents.push(ele);
            }
        }
        let meeting_date = dtarr[0]+'/'+dtarr[1]+'/'+dtarr[2];

        this.setState({committe_name : meetingInviteeCommitteeName , meeting_date : meeting_date , invited_members : invites , attended_members : attends , absent_members : absents , cur_tab : meetingInviteeFlag});




        //console.log("single", invites)
        //console.log("attended_members", attends)
        //console.log("single", absents)

		



       /* meetingInviteeMeetingList.map(m => {
			if(parseInt(meetingInviteeMeetingId) === parseInt(m.meetingid)){
				let dtarr = m.Json_Data.meetingdate.split('/');
				let dt = dtarr[2]+'-'+dtarr[1]+'-'+dtarr[0];
				let invited_members = [];
				let attended_members = [];
				let absent_members = [];
				for(let i=0;i<m.Json_Data.attendeemembers.length;i++){
					if(typeof m.Json_Data.attendeemembers[i].employeeid !== 'undefined'){
						invited_members.push(m.Json_Data.attendeemembers[i]);
					}
				}
				for(let i=0;i<m.Json_Data.guestmembers.length;i++){
					if(typeof m.Json_Data.guestmembers[i].employeeid !== 'undefined'){
						invited_members.push(m.Json_Data.guestmembers[i]);
					}
				}
				if(new Date() < new Date(dt)){
					//attendedcount = '-';
					//absentcount = '-';
				}else{
					for(let i=0;i<m.Json_Data.attendeemembers.length;i++){
						if(typeof m.Json_Data.attendeemembers[i].attended !== 'undefined'){
							if(m.Json_Data.attendeemembers[i].attended === 'Yes'){
								attended_members.push(m.Json_Data.attendeemembers[i]);
							}
							if(m.Json_Data.attendeemembers[i].attended === 'No'){
								absent_members.push(m.Json_Data.attendeemembers[i]);
							}
						}
					}
					for(let i=0;i<m.Json_Data.guestmembers.length;i++){
						if(typeof m.Json_Data.guestmembers[i].attended!='undefined'){
							if(m.Json_Data.guestmembers[i].attended === 'Yes'){
								attended_members.push(m.Json_Data.guestmembers[i]);
							}
							if(m.Json_Data.guestmembers[i].attended === 'No'){
								absent_members.push(m.Json_Data.guestmembers[i]);
							}
						}
					}
				}
				
				//let committee_name = committeeList[committeeList.findIndex(x => x.PK_CommitteeID == m.Json_Data.committeeid)].committeename;
				let meeting_date = dtarr[0]+'/'+dtarr[1]+'/'+dtarr[2];

				//console.log(meetingInviteeCommitteeName);
				//console.log(meeting_date);
				//console.log(invited_members);
				//console.log(attended_members);
				//console.log(absent_members);

				this.setState({committe_name : meetingInviteeCommitteeName , meeting_date : meeting_date , invited_members : invited_members , attended_members : attended_members , absent_members : absent_members , cur_tab : meetingInviteeFlag});
			}



			 
			

		});*/
    }



    closeModal = (event) => {
        event.preventDefault();
		this.props.closeModal();
    }

    changeTab = (tab) => (event) => {
        event.preventDefault();
        this.setState({cur_tab: tab});
    }

    exportToExcel = (cur_tab) =>  {

		let members1 = [];
        let members2 = [];
        let members3 = [];
		let fileName = 'meeting_attendee_list';
		
		/*
        switch (cur_tab) {
			case "invited":
				fileName = 'meetinginvitedlist'
				let invited_members = this.state.invited_members;
                //console.log("invited_members==>",invited_members);
                for(let i=0;i<invited_members.length;i++){
					let elm ={
						'Name' : invited_members[i].employeename,
						'Role' : invited_members[i].committeerolename,
						'Required' : (invited_members[i].required === 'Required') ? 'Yes': 'No',
                        'Guest' : invited_members[i].is_guest
					};
                    members1.push(elm);
                }
                
			case "attended":
				fileName = 'meetingattendedlist';
				let attended_members = this.state.attended_members;
                //console.log("attended_members==>",attended_members);
                for(let i=0;i<attended_members.length;i++){
					let elm ={
						'Name' : attended_members[i].employeename,
						'Role' : attended_members[i].committeerolename,
						'Required' : (attended_members[i].required === 'Required') ? 'Yes': 'No',
                        'Guest' : attended_members[i].is_guest
					};
                    members2.push(elm);
                }
			case "absent":
				fileName = 'meetingabsentlist';
				let absent_members = this.state.absent_members;
                //console.log("absent_members==>",absent_members);
                for(let i=0;i<absent_members.length;i++){
					let elm ={
						'Name' : absent_members[i].employeename,
						'Role' : absent_members[i].committeerolename,
						'Required' : (absent_members[i].required === 'Required') ? 'Yes': 'No',
                        'Guest' : absent_members[i].is_guest
					};
                    members3.push(elm);
                }
                break;
             default:
				 fileName = '';
				 break;
		}*/

		let invited_members = this.state.invited_members;
		let attended_members = this.state.attended_members;
		let absent_members = this.state.absent_members;
		for(let i=0;i<invited_members.length;i++){
			let elm ={
				'Name' : invited_members[i].employeename,
				'Role' : invited_members[i].committeerolename,
				'Required' : (invited_members[i].required === 'Required') ? 'Yes': 'No',
				'Guest' : invited_members[i].is_guest
			};
			members1.push(elm);
		}
		for(let i=0;i<attended_members.length;i++){
			let elm ={
				'Name' : attended_members[i].employeename,
				'Role' : attended_members[i].committeerolename,
				'Required' : (attended_members[i].required === 'Required') ? 'Yes': 'No',
				'Guest' : attended_members[i].is_guest
			};
			members2.push(elm);
		}
		for(let i=0;i<absent_members.length;i++){
			let elm ={
				'Name' : absent_members[i].employeename,
				'Role' : absent_members[i].committeerolename,
				'Required' : (absent_members[i].required === 'Required') ? 'Yes': 'No',
				'Guest' : absent_members[i].is_guest
			};
			members3.push(elm);
		}


       let wb = {SheetNames:[], Sheets:{}};
        const ws1 = XLSX.utils.json_to_sheet(members1); 
        wb.SheetNames.push("INVITED"); 
		wb.Sheets["INVITED"] = ws1;

        const ws2 = XLSX.utils.json_to_sheet(members2); 
        wb.SheetNames.push("ATTENDED"); 
		wb.Sheets["ATTENDED"] = ws2;

        const ws3 = XLSX.utils.json_to_sheet(members3); 
        wb.SheetNames.push("ABSENT"); 
		wb.Sheets["ABSENT"] = ws3;

        const format1 = "DD-MM-YYYY HH:mm:ss"
        let tm = moment().format(format1);

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + '-' + tm + fileExtension);
      
    }







    render()
    {
        console.log("state:", this.state);
        if (this.state.meeting_date === null) {
            return <div>Loading...</div>
        }
        return (
            <div>
                <MRModalContainer>
                    <div style={{height: "80px", width: "100%", borderLeft: "14px solid #2AACA5", boxSizing: "border-box"}}>
                        <div style={{float: "left", fontSize: "16px", fontWeight: "600", padding: "28px"}}>Meeting Invitees <br /> <div style={{float: "left", fontSize: "14px", fontWeight: "400"}}> {this.state.committe_name} Meeting - {this.state.meeting_date}</div> </div>
                        <MRModalHeaderCloseBtn onClick={this.closeModal} style={{padding: "28px"}}><FaTimes /></MRModalHeaderCloseBtn>
                        <div style={{clear: "both"}}></div>
                    </div>
                    <div style={{backgroundColor: "#F1F6F7", padding: "25px 0px 25px 43px"}}>
                        
                        <div style={{marginTop: "15px", width: "100%", boxSizing: "border-box"}}>
                            <div style={{float: "left", fontWeight: "600", fontSize: "14px", color: this.state.cur_tab === "invited" ? "#40B6B0" : "#000000", cursor: this.state.cur_tab === "invited" ? "default" : "pointer"}} onClick={this.changeTab("invited")}>INVITED</div>
                            <div style={{float: "left", fontWeight: "600", fontSize: "14px", color: this.state.cur_tab === "attended" ? "#40B6B0" : "#000000", marginLeft: "30px", cursor: this.state.cur_tab === "attended" ? "default" : "pointer"}} onClick={this.changeTab("attended")}>ATTENDED</div>
                            <div style={{float: "left", fontWeight: "600", fontSize: "14px", color: this.state.cur_tab === "absent" ? "#40B6B0" : "#000000", marginLeft: "30px", cursor: this.state.cur_tab === "absent" ? "default" : "pointer"}} onClick={this.changeTab("absent")}>ABSENT</div>
                            <div style={{clear: "both"}}></div>
                        </div>

                        <div style={{backgroundColor: "#ffffff", padding: "5px", width: "95%", marginTop: "15px"}}>
                        {
                            (() => {
                                if (this.state.cur_tab === "invited") {
                                    return (
                                        <div style={{width: "100%", boxSizing: "border-box", backgroundColor: "#F3F7FB"}}>
                                            <div style={{float: "left", width: "50%", fontSize: "14px", fontWeight: "600", padding: "15px 0px 15px 15px", boxSizing: "border-box"}}>Name</div>
                                            <div style={{float: "left", width: "20%", fontSize: "14px", fontWeight: "600", padding: "15px 0px", boxSizing: "border-box"}}>Guest?</div>
                                            <div style={{float: "left", width: "30%", fontSize: "14px", fontWeight: "600", padding: "15px 0px", boxSizing: "border-box"}}>Required?</div>
                                            <div style={{clear: "both"}}></div>
                                        </div>
                                    );
                                }
                                if (this.state.cur_tab === "attended") {
                                    return (
                                        <div style={{width: "100%", boxSizing: "border-box", backgroundColor: "#F3F7FB"}}>
                                            <div style={{float: "left", width: "50%", fontSize: "14px", fontWeight: "600", padding: "15px 0px 15px 15px", boxSizing: "border-box"}}>Name</div>
                                            <div style={{float: "left", width: "20%", fontSize: "14px", fontWeight: "600", padding: "15px 0px", boxSizing: "border-box"}}>Guest?</div>
                                            <div style={{float: "left", width: "30%", fontSize: "14px", fontWeight: "600", padding: "15px 0px", boxSizing: "border-box"}}>Required?</div>
                                            <div style={{clear: "both"}}></div>
                                        </div>
                                    );
                                }
                                if (this.state.cur_tab === "absent") {
                                    return (
                                        <div style={{width: "100%", boxSizing: "border-box", backgroundColor: "#F3F7FB"}}>
                                            <div style={{float: "left", width: "50%", fontSize: "14px", fontWeight: "600", padding: "15px 0px 15px 15px", boxSizing: "border-box"}}>Name</div>
                                            <div style={{float: "left", width: "20%", fontSize: "14px", fontWeight: "600", padding: "15px 0px", boxSizing: "border-box"}}>Guest?</div>
                                            <div style={{float: "left", width: "30%", fontSize: "14px", fontWeight: "600", padding: "15px 0px", boxSizing: "border-box"}}>Required?</div>
                                            <div style={{clear: "both"}}></div>
                                        </div>
                                    );
                                }
                            })()
                        }
                        {
                            (() => {
                                if (this.state.cur_tab === "invited") {
                                    return (
                                        <div style={{overflowY: "scroll", height: "250px"}}>
                                            {
                                                this.state.invited_members.map((ra, index) => {
                                                    return (
                                                        <div key={index} style={{width: "100%", boxSizing: "border-box"}}>
                                                            <div style={{float: "left", width: "50%", fontSize: "12px", fontWeight: "600", padding: "21px 0px 0px 15px", boxSizing: "border-box"}}>{ra.employeename}</div>
                                                            <div style={{float: "left", width: "20%", fontSize: "12px", fontWeight: "600", padding: "21px 0px 0px 15px", boxSizing: "border-box"}}>
                                                                {ra.is_guest}
                                                            </div>
                                                            <div style={{float: "left", width: "30%", fontSize: "12px", fontWeight: "600", padding: "21px 0px 0px 15px", boxSizing: "border-box"}}>
                                                                {ra.required}
                                                            </div>
                                                            <div style={{clear: "both"}}></div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    );
                                }
                                if (this.state.cur_tab === "attended") {
                                    return (
                                        <div style={{overflowY: "scroll", height: "250px"}}>
                                            {
                                                this.state.attended_members.map((ra, index) => {
                                                    return (
                                                        <div key={index} style={{width: "100%", boxSizing: "border-box"}}>
                                                            <div style={{float: "left", width: "50%", fontSize: "12px", fontWeight: "600", padding: "21px 0px 0px 15px", boxSizing: "border-box"}}>{ra.employeename}</div>
                                                            <div style={{float: "left", width: "20%", fontSize: "12px", fontWeight: "600", padding: "21px 0px 0px 15px", boxSizing: "border-box"}}>
                                                                {ra.is_guest}
                                                            </div>
                                                            <div style={{float: "left", width: "30%", fontSize: "12px", fontWeight: "600", padding: "21px 0px 0px 15px", boxSizing: "border-box"}}>
                                                                {ra.required}
                                                            </div>
                                                            <div style={{clear: "both"}}></div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    );
                                }
                                if (this.state.cur_tab === "absent") {
                                    return (
                                        <div style={{overflowY: "scroll", height: "250px"}}>
                                            {
                                                this.state.absent_members.map((ra, index) => {
                                                    return (
                                                        <div key={index} style={{width: "100%", boxSizing: "border-box"}}>
                                                            <div style={{float: "left", width: "50%", fontSize: "12px", fontWeight: "600", padding: "21px 0px 0px 15px", boxSizing: "border-box"}}>{ra.employeename}</div>
                                                            <div style={{float: "left", width: "20%", fontSize: "12px", fontWeight: "600", padding: "21px 0px 0px 15px", boxSizing: "border-box"}}>
                                                                {ra.is_guest}
                                                            </div>
                                                            <div style={{float: "left", width: "30%", fontSize: "12px", fontWeight: "600", padding: "21px 0px 0px 15px", boxSizing: "border-box"}}>
                                                                {ra.required}
                                                            </div>
                                                            <div style={{clear: "both"}}></div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    );
                                }
                            })()
                        }
                        </div>

                    </div>

                    <div style={{padding: "15px 25px 15px 0px"}}>
						<div style={{float : "left" , marginLeft : "40px" }} >  <RiskBtn onClick={()=>this.exportToExcel(this.state.cur_tab)} >Export</RiskBtn></div>
                        <CatBtn style={{backgroundColor: "#ffffff", border: "1px solid #F0F1F1", color: "black", float: "right"}} onClick={this.closeModal}>Cancel</CatBtn>
                        <div style={{clear: "both"}}></div>
                    </div>
                </MRModalContainer>
                {/* <AlertBox alertParam = {this.state.alert_param}/> */}
            </div>
        );
    }
}

export default MeetingInviteesModal;