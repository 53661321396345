import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import { Container, Row, Col } from 'react-grid-system';
import { FaPlus } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';

const ReactModalHeader = styled.div`
    padding: 10px 15px;
    background: linear-gradient(to bottom, #214570 0%, #426489 100%);
    color:#fff;
`;
const ReactModalBody = styled.div`
    position: relative;
    padding: 15px;
`;
const ReactFormGroup = styled.div`
    margin-bottom: 15px;
`;
const UploadReferenceFileButton = styled.button`
    background-color: #115d9c;
    color: #ffffff; 
    border-radius: 5px;
    border: 1px solid #115d9c;
    padding: 3px 18px;
    background-image: linear-gradient(to bottom, #3e7bb4 50%, #115d9c);
`;
const UploadReferenceFile = styled.div`
    padding: 10px;
    background: #f9f9f9;
    margin: 13px 0;
    color: black;
`;
const FormSubmitButton = styled.button`
    color: #fff;
    background: linear-gradient(to top, #429842 0%, #56b156 100%);
    box-shadow: inset 0px 1px 1px 0px #73c173;
    width: 116px;
    padding: 6px 16px;
    border: 1px solid #429842;
    border-radius:3px;
    margin-right:10px;
`;
const FormCancelButton = styled.button`
    color: #fff;
    background: linear-gradient(to top, #c3312d 0%, #d7504c 100%);
    box-shadow: inset 0px 1px 1px 0px #de6b68;
    width: 116px;
    padding: 6px 16px;
    border: 1px solid #ac2925;
    border-radius:3px;
    margin-right:10px;
`;
const CloseDiv = styled.div`
    box-sizing: border-box;
    cursor:pointer;
`;
const ModalContainer1 = styled.div`
    display: block;
    box-sizing: border-box;
    width: 25%;
    height: 100%;
    background: #ffffff;
    border: 1px solid #000000;
    z-index: 1001;
    position: absolute;
    margin: 0 20%;
`;
class AddCommitteeModal extends React.Component{

    noModal = () => {
        Store.updateStore('modal_overlay', null);
        Store.updateStore('modal_visibility', false);
    }
   
    render(){
        
        return(
            <div> 
                
                <ModalContainer1>
                    <ReactModalHeader>
                        <div style={{float:"left"}}>Add Committee</div>
                        <div style={{float:"right"}}><CloseDiv onClick={this.noModal}><MdClose/></CloseDiv></div>
                        <div style={{clear:"both"}}></div>
                    </ReactModalHeader>
                    
                </ModalContainer1>
                      
            </div>
            
        );
    }
}
export default AddCommitteeModal;