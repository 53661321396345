import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils.js';
import APICall from '../../Common/APICall.js';
import AlertBox from '../Common/AlertBox.js';
import ViewCommitteeDescription from './ViewCommitteeDescription.js';
import CommitteeEmailModal from './CommitteeEmailModal.js';
import { FaExclamationCircle , FaEye , FaUsers , FaTasks, FaRegEnvelope } from 'react-icons/fa';
import { MdEdit , MdDelete } from 'react-icons/md';
import { confirmAlert } from 'react-confirm-alert';
import CSLLoaderNonPromise from '../Common/CSLLoaderNonPromise';

const CommitteeDiv = styled.div`
    padding:22px 10px 9px 9px;
    margin-top:10px;
    background-color: #E7F0F7 ;
    border-style: solid;
    border-size: 1px;
    border-color: #CBD2D9;
    color: #63758A;
    display: inline-block;
    width: 100%;
    height: 74px;
    position:relative;
    font-weight: 700;
    font-size:14px;
`;
const RolesDiv = styled.div`
    padding:2px 9px 9px 9px;
    margin-top:2px;
    background-color: #FFFFFF;
    color: #1BB2B2;
    font-weight: 500;
    font-size:12px;
	
`
const CompanyNameDiv = styled.div`
	min-width: 15%;
	max-width: 15%;
	color: #1A3552;
	float: left;
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap;
    /*margin-right: 300px;*/
	
`
const NameDiv = styled.div`
    // width: 150px;
	color: #1A3552;
	float: left;
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap;
`
const PurposeDiv = styled.div`
	width: 20%;
	color: #1A3552;
	float: left;
    text-overflow: ellipsis; 
    overflow: hidden;
	cursor:pointer;
	/*word-wrap: break-word;
	display: -webkit-box;*/
    white-space: nowrap;
	/*word-break: break-all;*/
   /*-webkit-line-clamp: 2;*/ /* number of lines to show */
   /*-webkit-box-orient: vertical;*/
   
`

const PurposeDivNonGc = styled.div`
	width: 50%;
	color: #1A3552;
	float: left;
    text-overflow: ellipsis; 
    overflow: hidden;
	cursor:pointer;
	/*word-wrap: break-word;
	display: -webkit-box;*/
    white-space: nowrap;
	/*word-break: break-all;*/
   /*-webkit-line-clamp: 2;*/ /* number of lines to show */
   /*-webkit-box-orient: vertical;*/
   
`

const EntryDiv = styled.div`
	width: 100%;
	font-size: 12px;
	font-weight: 700;
	padding-left: 3px;
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap;
`

const Connector = styled.div`
	width: 18px;
	border-bottom-style: solid;
	border-left-style: solid;
	border-left-size: 4px;
	border-bottom-size: 4px;
	border-color: #1A3552;
	position: absolute;
`;

const ViewDiv = styled.div`
    box-sizing: border-box;
    cursor:pointer;
    position:relative;
    margin-right:3px;
`;
const CommiteeListIcons = styled.span`
        top: -2px;
        position: relative;
        font-size: 12px;
        padding-left: 3px;
`;

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1200px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  top: -202px;
  z-index: 995;
  opacity: 0.8;
  margin-left: -25px;
`;

class Committee extends React.Component {
	state = {ready: false, alert_param: null, is_loader:  false,modal_view: null}

	componentDidMount = () => {
		let members = Store.getStoreData('members')
		this.setState({ready: true, members: members})
	}
	componentDidUpdate = (prevprops) => {
		if(this.props === prevprops){
			return;
		}else{
			if(this.state.alert_param !== null)return;
		}
	}
	renderCommitteeEdit = (id) => {
		let h_id = "edit_committee_" + id;
         return (
            <ViewDiv style={{ curser: "pointer" ,color : "#1A3552"}} onClick={() => this.openEditModal(id)}   title="edit committee" id={h_id} ><MdEdit /></ViewDiv>
         )
    }

    renderCommitteeDelete = (id) => {
         return (
            <ViewDiv style={{ curser: "pointer" ,color : "#1A3552"}} onClick={this.openDeleteModal(id)}   title="delete committee" ><MdDelete /></ViewDiv>
         )
    }
    openEditModal = (PK_CommitteeID) => {

        Store.updateStore('modal_committee_Edit_CommitteeID', PK_CommitteeID);
		this.props.AddModal('committee_edit');
    }

	openDeleteModal = (PK_CommitteeID) => (event) => {
		event.preventDefault()
		let alert_param = {title: 'Alert', message: 'Are you sure you want to delete this Committee?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true, 
						   alertHandler: this.deleteProcessor, stack: {id: PK_CommitteeID}}
		this.setState({alert_param: alert_param})
		
	}

	deleteProcessor = (result, stack) => {
		if(!result || stack.prevent) {
			this.setState({alert_param: null})
			return
		}
		this.setState({alert_param: null,is_loader:true});
		setTimeout(() => {
			let PK_CommitteeID = stack.id;
			let postData = { command: "deleteCommittee", data:{committeeid: PK_CommitteeID} };
			let api = new APICall();
			let that=this;
			api.command(postData, function callback(result){that.processDeleteCommittee(result,PK_CommitteeID)});
		}, 30);


	}

	processDeleteCommittee = (result,PK_CommitteeID) => {
		//console.log("result delete", result);

		let title = "Success";
		let message = "Committee has been deleted";
		if(result.error_code === 1){
			title = "Alert";
			message = "This committee can not be deleted as meetings are associated."
		}

		let alert_param = {title: title, message: message, ok_text: 'OK', confirm: false, 
						   alertHandler: this.deleteSuccessProcessor, stack: {error_code: result.error_code , PK_CommitteeID: PK_CommitteeID}}
		this.setState({alert_param: alert_param,is_loader:false})
	}

	  
	deleteSuccessProcessor = (result, stack) => {
		if(result){
			if(stack.error_code === 0){
				let updated_committees_with_count = [];
				let updated_committees_for_meeting_records = [];
				let committee_with_count = Store.getStoreData('committee_with_count');
				let meeting_records = Store.getStoreData('meeting_records');
				for(let c of committee_with_count.committees){
					if(stack.PK_CommitteeID.toString() === c.PK_CommitteeID.toString())continue;
					updated_committees_with_count.push(c);
				}
				for(let c of meeting_records.committees){
					if(stack.PK_CommitteeID.toString() === c.PK_CommitteeID.toString())continue;
					updated_committees_for_meeting_records.push(c);
				}
				committee_with_count.committees = updated_committees_with_count;
				meeting_records.committees = updated_committees_for_meeting_records;
				Store.updateStore('committee_with_count',committee_with_count);
				Store.updateStore('meeting_records',meeting_records);
				this.props.refreshCommitteeTree();
				this.setState({alert_param: null});
			}else{
				this.setState({alert_param: null});
			}			
		}

		if(!result || stack.prevent) {
			this.setState({alert_param: null})
		}

	}
    renderRightSideIcons = (id,meetingcount,notecount) =>{

		 if(id > 0){

			 return (
					<div style={{float: "right" ,color: "#757575", fontSize: "15px", display : "flex" ,  width : "135px" , marginRight: "1px" }}>
						{this.props.committee.is_member_has_email_access ? this.renderCommitteeEmailView(id) : <ViewDiv>&nbsp;&nbsp;&nbsp;</ViewDiv>} &nbsp;
						{Store.getStoreData('permissions')['view_committee_details'] ? this.renderCommitteeView(id) : ''} &nbsp;
						{this.renderCommitteeMeeting(id,meetingcount)}  &nbsp;
						{this.renderCommitteeTasks(id)}
						{Store.getStoreData('permissions')['edit_committee'] ? this.renderCommitteeEdit(id) : ''} &nbsp;
						{Store.getStoreData('permissions')['delete_committee'] ? this.renderCommitteeDelete(id) : ''}
					</div>
			 )			
		 }else{
			 return (
					<div style={{float: "right" ,color: "#757575", fontSize: "15px", display : "flex" ,  width : "135px" , marginRight: "1px" }}>
                         &nbsp;
					</div>
			 )			
		 }

    }
	renderCommitteeTasks = (id) => {
         return (
            <ViewDiv style={{ title: "tasks count"}} onClick={() => this.openTasksListModal(id,Store.getStoreData('task_count_hash')[id])}  title="view outstanding tasks"><FaTasks style={{ title: "tasks count" ,color : "#1A3552"}}/><CommiteeListIcons >{Store.getStoreData('task_count_hash')[id]}</CommiteeListIcons></ViewDiv>
         )
    }
	renderCommitteeView = (id) => {
         return (
            <ViewDiv onClick={() => this.openViewModal(id)} title="view committee"><FaEye onClick={() => this.openViewModal(id)} style={{color : "#1A3552"}}/></ViewDiv>
         )
    }
	renderCommitteeEmailView = (id) => {
         return (
            <ViewDiv onClick={() => this.openCommitteeEmailModal(id)} title="committee emails access"><FaRegEnvelope onClick={() => this.openCommitteeEmailModal(id)} style={{color : "#1A3552"}}/></ViewDiv>
         )
    }
    openViewModal = (PK_CommitteeID) => {
        Store.updateStore('modal_committee_Details_CommitteeID', PK_CommitteeID);
		this.props.AddModal('committee_view');
    }
    //openEmailModal = (PK_CommitteeID) => {
    //    Store.updateStore('modal_committee_Details_CommitteeID', PK_CommitteeID);
		//this.props.AddModal('committee_view');
    //}

    openTasksListModal = (PK_CommitteeID , task_count) => {
		if(task_count > 0 ){
			Store.updateStore('ViewCommitteeTasksListVisibility', true);
			Store.updateStore('modal_committee_tasks_View_CommitteeID', PK_CommitteeID);
			this.props.AddModal('committee_tasks_list');
		}else{
			// alert("There are currently no tasks assigned to this committee.");
			let message = 'There are no tasks assigned to this committee.'
            let alert_param = {
                title: 'Alert', message: message, ok_text: 'OK', confirm: false,
                alertHandler: this.handlesubmitcheck, stack: {}
            }

            this.setState({ alert_param: alert_param })
            return false;
		}


    }
    
    handlesubmitcheck = (result, stack) => {
        //console.log(result,stack)
        this.setState({ alert_param: null })
    }
    
	renderCommitteeMeeting = (id,meetingcount) => {
         return (
            <ViewDiv style={{ curser: "pointer" }} onClick={() => this.openMeetingInterface(id,Store.getStoreData('meeting_count_hash')[id])}  title="view meeting"><FaUsers onClick={() => this.openMeetingInterface(id,Store.getStoreData('meeting_count_hash')[id])} style={{color : "#1A3552"}}/><CommiteeListIcons onClick={() => this.openMeetingInterface(id,Store.getStoreData('meeting_count_hash')[id])} style={{color : "#1A3552"}}>{Store.getStoreData('meeting_count_hash')[id]}</CommiteeListIcons></ViewDiv>
         )
    }
    openMeetingInterface = (PK_CommitteeID,meetingCount) => {
		if(!meetingCount){
			this.openMeetingModal(false);
		}else{
			Store.updateStore('filters',null);
			this.passCommitteIdToParent(PK_CommitteeID);
		}
    }
    openMeetingModal = (editmode) => {
        Store.updateStore('EditMode', editmode);
		this.props.AddModal('meeting_new');
    }
    passCommitteIdToParent = (PK_CommitteeID) => {
		Store.updateStore('committee_filter_for_meeting_data', true);
        this.props.getCommitteIdForAdvancedSearch({'committeeid' : PK_CommitteeID});
    }
    passCommitteIdForAdvancedSearch = (childData) => {
        this.props.getCommitteIdForAdvancedSearch(childData);
    }
	AddModal = (modal_name) => {
        this.props.AddModal(modal_name);  
	}
	openCommitteeDescriptionModal = (committee_name,committee_desc) =>{
		this.setState({committee_name,committee_desc,modal_view: 'openCommitteeDescriptionModal'});
	}
	openCommitteeEmailModal = (committee_id) =>{
		this.setState({modal_view: 'openCommitteeEmailModal', committee_id});
	}
    closeDialog = () =>{
		this.setState({committee_name: '',committee_desc: '',modal_view: null, committee_id: null});
    }
    refreshCommitteeTree =() => {
		this.props.refreshCommitteeTree();
    }
	render() {
		console.log('Committee props,', this.props.committee,this.props.filtered_gc_list_assoc)
		if(!this.state.ready)return (<div></div>);
		// return (<div></div>);
		let parent_has_group_companies = Store.getStoreData('parent_has_group_companies');
		let indent = this.props.committee.level * 30

		let inActiveStr = "";
		if(!this.props.committee.is_active){
		    inActiveStr = " (Inactive) ";
		}
		let text_color = '#1A3552';
		if(!this.props.committee.is_member_of_committee){
		    text_color = '#7c7c7c';
		}
		let committee_div_bg_color = '#E7F0F7' ; //default
		let gc_id = this.props.committee.gc_id;
		/*if(typeof this.props.committeeFilterByCompany !== 'undefined' && Object.keys(this.props.committeeFilterByCompany).length > 0){
			if(this.props.committeeFilterByCompany.gc_id.toString() === '-100' || this.props.committeeFilterByCompany.gc_id.toString() === gc_id.toString()){
				committee_div_bg_color = '#E7F0F7';
			}else{
				committee_div_bg_color = '#FFFFFF';				
			}
		}*/
		if(typeof this.props.filtered_gc_list_assoc !== 'undefined' && Object.keys(this.props.filtered_gc_list_assoc).length > 0){
			//console.log('Committee props,', gc_id,this.props.filtered_gc_list_assoc[gc_id])
			if(this.props.filtered_gc_list_assoc[gc_id] === 1 ){
				committee_div_bg_color = '#E7F0F7';
			}else{
				committee_div_bg_color =  (this.props.company_display_option === 'select') ? '#CCCCCC' : '#FFFFFF';				
			}
		}

		let company_name_position_adjustment = this.props.committee.level * 4;
		let purpose_position_adjustment = this.props.committee.level * 30;

		let connector_top = this.props.committee.prev_y + 100 -16;
		let connector_height = this.props.committee.y - connector_top + 36;
		let connector_left = (this.props.committee.level - 1) * 30 + 2;
		//console.log('Committee connector_top, connector_height', this.props.committee.name,this.props.committee.level, this.props.committee.y, this.props.committee.prev_y ,connector_top, connector_height)
		let companies = Store.getStoreData('companies_assoc')
		//console.log('committee', this.props.committee, this.props.filtered_gc_list_assoc, companies)
		return (
				<div>
				{
					(() => {
						if(this.state.is_loader) {
							return  <div><CSLLoaderNonPromise /></div>
						}

					})()
				}
				{
					(() => {
						if(this.props.committee.level > 0)return <Connector style={{height: connector_height, top: connector_top, left: connector_left}} />
					})()
				}
				{
					(() => {
						if(this.state.modal_view !== null && this.state.modal_view === 'openCommitteeDescriptionModal')return <div><InactiveOverlay/><ViewCommitteeDescription committee_name={this.state.committee_name} committee_desc={this.state.committee_desc} closeDialog={this.closeDialog}/></div>
					})()
				}
				{
					(() => {
						if(this.state.modal_view !== null && this.state.modal_view === 'openCommitteeEmailModal')return <div><InactiveOverlay/><CommitteeEmailModal closeDialog={this.closeDialog} committee_id={this.state.committee_id}/></div>
					})()
				}
					
					<CommitteeDiv style={{marginLeft: indent, width: `calc(100% - 60px - ${indent}px)` , backgroundColor: `${committee_div_bg_color}`, height: "30px"}}>
						<NameDiv title={this.props.committee.name} style={{color : text_color , width : "calc(12%)"}}>{this.props.committee.name}<br/><div style={{color: "#ff3333"}}>{inActiveStr}</div></NameDiv>
				{
					(() => {
						if(parent_has_group_companies){
							return(
								    <div>
									<CompanyNameDiv title={this.props.committee.company_name} style={{color : text_color , marginLeft : `calc(100px + ${company_name_position_adjustment}px - ${indent}px)`}}>{this.props.committee.company_name}</CompanyNameDiv>
									<PurposeDiv title={'Click here to view the purpose'} style={{color : text_color , marginLeft : `calc(125px + ${purpose_position_adjustment}px - ${indent}px)`}} onClick={()=>this.openCommitteeDescriptionModal(this.props.committee.name,this.props.committee.purpose)}>{this.props.committee.purpose}</PurposeDiv>
								    </div>
								);
						}else{
							return(
								    <div>
									<PurposeDivNonGc title={'Click here to view the purpose'} style={{color : text_color , marginLeft : `calc(100px + ${company_name_position_adjustment}px - ${indent}px)`}} onClick={()=>this.openCommitteeDescriptionModal(this.props.committee.name,this.props.committee.purpose)}>{this.props.committee.purpose}</PurposeDivNonGc>
								    </div>
								);						
						}

					})()
				}
		                {(this.props.committee.is_member_of_committee) ? this.renderRightSideIcons(this.props.committee.id,0,0) : this.renderRightSideIcons(0,0,0)}
					
					</CommitteeDiv>
					<AlertBox alertParam = {this.state.alert_param} />
					{
						(() => {
							if(this.props.showRoles) {
								return (
										<RolesDiv style={{width: `calc(100% - 60px`}}>
											<PurposeDiv>
											{
												this.props.committee.members.map((item, i) => {
													return (<EntryDiv>{item.committeerolename}</EntryDiv>)
												})
											}
											{
												this.props.committee.alt_members.map((item, i) => {
													if(item.thirdpartyembername !== '')return (<EntryDiv>{item.thirdpartyemberreason}</EntryDiv>)
												})
											}
											</PurposeDiv>
											<CompanyNameDiv>
											{
												this.props.committee.members.map((item, i) => {
													return (<EntryDiv>{this.state.members[item.employeeid]}</EntryDiv>)
												})
											}
											{
												this.props.committee.alt_members.map((item, i) => {
													if(item.thirdpartyembername !== '')return (<EntryDiv>{item.thirdpartyembername}</EntryDiv>)
												})
											}
											</CompanyNameDiv>
										</RolesDiv>
									)
							}
						})()
					}
					{
						this.props.committee.children.map((item, i) => {
                            if(item.show){
								return (
										<Committee 
											key={i} 
											committee={item}
											horizontalGap={this.props.horizontalGap}
											showRoles={this.props.showRoles}
											getCommitteIdForAdvancedSearch={this.passCommitteIdForAdvancedSearch}
											showInactiveCommittee={this.props.showInactiveCommittee}
											committeeFilterByCompany={this.props.committeeFilterByCompany}
											AddModal={this.AddModal}
											refreshCommitteeTree={this.refreshCommitteeTree}
											filtered_gc_list_assoc={this.props.filtered_gc_list_assoc}
											company_display_option={this.props.company_display_option}
										/>
									);
							}

						})
					}

					
					
				</div>
			)
	}
}

export default Committee