import React from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';


const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 5000px;
  width: 100%;
  background-color: #ffffff;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 20000;
  opacity: 0.1;
`;

class CSLProgressbar extends React.Component {

    static propTypes = {
        /** */
        value: PropTypes.number,
        /** current ID*/
        max: "100",
    };

    render() {
        //console.log('Inside CSLProgressbar loaded %==>',this.props.value);
        return (
            <div style={{width:'100%'}}>
                <progress style={{width:'calc(100% - 20px)',zIndex: '100'}} value={this.props.value} max={100}/>
                <span style={{marginLeft:'5px'}}>{this.props.value}%</span>
            </div>
        )
    }
}

export default CSLProgressbar;