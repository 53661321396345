import React from 'react';
import CommiteeTab from './CommiteeTab'
import RunExport from '../RunExport'
import MeetingTab from './MeetingTab'
import Store from '../../Common/Store';
import APICall from '../../Common/APICall.js';
import styled from 'styled-components';
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';


const AddCommiteeButton = styled.button`
    color: #fff;
    box-shadow: inset 0px 1px 1px 0px #1A3552;
    width: 156px;
    padding: 6px 16px;
    border: 1px solid #1A3552;
    border-radius:3px;
    background:#1A3552;
`;
const ScheduleMeetingButton = styled.button`
    color: #fff;
    box-shadow: inset 0px 1px 1px 0px #1A3552;
    width: 156px;
    padding: 6px 16px;
    border: 1px solid #1A3552;
    border-radius:3px;
    background:#1A3552;

`;

class PageTab extends React.Component{
	state = { 
		        tab:{committee:1,meeting:0},
		        ready: false,
			}
	constructor(props) {
		super(props);
	}

    handleButton =(event) =>{
        event.preventDefault();
        var tab = this.state.tab;
        var current_view = null;
        tab.committee = 0;
        tab.meeting = 0;
        if([event.target.value] == 'committee'){
            tab.committee = 1;
            current_view = 'committee';
			Store.updateStore('committee_clicked',true);
			console.log("committee_clicked",Store.getStoreData('committee_clicked'));
        }
        if([event.target.value] == 'meeting'){
            tab.meeting = 1;
            current_view = 'meeting';
        }
        console.log("tab", tab)
        this.props.refreshParrentCallback(current_view) 
        this.setState({tab : tab}) 
    }

    AddModal = (view) => (event) => {
    	this.props.AddModal(view)
    }


    render(){
       console.log("PageTab state:", this.state);
       return(
       	<div>
       	<Tabs>
       		<TabList style={{marginLeft: "5px" , background: "#E7F0F7" , height : "50px" , width: "100%" }}>
	       		{
	       		    (() =>{
	       		          if(this.state.tab.committee === 1){
	       		            return <Tab tabFor="one" value="committee" className='tab-active' onClick={this.handleButton} style={{marginLeft: "20px" , marginTop : "15px" ,fontFamily: "Montserrat, sans-serif" , cursor: "pointer"}}>COMMITTEES</Tab>
	       		          }else{
	       		            return <Tab tabFor="one" value="committee" className='tab' onClick={this.handleButton} style={{marginLeft: "20px" , marginTop : "15px" ,fontFamily: "Montserrat, sans-serif" , cursor: "pointer"}}>COMMITTEES</Tab>
	       		          }
	       		    })()
	       		}
	       		{
	       		    (() =>{
	       		          if(this.state.tab.meeting === 1){
	       		            return <Tab tabFor="one" value="meeting" className='tab-active' onClick={this.handleButton} style={{marginLeft: "20px" , marginTop : "15px" ,fontFamily: "Montserrat, sans-serif", cursor: "pointer"}}>MEETINGS</Tab>
	       		          }else{
	       		            return <Tab tabFor="one" value="meeting" className='tab' onClick={this.handleButton} style={{marginLeft: "20px" , marginTop : "15px" ,fontFamily: "Montserrat, sans-serif", cursor: "pointer"}}>MEETINGS</Tab>
	       		          }
	       		    })()
	       		}
	       		
       			{(this.state.tab.committee === 1 && Store.getStoreData('permissions')['add_committee'])? <AddCommiteeButton id="button_addcommittee" style={{float: "right" , marginRight: "40px" , marginTop : "10px", cursor: "pointer" }} onClick={this.AddModal('committee_new')} >Add Committee</AddCommiteeButton> : null }
       			{(this.state.tab.meeting === 1 && Store.getStoreData('permissions')['add_meeting'])? <ScheduleMeetingButton id="button_schedulemeeting" style={{float: "right" , marginRight: "40px" , marginTop : "10px", cursor: "pointer" }} onClick={this.AddModal('meeting_new')} >Schedule Meeting</ScheduleMeetingButton> : null }
	       		
       		</TabList>
       		</Tabs>
       	</div> 
       );

    }
}

export default PageTab;