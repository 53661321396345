import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import APICall from '../../Common/APICall.js';
import CSLLoader from '../Common/CSLLoader';
import CSLLoaderNonPromise from '../Common/CSLLoaderNonPromise';
import CommitteeUtils from '../Common/CommitteeUtils';
import reportData from './reportData.js';
import filters from './reportFilters.js';
import RegisterReporting from './CSLRegister/RegisterReporting';
import MeetingInviteesModal from './MeetingInviteesModal';
import { FaEye, FaPen } from "react-icons/fa";
import { MdDelete } from 'react-icons/md';
import { confirmAlert } from 'react-confirm-alert';
import AlertBox from '../Common/AlertBox.js';
import moment from 'moment';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';


const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 2200px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0%;
  top: -100px;
  z-index: 1001;
  opacity: 0.9;
`;
const ViewDiv = styled.div`
    box-sizing: border-box;
    cursor:pointer;
`;

const months = {
	'01': 'January',
	'02': 'February',
	'03': 'March',
	'04': 'April',
	'05': 'May',
	'06': 'June',
	'07': 'July',
	'08': 'August',
	'09': 'September',
	'10': 'October',
	'11': 'November',
	'12': 'December'
}


function stringToDate(_date, _format, _delimiter) {
	var formatLowerCase = _format.toLowerCase();
	var formatItems = formatLowerCase.split(_delimiter);
	var dateItems = _date.split(_delimiter);
	var monthIndex = formatItems.indexOf("mm");
	var dayIndex = formatItems.indexOf("dd");
	var yearIndex = formatItems.indexOf("yyyy");
	var month = parseInt(dateItems[monthIndex]);
	month -= 1;
	var formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
	return formatedDate;
}

const FloatRight = {
	float: "right"
}

const metingschedule = [
	{ id: "0", display: "Select Status", value: "0", selected: true },
	{ id: "Scheduled", display: "Scheduled", value: "Scheduled", selected: true },
	{ id: "Cancelled", display: "Cancelled", value: "Cancelled", selected: false },
	{ id: "Completed", display: "Completed", value: "Completed", selected: false }
];
const MeetingListHead = ["COMMITTEE", "MEETING DATE", "STATUS", "ORGANISER", "INVITED", "ATTENDED", "ABSENT", "TASKS"];

class Meetings extends React.Component {
	constructor(props) {
		super(props);
	}

	state = {
		ready: false,
		meetingList: [],
		prefered_columns: {},
		view_count: 0,
		meetingsData: [],
		tasks_list_hash: {},
		alert_param: null,
		trans_time: null,
		refresh: true,
		clear_filter_done: true,
		filters: {},
	}

	componentDidMount() {
		//console.log("in meeting list table componentDidMount props==>",this.props);
		let committee_filter_for_meeting_data = Store.getStoreData('committee_filter_for_meeting_data')
		let meeting_records = Store.getStoreData('meeting_records');
		if (committee_filter_for_meeting_data) {
			Store.updateStore('committee_filter_for_meeting_data', false);
		}
		let filters_1 = JSON.parse(JSON.stringify(filters));
		//let postData = { command: "meetingListWithCommitteesMembers" , data:{ employee_id: 0} };
		//let api = new APICall();
		//api.command(postData, this.processIndexData);
		this.processIndexData(meeting_records, filters_1);

	}

	componentDidUpdate(prevProps, prevState) {
		//console.log("in meeting list table componentDidUpdate props==>",this.props);
		//console.log("in meeting list table componentDidUpdate prevProps==>",prevProps);
		//console.log("updateSearchFlag==>"+this.state.updateSearchFlag);
		//console.log("prevProps==>"+JSON.stringify(prevProps));
		//console.log("currentProps==>"+JSON.stringify(this.props));
		//console.log("in MeetingListTable componentDidUpdate==>"+JSON.stringify(this.props.filterSearchJson));

		if (prevProps !== this.props && this.state.refresh) {
			let postData = { command: "meetingListWithCommitteesMembers", data: {  employee_id: 0 } };
			let api = new APICall();
			api.command(postData, this.processIndexData);

		}
	}

	processIndexData = (result, filters_input) => {
		console.log("meetings result==>", result);
		var meetingsData = [];
		var committeeList = {};
		var meetingList = [];
		var that = this;
		let committeeUtils = new CommitteeUtils();

		let version_hash = {};
		let task_hash_count = {};
		let tasks_list_hash = {};

		let committee_input_json = { committees: result.committees, meetings: result.meetings, tasks_list: result.tasks_list };
		let committeeWithCount_json = committeeUtils.convertToCommitteesWithCount(committee_input_json);
		let committee_meeting_date_assoc = committeeWithCount_json.committee_meeting_date_hash;
		Store.updateStore('committee_with_count', committeeWithCount_json);
		Store.updateStore('meeting_records', committee_input_json);
		Store.updateStore('raw_meetings_list', result.meetings);
		Store.updateStore('followup_tasks_list', result.tasks_list);
		Store.updateStore('committee_meeting_date_assoc', committee_meeting_date_assoc);
		//console.log("committeeWithCount_json,",committeeWithCount_json);
		//committee_with_count

		result.meetings.map(r => {
			let task_ref_id = JSON.parse(r.Json_Data).task_ref_id;
			version_hash[task_ref_id] = r.PK_CommitteeMettingID;
			task_hash_count[task_ref_id] = 0;
			tasks_list_hash[task_ref_id] = [];
		});
		//console.log("task_hash_count==>",task_hash_count);
		//console.log("tasks_list_hash==>",tasks_list_hash);
		result.tasks_list.map(r => {
			let task_ref_id = r.ref_id;
			if (typeof tasks_list_hash[task_ref_id] === 'undefined') {
				tasks_list_hash[task_ref_id] = [];
				task_hash_count[task_ref_id] = 0;
			}
			task_hash_count[task_ref_id] = task_hash_count[task_ref_id] + 1;

			tasks_list_hash[task_ref_id].push(r);
		});
		//console.log("tasks_list_hash=>",tasks_list_hash);
		result.meetings.map(r => {
			if (r.PK_CommitteeMettingID === version_hash[JSON.parse(r.Json_Data).task_ref_id]) {
				meetingsData.push({ meetingid: r.PK_CommitteeMettingID, Json_Data: JSON.parse(r.Json_Data), task_count: task_hash_count[JSON.parse(r.Json_Data).task_ref_id], FK_CMPClientID: r.FK_CMPClientID, FK_CommitteeID: r.FK_CommitteeID });
			}

		});
		result.committees.map(r => {
			//committeeList.push({ PK_CommitteeID: r.PK_CommitteeID, committeename: JSON.parse(r.Json_Data).committeename });
			committeeList[r.PK_CommitteeID.toString()] = JSON.parse(r.Json_Data).committeename;
		});

		for (let m of meetingsData) {
			if (!(m.FK_CommitteeID.toString() in committeeList)) continue;
			//meetingsData.map(m => {
			//let actionscount = m.Json_Data.addActionDivReturnData.length >0 ? m.Json_Data.addActionDivReturnData.length : '0';
			let actionscount = m.task_count;
			let invitedcount = 0;
			let attendedcount = 0;
			let absentcount = 0;
			console.log("meetingsData,", m.Json_Data.meetingdate, m.Json_Data.attendeemembers, m.Json_Data.guestmembers, m.Json_Data.additionalguestmembers);
			let dtarr = m.Json_Data.meetingdate.split('/');
			let dt = dtarr[2] + '-' + dtarr[1] + '-' + dtarr[0];
			for (let i = 0; i < m.Json_Data.attendeemembers.length; i++) {
				if (typeof m.Json_Data.attendeemembers[i].employeeid !== 'undefined') {
					invitedcount = invitedcount + 1;
				}
			}
			for (let i = 0; i < m.Json_Data.guestmembers.length; i++) {
				if (typeof m.Json_Data.guestmembers[i].employeeid !== 'undefined') {
					invitedcount = invitedcount + 1;
				}
			}
			for (let i = 0; i < m.Json_Data.additionalguestmembers.length; i++) {
				if (typeof m.Json_Data.additionalguestmembers[i].employeeid !== 'undefined') {
					if (m.Json_Data.additionalguestmembers[i].additionalguestname !== '') invitedcount = invitedcount + 1;
				}
			}
			if (new Date() < new Date(dt)) {
				attendedcount = '-';
				absentcount = '-';
			} else {
				for (let i = 0; i < m.Json_Data.attendeemembers.length; i++) {
					if (typeof m.Json_Data.attendeemembers[i].attended !== 'undefined') {
						if (m.Json_Data.attendeemembers[i].attended === 'Yes') {
							attendedcount = attendedcount + 1;
						}
						if (m.Json_Data.attendeemembers[i].attended === 'No') {
							absentcount = absentcount + 1;
						}
					}
				}
				for (let i = 0; i < m.Json_Data.guestmembers.length; i++) {
					if (typeof m.Json_Data.guestmembers[i].attended !== 'undefined') {
						if (m.Json_Data.guestmembers[i].attended === 'Yes') {
							attendedcount = attendedcount + 1;
						}
						if (m.Json_Data.guestmembers[i].attended === 'No') {
							absentcount = absentcount + 1;
						}
					}
				}
				for (let i = 0; i < m.Json_Data.additionalguestmembers.length; i++) {
					if (typeof m.Json_Data.additionalguestmembers[i].attended !== 'undefined') {
						if (m.Json_Data.additionalguestmembers[i].attended === 'Yes') {
							if (m.Json_Data.additionalguestmembers[i].additionalguestname !== '') attendedcount = attendedcount + 1;
						}
						if (m.Json_Data.additionalguestmembers[i].attended === 'No') {
							if (m.Json_Data.additionalguestmembers[i].additionalguestname !== '') absentcount = absentcount + 1;
						}
					}
				}
			}

			let meeting_date_moment_unix = moment(stringToDate(m.Json_Data.meetingdate, "dd/MM/yyyy", "/")).unix();
			meetingList.push({
				meetingid: m.meetingid,
				//COMMITTEE: committeeList[committeeList.findIndex(x => x.PK_CommitteeID == m.Json_Data.committeeid)].committeename,
				COMMITTEE: committeeList[m.FK_CommitteeID.toString()],
				Meetingdate: m.Json_Data.meetingdate,
				STATUS: metingschedule[metingschedule.findIndex(x => x.id == m.Json_Data.meetingschedule)].display,
				ORGANISER: m.Json_Data.organiser,
				INVITED: invitedcount,
				ATTENDED: attendedcount,
				ABSENT: absentcount,
				Actions: actionscount,
				Attendees: m.Json_Data.attendeemembers,
				committeeid: m.Json_Data.committeeid,
				meetingdateasdtobj: stringToDate(m.Json_Data.meetingdate, "dd/MM/yyyy", "/"),
				task_ref_id: m.Json_Data.task_ref_id,
				meetingschedule: m.Json_Data.meetingschedule,
				FK_CMPClientID: m.FK_CMPClientID,
				sort_field: meeting_date_moment_unix.toString(),
			})
		}
		meetingList.sort(this.meetingDateCompare);
		let prefered_columns = {};
		prefered_columns['committee'] = 'Committee';
		prefered_columns['meetingdate'] = 'Meeting Date';
		prefered_columns['status'] = 'Status';
		prefered_columns['organiser'] = 'Organiser';
		prefered_columns['invited'] = 'Invited';
		prefered_columns['attended'] = 'Attended';
		prefered_columns['absent'] = 'Absent';
		prefered_columns['taskcount'] = 'Task';

		let refresh = (parseInt(this.props.prop_meeting_id) > 0 || parseInt(this.props.prop_task_id) > 0) ? false : true;
		let filter_1 = (typeof filters_input === 'undefined') ? this.state.filters : filters_input;
		this.setState({
			ready: true,
			meetingList: meetingList,
			prefered_columns: prefered_columns,
			meetingsData: meetingsData,
			tasks_list_hash: tasks_list_hash,
			refresh: refresh,
			filters: filter_1,
		}, () => {
			//console.log('prop_task_id in MeetingList==>', this.props.prop_task_id);
			if (parseInt(this.props.prop_task_id) > 0) {
				//this.openMeetingModalForTask(this.props.prop_task_id , version_hash);
			}
			if (parseInt(this.props.prop_meeting_id) > 0) {
				//console.log("IN Meetings this.props.prop_meeting_id,",this.props.prop_meeting_id);
				this.openAmendMeetingModalFromUrl(this.props.prop_meeting_id);
			}

		})

		//console.log('meetingList', meetingList)

	}
	meetingDateCompare = (a, b) => {
		return b.sort_field.localeCompare(a.sort_field)
	}
	processAcceptanceQueueData = () => {
		let view_meeting_permissions = Store.getStoreData('permissions')['view_meeting'];
		let edit_meeting_permissions = Store.getStoreData('permissions')['edit_meeting'];
		// console.log("edit_meeting_permissions", edit_meeting_permissions)
		let delete_meeting_permissions = Store.getStoreData('permissions')['edit_meeting'];
		let view_followup_task_in_meeting_permissions = Store.getStoreData('permissions')['view_followup_task_in_meeting'];
		let is_member_of_committee_hash = Store.getStoreData('is_member_of_committee_hash');
		let parent_has_group_companies = Store.getStoreData('parent_has_group_companies');
		let role = Store.getStoreData('role');
		let loggedin_user_info = Store.getStoreData('loggedin_user_info');
		let employee_contact_assoc = Store.getStoreData('employee_contact_assoc');
		console.log("is_member_of_committee_hash", is_member_of_committee_hash)

		let taskData = this.state.meetingList;
		console.log("taskData==>", taskData);
		let ret = { data: [], columns: [], raw_data: [] };
		ret.columns = [
			{ Header: 'Committee', accessor: 'committee', minWidth: 300, headerStyle: { textAlign: 'left' } },
			{
				Header: 'Meeting Date', accessor: 'meetingdate', width: 200, headerStyle: { textAlign: 'left' }, sortMethod: (a, b) => {

					if (a === b) {
						return 0;
					}
					let aReverse = 0;
					let bReverse = 0;
					let adue = a.split("/");
					aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
					let bbdue = b.split("/");
					bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
					return aReverse > bReverse ? 1 : -1;

				}
			},
			{ Header: 'Status', accessor: 'status', width: 150, headerStyle: { textAlign: 'left' } },
			{ Header: 'Organiser', accessor: 'organiser', width: 150, headerStyle: { textAlign: 'left' } },
			{
				Header: 'Invited', accessor: 'invited', width: 150, headerStyle: { textAlign: 'center' }, Cell: row => (
					<div style={{ textAlign: "center" }}>
						{
							(() => {
								if (parseInt(row.original.invited) > 0) {
									return (<div style={{ color: "#37ada7", textDecoration: "underline", cursor: "pointer" }} onClick={this.openMeetingInviteesModal(row.original.id, 'invited', row.original.committee)}>{this.getFormattedNumber(row.original.invited)}</div>);
								} else {
									return (<div >{this.getFormattedNumber(0)}</div>);
								}

							})()
						}
					</div>
				)
			},
			{
				Header: 'Attended', accessor: 'attended', width: 150, headerStyle: { textAlign: 'center' }, Cell: row => (
					<div style={{ textAlign: "center" }}>
						{
							(() => {
								if (parseInt(row.original.attended) > 0) {
									return (<div style={{ color: "#37ada7", textDecoration: "underline", cursor: "pointer" }} onClick={this.openMeetingInviteesModal(row.original.id, 'attended', row.original.committee)}>{this.getFormattedNumber(row.original.attended)}</div>);
								} else {
									return (<div >{this.getFormattedNumber(0)}</div>);
								}

							})()
						}
					</div>
				)
			},
			{
				Header: 'Absent', accessor: 'absent', width: 150, headerStyle: { textAlign: 'center' }, Cell: row => (
					<div style={{ textAlign: "center" }}>
						{
							(() => {
								if (parseInt(row.original.absent) > 0) {
									return (<div style={{ color: "#37ada7", textDecoration: "underline", cursor: "pointer" }} onClick={this.openMeetingInviteesModal(row.original.id, 'absent', row.original.committee)}>{this.getFormattedNumber(row.original.absent)}</div>);
								} else {
									return (<div >{this.getFormattedNumber(0)}</div>);
								}

							})()
						}
					</div>
				)
			},
			{
				Header: 'Task', accessor: 'taskcount', width: 150, headerStyle: { textAlign: 'center' }, style: { textAlign: 'center' }, Cell: row => (
					<div style={{ textAlign: "center" }}>
						{
							(() => {
								if (parseInt(row.original.taskcount) > 0) {
									return (<div style={{ color: "#37ada7", textDecoration: "underline", cursor: "pointer" }} onClick={this.openMeetingTasksListModal(row.original.id, row.original.task_ref_id, row.original.committee, row.original.meetingdateasdtobj, row.original.committeeid)}>{this.getFormattedNumber(row.original.taskcount)}</div>);
								} else {
									return (<div >{this.getFormattedNumber(0)}</div>);
								}

							})()
						}
					</div>
				)
			},
			{
				'Header': 'Action', Cell: row => (
					<div>
						{
							(() => {
								//need to restrict by role
								if (view_meeting_permissions && is_member_of_committee_hash[row.original.committeeid.toString()]) {
									return (<ViewDiv onClick={this.openAmendMeetingModal(row.original.id, row.original.task_ref_id)}><FaEye style={{ float: "left", marginRight: "14px" }} title="View Meeting" /></ViewDiv>);
								} else {
									return null;
								}
							})()
						}
						{
							(() => {
								//need to restrict by role
								if (role === 'admin_manager' || role === 'team') {
									return (<ViewDiv onClick={this.openMeetingModal(row.original.id, row.original.committeeid, row.original.task_ref_id)}><FaPen style={{ float: "left", marginRight: "14px" }} title="Amend Meeting" /></ViewDiv>);
								} else {
									if (edit_meeting_permissions && is_member_of_committee_hash[row.original.committeeid.toString()] && (row.original.meetingschedule.toString() === 'Scheduled' || row.original.meetingschedule.toString() === '0')) {
										return (<ViewDiv onClick={this.openMeetingModal(row.original.id, row.original.committeeid, row.original.task_ref_id)}><FaPen style={{ float: "left", marginRight: "14px" }} title="Amend Meeting" /></ViewDiv>);
									} else {
										return null;
									}
								}

							})()
						}
						{
							(() => {
								//if(Store.getStoreData('role') === 'admin_manager' && row.original.meetingschedule === 'Completed'){
								//	return (<ViewDiv onClick={this.openMeetingModal(row.original.id,row.original.committeeid)}><FaPen style={{ float: "left", marginRight: "14px" }} title="Amend Meeting"/></ViewDiv>);
								//}
							})()
						}
						{
							(() => {
								//need to restrict by role
								if (delete_meeting_permissions && is_member_of_committee_hash[row.original.committeeid.toString()] && row.original.meetingschedule === 'Scheduled') {
									return (<ViewDiv onClick={this.openDeleteModal(row.original.id)}><MdDelete style={{ float: "left", marginRight: "14px" }} title="delete this meeting" /></ViewDiv>);
								} else {
									return null;
								}
							})()
						}

					</div>
				), width: 150, headerStyle: { textAlign: 'left' }
			}];


		//console.log("Meetings is_member_of_committee_hash,",is_member_of_committee_hash);
		//console.log("Meetings is_member_of_committee_hash,",is_member_of_committee_hash);
		for (var j = 0; j < taskData.length; j++) {
			let asked_month = taskData[j].Meetingdate.split("/")[1];
			let select_user_obj = taskData[j].Attendees.find(c => 'employeeid' in c && 'employee_id' in employee_contact_assoc[loggedin_user_info.ID.toString()] && c.employeeid.toString() === employee_contact_assoc[loggedin_user_info.ID.toString()].employee_id.toString());
			if(role === 'team' && typeof select_user_obj === 'undefined')continue;

			let elem = {
				'index': j,
				'committee': taskData[j].COMMITTEE,
				'meetingdate': taskData[j].Meetingdate,
				'status': taskData[j].STATUS,
				'organiser': taskData[j].ORGANISER,
				'invited': taskData[j].INVITED,
				'attended': taskData[j].ATTENDED,
				'absent': taskData[j].ABSENT,
				'taskcount': taskData[j].Actions,
				'month': months[asked_month.toString()],
				'id': taskData[j].meetingid,
				'committeeid': taskData[j].committeeid,
				'task_ref_id': taskData[j].task_ref_id,
				'meetingdateasdtobj': taskData[j].meetingdateasdtobj,
				'meetingschedule': taskData[j].meetingschedule,
			};
			if (typeof this.props.passCommitteIdForAdvancedSearch !== 'undefined' && Object.keys(this.props.passCommitteIdForAdvancedSearch).length !== 0) {
				if (this.props.passCommitteIdForAdvancedSearch.committeeid.toString() === taskData[j].committeeid.toString()) {
					if (parent_has_group_companies && role !== 'admin_manager') {
						if (is_member_of_committee_hash[taskData[j].committeeid.toString()]) ret.data.push(elem);
						if (is_member_of_committee_hash[taskData[j].committeeid.toString()]) ret.raw_data.push(elem);
					} else {
						ret.data.push(elem);
						ret.raw_data.push(elem);
					}

				}
			} else {
				if (parent_has_group_companies && role !== 'admin_manager') {
					if (is_member_of_committee_hash[taskData[j].committeeid.toString()]) ret.data.push(elem);
					if (is_member_of_committee_hash[taskData[j].committeeid.toString()]) ret.raw_data.push(elem);
				} else {
					ret.data.push(elem);
					ret.raw_data.push(elem);
				}
			}

		}

		console.log("elem", ret)

		return ret;
	}

	exportMeetingXls = () => {
		console.log("exportMeetingXls");
		let record_xls = [];
		let fileName = 'meeting_report';
		let records = this.processAcceptanceQueueData().data;
		for (let r of records) {
			let elm = {
				'Committee Name': r.committee,
				'Meeting Date': r.meetingdate,
				'Status': r.status,
				'Organiser': r.organiser,
				'Invited': r.invited,
				'Attended': r.attended,
				'Absent': r.absent,
				'Task': r.taskcount,
				//'Notes Count' : `${day}-${month}-${year}`,
			};
			record_xls.push(elm);
		}

		let wb = { SheetNames: [], Sheets: {} };
		const ws1 = XLSX.utils.json_to_sheet(record_xls);
		wb.SheetNames.push("COMMITTEES");
		wb.Sheets["COMMITTEES"] = ws1;

		const format1 = "DD-MM-YYYY HH:mm:ss"
		let tm = moment().format(format1);

		const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, fileName + '-' + tm + fileExtension);
	}

	getFormattedNumber = (input_number) => {
		var number = (input_number) < 10 ? '0' + (input_number) : (input_number);
		return number;
	}

	openMeetingTasksListModal = (meetingid, task_ref_id, committee_name, meeting_date, committeeid) => (event) => {
		event.preventDefault();
		Store.updateStore('MeetingTasksListTaskListHash', this.state.tasks_list_hash);
		Store.updateStore('MeetingTasksListMeetingId', meetingid);
		Store.updateStore('MeetingTasksListCommitteeId', committeeid);
		Store.updateStore('MeetingTasksListCommitteeName', committee_name);
		Store.updateStore('MeetingTasksListTaskRefId', task_ref_id);
		Store.updateStore('MeetingTasksListMeetingDate', meeting_date);
		//Store.updateStore('MeetingTaskListModal', true);
		//this.setState({ open_meeting_invitees_modal: true });
		this.props.AddModal('meeting_taskslist');
	}

	openMeetingInviteesModal = (meetingid, flag, committee_name) => (event) => {
		event.preventDefault();
		Store.updateStore('MeetingInviteeMeetingList', this.state.meetingsData);
		Store.updateStore('MeetingInviteeMeetingId', meetingid);
		Store.updateStore('MeetingInviteeCommitteeName', committee_name);
		Store.updateStore('MeetingInviteeFlag', flag);
		//Store.updateStore('MeetingInviteeModal', true);
		//this.setState({ open_meeting_invitees_modal: true });
		this.props.AddModal('meeting_invitees');
	}

	openAmendMeetingModalFromUrl = (meetingid) => {
		this.setState({ ready: false });
		Store.updateStore('Meeting_view_meeting_id', meetingid);
		let postData = { command: "getMeetingByID", data: {  meetingid: meetingid } };
		let api = new APICall();
		api.command(postData, this.processOpenAmendMeetingModalData);


	}

	openAmendMeetingModal = (meetingid, task_ref_id) => (event) => {
		event.preventDefault();
		Store.updateStore('taskRefIdInMeeting', task_ref_id);
		Store.updateStore('Meeting_view_meeting_id', meetingid);
		this.props.AddModal('meeting_view');
		this.setState({ ready: false, trans_time: new Date().getTime() });
		/*this.setState({ ready: false , trans_time : new Date().getTime()});
		let postData = { command: "getMeetingByID" , data: {  meetingid: meetingid } , task_ref_id : task_ref_id };
		let api = new APICall();
		api.command(postData, this.processOpenAmendMeetingModalData);*/

	}

	processOpenAmendMeetingModalData = (result) => {
		//console.log("processOpenAmendMeetingModalData result=>",result);
		let loggedin_user_info = Store.getStoreData('loggedin_user_info');
		//console.log("processOpenAmendMeetingModalData loggedin_user_info=>",loggedin_user_info);
		let role = Store.getStoreData('role');
		if (result.FK_CMPClientID.toString() !== loggedin_user_info.ClientCompanyID.toString()) {
			let alert_param = { title: 'No Access', message: 'You are not authorised to access this part. If you think this is in error, please contact your administrator.', ok_text: 'Ok', cancel_text: 'No', confirm: false, alertHandler: this.taskAlertHandler, stack: {} };
			this.setState({ alert_param: alert_param });
		} else {
			let meeting_obj = JSON.parse(result.Json_Data);
			let contact_ids_of_members = [];
			for (let p of meeting_obj.contact_ids_of_members) {
				contact_ids_of_members.push(parseInt(p.FK_ContactID));
			}
			if (role !== 'admin_manager' && !contact_ids_of_members.includes(parseInt(loggedin_user_info.ID))) {
				let alert_param = { title: 'No Access', message: 'You are not authorised to access this part. If you think this is in error, please contact your administrator.', ok_text: 'Ok', cancel_text: 'No', confirm: false, alertHandler: this.taskAlertHandler, stack: {} };
				this.setState({ alert_param: alert_param });
			} else {
				Store.updateStore('meetingDataViewMode', result);
				//Store.updateStore('AmendMeetingOverlay', `${document.body.scrollHeight.toString()}px`);
				//Store.updateStore('AmendMeetingVisibility', true);
				this.props.AddModal('meeting_view');
				this.setState({ ready: true });
			}
		}


	}

	openMeetingModal = (meetingid, committeeid, task_ref_id) => (event) => {
		event.preventDefault();
		//this.setState({ ready: false },()=>{
		//	let postData = { command: "getMeetingByID" , data: {  meetingid: meetingid } };
		//	let api = new APICall();
		//	api.command(postData, this.processOpenMeetingModal);
		//});
		Store.updateStore('meetingIdEditMode', meetingid);
		Store.updateStore('committeeIdInMeeting', committeeid);
		Store.updateStore('taskRefIdInMeeting', task_ref_id);
		Store.updateStore('EditMode', true);
		this.props.AddModal('meeting_new');
		this.setState({ ready: true, refresh: true });
	}
	openMeetingModalForTask = (task_id, version_hash) => {
		//alert(meetingid)

		//Store.updateStore('meetingDataEditMode', null);
		let postData = { command: "getMeetingByTaskId", data: {  task_id: task_id } };;
		let api = new APICall();
		api.command(postData, this.processOpenMeetingModal);


	}



	openDeleteModal = (meeting_id) => (event) => {
		event.preventDefault();
		let alert_param = {
			title: 'Alert', message: 'Are you sure you want to delete this Meeting? All incomplete tasks will be deleted.', ok_text: 'OK', cancel_text: 'Cancel', confirm: true,
			alertHandler: this.deleteProcessor, stack: { meeting_id: meeting_id }
		}
		this.setState({ alert_param: alert_param })

	}

	deleteProcessor = (result, stack) => {
		if (!result || stack.prevent) {
			this.setState({ alert_param: null })
			return
		}
		this.setState({ alert_param: null, ready: false });
		setTimeout(() => {
			let meeting_id = stack.meeting_id;
			let postData = { command: "deleteMeeting", data: { meetingid: meeting_id } };
			let api = new APICall();
			api.command(postData, this.processDeleteMeeting);
		}, 30);

	}

	processDeleteMeeting = (result) => {
		let alert_param = {
			title: 'Success', message: 'Meeting has been deleted', ok_text: 'Ok', confirm: false,
			alertHandler: this.deleteSuccessProcessor, stack: { meetings: result.result.meetings }
		}
		this.setState({ alert_param: alert_param, ready: true })
	}


	deleteSuccessProcessor = (result, stack) => {
		this.setState({ alert_param: null })
		//let postData = { command: "meetingListWithCommitteesMembers" , data:{ employee_id: 0} };
		//let api = new APICall();
		//api.command(postData, this.processIndexData);
		this.processIndexData(stack.meetings);
	}

	openDeleteInterface = (meeting_id) => {
		const url = Store.getStoreData('api_url');

		let postData = { command: "deleteMeeting", data: { meetingid: meeting_id } };

		let httpVerb = { method: "POST", mode: "cors", headers: { "Content-Type": "application/x-www-form-urlencoded" }, body: "jsondata=" + JSON.stringify(postData) };
		fetch(url, httpVerb)
			.then((res) => res.json())
			.then(result => {
				console.log("result ==>" + JSON.stringify(result));
				if (result.error_code === 0) {
					let postData = { command: "meetingListWithCommitteesMembers", data: {  employee_id: 0 } };
					let api = new APICall();
					api.command(postData, this.processIndexData);
				}

			});
	}

	processOpenMeetingModal = (result) => {
		console.log("result ==>", result)
		if (result.error_code === 2001) {
			let alert_param = { title: 'No Access', message: 'You are not authorised to access this task. If you think this is in error, please contact your administrator.', ok_text: 'Ok', cancel_text: 'No', confirm: false, alertHandler: this.taskAlertHandler, stack: {} };
			this.setState({ alert_param: alert_param });
		} else {

			Store.updateStore('meetingDataEditMode', result);
			//Store.updateStore('MeetingOverlay', `${document.body.scrollHeight.toString()}px`);
			//Store.updateStore('MeetingVisibility', true);
			Store.updateStore('EditMode', true);
			this.props.AddModal('meeting_new');
			this.setState({ ready: true });
		}


	}
	taskAlertHandler = (result, stack) => {
		// this.setState({alert_param: null})
		window.location = '/';
	}

	refreshCallback = (jsonObj) => {
		Store.updateStore('filters', jsonObj.applied_filters);
		this.setState({ view_count: this.state.view_count + 1, filters: jsonObj.applied_filters })
	}




	render() {
		if (Object.keys(this.state.filters).length === 0) {
			return <div></div>
		}

		// return <div>RDR</div>
		//let filters = Store.getStoreData('filters');
		let filters1 = (Store.getStoreData('filters') !== null) ? Store.getStoreData('filters') : this.state.filters;

		return (
			<div>
				{
					(() => {
						if (!this.state.ready) {
							return <div><CSLLoaderNonPromise /></div>
						}

					})()
				}
				<RegisterReporting
					headerText='meetingList'
					moduleId='committees'
					processTableData={this.processAcceptanceQueueData}
					data={reportData}
					filters={filters1}
					preferedColumns={this.state.prefered_columns}
					refreshCallback={this.refreshCallback}
					exportxls={this.exportMeetingXls}

				/>
				<AlertBox alertParam={this.state.alert_param} />
			</div>
		)
	}
}

export default Meetings;
