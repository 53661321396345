import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils.js';
import APICall from '../../Common/APICall.js';
import { MdClose , MdDelete } from 'react-icons/md';
import { Row, Col } from 'react-grid-system';
import { TiArrowSortedDown } from "react-icons/ti";
import { FaEye, FaTimes, FaPlusCircle } from "react-icons/fa";
import { FaPen } from "react-icons/fa";
import { FaPlus } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, thin } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import MeetingGuestTable from '../../Components/ScheduleMeetingModal/MeetingGuestTable';
import ViewVersions from '../../Components/ScheduleMeetingModal/ViewVersions';
import ViewVersionsTop from '../../Components/ScheduleMeetingModal/ViewVersionsTop';
import AddActions from './AddActions';
import CSLTable from '../Common/CSLTable';
import DatePicker from 'react-date-picker';
//import TimePicker from 'rc-time-picker';
//import 'rc-time-picker/assets/index.css';
import TimePicker from "../Common/TimePicker";
import moment from 'moment';
import AddCommitteeMembers from './AddCommitteeMembers';
import Dropzone from '../Common/Dropzone.js';
import '../Common/Dropzone.css';
import * as Style from '../Common/StyledComponents';
import { saveAs } from 'file-saver';
import CSLLoader from '../Common/CSLLoader';
import HtmlEditor from '../Common/HtmlEditor';
import CSLLoaderNonPromise from '../Common/CSLLoaderNonPromise';
import AlertBox from '../Common/AlertBox.js';
import CommitteeUtils from '../Common/CommitteeUtils.js';
import InputBox from './InputBox';
import ConfirmationBox from '../AddCommitteeModal/ConfirmationBox';
import Dropzone1 from "../Dropzone/Dropzone.js";
import ClipLoader from "react-spinners/ClipLoader";


const last_action_time = moment().unix();



const ReactModalHeader = styled.div`
    padding: 10px 15px;
    background: linear-gradient(to bottom, #04ADA8 0%, #04ADA8 100%);
    color:#fff;
`;
const ModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 40%;
    height: 690px;
    background: #ffffff;
    border: 1px solid #868484;
    z-index: 1001;
    position: absolute;
    margin: 0 20%;
    border-radius: 6px;
`;
const MRModalInput = styled.input`
    height: 26px;
    width: 84%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
	/*font-weight: bold;
    margin-left: 24px;*/
    margin-top: 10px;
    font-family: Montserrat,sans-serif
`;
const CSLDateCover = styled.div`
    margin-top: 8px;
    width: calc(50% - 5px);
    border-radius: 3px;
    height: 34px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0 0 4px #c4c4c4;
	font-weight: bold;
    `;
const CloseDiv = styled.div`
    box-sizing: border-box;
    cursor:pointer;
`;
const ReactFormGroup = styled.div`
    margin-bottom: 15px;
`;

const FormSubmitButton = styled.button`
    color: #fff;
    background: linear-gradient(to top, #04ADA8 0%, #04ADA8 100%);
    box-shadow: inset 0px 1px 1px 0px #04ADA8;
    width: 116px;
    padding: 6px 16px;
    border: 1px solid #04ADA8;
    border-radius:3px;
    margin-right:10px;
`;
const FormCancelButton = styled.button`
    color: #000000;
    background: linear-gradient(to top, #FFFFFF 0%, #FFFFFF 100%);
    box-shadow: inset 0px 1px 1px 0px #FFFFFF;
    width: 116px;
    padding: 6px 16px;
    border: 1px solid grey;
    border-radius:3px;
    margin-right:10px;
`;

const AddActionButton = styled.button`
    background-color: #04ADA8;
    color: #ffffff; 
    border-radius: 5px;
    border: 1px solid #04ADA8;
    padding: 3px 18px;
    background-image: linear-gradient(to bottom, #04ADA8 50%, #04ADA8);
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #01B0A2;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 110px;
    font-size: 11px;
    height: 25px;
    border-radius: 4px;
    margin-right:2px;
`;


/*  */
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 12px;
    font-weight: 600;
    height: 80px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151;  
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: rgb(243, 247, 251);
    padding: 10px;
    border-radius: 4px;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 85%;
    position: absolute;
    top: 38px;
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 50px;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 2200px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0%;
  top: -100px;
  z-index: 1001;
  opacity: 0.9;
`;

const ModalInput = styled.input`
    height: 40px;
    width: calc(100% - 13px);
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;

const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto;
    margin-top: 30px;    
`;
const InactiveOverlay2 = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1800px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 1002;
  opacity: 0.8;
`;
const ViewDiv = styled.div`
    box-sizing: border-box;
    cursor:pointer;
    position:relative;
    margin-right:3px;
`;
const AlertBoxContainer = styled.div`
	position: fixed;
	top: 40vh;
	left: 40vw;
    width: 450px;
    box-shadow: 8px 8px 8px 8px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #F7F7F7;
    color: #2D2D2D;
    border-radius: 10px;
    border-style: solid;
    border-color: #F7F7F7;
    z-index: 2000;
`;
const AlertBoxBody = styled.div`
    width: calc(100% - 30px);
    background-color: #F7F7F7;
    color: #646A7E;
    font-size: 18px;
    min-height: 80px;
    text-align: center;
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px
`
;
const AlertBoxFooter = styled.div`
    width: calc(100% - 15px);
    background-color: #EDF1F2;
    color: #2D2D2D;
    height: 50px;
    padding-right: 15px;
    padding-top: 10px;
`
;
const dropdown = [
    { id: 0, display: "Select Status", value: "0", selected: false },
    { id: 1, display: "Scheduled", value: "Scheduled", selected: true },
    { id: 2, display: "Cancelled", value: "Cancelled", selected: false },
    { id: 3, display: "Completed", value: "Completed", selected: false }
];
const RequiredDropDown = [
    { required: "Required", value: "Required", selected: true },
    { required: "Optional", value: "Optional", selected: false },
];
const AttendedDropDown = [
    { required: "Yes", value: "Yes", selected: true },
    { required: "No", value: "No", selected: false },
];
function setHours(dt, h) {
    var s = /(\d+):(\d+)\s+(.+)/.exec(h);
    dt.setHours(s[3] === "pm" ?
        12 + parseInt(s[1], 10) :
        parseInt(s[1], 10));
    dt.setMinutes(parseInt(s[2], 10));
    return dt;
}
function getHours(h) {
    var s = /(\d+):(\d+)\s+(.+)/.exec(h);
    //var hour = 9;
    var hour = 9;
    if (s !== null) hour = s[3] === "pm" ? 12 + parseInt(s[1], 10) : parseInt(s[1], 10);
    //var hour = parseInt(s[1], 10);
    return hour;
}
function getMinutes(h) {
    var s = /(\d+):(\d+)\s+(.+)/.exec(h);
    //var hour = s[3] === "pm" ? 12 + parseInt(s[1], 10) : parseInt(s[1], 10);
    var minutes = 0;
    if (s !== null) minutes = parseInt(s[2], 10);
    return minutes;
}

function GetDynamicTitle() {
    if (Store.getStoreData('meetingDataEditMode') === null) {
        return (<div style={{ float: "left" }}>New Meeting</div>);
    } else {
        return (<div style={{ float: "left" }}>Amend Meeting</div>);
    }
}
Date.prototype.withoutTime = function () {
    var d = new Date(this);
    d.setHours(0, 0, 0, 0);
    return d;
}

class ScheduleMeeting extends React.Component {

    constructor(props) {
        super(props);
        this.openAddNoteModal = this.openAddNoteModal.bind(this);
        //props.actionCallback = {callbackFromAddActionDiv};

        this.state = {
            viewconfirmation: false,
            editMode: false,
            version: 0,
            committeelist: [],
            meetingid: 0,
            rootmeetingid: 0,
            task_ref_id: '',
            committeeid: 0,
            startDate: new Date(),
            starttime: moment().hour(9).minute(0),
            endtime: moment().hour(10).minute(0),
            meetinglocation: "",
            meetingagenda: "",
            agendadocs: [],
            minutes: "",
            minutesdocs: [],
            miscattachments: [],
            meetingschedule: "Scheduled",
            scheduleid: 0,
            committeemembers: [],
            thirdpartyembers: [],
            attendeerows: "",
            statusdropdown: [],
            requireddropdown: [],
            additionalguestrequireddropdown: [],
            additionalguestattendeddropdown: [],
            attendeedropdown: [],
            meetingguestdropdown: [],
            fiteredemployeelistbeforechange: [],
            fiteredemployeelist: [],
            attendeemembers: [],
            guestmembers: [],
            additionalguestmembers: [],
            newguestmembers: [],
            addActionDivReturnData: [],
            actionRows: [],
            children: [],
            actioneditindex: "",
            occurancecounter: 0,
            cur_lane: "",
            action_extra_json: {},
            followup_tasks: [],
            actions: [],
            tasks_list: [],
            ready: false,
            is_loader: false,
            alert_param: null,
            modal_view: null,
            agenda_curBinFiles: [],
            minutes_curBinFiles: [],
            misc_curBinFiles: [],
            assign_to_multiple_users: false,


        };

        this.temp_start_time = 0;

    }

    callbackFromAddActionDiv = (childData1) => {
        //console.log("childData==>"+JSON.stringify(childData1));
        //this.setState({
        //	addActionDivReturnData: childData1

        //});
        let addActionDivReturnData = this.state.addActionDivReturnData;
        if (this.state.actioneditindex > 0) {
            addActionDivReturnData[this.state.actioneditindex - 1] = childData1;
        } else {
            addActionDivReturnData.push(childData1);
        }
        //console.log("addActionDivReturnData==>"+JSON.stringify(addActionDivReturnData));
        this.setState({
            showAddNote: false,
            addActionDivReturnData: addActionDivReturnData,
            //actionRows: this.createActionRows(childData1)
        }, () => {
            //console.log("addActionDivReturnData==>"+JSON.stringify(this.state.addActionDivReturnData));
            this.setState({
                actionRows: this.createActionRows()
            });
        });

    }
    openAddNoteModal = (event) => {
        event.preventDefault();

        let alert_param = { title: 'Alert', message: 'You will now be redirected to the SM&CR module, please save changes to the meeting before exiting this screen. Do you want to be redirected?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true, alertHandler: this.processOpenAddNote, stack: {}, continue_to_next_screen_if_yes: false };
        this.setState({
            alert_param: alert_param,
        });

    }
    processOpenAddNote = (result, stack) => {
        this.setState({ alert_param: null }, () => {
            if (result === false) return;
            if (result === true) window.location.href = '/';
        });

    }
	fetchCommitteeMembers = (committeeId) => {
		let fiteredemployeelist = [];
		let fiteredemployeelistbeforechange = [];
		let committee_with_count = Store.getStoreData('committee_with_count');
		let user_assignments = Store.getStoreData('user_assignments');
		let companies_assoc = Store.getStoreData('companies_assoc');
		let employee_contact_assoc = Store.getStoreData('employee_contact_assoc');
		let employee_list = Store.getStoreData('employee_list');
		let selected_committee = {};
		for(let com of committee_with_count.committees){
			if(com.PK_CommitteeID.toString() === committeeId.toString()){
				selected_committee = com;
				break;
			}
		}
		let parsedresult = JSON.parse(selected_committee.Json_Data);
		console.log("selected_committee,",parsedresult,user_assignments);

		let thirdpartymembers = parsedresult.thirdpartyembers;
		let additionalguestmembers = [];
		for (let i = 0; i < thirdpartymembers.length; i++) {
			additionalguestmembers.push({
				employeeid: thirdpartymembers[i].employeeid,
				additionalguestname: thirdpartymembers[i].thirdpartyembername,
				additionalguestcompany: thirdpartymembers[i].thirdpartyembercompany,
				additionalguestreason: thirdpartymembers[i].thirdpartyemberreason
			});
		}
		for (let i = 0; i < additionalguestmembers.length; i++) {
			additionalguestmembers[i].attended = 'Yes';
			additionalguestmembers[i].required = 'Required';
		}
		let attendeemembers = parsedresult.committeemembers;
		for (let i = 0; i < attendeemembers.length; i++) {
			attendeemembers[i].attended = 'Yes';
			attendeemembers[i].required = 'Required';
		}
		Store.updateStore('Committee_Name_For_Task', parsedresult.committeename);
		let t = {};
		for (let j of parsedresult.committeemembers) {
			t[j.employeeid.toString()] = true;
		}
		let company_users = Store.getStoreData('company_users');
		if(Store.getStoreData('parent_has_group_companies') === true){
			if(typeof parsedresult.gc_id !== 'undefined' && parsedresult.gc_id.toString() !== '0'){
				let gc_users = [];
				for(let u_id in user_assignments){
					if(parsedresult.gc_id in user_assignments[u_id].companies){
						gc_users.push(u_id);
					}
				}
				for(let u_id of gc_users){
					if(u_id in employee_contact_assoc){
						if (typeof t[employee_contact_assoc[u_id].employee_id.toString()] === 'undefined' && company_users[u_id.toString()].IsActive===true) {
							fiteredemployeelist.push({ value: employee_contact_assoc[u_id].employee_id, label: employee_contact_assoc[u_id].employee_name, include: false });
							fiteredemployeelistbeforechange.push({ value: employee_contact_assoc[u_id].employee_id, label: employee_contact_assoc[u_id].employee_name, include: false });
						}
					}
				}
				console.log("selected_committee,",gc_users,employee_contact_assoc);
			}else{
				for (let i of employee_list) {
					if (typeof t[i.employeeid.toString()] === 'undefined' && company_users[i.contact_id.toString()].IsActive===true) {
						fiteredemployeelist.push({ value: i.employeeid, label: i.employeename, include: false });
						fiteredemployeelistbeforechange.push({ value: i.employeeid, label: i.employeename, include: false });
						t[i.employeeid.toString()] = true;
					}
				}
			}

		}else{
			for (let i of employee_list) {
				if (typeof t[i.employeeid.toString()] === 'undefined' && company_users[i.contact_id.toString()].IsActive===true) {
					fiteredemployeelist.push({ value: i.employeeid, label: i.employeename, include: false });
					fiteredemployeelistbeforechange.push({ value: i.employeeid, label: i.employeename, include: false });
					t[i.employeeid.toString()] = true;
				}
			}
		}
		let committeemembers = parsedresult.committeemembers;
		let u_c_members = [];
		let employee_assoc = Store.getStoreData('employee_assoc');
		for(let c of committeemembers){
			if(!employee_assoc[c.employeeid.toString()].is_active)continue;
			u_c_members.push(c);
		}
		this.setState({
			//attendeerows: attendeerows,
			attendeemembers: attendeemembers,
			committeemembers: u_c_members,
			thirdpartyembers: parsedresult.thirdpartyembers,
			additionalguestmembers: additionalguestmembers,
			fiteredemployeelist,
			fiteredemployeelistbeforechange,

		});

        console.log("selected_committee,", selected_committee,employee_list,u_c_members);
	}

    attendeeRequiredDropDownInputChange = (employeeId) => (event) => {
        event.preventDefault();
        //console.log("val==>"+event.target.value);
        this.state.requireddropdown.map((item) => {
            if (item.employeeid == employeeId) {
                item.selectedvalue = event.target.value;
            }
            for (let i = 0; i < this.state.committeemembers.length; i++) {
                if (this.state.committeemembers[i].employeeid == item.employeeid) {
                    this.state.committeemembers[i].required = item.selectedvalue;
                }
            }
        });
    };

    attendeeAttendedDropDownInputChange = (employeeId) => (event) => {
        event.preventDefault();
        //console.log("val==>"+event.target.value);
        this.state.attendeedropdown.map((item) => {
            if (item.employeeid == employeeId) {
                item.selectedvalue = event.target.value;
            }
            for (let i = 0; i < this.state.committeemembers.length; i++) {
                if (this.state.committeemembers[i].employeeid == item.employeeid) {
                    this.state.committeemembers[i].attended = item.selectedvalue;
                }
            }
        });
    };

    additionalguestattendeeRequiredDropDownInputChange = (employeeId) => (event) => {
        event.preventDefault();
        //console.log("val==>"+event.target.value);
        this.state.additionalguestrequireddropdown.map((item) => {
            if (item.employeeid == employeeId) {
                item.selectedvalue = event.target.value;

                for (let i = 0; i < this.state.additionalguestmembers.length; i++) {
                    //if(this.state.additionalguestmembers[i].employeeid == item.employeeid){
                    if (this.state.additionalguestmembers[i].employeeid == employeeId) {
                        //this.state.additionalguestmembers[i].required = item.selectedvalue;
                        this.state.additionalguestmembers[i].required = event.target.value;
                    }
                }
            }

        });
    };

    handleAdditionalGuestRequiredDropDown = (employeeId, requiredvalue) => {
        return (
            <select style={{ border: "none", background: "transparent" }} onChange={this.additionalguestattendeeRequiredDropDownInputChange(employeeId)} >
                {
                    RequiredDropDown.map((item, index) => {
                        if (item.selected) {
                            let json = { 'employeeid': employeeId, 'selectedvalue': item.value };
                            this.state.additionalguestrequireddropdown.push(json);
                        }
                        let selectedbool = false;
                        if (requiredvalue != '') {
                            if (item.value == requiredvalue) {
                                selectedbool = true;
                            } else {
                                selectedbool = false;
                            }
                        } else {
                            selectedbool = item.selected;
                        }
                        //console.log("employeeId==>"+employeeId+",selectedbool==>"+selectedbool);
                        return (<option key={index} value={item.value} selected={selectedbool}>{item.required}</option>);
                    })
                }

            </select>
        );

    }

    handleAdditionalGuestAttendedDropDown = (employeeId, attendedvalue) => {
        return (
            <select style={{ border: "none", background: "transparent" }} onChange={this.additionalGuestAttendedDropDownInputChange(employeeId)} >
                {
                    AttendedDropDown.map((item, index) => {
                        if (item.selected) {
                            let json = { 'employeeid': employeeId, 'selectedvalue': item.value };
                            this.state.additionalguestattendeddropdown.push(json);
                        }
                        let selectedbool = false;
                        if (attendedvalue != '') {
                            if (item.value == attendedvalue) {
                                selectedbool = true;
                            } else {
                                selectedbool = false;
                            }
                        } else {
                            selectedbool = item.selected;
                        }
                        return (<option key={index} value={item.value} selected={selectedbool}>{item.required}</option>);
                    })
                }
                {/* <option>Required</option>
						<option>Optional</option> */}
            </select>
        );

    }

    additionalGuestAttendedDropDownInputChange = (employeeId) => (event) => {
        event.preventDefault();
        //console.log("val==>"+event.target.value);
        this.state.additionalguestattendeddropdown.map((item) => {

            if (item.employeeid == employeeId) {
                item.selectedvalue = event.target.value;

                for (let i = 0; i < this.state.additionalguestmembers.length; i++) {
                    //if(this.state.additionalguestmembers[i].employeeid == item.employeeid){
                    if (this.state.additionalguestmembers[i].employeeid == employeeId) {
                        //this.state.additionalguestmembers[i].required = item.selectedvalue;
                        this.state.additionalguestmembers[i].attended = event.target.value;
                    }
                }
            }

        });
    };

    handleAttendeeRequiredDropDown = (employeeId, requiredvalue) => {
        return (
            <select style={{ border: "none", background: "transparent" }} onChange={this.attendeeRequiredDropDownInputChange(employeeId)} >
                {
                    RequiredDropDown.map((item, index) => {
                        if (item.selected) {
                            let json = { 'employeeid': employeeId, 'selectedvalue': item.value };
                            this.state.requireddropdown.push(json);
                        }
                        let selectedbool = false;
                        if (requiredvalue != '') {
                            if (item.value == requiredvalue) {
                                selectedbool = true;
                            } else {
                                selectedbool = false;
                            }
                        } else {
                            selectedbool = item.selected;
                        }
                        return (<option key={index} value={item.value} selected={selectedbool}>{item.required}</option>);
                    })
                }

            </select>
        );

    }

    handleAttendeeAttendedDropDown = (employeeId, attendedvalue) => {
        return (
            <select style={{ border: "none", background: "transparent" }} onChange={this.attendeeAttendedDropDownInputChange(employeeId)} >
                {
                    AttendedDropDown.map((item, index) => {
                        if (item.selected) {
                            let json = { 'employeeid': employeeId, 'selectedvalue': item.value };
                            this.state.attendeedropdown.push(json);
                        }
                        let selectedbool = false;
                        if (attendedvalue != '') {
                            if (item.value == attendedvalue) {
                                selectedbool = true;
                            } else {
                                selectedbool = false;
                            }
                        } else {
                            selectedbool = item.selected;
                        }
                        return (<option key={index} value={item.value} selected={selectedbool}>{item.required}</option>);
                    })
                }
                {/* <option>Required</option>
						<option>Optional</option> */}
            </select>
        );

    }

    createAttendeeRows = () => {
        let table = [];
        for (let i = 0; i < this.state.committeemembers.length; i++) {
            //console.log("employeename==>"+this.state.committeemembers[i].employeename+"<=employeename");
            if (typeof this.state.committeemembers[i].employeename != 'undefined' && this.state.committeemembers[i].employeename != "") {
                let children = [];
                children.push(<td>{this.state.committeemembers[i].employeename}</td>);
                children.push(<td>{this.state.committeemembers[i].committeerolename}</td>);
                children.push(<td>{this.handleAttendeeRequiredDropDown(this.state.committeemembers[i].employeeid, '')}</td>);
                children.push(<td>{this.handleAttendeeAttendedDropDown(this.state.committeemembers[i].employeeid, '')}</td>);

                table.push(<tr>{children}</tr>);
            }


        }
        return table;
    }

    createAttendeeRowsEdit = (committeemembers) => {
        let table = [];
        for (let i = 0; i < committeemembers.length; i++) {
            //console.log("employeename==>"+this.state.committeemembers[i].employeename+"<=employeename");
            if (typeof committeemembers[i].employeename != 'undefined' && committeemembers[i].employeename != "") {
                let children = [];
                children.push(<td>{committeemembers[i].employeename}</td>);
                children.push(<td>{committeemembers[i].committeerolename}</td>);
                children.push(<td>{this.handleAttendeeRequiredDropDown(committeemembers[i].employeeid, committeemembers[i].required)}</td>);
                children.push(<td>{this.handleAttendeeAttendedDropDown(committeemembers[i].employeeid, committeemembers[i].attended)}</td>);

                table.push(<tr>{children}</tr>);
            }


        }
        return table;
    }

    formatTime = (date) => {
        let day = date.getDate();
        let seconds = date.getSeconds();
        let minutes = date.getMinutes();
        let hour = date.getHours();
        //console.log("hour==>"+hour);
    }

    formatDate = (date) => {
        //console.log("date 1==>"+date);
        //console.log("date 2==>"+new Date());
        let day = date.getDate();
        let monthIndex = date.getMonth() + 1;
        let year = date.getFullYear();

        if (day < 10) {
            day = '0' + day;
        }
        if (monthIndex < 10) {
            monthIndex = '0' + monthIndex;
        }

        return day + '/' + monthIndex + '/' + year;
    }

    createActionRows = () => {
        console.log("in createActionRows ==>" + JSON.stringify(this.state.addActionDivReturnData));
        let table = [];
        for (let i = 0; i < this.state.addActionDivReturnData.length; i++) {
            let children = [];
            children.push(<td>{this.state.addActionDivReturnData[i].title}</td>);
            children.push(<td>{this.state.addActionDivReturnData[i].addedBy}</td>);
            children.push(<td>{this.state.addActionDivReturnData[i].addedDate}</td>);
            children.push(<td><MdClose style={{ float: "right" }} onClick={() => this.deleteActionRow(i)} /> <FaPen style={{ float: "right", marginRight: "7px" }} onClick={() => this.editAction(i)} /></td>);

            table.push(<tr>{children}</tr>);
        }

        return table;
    }

    editAction = (arrayindex) => {
        //alert("arrayindex==>"+arrayindex);
        //console.log("arrayindex==>"+arrayindex);
        let addActionDivReturnData = this.state.addActionDivReturnData;
        let actionJson = addActionDivReturnData[arrayindex];
        console.log("actionJson==>" + JSON.stringify(actionJson));
        Store.updateStore('EditActionRecord', actionJson);
        this.setState({
            actioneditindex: arrayindex + 1,
            showAddNote: true,

        });
    }
    deleteActionRow = (arrayindex) => {
        //console.log("arrayindex==>"+arrayindex);
        let addActionDivReturnData = this.state.addActionDivReturnData;
        addActionDivReturnData.splice(arrayindex, 1);
        this.setState({
            addActionDivReturnData: addActionDivReturnData

        }, () => {
            console.log("addActionDivReturnData==>" + JSON.stringify(this.state.addActionDivReturnData));
            this.setState({
                actionRows: this.createActionRows()
            });
        });
    }

    createActionRowsEdit = (childData) => {
        let table = [];
        for (let i = 0; i < childData.length; i++) {
            let children = [];
            let followupText = childData[i].followupIsChecked ? 'Yes' : 'No';
            let followupDate = this.formatDate(new Date(childData[i].followUpDate));
            children.push(<td>{childData[i].title}</td>);
            children.push(<td>{childData[i].addedBy}</td>);
            children.push(<td>{childData[i].addedDate}</td>);
            //children.push(<td>{followupText}</td>);
            //children.push(<td>{followupDate}</td>);
            children.push(<td><MdClose style={{ float: "right" }} onClick={() => this.deleteActionRow(i)} /> <FaPen style={{ float: "right", marginRight: "7px" }} onClick={() => this.editAction(i)} /></td>);

            table.push(<tr>{children}</tr>);

        }

        return table;
    }



    openConfirmationBoxModal = (event) => {
        event.preventDefault();
        //Store.updateStore('ConfirmationBoxOverlay', `${document.body.scrollHeight.toString()}px`);
        //Store.updateStore('ConfirmationBoxVisibility', true);
        //this.setState({ viewconfirmation: true });
		let alert_param = {
			title: 'Alert', message: 'Are you sure you want to exit this page, all unsaved data including new follow up tasks will be lost.', ok_text: 'OK', confirm: true,
			alertHandler: this.handleConf_check, stack: {}
		}
		this.setState({ alert_param: alert_param })
    }

    handleConf_check = (result, stack) => {
        //console.log(result,stack)
        this.setState({ alert_param: null });
		if(result)this.closeParentModal();
    }

    committeeDropDownInputChange = event => {
        event.preventDefault();
        this.setState({ [event.target.name]: event.target.value });
        //console.log("val==>"+event.target.value);
        if(event.target.value.toString() !== '0'){
			this.fetchCommitteeMembers(event.target.value);
		}else{
			this.setState({
				//attendeerows: attendeerows,
				attendeemembers: [],
				committeemembers: [],
				thirdpartyembers: [],
				additionalguestmembers: [],
				fiteredemployeelist: [],
				fiteredemployeelistbeforechange: []

			});
		}

    };

    handleInputChange = event => {
        event.preventDefault();
        this.setState({ [event.target.name]: event.target.value });

    };

    handleStartTimeChange = (value) => {
        //
        let d4 = moment(value).format("h:mm a");
        let d5 = moment(this.state.endtime).format("h:mm a");
        let starttime = moment().hour(getHours(d4)).minute(getMinutes(d4));


        if (moment(value).unix() > moment(this.state.endtime).unix()) {
            let endtime = moment(value).add(1, 'hours');
            //console.log("handleStartTimeChange time_difference ==>", endtime.format("h:mm a"));
            this.setState({
                starttime: starttime,
                endtime: endtime,
            });
        } else {
            this.setState({
                starttime: starttime,
            });
        }



    }

    handleEndTimeChange = (value) => {
        let d4 = moment(value).format("h:mm a");
        let d5 = moment(this.state.starttime).format("h:mm a");
        let endtime = moment().hour(getHours(d4)).minute(getMinutes(d4));
        //console.log("handleEndTimeChange ,", moment(this.state.starttime).format("h:mm a"), moment(value).format("h:mm a"), moment(this.state.starttime).unix() - moment(value).unix());
        if (moment(value).unix() < moment(this.state.starttime).unix()) {
            let starttime = moment(value).subtract(1, 'hours');
            this.setState({
                starttime: starttime,
                endtime: endtime,
            });
        } else {
            this.setState({
                endtime: endtime
            });
        }

    }

    getDisabledHours = () => {
        let d4 = moment(this.state.starttime).format("h:mm a");
        let d5 = moment(this.state.endtime).format("h:mm a");
        let hours = [];


        let hour_to_enable = (getMinutes(d4) > 0) ? getHours(d4) : getHours(d4);
        if (getMinutes(d4) > 0 && getMinutes(d4) === 55) hour_to_enable = getHours(d4) + 1;
        //console.log("getDisabledHours,",getMinutes(d4),getHours(d4));
        for (let i = 0; i <= 23; i++) {
            if (i < hour_to_enable) hours.push(i);

        }

        return hours;
    }

    getDisabledMinutes = () => {
        let minutes = [];
        let d4 = moment(this.state.starttime).format("h:mm a");


        let selected_hour_end_time = moment(this.state.endtime).hour();
        console.log("selected_hour_end_time", selected_hour_end_time);
        if (selected_hour_end_time === getHours(d4)) {
            let minute_to_start = (getMinutes(d4) > 0) ? getMinutes(d4) + 5 : 5;
            for (let i = 0; i <= 59; i++) {
                if (i < minute_to_start) minutes.push(i);
            }
        }
        return minutes;
    }


    handleDateChange = date => {
        console.log("handleDateChange,", date);
        Store.updateStore('Meeting_Date_For_Task', date);
        this.setState({
            startDate: date
        });
    };

    handleAdditionalGuestAddClick = event => {

        event.preventDefault()

        let tmparr = this.state.additionalguestmembers;
        let employeeid = Utils.genKey(10);
        tmparr.push({
            employeeid: employeeid,
            additionalguestname: "",
            additionalguestcompany: "",
            additionalguestreason: "",
            required: "Required",
            attended: "Yes"
        })

        console.log("key", tmparr);

        this.setState({ additionalguestmembers: tmparr })

    }

    handleAdditionalGuestAddNameChange = event => {
        event.preventDefault()
        var c = parseInt(event.target.name.split('-')[1])
        let tmparr = this.state.additionalguestmembers
        tmparr[c - 1].additionalguestname = event.target.value
        // this.setState({ newguestmembers: tmparr })
        // this.setState({ additionalguestmembers: tmparr })

    }

    showInput = (key, value) => {
        let tmparr = this.state.additionalguestmembers;
        console.log("key =======>", tmparr);
        tmparr[parseInt(key) - 1].additionalguestname = value;
    }



    handleAdditionalGuestAddCompanyChange = event => {
        event.preventDefault()
        var c = parseInt(event.target.name.split('-')[1])
        let tmparr = this.state.additionalguestmembers

        tmparr[c - 1].additionalguestcompany = event.target.value
        //console.log(tmparr)
        this.setState({ additionalguestmembers: tmparr })
        // console.log(c)
    }
    handleAdditionalGuestAddReasonChange = event => {
        event.preventDefault()
        var c = parseInt(event.target.name.split('-')[1])
        let tmparr = this.state.additionalguestmembers

        tmparr[c - 1].additionalguestreason = event.target.value
        //console.log(tmparr)
        this.setState({ additionalguestmembers: tmparr })
        // console.log(c)
    }

    callbackFromGuestTable = (childData) => {

        console.log("children", childData, this.state.fiteredemployeelist,this.state.fiteredemployeelistbeforechange)
        let list_arr = this.state.fiteredemployeelist;
        let fiteredemployeelistbeforechange = this.state.fiteredemployeelistbeforechange;
        for (let c of childData) {
            // console.log("children", c)
            for (let d of list_arr) {
                if (c.employeeid.toString() === d.value.toString()) {
                    // list_arr[]
                    d.include = true;
                }
            }
        }
        // console.log("children", list_arr)
        let new_arr = [];
        for (let k of list_arr) {
            if (!k.include) {
                new_arr.push(k);
            }
        }
        this.setState({
            guestmembers: childData, fiteredemployeelist: new_arr
        });
        //console.log("guestmembers 1 ==>"+JSON.stringify(this.state.guestmembers));
    };
    deleteCallbackFromGuestTable = (jsondata) => {
		let deleted_obj = jsondata.deleted_obj;
		let childData = jsondata.childData
        console.log("children", jsondata, this.state.fiteredemployeelist,this.state.fiteredemployeelistbeforechange);
		//return;
        let list_arr = this.state.fiteredemployeelist;
        let committeemembers = this.state.committeemembers;
        let fiteredemployeelistbeforechange = this.state.fiteredemployeelistbeforechange;
        for (let c of childData) {
            // console.log("children", c)
            for (let d of fiteredemployeelistbeforechange) {
                if (c.employeeid.toString() === d.value.toString()) {
                    // list_arr[]
                    d.include = true;
                }
            }
        }
        // console.log("children", list_arr)
        let new_arr = [];
		let guest_ids = [];
        for (let k of fiteredemployeelistbeforechange) {
            if (!k.include) {
                new_arr.push(k);
            }
        }
		//console.log("children", new_arr)

		for(let n of new_arr){
			guest_ids.push(n.value.toString());
		}
		if(!guest_ids.includes(deleted_obj.employeeid.toString())){
			new_arr.push({
				include: false,
				label: deleted_obj.employeename,
				value: deleted_obj.employeeid
			})
		}
		new_arr.sort(this.nameCompare);
        this.setState({
            guestmembers: childData,
			fiteredemployeelist: new_arr
        });
        //console.log("guestmembers 1 ==>"+JSON.stringify(this.state.guestmembers));
    };
    nameCompare = (a,b) => {
        return a.label.localeCompare(b.label)
    }
    callbackFromAgendaDoc = (childData) => {
        this.setState({
            agendadocs: childData
        });

    };

    callbackFromMiscAttachments = (childData) => {
        console.log("miscattachments 1 ==>" + JSON.stringify(childData));
        this.setState({
            miscattachments: childData
        });

    };

    callbackFromMinutesDoc = (childData) => {
        this.setState({
            minutesdocs: childData
        });

    };

	handleSubmit = event => {
		event.preventDefault();

        let module_configs = Store.getStoreData('module_configs');
        let current_meeting_actions = Store.getStoreData('Current_Meeting_Actions');
        if (!this.state.committeeid || !this.state.startDate || this.state.meetingschedule.toString() === '0') {
            if (!this.state.committeeid) {
                let message = "Please select a committee"
                let alert_param = {
                    title: 'Alert', message: message, ok_text: 'OK', confirm: false,
                    alertHandler: this.handleSubmit_check, stack: {}
                }
                this.setState({ alert_param: alert_param })
                return false;
            }
            if (!this.state.startDate) {
                let message = "Please enter a meeting date"
                let alert_param = {
                    title: 'Alert', message: message, ok_text: 'OK', confirm: false,
                    alertHandler: this.handleSubmit_check, stack: {}
                }
                this.setState({ alert_param: alert_param })
                return false;
            }
            if (this.state.meetingschedule === 0) {
                let message = "Please select status"
                let alert_param = {
                    title: 'Alert', message: message, ok_text: 'OK', confirm: false,
                    alertHandler: this.handleSubmit_check, stack: {}
                }
                this.setState({ alert_param: alert_param })
                return false;
            }

        }

        if (this.state.starttime === null) {
            let message = "Please select meeting start time."
            let alert_param = {
                title: 'Alert', message: message, ok_text: 'OK', confirm: false,
                alertHandler: this.handleSubmit_check, stack: {}
            }
            this.setState({ alert_param: alert_param })
            return false;
        }


        let is_incomplete_actions_exists = false;
        if (typeof current_meeting_actions !== 'undefined' && current_meeting_actions !== null) {
            current_meeting_actions.forEach(function (value, i) {
                if (value.cur_lane !== 'COMP_COMMITTEES_COMPLETE') { is_incomplete_actions_exists = true; }
            })
        }


        if (this.state.meetingschedule === 'Completed' && module_configs.general.allow_completion_of_meeting_where_tasks_are_outstanding === false && is_incomplete_actions_exists) {
            // alert("All follow-up tasks must have a status of Complete to be able to mark the meeting status as Complete.");
            // return false;
            let message = "One or more follow-up tasks are still pending. This must be completed before the meeting can be marked as complete."
            let alert_param = {
                title: 'Alert', message: message, ok_text: 'OK', confirm: false,
                alertHandler: this.handleSubmit_check, stack: {}
            }
            this.setState({ alert_param: alert_param })
            return false;

        }


		let agenda_curBinFiles = this.state.agenda_curBinFiles;
		let minutes_curBinFiles = this.state.minutes_curBinFiles;
		let misc_curBinFiles = this.state.misc_curBinFiles;

		if(agenda_curBinFiles.length === 0 && minutes_curBinFiles.length === 0 && misc_curBinFiles.length === 0){
			this.submitFuncMain();
		}else{
			let all_files_pre = [...agenda_curBinFiles,...minutes_curBinFiles,...misc_curBinFiles];
			let all_files = [];
			for(let a of all_files_pre){
				if(!('bin_file' in a))continue;
				all_files.push(a);
			}
			console.log("all_files,",all_files_pre,all_files);
			//return;
			let fileSize = 0;
			for(let af of all_files){
				if(!('bin_file' in af))continue;
				let dnld = af.bin_file.replace(/ /g, "+");
				let binaryString = window.atob(dnld);
				fileSize = fileSize + binaryString.length;
			}

			if(fileSize > 26214400){ // more than 25 mb
				let alert_param = { title: "Alert", message: "The files uploaded for this meeting have exceeded the upload size of 25mb. Please reduce the file sizes and try again.", ok_text: "OK", confirm: false, alertHandler: this.processCloseloading, stack: {} };
				this.setState({ alert_param: alert_param });
			}else{
				if(fileSize > 20971520 && fileSize <= 26214400){// more than 20 mb and less than equal to 25 mb
					let alert_param = { title: "Alert", message: "This meeting may take some time to register due to the files added. Would you like to continue to submit this meeting?", ok_text: "OK", confirm: true, alertHandler: this.processMeetingPostSubmit, stack: {} };
					this.setState({ alert_param: alert_param });
				}else if(fileSize > 5242880 && fileSize <= 20971520){ // more than 5 mb and less than equal to 20 mb
				  let alert_param = { title: "Alert", message: "This file may take some time to upload and for users to download. Would you like to continue to upload this document?", ok_text: "OK", confirm: true, alertHandler: this.processORcancel, stack: {} };
				  this.setState({ alert_param: alert_param });

				}else{
				  if(fileSize === 0){
					setTimeout(() => {
						this.submitFuncMain()
					},30);
				  }else{
					this.refs.dropZone.uploadFilesFromChild(all_files);
				  }
				}
			}

		}
	}
	processCloseloading = (result, stack) => {
		this.setState({ alert_param: null });
	};
	processMeetingPostSubmit = (result, stack) => {
	  if (!result || stack.prevent) {
	    this.setState({ alert_param: null });
	    return;
	  }
	  let alert_param = { title: "Alert", message: "Your meeting is being registered. This may take some time.", ok_text: "OK", confirm: false, alertHandler: this.processORcancel, stack: {} , no_button: true};
	  this.setState({ alert_param});
	  let agenda_curBinFiles = this.state.agenda_curBinFiles;
	  let minutes_curBinFiles = this.state.minutes_curBinFiles;
	  let misc_curBinFiles = this.state.misc_curBinFiles;
	  let all_files = [...agenda_curBinFiles,...minutes_curBinFiles,...misc_curBinFiles];
	  setTimeout(() => {
		 this.refs.dropZone.uploadFilesFromChild(all_files);
	  },30);

	}
	processORcancel = (result, stack) => {
	  if (!result || stack.prevent) {
	    this.setState({ alert_param: null });
	    return;
	  }
	  this.setState({ alert_param: null });
	  let agenda_curBinFiles = this.state.agenda_curBinFiles;
	  let minutes_curBinFiles = this.state.minutes_curBinFiles;
	  let misc_curBinFiles = this.state.misc_curBinFiles;
	  let all_files = [...agenda_curBinFiles,...minutes_curBinFiles,...misc_curBinFiles];
	  this.refs.dropZone.uploadFilesFromChild(all_files);
	}
    submitFuncMain = () =>{


        let module_configs = Store.getStoreData('module_configs');
        let current_meeting_actions = Store.getStoreData('Current_Meeting_Actions');

        //this.setState({ ready: false });

        let datesarr = this.formatDate(this.state.startDate).split('/');
        let meeting_date = datesarr[1] + '/' + datesarr[0] + '/' + datesarr[2];

        let new_meeting = (this.state.task_ref_id !== '') ? false : true;
        let task_ref_id = (this.state.task_ref_id !== '') ? this.state.task_ref_id : Utils.genKey(10);

        let userid = Store.getStoreData('loggedin_user_info').ID;
        let followup_tasks_to_be_created = [];
        for (let i = 0; i < this.state.actions.length; i++) {
            if (this.state.actions[i].action_status === 'New') {
                this.state.actions[i].task_ref_id = task_ref_id;
                this.state.actions[i].committee_id = this.state.committeeid;
                this.state.actions[i].action_note_undertaken = '';
                let action_json = {};

                action_json = this.state.actions[i];
                delete action_json.action_status;
                delete action_json.cur_lane;
                delete action_json.task_id;
                let headerinfo = {}
                headerinfo.action_json = action_json;
                let task = {
                    "task_data": { "module_id": "committees", "last_action": "COMP_COMMITTEES_CREATE", "ref_id": task_ref_id, "actiondate": Date.now(), "cur_assigned_to": this.state.actions[i].action_assignto, 'task_type': 'COMP_COMMITTEES', 'parent_task_id': 0, 'cur_lane': 'COMP_COMMITTEES_ASSIGNMENT', 'due_date': this.state.actions[i].due_date },
                    "object_data": { headerinfo },
                    "action_data": [{ "action": "COMP_COMMITTEES_CREATE", "actiondate": Date.now(), "assigned_to": this.state.actions[i].action_assignto, "cur_lane": "COMP_COMMITTEES_ASSIGNMENT", performed_by: userid }]
                };
                followup_tasks_to_be_created.push(task);

            }

        }

        let meetingagenda_text = Store.getStoreData('meetingagenda');
        if (meetingagenda_text === null) {
            meetingagenda_text = this.state.meetingagenda;
        }
        let meetingagenda_value = btoa(window.encodeURIComponent(meetingagenda_text));

        let minutes_text = Store.getStoreData('minutes');
        if (minutes_text === null) {
            minutes_text = this.state.minutes;
        }
        let minutes_value = btoa(window.encodeURIComponent(minutes_text));

		const filtered_c_members = this.state.committeemembers.filter(
		  (thing, index, self) =>
			index ===
			self.findIndex((t) => t.employeeid === thing.employeeid)
		);

        let submitedvalue =
        {
            "system_id": 1001,
            "contact_id": Store.getStoreData('loggedin_user_info').ID,
            "committee_id": this.state.committeeid,
            "meeting_date": meeting_date,

            json_data: {
                "committeeid": this.state.committeeid,
                "meetingagenda": meetingagenda_value,
                "meetinglocation": this.state.meetinglocation,
                "minutes": minutes_value,
                "meetingschedule": this.state.meetingschedule,
                "meetingdate": this.formatDate(this.state.startDate),
                "meetingstarttime": this.state.starttime,
                "meetingendtime": this.state.endtime,
                "attendeemembers": filtered_c_members,
                "guestmembers": this.state.guestmembers,
                "additionalguestmembers": this.state.additionalguestmembers,
                "addActionDivReturnData": this.state.addActionDivReturnData,
                "organiser": Store.getStoreData('loggedin_user_info').ContactName,
                "organiser_id": Store.getStoreData('loggedin_user_info').ID,
                "agendadocs": this.state.agendadocs,
                "miscattachments": this.state.miscattachments,
                "minutesdocs": this.state.minutesdocs,
                "version": this.state.version + 1,
                "rootmeetingid": this.state.rootmeetingid,
                "task_ref_id": task_ref_id,
                "children": this.state.children

            }
        }
		//console.log("agendadocs,",this.state.agendadocs);
		//return;
        if(this.state.assign_to_multiple_users){
			this.setState({ assign_to_multiple_users_loader: true });
		}else{
			this.setState({ is_loader: true });
		}

        let postData = { command: "addMeeting", data: submitedvalue, "followup_tasks_list": followup_tasks_to_be_created, new_meeting: new_meeting };
        let api = new APICall();
        api.command(postData, this.processHandleSubmit);
    }

    processHandleSubmit = (result) => {
        let committeeMeetingID = result.PK_CommitteeMettingID;

        this.passMeetingTabRefreshInstructionPostSubmit({ 'refreshmeetingtab': true, 'occurancecounter': committeeMeetingID });
        this.setState({ occurancecounter: committeeMeetingID, ready: true , is_loader: false, assign_to_multiple_users_loader: false, alert_param: null});
        this.closeMeetingModal();
    }

    handleSubmit_check = (result, stack) => {
        //console.log(result,stack)
        this.setState({ alert_param: null })
    }

    closeMeetingModal = () => {
        Store.updateStore('Committee_Name_For_Task', null);
        Store.updateStore('Meeting_Date_For_Task', new Date());
        Store.updateStore('meetingDataEditMode', null);
        Store.updateStore('meetingIdEditMode', null);
        Store.updateStore('EditMode', null);

        this.props.closeModal();
    }

    passMeetingTabRefreshInstructionPostSubmit = (childData) => {
        //console.log("in CommitteeTab==>"+JSON.stringify(childData));
        this.props.getMeetingTabRefreshInstruction(childData);
    }

    componentDidMount() {
        this.cleanHTMLEditor();
        this.processTaskData();
    }

    processTaskData = () => {
		console.log("prop_task_id,",this.props.prop_task_id);
		let temp_meeting = null;
		let temp_committee = null;
		let result = {};
        let meetingid = Store.getStoreData('meetingIdEditMode');
        if (this.props.prop_task_id > 0) {
            let postData = { command: "getMeetingByTaskId", data: {  task_id: this.props.prop_task_id }};
            let api = new APICall();
            api.command(postData, this.callbackProcessMeetingData);
        } else {


            let tmpcommittee = [];
            let committeeid = 0;

            let meeting_mode = 'new';
            if (meetingid !== null) {
                meeting_mode = 'edit';
                committeeid = Store.getStoreData('committeeIdInMeeting');

				let meeting_records = Store.getStoreData('meeting_records');
				let employee_list = Store.getStoreData('employee_list');
				let followup_tasks_list = Store.getStoreData('followup_tasks_list');
				let current_meeting_tasks_list = [];

				for (let mr of meeting_records.meetings)
				{
					if(mr.PK_CommitteeMettingID.toString() === meetingid.toString()){
						temp_meeting = mr;
						break;
					}
				}
				result.meeting_by_id = temp_meeting;

				for (let mr of meeting_records.committees)
				{
					if(mr.PK_CommitteeID.toString() === committeeid.toString()){
						temp_committee = mr;
						break;
					}
				}
				result.committee_by_id = temp_committee;
				for (let mr of followup_tasks_list)
				{
					if(mr.ref_id === temp_meeting.task_ref_id){
						current_meeting_tasks_list.push(mr)
					}
				}
				result.tasks_list = current_meeting_tasks_list;
            }
            //let taskRefIdInMeeting = Store.getStoreData('taskRefIdInMeeting');
            //if(this.props.prop_meeting_id > 0){
            //	Store.updateStore('meetingIdEditMode',this.props.prop_meeting_id)
            //	meetingid = this.props.prop_meeting_id;
            //	meeting_mode = 'edit';
            //}

            /*let postData = { command: "getMeetingEdit", data: {  employee_id: 0, committeeid: committeeid, meetingid: meetingid }, meeting_mode: meeting_mode, task_ref_id: taskRefIdInMeeting };
            let api = new APICall();
            api.command(postData, this.callbackProcessTaskData);
			*/

            this.callbackProcessTaskData(result);

        }

    }

    callbackProcessMeetingData = (result1) => {
		console.log("result1,",result1);
        if (result1.error_code === 2001) {
            let alert_param = { title: 'No Access', message: 'You are not authorised to access this task. If you think this is in error, please contact your administrator.', ok_text: 'Ok', cancel_text: 'No', confirm: false, alertHandler: this.taskAlertHandler, stack: {} };
            this.setState({ alert_param: alert_param , ready: true});
        } else {
            let tmpcommittee = [];
            let committeeid = 0;

            let meeting_mode = 'new';
            if (result1.meeting_id_for_task !== null) {
                meeting_mode = 'edit';
                committeeid = result1.FK_CommitteeID;
            }
            Store.updateStore('meetingIdEditMode', result1.meeting_id_for_task);
			let Json_Data = JSON.parse(result1.Json_Data);
			let tasks_list = Json_Data.tasks_list;

            let postData = { command: "getMeetingEdit", data: {  employee_id: 0, committeeid: committeeid, meetingid: result1.meeting_id_for_task }, meeting_mode: meeting_mode};
            let api = new APICall();
			let that = this;
            api.command(postData, function callback(result){that.callbackProcessTaskData(result , 'open_task' ,tasks_list , result1.task_obj )});
        }
    }
    taskAlertHandler = (result, stack) => {
        // this.setState({alert_param: null})
        window.location = '/';
    }

    callbackProcessTaskData = (result , flag , tasks_list_open_task , task_obj) => {
        console.log("callbackProcessTaskData result=>",result,flag,task_obj);

        let committeeUtils = new CommitteeUtils();
        let is_member_of_committee_hash = Store.getStoreData('is_member_of_committee_hash');
        let role = Store.getStoreData('role');
        let parent_has_group_companies = Store.getStoreData('parent_has_group_companies');
        let committee_with_count = Store.getStoreData('committee_with_count');
		let user_assignments = Store.getStoreData('user_assignments');
		let companies_assoc = Store.getStoreData('companies_assoc');
		let employee_contact_assoc = Store.getStoreData('employee_contact_assoc');

        let tmpcommittee = []

        //let committeeWithCount = result.committee_with_count;
		console.log("callbackProcessTaskData committee_with_count=>",committee_with_count);

        committee_with_count.committees.map(r => {
            if (parent_has_group_companies && role !== 'admin_manager') {
                if (is_member_of_committee_hash[r.PK_CommitteeID] && r.IsActive.toString() === '1') tmpcommittee.push({ PK_CommitteeID: r.PK_CommitteeID, committeename: JSON.parse(r.Json_Data).committeename });
            } else {
                if (r.is_member_of_this_committee && r.IsActive.toString() === '1') {
                    tmpcommittee.push({ PK_CommitteeID: r.PK_CommitteeID, committeename: JSON.parse(r.Json_Data).committeename });
                }
            }

        })

        if (Store.getStoreData('meetingIdEditMode') !== null) {
            let meetingDataEditMode = result.meeting_by_id;
            Store.updateStore('meetingDataEditMode', meetingDataEditMode);

            let committeemembers = [];
            let thirdpartyembers = [];
            let fiteredemployeelist = [];
            let fiteredemployeelistbeforechange = [];

            let parsedresult = JSON.parse(result.committee_by_id.Json_Data);
            committeemembers = parsedresult.committeemembers;
            thirdpartyembers = parsedresult.thirdpartyembers;
            Store.updateStore('Committee_Name_For_Task', parsedresult.committeename);

            let t = {};
            for (let j = 0; j < committeemembers.length; j++) {
                t[committeemembers[j].employeeid] = true;
            }
            //console.log("t ==>"+JSON.stringify(t));
            //let memberList = result.memberList
            let memberList = Store.getStoreData('employee_list');
            /*for (let i = 0; i < memberList.length; i++) {
                if (typeof t[memberList[i].employeeid] == 'undefined') {
                    fiteredemployeelist.push({ value: memberList[i].employeeid, label: memberList[i].employeename, include: false });
                    t[memberList[i].employeeid] = true;
                }
            }*/
			if(Store.getStoreData('parent_has_group_companies') === true){
				if(typeof parsedresult.gc_id !== 'undefined' && parsedresult.gc_id.toString() !== '0'){
					let gc_users = [];
					for(let u_id in user_assignments){
						if(parsedresult.gc_id in user_assignments[u_id].companies){
							gc_users.push(u_id);
						}
					}
					for(let u_id of gc_users){
						if(u_id in employee_contact_assoc){
							if (typeof t[employee_contact_assoc[u_id].employee_id.toString()] === 'undefined') {
								fiteredemployeelist.push({ value: employee_contact_assoc[u_id].employee_id, label: employee_contact_assoc[u_id].employee_name, include: false });
								fiteredemployeelistbeforechange.push({ value: employee_contact_assoc[u_id].employee_id, label: employee_contact_assoc[u_id].employee_name, include: false });
							}
						}
					}
					console.log("selected_committee,",gc_users,employee_contact_assoc);
				}else{
					for (let i = 0; i < memberList.length; i++) {
						if (typeof t[memberList[i].employeeid] == 'undefined') {
							fiteredemployeelist.push({ value: memberList[i].employeeid, label: memberList[i].employeename, include: false });
							fiteredemployeelistbeforechange.push({ value: memberList[i].employeeid, label: memberList[i].employeename, include: false });
							t[memberList[i].employeeid] = true;
						}
					}
				}

			}else{
				for (let i = 0; i < memberList.length; i++) {
					if (typeof t[memberList[i].employeeid] == 'undefined') {
						fiteredemployeelist.push({ value: memberList[i].employeeid, label: memberList[i].employeename, include: false });
						fiteredemployeelistbeforechange.push({ value: memberList[i].employeeid, label: memberList[i].employeename, include: false });
						t[memberList[i].employeeid] = true;
					}
				}
			}
            //let employee_name = Store.getStoreData('loggedin_user_info').ContactName;
            //Store.updateStore('LoggedInUser', employee_name);

            //Store.updateStore('Meeting_Date_For_Task', JSON.parse(meetingDataEditMode.Json_Data).meeting_date_for_task);
            let actions = [];
            let tasks_list = [];
			let action_extra_json = {};

			if(typeof flag !== 'undefined' && flag === 'open_task' && tasks_list_open_task.length > 0){
				tasks_list = tasks_list_open_task;

				let task_json = JSON.parse(task_obj.task_json);
				let action_json = task_json.object_data.headerinfo.action_json;
				let task_id = task_obj.id;
				let bin_files = task_json.object_data.bin_files;
				let view_mode = (task_obj.cur_lane !== 'COMP_COMMITTEES_COMPLETE') ? false : true ;
				action_extra_json = {action_title : action_json.action_title,action_description : action_json.action_description,action_assignto : action_json.action_assignto, due_date : action_json.due_date, action_note_undertaken : action_json.action_note_undertaken , followupBinFiles: bin_files ,showModal : true , view_mode : view_mode , task_id : task_id , cur_lane: task_obj.cur_lane};
			}else{
				if (result.tasks_list.length > 0) {
					//tasks_list = JSON.parse(meetingDataEditMode.Json_Data).tasks_list;
					tasks_list = result.tasks_list;
				}
			}
			for (let i = 0; i < tasks_list.length; i++) {
				let task_json = JSON.parse(tasks_list[i].task_json);
				let action_json = task_json.object_data.headerinfo.action_json;
				action_json.cur_lane = tasks_list[i].cur_lane;
				action_json.task_id = tasks_list[i].id;
				//console.log("action_json==>",action_json);
				actions.push(action_json);
			}

            Store.updateStore('Current_Meeting_Actions', actions);
            let meetingdate = JSON.parse(meetingDataEditMode.Json_Data).meetingdate;
            let meetingdatearray = meetingdate.split('/');
            let newmeetingdate = meetingdatearray[2] + '-' + meetingdatearray[1] + '-' + meetingdatearray[0];
            let additionalguestmembers = (typeof JSON.parse(meetingDataEditMode.Json_Data).additionalguestmembers !== 'undefined') ? JSON.parse(meetingDataEditMode.Json_Data).additionalguestmembers : [];

            let agenda_curBinFiles = [];
            let minutes_curBinFiles = [];
            let misc_curBinFiles = [];
			let agenda_assoc = {};
			let minutes_assoc = {};
			let misc_assoc = {};

            JSON.parse(meetingDataEditMode.Json_Data).agendadocs.forEach((item) => {
				//if(!(item.file_name.toString() in agenda_assoc)){
					let status = ('status' in item) ? item.status : 'active';
					agenda_curBinFiles.push({ uid: item.file_id, name: item.file_name, status: status });
					agenda_assoc[item.file_name.toString()]=true;
				//}
            });
            JSON.parse(meetingDataEditMode.Json_Data).minutesdocs.forEach((item) => {
				//if(!(item.file_name.toString() in minutes_assoc)){
					let status = ('status' in item) ? item.status : 'active';
					minutes_curBinFiles.push({ uid: item.file_id, name: item.file_name, status: status });
					minutes_assoc[item.file_name.toString()]=true;
				//}

            });
            JSON.parse(meetingDataEditMode.Json_Data).miscattachments.forEach((item) => {
				//if(!(item.file_name.toString() in misc_assoc)){
					let status = ('status' in item) ? item.status : 'active';
					misc_curBinFiles.push({ uid: item.file_id, name: item.file_name, status: status });
					misc_assoc[item.file_name.toString()]=true;
				//}
            });

            let meetingagenda_text = committeeUtils.changeStringToAtob(JSON.parse(meetingDataEditMode.Json_Data).meetingagenda);
            let minutes_text = committeeUtils.changeStringToAtob(JSON.parse(meetingDataEditMode.Json_Data).minutes);
            console.log("minutes_text,",JSON.parse(meetingDataEditMode.Json_Data).minutes,minutes_text);
            //console.log("meetingagenda_text,",JSON.parse(meetingDataEditMode.Json_Data).meetingagenda,meetingagenda_text);
			Store.updateStore('Meeting_Date_For_Task', new Date(newmeetingdate));
            let jsonobj = {};
            if (this.props.prop_task_id > 0) {
                //console.log("in open task window");
                /*for (let i = 0; i < actions.length; i++) {
                    if (actions[i].task_id === this.props.prop_task_id) {
                        jsonobj = JSON.parse(JSON.stringify(actions[i]));
                        jsonobj.showModal = true;
                        jsonobj.view_mode = (jsonobj.cur_lane === 'COMP_COMMITTEES_COMPLETE') ? true : false;

                    }
                }*/
				//Store.updateStore('Meeting_Date_For_Task', new Date(newmeetingdate));
                this.setState({
                    editMode: true,
                    showAddNote: false,
                    committeelist: tmpcommittee,
                    meetingid: meetingDataEditMode.PK_CommitteeMettingID,
                    committeeid: JSON.parse(meetingDataEditMode.Json_Data).committeeid,
                    startDate: new Date(newmeetingdate),
                    starttime: moment(JSON.parse(meetingDataEditMode.Json_Data).meetingstarttime),
                    endtime: moment(JSON.parse(meetingDataEditMode.Json_Data).meetingendtime),
                    meetinglocation: JSON.parse(meetingDataEditMode.Json_Data).meetinglocation,
                    meetingagenda: meetingagenda_text,
                    // agendadocs: [],
                    minutes: minutes_text,
                    minutesdocs: JSON.parse(meetingDataEditMode.Json_Data).minutesdocs,
                    miscattachments: JSON.parse(meetingDataEditMode.Json_Data).miscattachments,
                    meetingschedule: JSON.parse(meetingDataEditMode.Json_Data).meetingschedule,
                    scheduleid: JSON.parse(meetingDataEditMode.Json_Data).meetingschedule,
                    version: JSON.parse(meetingDataEditMode.Json_Data).version,
                    rootmeetingid: JSON.parse(meetingDataEditMode.Json_Data).rootmeetingid == 0 ? meetingDataEditMode.PK_CommitteeMettingID : JSON.parse(meetingDataEditMode.Json_Data).rootmeetingid,
                    task_ref_id: JSON.parse(meetingDataEditMode.Json_Data).task_ref_id,
                    children: JSON.parse(meetingDataEditMode.Json_Data).children,
                    attendeemembers: JSON.parse(meetingDataEditMode.Json_Data).attendeemembers,
                    committeemembers: JSON.parse(meetingDataEditMode.Json_Data).attendeemembers,
                    attendeerows: this.createAttendeeRowsEdit(JSON.parse(meetingDataEditMode.Json_Data).attendeemembers),
                    guestmembers: JSON.parse(meetingDataEditMode.Json_Data).guestmembers,
                    additionalguestmembers: additionalguestmembers,
                    fiteredemployeelist: fiteredemployeelist,
                    fiteredemployeelistbeforechange: fiteredemployeelistbeforechange,
                    agendadocs: JSON.parse(meetingDataEditMode.Json_Data).agendadocs,
                    addActionDivReturnData: JSON.parse(meetingDataEditMode.Json_Data).addActionDivReturnData,
                    actionRows: this.createActionRowsEdit(JSON.parse(meetingDataEditMode.Json_Data).addActionDivReturnData),
                    actions: actions,
                    tasks_list: tasks_list,
                    agenda_curBinFiles: agenda_curBinFiles,
                    minutes_curBinFiles: minutes_curBinFiles,
                    misc_curBinFiles: misc_curBinFiles,
                    ready: true,
                    actionModalOpen: true,
                    action_extra_json: action_extra_json,
					modal_view: 'open_followup_task_modal',
                });
            } else {

                this.setState({
                    editMode: true,
                    showAddNote: false,
                    committeelist: tmpcommittee,
                    meetingid: meetingDataEditMode.PK_CommitteeMettingID,
                    committeeid: JSON.parse(meetingDataEditMode.Json_Data).committeeid,
                    startDate: new Date(newmeetingdate),
                    starttime: moment(JSON.parse(meetingDataEditMode.Json_Data).meetingstarttime),
                    endtime: moment(JSON.parse(meetingDataEditMode.Json_Data).meetingendtime),
                    meetinglocation: JSON.parse(meetingDataEditMode.Json_Data).meetinglocation,
                    meetingagenda: meetingagenda_text,
                    // agendadocs: [],
                    minutes: minutes_text,
                    minutesdocs: JSON.parse(meetingDataEditMode.Json_Data).minutesdocs,
                    miscattachments: JSON.parse(meetingDataEditMode.Json_Data).miscattachments,
                    meetingschedule: JSON.parse(meetingDataEditMode.Json_Data).meetingschedule,
                    scheduleid: JSON.parse(meetingDataEditMode.Json_Data).meetingschedule,
                    version: JSON.parse(meetingDataEditMode.Json_Data).version,
                    rootmeetingid: JSON.parse(meetingDataEditMode.Json_Data).rootmeetingid == 0 ? meetingDataEditMode.PK_CommitteeMettingID : JSON.parse(meetingDataEditMode.Json_Data).rootmeetingid,
                    task_ref_id: JSON.parse(meetingDataEditMode.Json_Data).task_ref_id,
                    children: JSON.parse(meetingDataEditMode.Json_Data).children,
                    attendeemembers: JSON.parse(meetingDataEditMode.Json_Data).attendeemembers,
                    committeemembers: JSON.parse(meetingDataEditMode.Json_Data).attendeemembers,
                    attendeerows: this.createAttendeeRowsEdit(JSON.parse(meetingDataEditMode.Json_Data).attendeemembers),
                    guestmembers: JSON.parse(meetingDataEditMode.Json_Data).guestmembers,
                    additionalguestmembers: additionalguestmembers,
                    fiteredemployeelist: fiteredemployeelist,
                    fiteredemployeelistbeforechange: fiteredemployeelistbeforechange,
                    agendadocs: JSON.parse(meetingDataEditMode.Json_Data).agendadocs,
                    addActionDivReturnData: JSON.parse(meetingDataEditMode.Json_Data).addActionDivReturnData,
                    actionRows: this.createActionRowsEdit(JSON.parse(meetingDataEditMode.Json_Data).addActionDivReturnData),
                    actions: actions,
                    tasks_list: tasks_list,
                    agenda_curBinFiles: agenda_curBinFiles,
                    minutes_curBinFiles: minutes_curBinFiles,
                    misc_curBinFiles: misc_curBinFiles,
                    ready: true,
                });
            }
        } else {
            Store.updateStore('Meeting_Date_For_Task', this.state.startDate);
            this.setState({ committeelist: tmpcommittee, ready: true });
        }



    }



    getNoteCloseCommand = (childData) => {
        if (childData.close) {
            this.setState({
                showAddNote: false
            });
        }

    }

    handleAdditionalGuestDeleteClick = (index) => (event) => {
        event.preventDefault();
        let tempcm = []
        tempcm = this.state.additionalguestmembers;

        tempcm.splice(index, 1);

		if(tempcm.length === 0){
			let employeeid = Utils.genKey(10);
			tempcm.push({
				employeeid: employeeid,
				additionalguestname: "",
				additionalguestcompany: "",
				additionalguestreason: "",
				required: "Required",
				attended: "Yes"
			});
		}


        this.setState({
            additionalguestmembers: tempcm,

        });

    }

    refreshAddActionValue = (action_title, actionDescription, actionAssignedTo, actionBinFiles, action_status, due_date, action_note_undertaken, task_id, assign_to_multiple_users, multiple_users_array) => {
        console.log("refreshAddActionValue,", action_title, actionDescription, actionAssignedTo, actionBinFiles, action_status, due_date, action_note_undertaken, task_id, assign_to_multiple_users, multiple_users_array);
        let actions = this.state.actions;
		if(!assign_to_multiple_users){
			if (action_title !== '' && actionAssignedTo !== 0) {
				//let actions = JSON.parse(JSON.stringify(this.state.actions));
				let matches = 0;
				for (let i = 0; i < actions.length; i++) {
					if (actions[i].task_id === task_id) {
						actions[i].task_id = task_id;
						actions[i].action_title = action_title;
						actions[i].action_description = btoa(window.encodeURIComponent(actionDescription));
						actions[i].action_assignto = actionAssignedTo;
						actions[i].actionBinFiles = actionBinFiles;
						actions[i].action_status = 'New';
						actions[i].due_date = due_date;
						actions[i].action_note_undertaken = action_note_undertaken;

						matches = 1;
					}
				}
				if (matches === 0) {
					let actionsObject = {};
					actionsObject.task_id = task_id;
					actionsObject.action_title = action_title;
					actionsObject.action_description = btoa(window.encodeURIComponent(actionDescription));
					actionsObject.action_assignto = actionAssignedTo;
					actionsObject.actionBinFiles = actionBinFiles;
					actionsObject.action_status = 'New';
					actionsObject.due_date = due_date;
					actionsObject.action_note_undertaken = action_note_undertaken;
					actions.push(actionsObject);
				}

			}
		}else{
			for(let m of multiple_users_array){
				let actionsObject = {};
				actionsObject.task_id = Utils.genKey(10);
				actionsObject.action_title = action_title;
				actionsObject.action_description = btoa(window.encodeURIComponent(actionDescription));
				actionsObject.action_assignto = parseInt(m);
				actionsObject.actionBinFiles = actionBinFiles;
				actionsObject.action_status = 'New';
				actionsObject.due_date = due_date;
				actionsObject.action_note_undertaken = action_note_undertaken;
				actions.push(actionsObject);
			}
		}

        actions.sort(function (a, b) { return new Date(a.due_date).getTime() - new Date(b.due_date).getTime() });
        this.setState({ actions: actions, modal_view: null , assign_to_multiple_users });
    }

    actionModalOpen = (jsonObj) => {
        console.log("actionModalOpen=>", jsonObj);
        if ('task_id' in jsonObj && 'task_status' in jsonObj) {
            let actions = JSON.parse(JSON.stringify(this.state.actions));
            for (let i = 0; i < actions.length; i++) {
                if (actions[i].task_id === jsonObj.task_id && jsonObj.task_status === 'complete') {
                    actions[i].cur_lane = 'COMP_COMMITTEES_COMPLETE';
                    actions[i].action_note_undertaken = jsonObj.action_note_undertaken;
                }
                if (actions[i].task_id === jsonObj.task_id && jsonObj.task_status === 'incomplete') {
                    actions[i].cur_lane = 'COMP_COMMITTEES_SAVED';
                    actions[i].action_title = jsonObj.subject;
                    actions[i].action_assignto = jsonObj.assignedTo;
                    actions[i].due_date = jsonObj.due_date;
                    //actions[i].action_note_undertaken = jsonObj.action_note_undertaken;
                }
            }
            this.setState({ actionModalOpen: jsonObj.status, action_extra_json: {}, actions: actions, modal_view: null });
        } else {
            this.setState({ actionModalOpen: jsonObj.status, action_extra_json: {}, modal_view: null });
        }
    }

    processMyActionData = () => {
        let role = Store.getStoreData('role');
        let loggedin_user_info = Store.getStoreData('loggedin_user_info');
        var keysdata = this.state.actions;
        console.log("keysdata==>",keysdata);
        let ret = { data: [], columns: [] };
        let i = 0;

        ret.columns = [{ Header: '#', accessor: 'index', minWidth: 30, headerStyle: { textAlign: 'left' } },
        { Header: 'Assigned to', accessor: 'action_assignto', minWidth: 90, headerStyle: { textAlign: 'left' } },
        { Header: 'Task Name', accessor: 'action_title', minWidth: 110, headerStyle: { textAlign: 'left' } },
        { Header: 'Due Date', id: 'due_date', accessor: c => c.due_date, Cell: c => <span>{c.original.due_date && moment(c.original.due_date).format("DD/MM/YYYY")}</span>, minWidth: 30, headerStyle: { textAlign: 'left' } },
        {
            'Header': 'Status', Cell: row => (
                <div>
                    {
                        (() => {
                            //need to restrict by role
                            if (row.original.action_status === "Incomplete") {
                                if (row.original.cur_lane === "COMP_COMMITTEES_ASSIGNMENT") {
                                    return (<MRButton style={{ float: "center" , marginLeft: "20px", backgroundColor: "#F39C12", width: "70px" }}>Pending</MRButton>);
                                } else if (row.original.cur_lane === "COMP_COMMITTEES_SAVED") {
                                    return (<MRButton style={{ float: "center", marginLeft: "20px",backgroundColor: "blue", width: "70px" }}>In Progress</MRButton>);
                                } else if (row.original.cur_lane === "OVERDUE") {
                                    return (<MRButton style={{ float: "center", marginLeft: "20px",  backgroundColor: "red", width: "70px" }}>Overdue</MRButton>);
                                }

                            } else if (row.original.action_status === "New") {
                                if (row.original.cur_lane === "OVERDUE") {
                                    return (<MRButton style={{ float: "center", marginLeft: "20px", backgroundColor: "red", width: "70px" }}>Overdue</MRButton>);
                                } else {
                                    return (<MRButton style={{ float: "center", marginLeft: "20px",backgroundColor: "#F39C12", width: "70px" }}>Pending</MRButton>);
                                }
                            } else {
                                return (<MRButton style={{ float: "center",marginLeft: "20px", width: "70px" }}>Complete</MRButton>);
                            }
                        })()
                    }


                </div>
            ), minWidth: 30, headerStyle: { textAlign: 'center' }
        },
        {
            'Header': 'Action', Cell: row => (
                <div style={{ textAlign: "center" }}>
                    {
                        (() => {
                            //need to restrict by role
                            if (row.original.action_status === "Complete") {
                                return (<FaEye style={{ float: "left", marginLeft : "60px", cursor: "pointer" }} title="view this task" onClick={() => this.viewAction({ action_title: row.original.action_title, action_description: row.original.action_description, action_assignto: row.original.action_assignto_id, due_date: row.original.unformatted_due_date, action_note_undertaken: row.original.action_note_undertaken, showModal: true, view_mode: true, task_id: row.original.task_id, action_status: row.original.action_status, actionBinFiles: row.original.actionBinFiles, roll_over_task_ref_id: row.original.roll_over_task_ref_id })} />);

                            } else if (row.original.action_status === "Incomplete" || row.original.action_status === "New") {
								if(role === 'admin_manager' || role === 'governance_manager'){
									return (<FaPen style={{ float: "left", marginLeft : "60px", cursor: "pointer" }} title="edit this task" onClick={() => this.viewAction({ action_title: row.original.action_title, action_description: row.original.action_description, action_assignto: row.original.action_assignto_id, due_date: row.original.unformatted_due_date, action_note_undertaken: '', showModal: true, view_mode: false, task_id: row.original.task_id, action_status: row.original.action_status, actionBinFiles: row.original.actionBinFiles, roll_over_task_ref_id: row.original.roll_over_task_ref_id })} />);
								}else{
									if( row.original.action_assignto_id.toString() === row.original.loggedin_user_info_id.toString()){
										return (<FaPen style={{ float: "left", marginLeft : "60px", cursor: "pointer" }} title="edit this task" onClick={() => this.viewAction({ action_title: row.original.action_title, action_description: row.original.action_description, action_assignto: row.original.action_assignto_id, due_date: row.original.unformatted_due_date, action_note_undertaken: '', showModal: true, view_mode: false, task_id: row.original.task_id, action_status: row.original.action_status, actionBinFiles: row.original.actionBinFiles, roll_over_task_ref_id: row.original.roll_over_task_ref_id })} />);
									}else{
										return (<FaEye style={{ float: "left", marginLeft : "60px", cursor: "pointer" }} title="view this task" onClick={() => this.viewAction({ action_title: row.original.action_title, action_description: row.original.action_description, action_assignto: row.original.action_assignto_id, due_date: row.original.unformatted_due_date, action_note_undertaken: row.original.action_note_undertaken, showModal: true, view_mode: true, task_id: row.original.task_id, action_status: row.original.action_status, actionBinFiles: row.original.actionBinFiles, roll_over_task_ref_id: row.original.roll_over_task_ref_id })} />);
									}

								}

                            } else {
                                if (row.original.cur_lane === "COMP_COMMITTEES_SAVED") {
                                    return (<FaPen style={{ float: "left", marginLeft : "60px", cursor: "pointer" }} title="edit this task" onClick={() => this.viewAction({ action_title: row.original.action_title, action_description: row.original.action_description, action_assignto: row.original.action_assignto_id, due_date: row.original.unformatted_due_date, action_note_undertaken: '', showModal: true, view_mode: false, task_id: row.original.task_id, action_status: row.original.action_status, actionBinFiles: row.original.actionBinFiles, roll_over_task_ref_id: row.original.roll_over_task_ref_id })} />);
                                }
                            }
                        })()
                    }
					{
						(() => {
							if(row.original.cur_lane !== 'COMP_COMMITTEES_COMPLETE'){
								if(role === 'admin_manager' || role === 'governance_manager' ){
									return (
										<div style={{float: "right", marginRight : "60px"}}>
											<ViewDiv style={{ curser: "pointer" ,color : "#1A3552"}} onClick={this.deleteConfirm(row.original.task_id)}   title="delete this follow-up task" ><MdDelete /></ViewDiv>
										</div>
										);
								}

							}

						})()
					}


                </div>
            ), minWidth: 50, headerStyle: { textAlign: 'center' }
        }
            ,

        ];

        keysdata.sort(function (a, b) { return new Date(a.due_date).getTime() - new Date(b.due_date).getTime() });
        for (let d of keysdata) {
            //console.log("d=>",d);
            i++;
            let assignto = Store.getStoreData('contact_list')[d.action_assignto];
            let g1 = new Date();
            let g2 = new Date(d.due_date);
            let cur_lane = d.cur_lane;

            let action_status = '';
            if ('action_status' in d) {
                action_status = d.action_status;
            } else {
                if (cur_lane !== 'COMP_COMMITTEES_COMPLETE') {
                    action_status = 'Incomplete';
                    if (g1.withoutTime() > g2.withoutTime()) {
                        cur_lane = 'OVERDUE';
                    }
                } else {
                    action_status = 'Complete';
                }

            }
            if (typeof cur_lane === 'undefined') {
                if (g1.withoutTime() > g2.withoutTime()) {
                    cur_lane = 'OVERDUE';
                }
            }
            let roll_over_task_ref_id = '';
            if ('roll_over_task_ref_id' in d) roll_over_task_ref_id = d.roll_over_task_ref_id;
            let elem = {
                'index': i,
                'action_title': d.action_title,
                'action_description': d.action_description,
                'action_assignto': assignto,
                'action_status': action_status,
                'due_date': d.due_date === '-' ? '-' : d.due_date,
                'cur_lane': cur_lane,
                'followupBinFiles': d.followupBinFiles,
                'task_id': d.task_id,
                'action_assignto_id': d.action_assignto,
                'unformatted_due_date': d.due_date,
                'action_note_undertaken': d.action_note_undertaken,
                'actionBinFiles': d.actionBinFiles,
                'roll_over_task_ref_id': d.roll_over_task_ref_id,
                'loggedin_user_info_id': loggedin_user_info.ID,
            };
            //console.log("elem==>", elem);
            ret.data.push(elem);
        }
        return ret;
    }

	deleteConfirm = (task_id) => (event) => {
		event.preventDefault();

		let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this follow-up task?', ok_text: 'Yes', cancel_text: 'No', confirm: true,
						   alertHandler: this.deleteProcessor, stack: {task_id: task_id}}
		this.setState({alert_param: alert_param})

	}
	deleteProcessor = (result, stack) => {
		if(!result || stack.prevent) {
			this.setState({alert_param: null})
			return
		}
		let task_id = stack.task_id;
		this.setState({is_loader: true,alert_param: null});
		//console.log("deleteProcessor task_id,",task_id,this.state.actions,parseInt(task_id));
		if(parseInt(task_id).toString() === 'NaN'){
			let actions = [];
			for(let a of this.state.actions){
				if(a.task_id.toString() === task_id.toString())continue;
				actions.push(a);
			}
			Store.updateStore('Current_Meeting_Actions', actions);
			this.setState({actions,is_loader: false});
		}else{
			let postData = { command: "delete_followup_task", task_id: task_id };
			let api = new APICall();
			api.command(postData, this.processDeleteAction);
			//this.setState({alert_param: null});
		}
		//return;

	}
	processDeleteAction = (result ) =>{
        console.log("processDeleteAction result,",result);
		let actions = [];
		for(let a of this.state.actions){
			if(a.task_id.toString() === result.task_id.toString())continue;
			actions.push(a);
		}
		Store.updateStore('Current_Meeting_Actions', actions);
		this.setState({actions,is_loader: false})
	}


    viewAction = (jsonObj) => {
        console.log("task_id in viewAction ==>", jsonObj);
        if (jsonObj.action_status === 'New') {
            let action_extra_json = { action_title: jsonObj.action_title, action_description: jsonObj.action_description, action_assignto: jsonObj.action_assignto, due_date: jsonObj.due_date, action_note_undertaken: jsonObj.action_note_undertaken, followupBinFiles: jsonObj.actionBinFiles, showModal: true, view_mode: false, task_id: jsonObj.task_id, cur_lane: jsonObj.cur_lane };
            this.setState({ action_extra_json, modal_view: 'open_followup_task_modal', ready: true });
        } else {
            this.setState({ is_loader: true });
            //return;
            let postData = { "command": "get_task", "task_id": jsonObj.task_id };
            let api = new APICall();
            //let that = this;
            api.command(postData, this.openTaskModal);
            //this.setState({actionModalOpen: true , action_extra_json : jsonObj , modal_view: 'open_followup_task_modal'});
        }

    }
    openTaskModal = (result) => {
        console.log("result,", result);
		let role = Store.getStoreData('role');
		let loggedin_user_info = Store.getStoreData('loggedin_user_info');
        let task_json = JSON.parse(result.result.task.parenttask.task_json);
        let action_json = task_json.object_data.headerinfo.action_json;
        let task_id = result.result.task.parenttask.id;
        let bin_files = task_json.object_data.bin_files;
        let view_mode = (result.result.task.parenttask.cur_lane !== 'COMP_COMMITTEES_COMPLETE') ? false : true;
		if(role === 'team' && result.result.task.parenttask.cur_assigned_to.toString() !== loggedin_user_info.ID.toString())view_mode=true;
        let action_extra_json = { action_title: action_json.action_title, action_description: action_json.action_description, action_assignto: action_json.action_assignto, due_date: action_json.due_date, action_note_undertaken: action_json.action_note_undertaken, followupBinFiles: bin_files, showModal: true, view_mode: view_mode, task_id: task_id, cur_lane: result.result.task.parenttask.cur_lane , roll_over_task_ref_id: action_json.roll_over_task_ref_id };
        this.setState({ action_extra_json, modal_view: 'open_followup_task_modal', ready: true , is_loader: false});
        //return;
    }
    processMeetingAttendeesData = () => {
		let committeeUtils = new CommitteeUtils();
        let committeemembers = this.state.committeemembers;
		let task_ref_id = this.state.task_ref_id;
		let employee_rsvp_assoc = committeeUtils.get_rsvp_response_assoc_for_meeting_ref(task_ref_id,committeemembers);
		let module_configs = Store.getStoreData('module_configs');
		let employee_assoc = Store.getStoreData('employee_assoc');
		//console.log("module_configs,",module_configs);

        let ret = { data: [], columns: [] };
        let i = 0;

        //ret.columns = [{ Header: 'Name', accessor: 'employee_name', width: '30%', headerStyle: { textAlign: 'left' } },
        ret.columns = [
		{ Header: 'Name', accessor: 'employee_name', minWidth: 200, headerStyle: { textAlign: 'left' } },
		{ Header: '', accessor: 'rsvp_comp', minWidth: 30, headerStyle: { textAlign: 'right' }, style: { textAlign: 'center' }},

        { Header: 'Role', accessor: 'role', minWidth: 100, headerStyle: { textAlign: 'left' } },
        {
            'Header': 'Required?', Cell: row => (
                <div>
                    {
                        (() => {
                            return (
                                this.handleAttendeeRequiredDropDown(row.original.employee_id, row.original.required)
                            )
                        })()
                    }


                </div>
            ), minWidth: 100, headerStyle: { textAlign: 'left' }
        },
        {
            'Header': 'Attended?', Cell: row => (
                <div>
                    {
                        (() => {
                            return (
                                this.handleAttendeeAttendedDropDown(row.original.employee_id, row.original.attended)
                            )
                        })()
                    }


                </div>
            ), minWidth: 100, headerStyle: { textAlign: 'left' }
        },
        ];
		if(this.state.meetingid.toString() === '0' || !module_configs.general.track_member_responses_to_meeting_invitees)ret.columns.splice(1, 1);
        //console.log("this.state.committeemembers=>",this.state.committeemembers);
        for (let i = 0; i < committeemembers.length; i++) {
            //console.log("employeename==>"+this.state.committeemembers[i].employeename+"<=employeename");
            if (typeof committeemembers[i].employeename != 'undefined' && committeemembers[i].employeename != "") {

                let required = ('required' in committeemembers[i]) ? committeemembers[i].required : '';
                let attended = ('attended' in committeemembers[i]) ? committeemembers[i].attended : '';
				let rsvp_response_str = (committeemembers[i].employeeid.toString() in employee_rsvp_assoc) ? employee_rsvp_assoc[committeemembers[i].employeeid.toString()] : 'NA';
				let rsvp_comp = '';
				if(rsvp_response_str === 'NA'){
					rsvp_comp=<FontAwesomeIcon icon={solid('dash')} style={{color: "#1E3E62"}} size="2x" />;
				}
				if(rsvp_response_str === 'Yes'){
					rsvp_comp=<FontAwesomeIcon icon={solid('check')} style={{color: "#5BB85C"}} size="2x" />;
				}
				if(rsvp_response_str === 'No'){
					rsvp_comp=<FontAwesomeIcon icon={solid('xmark')} style={{color: "#D9544F"}} size="2x" />;
				}
				if(rsvp_response_str === 'Maybe'){
					rsvp_comp=<FontAwesomeIcon icon={solid('question')} style={{color: "#f0b62e"}} size="2x" />;
				}
				let employee_name = committeemembers[i].employeename;
				if(committeemembers[i].employeeid.toString() in employee_assoc && !employee_assoc[committeemembers[i].employeeid.toString()].is_active)employee_name=committeemembers[i].employeename+' (Inactive)';
                let elem = {
                    'index': i + 1,
                    'employee_id': committeemembers[i].employeeid,
                    'employee_name': employee_name,
                    'role': committeemembers[i].committeerolename,
                    'required': required,
                    'attended': attended,
                    'rsvp_response_str': rsvp_response_str,
                    'rsvp_comp': rsvp_comp,

                };
                ret.data.push(elem);
            }
        }
		//console.log("GGGGG=>",task_ref_id,ret);


        return ret;
    }

    changeInputText = (index, name) => {
        return (
            <ReactFormGroup>
                <ModalInput placeholder="Full Name" name={`tpname-${index + 1}`} value={name} onChange={this.handleAdditionalGuestAddNameChange} />
            </ReactFormGroup>
        )
    }





    processAdditionalGuestData = () => {

        let ret = { data: [], columns: [] };
        let i = 0;

        ret.columns = [{
            'Header': 'Name', Cell: row => (
                <div>
                    {/*{
	                            (() => {
									return (
										<ReactFormGroup>
                                            <ModalInput placeholder="Full Name" name={`tpname-${row.original.index + 1}`} value={row.original.additionalguestname} onChange={this.handleAdditionalGuestAddNameChange}  />
										    this.changeInputText(row.original.index,row.original.additionalguestname);
                                        </ReactFormGroup>
										)
	                            })()
	                        }*/}

                    {
                        (() => {
                            return <InputBox value={row.original.additionalguestname} key_value={row.original.index + 1} showInput={this.showInput} />
                        })()
                    }





                </div>
            ), minWidth: 150, headerStyle: { textAlign: 'left' }
        },

        {
            'Header': 'Required?', Cell: row => (
                <div>
                    {
                        (() => {
                            return (
                                this.handleAdditionalGuestRequiredDropDown(row.original.employee_id, row.original.required)
                            )
                        })()
                    }


                </div>
            ), minWidth: 70, headerStyle: { textAlign: 'left' }
        },
        {
            'Header': 'Attended?', Cell: row => (
                <div>
                    {
                        (() => {
                            return (
                                this.handleAdditionalGuestAttendedDropDown(row.original.employee_id, row.original.attended)
                            )
                        })()
                    }


                </div>
            ), minWidth: 70, style: { textAlign: 'center' }, headerStyle: { textAlign: 'left' }
        },
        {
            'Header': '', Cell: row => (
                <div>
                    {
                        (() => {
                            return (
                                <FaPlus style={{ color: "#979797", top: "16px", position: "relative" }} onClick={this.handleAdditionalGuestAddClick} />
                            )
                        })()
                    }


                </div>
            ), minWidth: 30, style: { textAlign: 'center' }, headerStyle: { textAlign: 'left' }
        },
        {
            'Header': '', Cell: row => (
                <div>
                    {
                        (() => {
                            return (
                                <MdClose style={{ color: "#979797", top: "16px", position: "relative" }} onClick={this.handleAdditionalGuestDeleteClick(`${row.original.index}`)} />
                            )
                        })()
                    }


                </div>
            ), minWidth: 30, headerStyle: { textAlign: 'left' }
        },
        ];
        //console.log("this.state.committeemembers=>",this.state.committeemembers);


        let additionalguestmembers = this.state.additionalguestmembers;

        console.log("additionalguestmembers=>",additionalguestmembers);

        for (let i = 0; i < additionalguestmembers.length; i++) {
            if (typeof additionalguestmembers[i].additionalguestname !== 'undefined') {
                let required = ('required' in additionalguestmembers[i]) ? additionalguestmembers[i].required : '';
                let attended = ('attended' in additionalguestmembers[i]) ? additionalguestmembers[i].attended : '';
                let elem = {
                    'index': i,
                    'employee_id': additionalguestmembers[i].employeeid,
                    'additionalguestname': additionalguestmembers[i].additionalguestname,
                    'additionalguestcompany': additionalguestmembers[i].additionalguestcompany,
                    'additionalguestreason': additionalguestmembers[i].additionalguestreason,
                    'required': required,
                    'attended': attended,

                };
                ret.data.push(elem);
            }
        }



        return ret;
    }

	agendaFilesLoaded = (files) => {
	  console.log("files", files)
	  let contact_id = Store.getStoreData("loggedin_user_info").ID;
	  let a = []
	  for (let i = 0; i < files.length; i++) {
	    files[i].name = files[i].name.replace("&", "");
		files[i].status = ('status' in files[i]) ? files[i].status : 'active';
	    if (!("user_id" in files[i])) {
	      files[i].user_id = contact_id;
	      files[i].upload_date = last_action_time;
	    }
	    // a.push({uid: files[i].uid, name: files[i].name})
	  }

	  console.log("files a", files)
	  this.setState({agenda_curBinFiles: files,addnew: false});
	}
	agendaFilesRemoved = (obj) =>{
		let files = obj.files;
		let file_name = obj.file_name;
		let uid = obj.uid;
		let agendadocs = this.state.agendadocs;
		console.log("agendaFilesRemoved,",files,agendadocs,this.state.agenda_curBinFiles);
		let fl_assoc={};
		let fl_assoc_name={};
		for(let f of files){
			if(f.status==='deleted'){
				fl_assoc[f.uid]=true;
				fl_assoc_name[f.name]=true;
			}
		}
		for(let a of agendadocs){
			if(fl_assoc[a.uid]===true || fl_assoc[a.file_id]===true  || fl_assoc[a.ref_id]===true)a.status='deleted';
		}
		let c = [];
		for(let a of this.state.agenda_curBinFiles){
			if(fl_assoc[a.uid]===true)continue;
			if(a.uid===uid)continue;
			c.push(a);
		}
		//console.log("agendaFilesRemoved,",agendadocs);
		this.setState({agendadocs,agenda_curBinFiles:c});
	}
	minutesFilesRemoved = (obj) =>{
		let minutesdocs = this.state.minutesdocs;
		let files = obj.files;
		let file_name = obj.file_name;
		let uid = obj.uid;
		//console.log("agendaFilesRemoved,",files,agendadocs);
		let fl_assoc={};
		let fl_assoc_name={};
		for(let f of files){
			if(f.status==='deleted'){
				fl_assoc[f.uid]=true;
				fl_assoc_name[f.name]=true;
			}
		}
		for(let a of minutesdocs){
			if(fl_assoc[a.uid]===true || fl_assoc[a.file_id]===true  || fl_assoc[a.ref_id]===true)a.status='deleted';
		}
		let c = [];
		for(let a of this.state.minutes_curBinFiles){
			if(fl_assoc[a.uid]===true )continue;
			if(a.uid===uid)continue;
			c.push(a);
		}
		//console.log("agendaFilesRemoved,",agendadocs);
		this.setState({minutesdocs,minutes_curBinFiles:c});
	}
	miscFilesRemoved = (obj) =>{
		let files = obj.files;
		let file_name = obj.file_name;
		let uid = obj.uid;
		let miscattachments = this.state.miscattachments;
		//let misc_curBinFiles = this.state.misc_curBinFiles;
		//console.log("miscFilesRemoved,",files,miscattachments,this.state.misc_curBinFiles);
		let fl_assoc={};
		let fl_assoc_name={};
		for(let f of files){
			if(f.status==='deleted'){
				fl_assoc[f.uid]=true;
				fl_assoc_name[f.name]=true;
			}
		}
		for(let a of miscattachments){
			if(fl_assoc[a.uid]===true || fl_assoc[a.file_id]===true  || fl_assoc[a.ref_id]===true)a.status='deleted';
		}
		let c = [];
		for(let a of this.state.misc_curBinFiles){
			if(fl_assoc[a.uid]===true)continue;
			if(a.uid===uid)continue;
			c.push(a);
		}
		//console.log("miscFilesRemoved,",c);
		this.setState({miscattachments, misc_curBinFiles:c});
	}

    agendaFilesLoaded_obsolate = (jsonObj) => {

        console.log('Jsonext------->', jsonObj);
        let me = Store.getStoreData('loggedin_user_info');
        let files_to_show = JSON.parse(JSON.stringify(jsonObj.files));
        let agendadocs = this.state.agendadocs;
        let curBinFiles = this.state.agenda_curBinFiles;

        let file_exists = {};
        let curBinFiles_1 = [];
        let file_elements = [];
        let file_names = [];

        for (let p of agendadocs) {
            file_exists[p.file_name] = true;
        }

        if (jsonObj.command === 'add') {
            for (let i = 0; i < files_to_show.length; i++) {
                if (typeof files_to_show[i].name !== "undefined") {
                    files_to_show[i].name = jsonObj.files[i].name.replace('&', '');
                    if (!('user_id' in files_to_show[i])) {
                        files_to_show[i].user_id = me.ID;
                    }

                    if (typeof file_exists[files_to_show[i].name] === "undefined") {
                        let fElement = { name: files_to_show[i].name, bin_file: files_to_show[i].bin_file.replace(/\n/g, '') };
                        file_elements.push(fElement);
                        file_exists[files_to_show[i].name] = true;
                        file_names.push({ name: files_to_show[i].name, uid: files_to_show[i].uid });
                    }
                }

            }
            let postData = { command: "uploadFiles", data: { fElement: file_elements } };
            let api = new APICall();
            let that = this;
            api.command(postData, function callback(result) { that.processAgendaFilesLoaded(file_names, result) });

        } else {//delete
            for (let i = 0; i < curBinFiles.length; i++) {
                if (curBinFiles[i].uid.toString() === jsonObj.deleted_uid.toString()) {
                    curBinFiles.splice(i, 1);
                }
            }
            for (let i = 0; i < agendadocs.length; i++) {
                if (agendadocs[i].file_id.toString() === jsonObj.deleted_uid.toString()) {
                    agendadocs.splice(i, 1);
                }
            }
            this.setState({ curBinFiles, agendadocs });
        }

    }
    processAgendaFilesLoaded = (file_names, result) => {
        let file_ids = result;
        let curBinFiles = this.state.agenda_curBinFiles;
        let agendadocs = this.state.agendadocs;
        for (let i = 0; i < file_ids.length; i++) {
            agendadocs.push({ file_name: file_names[i].name, file_id: file_ids[i].file_id });
            curBinFiles.push({ name: file_names[i].name, uid: file_ids[i].file_id });
        }
        let curBinFiles_1 = curBinFiles.filter((curBinFiles, index, self) => index === self.findIndex((t) => (t.name === curBinFiles.name && t.uid === curBinFiles.uid)));
        this.setState({
            curBinFiles: curBinFiles_1,
            agendadocs: agendadocs,
        });
    }
    filesRemoved = (jsonObj) => {

    }

    downloadAttachment_obsolate = (file_id, file_name) => {
        console.log("file_id==>" + file_id);
        let that = this;
        let postData = { command: "downloadFile", data: { file_id: file_id } };
        let api = new APICall();
        api.command(postData, function callback(result) { that.downloadFileToBrowser(file_name, result) });
    }
    downloadAttachment = (file_id, file_name) => {
        console.log("file_id==>" , file_id);
        let that = this;
        let postData = { command: "downloadFile", data: { file_id: file_id } };
        let api = new APICall();
        api.command(postData, function callback(result) { that.downloadFileToBrowser(file_name, result) });
    }
    downloadFileToBrowser = (file_name, result) => {
        let bin_file = result.base64filedata.bin_file;
        let fileName = result.base64filedata.name;
        let dnld = bin_file.replace(/ /g, '+');
        // console.log("binary file before download:", dnld);
        var binaryString = window.atob(dnld);
        // console.log('binaryString', binaryString);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        var blob = new Blob([bytes]);
        saveAs(blob, fileName);
    }
	minutesFilesLoaded = (files) => {
	  console.log("files", files)
	  let contact_id = Store.getStoreData("loggedin_user_info").ID;
	  let a = []
	  for (let i = 0; i < files.length; i++) {
	    files[i].name = files[i].name.replace("&", "");
	    if (!("user_id" in files[i])) {
	      files[i].user_id = contact_id;
	      files[i].upload_date = last_action_time;
	    }
	    // a.push({uid: files[i].uid, name: files[i].name})
	  }

	  //console.log("files a", files)
	  this.setState({minutes_curBinFiles: files,addnew: false});
	}
    minutesFilesLoaded_obsolate = (jsonObj) => {
        let me = Store.getStoreData('loggedin_user_info');
        let files_to_show = JSON.parse(JSON.stringify(jsonObj.files));
        let minutesdocs = this.state.minutesdocs;
        let minutes_curBinFiles = this.state.minutes_curBinFiles;

        let file_exists = {};
        let curBinFiles_1 = [];
        let file_elements = [];
        let file_names = [];

        for (let p of minutesdocs) {
            file_exists[p.file_name] = true;
        }

        if (jsonObj.command === 'add') {
            for (let i = 0; i < files_to_show.length; i++) {
                if (typeof files_to_show[i].name !== "undefined") {
                    files_to_show[i].name = jsonObj.files[i].name.replace('&', '');
                    if (!('user_id' in files_to_show[i])) {
                        files_to_show[i].user_id = me.ID;
                    }
                    if (typeof file_exists[files_to_show[i].name] === "undefined") {
                        let fElement = { name: files_to_show[i].name, bin_file: files_to_show[i].bin_file.replace(/\n/g, '') };
                        file_elements.push(fElement);
                        file_exists[files_to_show[i].name] = true;
                        file_names.push({ name: files_to_show[i].name, uid: files_to_show[i].uid });
                    }
                }

            }
            let postData = { command: "uploadFiles", data: { fElement: file_elements } };
            let api = new APICall();
            let that = this;
            api.command(postData, function callback(result) { that.processMinutesFilesLoaded(file_names, result) });

        } else {//delete
            for (let i = 0; i < minutes_curBinFiles.length; i++) {
                if (minutes_curBinFiles[i].uid.toString() === jsonObj.deleted_uid.toString()) {
                    minutes_curBinFiles.splice(i, 1);
                }
            }
            for (let i = 0; i < minutesdocs.length; i++) {
                if (minutesdocs[i].file_id.toString() === jsonObj.deleted_uid.toString()) {
                    minutesdocs.splice(i, 1);
                }
            }
            this.setState({ minutes_curBinFiles, minutesdocs });
        }
    }
    processMinutesFilesLoaded = (file_names, result) => {
        let file_ids = result;
        let curBinFiles = this.state.minutes_curBinFiles;
        let minutesdocs = this.state.minutesdocs;
        for (let i = 0; i < file_ids.length; i++) {
            minutesdocs.push({ file_name: file_names[i].name, file_id: file_ids[i].file_id });
            curBinFiles.push({ name: file_names[i].name, uid: file_ids[i].file_id });
        }
        let curBinFiles_1 = curBinFiles.filter((curBinFiles, index, self) => index === self.findIndex((t) => (t.name === curBinFiles.name && t.uid === curBinFiles.uid)));
        this.setState({
            minutes_curBinFiles: curBinFiles_1,
            minutesdocs: minutesdocs,
        });
    }
	miscFilesLoaded = (files) => {
	  console.log("files", files)
	  let contact_id = Store.getStoreData("loggedin_user_info").ID;
	  let a = []
	  for (let i = 0; i < files.length; i++) {
	    files[i].name = files[i].name.replace("&", "");
	    if (!("user_id" in files[i])) {
	      files[i].user_id = contact_id;
	      files[i].upload_date = last_action_time;
	    }
	    // a.push({uid: files[i].uid, name: files[i].name})
	  }

	  console.log("files a", a)
	  this.setState({misc_curBinFiles: files,addnew: false});
	}
    miscFilesLoaded_obsolate = (jsonObj) => {
        let me = Store.getStoreData('loggedin_user_info');
        let files_to_show = JSON.parse(JSON.stringify(jsonObj.files));
        let misc_curBinFiles = this.state.misc_curBinFiles;
        let miscattachments = this.state.miscattachments;

        let file_exists = {};
        let curBinFiles_1 = [];
        let file_elements = [];
        let file_names = [];

        for (let p of miscattachments) {
            file_exists[p.file_name] = true;
        }

        if (jsonObj.command === 'add') {
            for (let i = 0; i < files_to_show.length; i++) {
                if (typeof files_to_show[i].name !== "undefined") {
                    files_to_show[i].name = jsonObj.files[i].name.replace('&', '');
                    if (!('user_id' in files_to_show[i])) {
                        files_to_show[i].user_id = me.ID;
                    }
                    if (typeof file_exists[files_to_show[i].name] === "undefined") {
                        let fElement = { name: files_to_show[i].name, bin_file: files_to_show[i].bin_file.replace(/\n/g, '') };
                        file_elements.push(fElement);
                        file_exists[files_to_show[i].name] = true;
                        file_names.push({ name: files_to_show[i].name, uid: files_to_show[i].uid });
                    }
                }
            }

            let postData = { command: "uploadFiles", data: { fElement: file_elements } };
            let api = new APICall();
            let that = this;
            api.command(postData, function callback(result) { that.processMiscFilesLoaded(file_names, result) });

        } else {//delete
            for (let i = 0; i < misc_curBinFiles.length; i++) {
                if (misc_curBinFiles[i].uid.toString() === jsonObj.deleted_uid.toString()) {
                    misc_curBinFiles.splice(i, 1);
                }
            }
            for (let i = 0; i < miscattachments.length; i++) {
                if (miscattachments[i].file_id.toString() === jsonObj.deleted_uid.toString()) {
                    miscattachments.splice(i, 1);
                }
            }
            this.setState({ misc_curBinFiles, miscattachments });
        }
    }
    processMiscFilesLoaded = (file_names, result) => {

        let file_ids = result;
        let curBinFiles = this.state.misc_curBinFiles;
        let miscattachments = this.state.miscattachments;
        //console.log("processMiscFilesLoaded result,",result,curBinFiles,miscattachments);
        for (let i = 0; i < file_ids.length; i++) {
            miscattachments.push({ file_name: file_names[i].name, file_id: file_ids[i].file_id });
            curBinFiles.push({ name: file_names[i].name, uid: file_ids[i].file_id });
        }
        let curBinFiles_1 = curBinFiles.filter((curBinFiles, index, self) => index === self.findIndex((t) => (t.name === curBinFiles.name && t.uid === curBinFiles.uid)));
        //console.log("processMiscFilesLoaded result,",curBinFiles_1,miscattachments);
        this.setState({
            misc_curBinFiles: curBinFiles_1,
            miscattachments: miscattachments,
        });
    }


    closeDialog = () => {
        this.setState({ viewconfirmation: false });
    }
    closeParentModal = () => {
        this.closeMeetingModal();
        this.setState({ viewconfirmation: false });
    }
    cleanHTMLEditor = () => {
        Store.updateStore('meetingagenda', null);
        Store.updateStore('minutes', null);
    }
    showAddAction = (index) => (event) => {
        //console.log("in show action");
        event.preventDefault();
        this.setState({ modal_view: 'open_followup_task_modal' });
    }

  imageUrl(file) {
    const file_parts = file.split(/\.(?=[^\.]+$)/);
    let ext = "PDF";
    let extention = file_parts[file_parts.length - 1];
    switch (extention) {
      case "jpeg":
      case "jpg":
        ext = "JPG";
        break;
      case "png":
        ext = "PNG";
        break;
      case "docx":
        ext = "DOC";
        break;
      case "doc":
        ext = "DOC";
        break;
      case "msg":
        ext = "MSG";
        break;
      case "txt":
        ext = "TXT";
        break;
      case "ppt":
        ext = "PPT";
        break;
      case "pptx":
        ext = "PPTX";
        break;
      case "xls":
        ext = "XLS";
        break;
      case "xlsx":
        ext = "XLS";
        break;
      case "zip":
        ext = "ZIP";
        break;
      default:
        ext = "PDF";
        break;
    }
    console.log("ext", ext);
    let file_icon_var = ext === "DOCX" ? "DOC" : ext;
    file_icon_var = ext === "XLSX" ? "XLS" : ext;
    const file_icon = `/dropzoneImages/${ext}.png`;
    return file_icon;
  }

   onUploadComplete  = (files) => {
		//alert("hello", files)
		let returnedFiles = JSON.parse(files);
		let agenda_bin_files = JSON.parse(JSON.stringify(this.state.agenda_curBinFiles));
		let agendadocs = JSON.parse(JSON.stringify(this.state.agendadocs));
		let mint_bin_files = JSON.parse(JSON.stringify(this.state.minutes_curBinFiles));
		let minutesdocs = JSON.parse(JSON.stringify(this.state.minutesdocs));
		let misc_bin_files = JSON.parse(JSON.stringify(this.state.misc_curBinFiles));
		let miscattachments = JSON.parse(JSON.stringify(this.state.miscattachments));
		console.log("hello==>", returnedFiles,agenda_bin_files,mint_bin_files,misc_bin_files)
		let contact_id = Store.getStoreData("loggedin_user_info").ID;
		let flag = 0;
		if (typeof agenda_bin_files !== 'undefined' && agenda_bin_files.length > 0) {
			for(let file of agenda_bin_files){
				let fileName = file.uid;
				//console.log("wello 1==>");
				//let refID_obj = returnedFiles.find((o) => o.inserted_file_name == fileName);
				let refID_obj = {}
				for(let o of returnedFiles){
					//if(o.inserted_file_name !== fileName)continue;
					if(o.uid !== fileName)continue;
					refID_obj=o;
				}
				let refID = refID_obj['ref_id'];
				//console.log("wello 2==>");
				delete file.bin_file;
				//console.log("wello 3==>");
				if(typeof refID === 'undefined')continue;
				//console.log("wello 4==>");
				file.ref_id = refID;
				agendadocs.push({uid: file.uid, file_name: file.name, ref_id: refID, user_id: contact_id, upload_date: last_action_time ,file_id: refID , upload_type: "bp",status: "active"}) ; //bp = bypass
				//console.log("wello 5==>");
			}
			flag = 1;
		}
				//console.log("wello 6==>");
		if (typeof mint_bin_files !== 'undefined' && mint_bin_files.length > 0) {
			for(let file of mint_bin_files){
				let fileName = file.uid;
				//console.log("wello 6 1==>");
				//let refID_obj = returnedFiles.find((o) => o.inserted_file_name == fileName);
				let refID_obj = {}
				for(let o of returnedFiles){
					if(o.uid !== fileName)continue;
					refID_obj=o;
				}
				let refID = refID_obj['ref_id'];
				//console.log("wello 6 2==>");
				delete file.bin_file;
				//console.log("wello 6 3==>");
				if(typeof refID === 'undefined')continue;
				//console.log("wello 6 4==>");
				file.ref_id = refID;
				minutesdocs.push({uid: file.uid, file_name: file.name, ref_id: refID, user_id: contact_id, upload_date: last_action_time ,file_id: refID , upload_type: "bp",status: "active"});
			}
			flag = 1;
		}
				//console.log("wello 7==>");
		if (typeof misc_bin_files !== 'undefined' && misc_bin_files.length > 0) {
			for(let file of misc_bin_files){
				let fileName = file.uid;
				//let refID_obj = returnedFiles.find((o) => o.inserted_file_name == fileName);
				let refID_obj = {}
				for(let o of returnedFiles){
					if(o.uid !== fileName)continue;
					refID_obj=o;
				}
				let refID = refID_obj['ref_id'];
				delete file.bin_file;
				if(typeof refID === 'undefined')continue;
				file.ref_id = refID;
				miscattachments.push({uid: file.uid, file_name: file.name, ref_id: refID, user_id: contact_id, upload_date: last_action_time ,file_id: refID , upload_type: "bp",status: "active"});
			}
			flag = 1;
		}
		//console.log("wello 8==>",flag);
		if(flag === 1){
			this.setState({ agendadocs , minutesdocs , miscattachments});
			setTimeout(() => {
				this.submitFuncMain()
			},30);
		}
    }
    render() {

        if(!this.state.ready) {
			return <div></div>
        }
        //console.log("fiteredemployeelist", this.state.fiteredemployeelist)
		let committeeUtils = new CommitteeUtils();
		let task_ref_id = this.state.task_ref_id;
		let employee_rsvp_assoc = committeeUtils.get_rsvp_response_assoc_for_meeting_ref(task_ref_id,this.state.committeemembers);
		let module_configs = Store.getStoreData('module_configs');
		let rsvp_count_assoc = {
			'NA': 0,
			'Yes': 0,
			'No': 0,
			'Maybe': 0
		};
		let rsvp_d_str = {
			'NA': {'m': 'members', 'h': 'have'},
			'Yes': {'m': 'members', 'h': 'have'},
			'No': {'m': 'members', 'h': 'have'},
			'Maybe': {'m': 'members', 'h': 'are'}
		};
		for(let e in employee_rsvp_assoc){
			if(employee_rsvp_assoc[e] === 'NA')rsvp_count_assoc['NA']++;
			if(employee_rsvp_assoc[e] === 'Yes')rsvp_count_assoc['Yes']++;
			if(employee_rsvp_assoc[e] === 'No')rsvp_count_assoc['No']++;
			if(employee_rsvp_assoc[e] === 'Maybe')rsvp_count_assoc['Maybe']++;
		}
		for(let r in rsvp_count_assoc){
			if(rsvp_count_assoc[r] === 1){rsvp_d_str[r]['h'] = 'has';rsvp_d_str[r]['m'] = 'member';}
			if(rsvp_count_assoc[r] > 1)rsvp_d_str[r]['m'] = 'members';
			if(r === 'Maybe' && rsvp_count_assoc[r] === 1){rsvp_d_str[r]['h'] = 'is';rsvp_d_str[r]['m'] = 'member';}
		}
		let role = Store.getStoreData('role');
		let do_remove = false;
		if(role==='admin_manager' || role==='governance_manager' )do_remove=true;
		console.log("scheduled meeting state==>",this.state,role,do_remove);
        return (
            <div>
                <MRModalContainer style={{ zIndex: 1000 }}>

                    <MRModalHeader>
                        <MRModalHeaderText><GetDynamicTitle /></MRModalHeaderText>
                        <MRModalHeaderCloseBtn onClick={this.openConfirmationBoxModal}><FaTimes /></MRModalHeaderCloseBtn>
                        <div style={{ clear: "both" }}></div>
                        <MRModalSubHeaderText></MRModalSubHeaderText>
                        <div style={{ clear: "both" }}></div>
                    </MRModalHeader>
                    <MRModalBody>
                        <div style={{ padding: "15px", height: "650px", overflowY: "scroll", backgroundColor: "#F1F6F7" }}>
                            {
                                (() => {
                                    if (this.state.is_loader) {
                                        return <div><CSLLoaderNonPromise /></div>
                                    }

                                })()
                            }
                            {
                                (() => {
                                    if (this.state.viewconfirmation) {
                                        return <ConfirmationBox closeDialog={this.closeDialog} closeParentModal={this.closeParentModal} />
                                    }

                                })()
                            }
                            {
                                (() => {
                                    if (this.state.modal_view === 'open_followup_task_modal') {
                                        return <div><InactiveOverlay /><AddActions actionModalOpen={this.actionModalOpen} refreshAddActionValue={this.refreshAddActionValue} curLane={this.state.cur_lane} action_extra_json={this.state.action_extra_json} tasks_list={this.state.tasks_list} prop_task_id={this.props.prop_task_id} committeeId={this.state.committeeid} /></div>;
                                    }

                                })()
                            }
                            <ViewVersionsTop />

                            <Row>
								<Col sm={6}>
									<p style={{ marginBottom: "7px" }}><b>Committee</b></p>
									<select className="react-form-control" value={this.state.committeeid} onChange={this.committeeDropDownInputChange} name="committeeid">
										<option value="0">Select Committee</option>
										{
											this.state.committeelist.map((c, i) => {
												return (
													<option key={c.PK_CommitteeID} value={c.PK_CommitteeID}>{c.committeename}</option>
												)
											})
										}
									</select>
                                </Col>
								<Col sm={6}>
									<p style={{ marginBottom: "7px" }}><b>Meeting Status</b></p>
									<select className="react-form-control" onChange={this.handleInputChange} name="meetingschedule" value={this.state.meetingschedule}>
										{/*<option value="0">Select Status</option>*/}
										{
											dropdown.map((item) => {
												return (<option key={item.id} value={item.value} >{item.display}</option>);
											})
										}
									</select>
                                </Col>
                            </Row>

                            <hr style={{ border: "1px solid #dbdbdb" }}></hr>

                            {/* <SetMeetingDate /> */}
                            <p style={{ marginBottom: "7px" }}><b>Meeting Date</b></p>
                            <CSLDateCover style={{ display: "inline-block", width: "28.5%" }}>

                                <DatePicker name="startDate"
                                    onChange={this.handleDateChange}
                                    clearIcon={null}
                                    calendarIcon={null}
                                    locale={"en-GB"}
                                    value={this.state.startDate}
                                    style={{ border: "none", fontWeight: "bold" }}
                                />
                            </CSLDateCover>
                            <div style={{ clear: "both", paddingTop: "10px" }}></div>
                            <p style={{ marginBottom: "7px" }}><b>Meeting Time</b></p>
                            <Row>
                                <Col sm={2}>
                                    {this.state.editMode ? <TimePicker placeholder="Start Time" name="starttime" showSecond={false} value={this.state.starttime} onChange={this.handleStartTimeChange} format={'h:mm a'} use12Hours inputReadOnly style={{ fontFamily: "Montserrat,sans-serif" }} /> : <TimePicker placeholder="Start Time" name="starttime" showSecond={false} value={this.state.starttime} onChange={this.handleStartTimeChange} format={'h:mm a'} use12Hours inputReadOnly style={{ fontFamily: "Montserrat,sans-serif" }} />}
                                </Col>
                                <Col sm={2}>
                                    <ReactFormGroup>
                                        {this.state.editMode ? <TimePicker placeholder="End Time" name="endtime" showSecond={false} value={this.state.endtime} onChange={this.handleEndTimeChange} format={'h:mm a'} use12Hours inputReadOnly disabledHours={this.getDisabledHours} disabledMinutes={this.getDisabledMinutes} /> : <TimePicker placeholder="End Time" name="endtime" showSecond={false} value={this.state.endtime}  onChange={this.handleEndTimeChange} format={'h:mm a'} use12Hours inputReadOnly disabledHours={this.getDisabledHours} disabledMinutes={this.getDisabledMinutes} />}

                                    </ReactFormGroup>
                                </Col>
                            </Row>

                            <p style={{ marginBottom: "7px" }}><b>Meeting Location</b></p>

                            <MRModalInput style={{ width: "99%" }} name="meetinglocation" onChange={this.handleInputChange} value={this.state.meetinglocation} placeholder="Meeting Location"></MRModalInput>

                            <hr style={{ border: "1px solid #dbdbdb" }}></hr>
                            <p style={{ marginBottom: "7px" }}><b>Meeting Agenda</b></p>
                            <ReactFormGroup>
                                <div style={{ width: "100%" }}><HtmlEditor content={this.state.meetingagenda} field_name={'meetingagenda'} /></div>
                            </ReactFormGroup>

                            <p style={{ marginBottom: "7px" }}><b>Upload Agenda Document</b></p>
                            <div style={{ width: "50%" }}>
                                <div style={{ padding: "10px" }}>
                                    <Style.ModalNameInputDiv >
                                        {
                                            (() => {
                                                //return <Dropzone onFilesAdded={this.agendaFilesLoaded} initFiles={this.state.agenda_curBinFiles} height={"200px"} onRemoveFile={this.filesRemoved} showFileButton={true} handleDownload={this.downloadAttachment} removable={true} />
												return <Dropzone1 onFilesAdded={this.agendaFilesLoaded} initFiles={this.state.agenda_curBinFiles} addnew={true} buttontxt="Add Attachment" onUploadComplete={this.onUploadComplete} ref="dropZone" handleDownload={this.downloadAttachment}  onFilesRemoved={this.agendaFilesRemoved} do_remove={do_remove}/>
                                            })()
                                        }
                                    </Style.ModalNameInputDiv>
                                </div>
                            </div>


                            <p style={{ marginBottom: "10px" }}><b>Meeting Attendees</b></p>
                            <div>
                                <AddCommitteeMembers showAddIcon={false} headerText={'Meeting Attendees'} />
                                <OverviewInfoBody>
                                    <CSLTable add={false} showPagination={false} processData={this.processMeetingAttendeesData} refreshCallback={this.refreshCallback}  tableRows={1000} />
									{
										(() => {
											if(this.state.meetingid.toString() !== '0' && module_configs.general.track_member_responses_to_meeting_invitees){
												return(
													<p style={{ marginTop: "7px", color: "rgb(12,110,228)"}}><b>{rsvp_count_assoc['Yes']} {rsvp_d_str['Yes']['m']} {rsvp_d_str['Yes']['h']} accepted, {rsvp_count_assoc['Maybe']} {rsvp_d_str['Maybe']['m']} {rsvp_d_str['Maybe']['h']} unsure, {rsvp_count_assoc['No']} {rsvp_d_str['No']['m']} {rsvp_d_str['No']['h']} declined, no response has been received from {rsvp_count_assoc['NA']} {rsvp_d_str['NA']['m']}</b></p>
												);
											}
										})()
									}

                                </OverviewInfoBody>
                            </div>

                            {this.state.editMode ? <MeetingGuestTable dataFromParent={this.state.fiteredemployeelist} parentCallback={this.callbackFromGuestTable} deleteCallback={this.deleteCallbackFromGuestTable} guestMembers={this.state.guestmembers} /> : <MeetingGuestTable dataFromParent={this.state.fiteredemployeelist} parentCallback={this.callbackFromGuestTable} guestMembers={this.state.guestmembers} deleteCallback={this.deleteCallbackFromGuestTable} />}

                            <hr style={{ border: "1px solid #dbdbdb" }}></hr>
                            <p style={{ marginBottom: "17px" }}><b>Additional Guests</b></p>

                            <div>
                                <AddCommitteeMembers showAddIcon={false} headerText={'Additional Guests'} />
                                <OverviewInfoBody>
                                    <CSLTable add={false} showPagination={false} processData={this.processAdditionalGuestData} refreshCallback={this.refreshCallback}  tableRows={1000} />
                                </OverviewInfoBody>
                            </div>
                            <hr style={{ border: "1px solid #dbdbdb" }}></hr>
                            <p style={{ marginBottom: "7px" }}><b>Meeting Minutes</b></p>
                            <ReactFormGroup>
                                <div style={{ width: "100%" }}><HtmlEditor content={this.state.minutes} field_name={'minutes'} /></div>
                            </ReactFormGroup>


                            <p style={{ marginBottom: "7px" }}><b>Upload Minutes Documents</b></p>
                            <div style={{ width: "50%" }}>
                                <div>
                                    <Style.ModalNameInputDiv >
                                        {
                                            (() => {
                                                //return <Dropzone onFilesAdded={this.minutesFilesLoaded} initFiles={this.state.minutes_curBinFiles} height={"200px"} onRemoveFile={this.filesRemoved} showFileButton={true} handleDownload={this.downloadAttachment} removable={true} />;
												return <Dropzone1 onFilesAdded={this.minutesFilesLoaded} initFiles={this.state.minutes_curBinFiles} addnew={true} buttontxt="Add Attachment" onUploadComplete={this.onUploadComplete} ref="dropZone" handleDownload={this.downloadAttachment} onFilesRemoved={this.minutesFilesRemoved} do_remove={do_remove}/>
                                            })()
                                        }
                                    </Style.ModalNameInputDiv>
                                </div>
                            </div>


                            <br />
                            <br />

                            <p style={{ marginBottom: "7px" }}><b>Upload Misc. Attachments</b></p>
                            <div style={{ width: "50%" }}>
                                <div>
                                    <Style.ModalNameInputDiv >
                                        {
                                            (() => {
                                                //return <Dropzone onFilesAdded={this.miscFilesLoaded} initFiles={this.state.misc_curBinFiles} height={"200px"} onRemoveFile={this.filesRemoved} showFileButton={true} handleDownload={this.downloadAttachment} removable={true} />
												return <Dropzone1 onFilesAdded={this.miscFilesLoaded} initFiles={this.state.misc_curBinFiles} addnew={true} buttontxt="Add Attachment" onUploadComplete={this.onUploadComplete} ref="dropZone" handleDownload={this.downloadAttachment} onFilesRemoved={this.miscFilesRemoved} do_remove={do_remove}/>
                                            })()
                                        }
                                    </Style.ModalNameInputDiv>
                                </div>
                            </div>

                            <hr style={{ border: "1px solid #dbdbdb", width: "253px" }}></hr>
                            <ReactFormGroup style={{ marginBottom: "20px" }}>

                            </ReactFormGroup>
							{
								(() => {
									if(this.state.assign_to_multiple_users_loader) {
										return (
											<AlertBoxContainer id="CSLAlertBox">
												<AlertBoxBody>Tasks are being assigned to the selected user(s). This may take a few seconds to update.</AlertBoxBody>
												<span style={{marginLeft: "50%"}}><ClipLoader  size={25} color={"#04ada8"} loading={true} /></span>
												<AlertBoxFooter>
													{/* <OkButton onClick={this.processOk}>OK</OkButton> */}
												</AlertBoxFooter>
											</AlertBoxContainer>
										);
									}
								})()
							}
							{
								(() => {
									if(this.state.committeeid.toString() !== '0'){
										return(
											<div>
												<OverviewInfoHeader >
													<div style={{ float: "right", color: "#FFFFFF", fontSize: "17px", cursor: "pointer" }} onClick={this.showAddAction(-1)} title="add a followup task"><FaPlusCircle /></div>
													<div style={{ clear: "both" }}></div>
												</OverviewInfoHeader>
												<OverviewInfoBody>
													<CSLTable add={false} processData={this.processMyActionData} headerText={'Follow-up Tasks List'} tableRows="10" refreshCallback={this.refreshCallback} />
												</OverviewInfoBody>
											</div>
										);
									}

								})()
							}
                            <ViewVersions />


                        </div>
                    </MRModalBody>
                    <MRModalFooter>
                        <div style={{ marginTop: "20px" }}>
                            <div style={{ float: "left", marginLeft: "5px" }}>
                                <b>For SMCR Employees Only:</b> <br />
                                <AddActionButton id="button_addroleschedulemeeting" onClick={this.openAddNoteModal} >Add Role Note</AddActionButton>
                            </div>

                            <div style={{ float: "right" }}>
                                <FormSubmitButton id="button_submitschedulemeetingmodal" onClick={this.handleSubmit} style={{ float: "right" }}>Submit</FormSubmitButton>
                                <FormCancelButton id="button_cancelschedulemeetingmodal" onClick={this.openConfirmationBoxModal}>Cancel</FormCancelButton>
                            </div>
                        </div>
                    </MRModalFooter>
                </MRModalContainer>
                <AlertBox alertParam={this.state.alert_param} />
            </div>
        );
    }
}
export default ScheduleMeeting;
