import React from 'react';
import ReactDOM from 'react-dom';
//import './style.css';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import { FaEye } from 'react-icons/fa';
import { FaUsers } from 'react-icons/fa';
import { FaRegFile } from 'react-icons/fa';
import { FaMinus } from 'react-icons/fa';
import { FaPlus } from 'react-icons/fa';
import { FaTasks } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';
import { MdDelete } from 'react-icons/md';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Store from '../../Common/Store';
import APICall from '../../Common/APICall.js';
import GovmapReport from '../GovernanceMap/GovmapReport.js';
import AlertBox from '../Common/AlertBox.js';
import CommitteeUtils from '../Common/CommitteeUtils';

// const boxBorderWidth = parseInt(process.env.REACT_APP_BOXBORDERWIDTH);
const lineBorderWidth = 5;
// const boxTrueHeight =parseInt(process.env.REACT_APP_BOXHEIGHT);
const boxTrueWidth = 267;
// const boxHeight = boxTrueHeight - (2 * boxBorderWidth);
// const boxWidth = boxTrueWidth - (2 * boxBorderWidth);
const horzGap = boxTrueWidth * 2 / 3;
const vertGap = 40;
const initialHorzGap = 20;

const CommiteeListSections = styled.div`
        padding: 15px 0px;
        /*background: #f2f2f2;*/
        background: #ffffff;
		height:auto;
`;

const CommiteeListName = styled.div`
        color: #115d9c;
        font-weight: 600;
`;
const CommiteeListName2ndLevel = styled.div`
        color: #115d9c;
        font-weight: 600;
		padding-left: 130px;
`;
const CommiteeListIcons = styled.span`
        top: -2px;
        position: relative;
        font-size: 12px;
        padding-left: 3px;
`;
const ViewDiv = styled.div`
    box-sizing: border-box;
    cursor:pointer;
    position:relative;
    margin-right:3px;
`;
const FaIconMinus = {
    border: "1px solid #bbb7b7",
    padding: "3px",
    marginRight: "10px",
    top: "2px",
    position: "relative",
    height: "18px",
}

const BlankSpace = {
    padding: "3px",
}

const MainDiv = styled.div`
  position:absolute;
  left:250px;
  top:358px;
`;
const MainDivState0 = styled.div`
  position:relative;
  left:0px;
  top:0px;
  width:1179px;
  height:740px;
  background-image:none;
`;
const MainDivState0Content = styled.div`
  border-width:0px;
  position:absolute;
  left:0px;
  top:0px;
  width:1px;
  height:1px;
`;


class CommiteeList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            view_committee_icon_permission : {},
            committee_meeting_icon_permission : {},
            committee_notes_icon_permission : {},
            committee_edit_icon_permission : {},

            componentDidUpdateMode: false,
            showInactiveCommittee: false,
            meetingCountJson: {},
            passCommitteeTabRefreshInstruction: {},
            companyName: "",
            committeelist: [],
            committeelisttree: [],
            alert_param: null,
            ready: false,

        }
    }





    componentDidUpdate(prevProps, prevState) {
        console.log("in CommitteeList componentDidUpdate prevProps==>",prevProps);
        console.log("in CommitteeList componentDidUpdate props==>",this.props,this.state.passCommitteeTabRefreshInstruction);
		//if(prevProps !== this.props){
			if(typeof this.props.showInactiveCommittee !== 'undefined' || this.props.passCommitteeTabRefreshInstruction !== 'undefined'){
				console.log("in CommitteeList componentDidUpdate props difference==>",Store.getStoreData('committee_clicked'));
				 if(this.state.showInactiveCommittee !== this.props.showInactiveCommittee){
					 this.setState({
						 showInactiveCommittee : this.props.showInactiveCommittee
					 },()=>{
						 let include_inactive_committee = (this.state.showInactiveCommittee) ? 'yes' : 'no';
						 let postData = { command: "getCommitteeListData", data:{ employee_id: 0 , include_inactive_committee : include_inactive_committee} };
						 let api = new APICall();
						 let that = this;
						 api.command(postData, function callback(result){that.processIndexData(result,'')});
					 });
				 }
				 if(this.state.passCommitteeTabRefreshInstruction !== this.props.passCommitteeTabRefreshInstruction){
					 console.log("in CommitteeList componentDidUpdate props difference==>",Store.getStoreData('committee_clicked'));
					 let include_inactive_committee = (this.state.showInactiveCommittee) ? 'yes' : 'no';
					 let postData = { command: "getCommitteeListData", data:{ employee_id: 0 , include_inactive_committee : include_inactive_committee} };
					 let api = new APICall();
					 let that = this;
					 api.command(postData, function callback(result){that.processIndexData(result,'')});

				 }
				 if(Store.getStoreData('committee_deleted') !== null && Store.getStoreData('committee_deleted') === true){
					 console.log("in CommitteeList componentDidUpdate props difference committee_deleted==>");
					 let include_inactive_committee = (this.state.showInactiveCommittee) ? 'yes' : 'no';
					 let postData = { command: "getCommitteeListData", data:{ employee_id: 0 , include_inactive_committee : include_inactive_committee} };
					 let api = new APICall();
					 let that = this;
					 api.command(postData, function callback(result){that.processIndexData(result,'')});

				 }
				 console.log("committee_clicked",Store.getStoreData('committee_clicked'));
			}
		//}
	}


    componentDidMount() {
		//console.log("I am in CommitteeList 1");
		//console.log("committee_clicked",Store.getStoreData('committee_clicked'));

		/*
		let meetingCountJson = {};
        const url = Store.getStoreData('api_url');

		 let include_inactive_committee = (this.state.showInactiveCommittee) ? 'yes' : 'no';
		 let postData = { command: "getCommitteeWithCount", data:{ employee_id: 0 , include_inactive_committee : include_inactive_committee} };
		 let api = new APICall();
		 api.command(postData, this.processIndexData);
		 */
		 /*let committee_with_count = Store.getStoreData('committee_with_count');
		 let governance = Store.getStoreData('governance');
		 let ret = {committee_with_count : committee_with_count , governance : governance , get_refresh : true};
		 if(committee_with_count.committees.length > 0){
			this.processIndexData(ret);
		 }*/
		 this.initializeState('loading_time');
    }


	initializeState = (flag) => {
		 let committee_with_count = Store.getStoreData('committee_with_count');
		 let governance = Store.getStoreData('governance');
		 let ret = {committee_with_count : committee_with_count , governance : governance , get_refresh : true};
		 if(committee_with_count.committees.length > 0){
			this.processIndexData(ret,flag);
		 }
	}

	processIndexData = (result,flag) => {
        console.log("in CommitteeList processIndexData result==>",result);
        Store.updateStore('committee_deleted', null);
		//Store.updateStore('committee_clicked',null);
		let committeeUtils = new CommitteeUtils();

		let companies_assoc = Store.getStoreData('companies_assoc');
		let assignments = Store.getStoreData('assignments');
		let raw_meetings_list = Store.getStoreData('raw_meetings_list');
		let followup_tasks_list = Store.getStoreData('followup_tasks_list');
		let role = Store.getStoreData('role');
		let meeting_records = Store.getStoreData('meeting_records');
		console.log("meeting_records,",meeting_records);
		let filtered_committees = []
		console.log('companies_assoc', companies_assoc);
		if(flag !== 'loading_time'){
			for(let gc of result.governance.committees) {
				if(role !== 'admin_manager'){
					let association = 'association' in gc.json_data ? gc.json_data.association : 'parent'
					switch(association) {
						case 'gc': if(gc.json_data.gc_id in companies_assoc)filtered_committees.push(gc); break;
						case 'grouping' : if(gc.json_data.grouping_id in assignments.tags && gc.json_data.grouping_value_id in assignments.tags[gc.json_data.grouping_id])filtered_committees.push(gc); break;
						default: filtered_committees.push(gc);
					}
				}else{
					filtered_committees.push(gc);
				}

			}
			for(let g of filtered_committees) {
				g.parent_id = g.json_data.parent_id === '' ? '0' : g.json_data.parent_id;
				g.id = g.id;
				g.show = g.is_active === true ? true : false;
				g.gc_id = ('gc_id' in g.json_data) ? g.json_data.gc_id : '0';
				g.combined_gcs = [];
			}
		}



		Store.updateStore('members', result.governance.employee_hash);

		let committee_input_json = {committees: result.committee_with_count.committees , meetings : raw_meetings_list , tasks_list: meeting_records.tasks_list};
		let committeeWithCount_json = committeeUtils.convertToCommitteesWithCount(committee_input_json);
		Store.updateStore('committee_with_count', committeeWithCount_json);
		Store.updateStore('meeting_records', committee_input_json);

        let meetingCountJson = {};
		let committeeCount = 0;
		let is_member_of_committee_hash = {};
		let parent_has_group_companies = Store.getStoreData('parent_has_group_companies');
		let user_assignments = Store.getStoreData('user_assignments');
		let loggedin_user_info = Store.getStoreData('loggedin_user_info');
		let task_count_hash = {};
		let incomplete_tasks_for_committee_assoc = {};
		let committee_name_assoc = {};
		let meeting_task_ref_assoc = {};
		let committee_meeting_date_assoc = committeeWithCount_json.committee_meeting_date_hash;

		for(let m of raw_meetings_list){
			if(m.IsDeleted.toString() === '0')meeting_task_ref_assoc[m.task_ref_id] = m;
		}
//console.log("meeting_task_ref_assoc,",meeting_task_ref_assoc);
//console.log("meeting_task_ref_assoc,",committeeWithCount_json.tasks_list);

		committeeWithCount_json.committees.map(committee => {
			//console.log("PK_CommitteeID==>"+committee.PK_CommitteeID+",IsActive==>"+committee.IsActive);
			if(committee.is_member_of_this_committee){
				committeeCount++;
				is_member_of_committee_hash[committee.PK_CommitteeID.toString()] = true;
			}else{
				is_member_of_committee_hash[committee.PK_CommitteeID.toString()] = false;
			}
			task_count_hash[committee.PK_CommitteeID] = 0;
			committee_name_assoc[committee.PK_CommitteeID] = JSON.parse(committee.Json_Data).committeename
		});

        if(flag !== 'loading_time'){
			if(parent_has_group_companies && role === 'governance_manager'){
				for(let p of filtered_committees){
					if(!is_member_of_committee_hash[p.id.toString()]){
						if(user_assignments[loggedin_user_info.ID]['companies'][p.gc_id] !== 1)is_member_of_committee_hash[p.id.toString()] = false;
						if(p.gc_id.toString() === '0')is_member_of_committee_hash[p.id.toString()] = true;
					}
				}
			}

			for(let p of filtered_committees){
				p.is_member_of_committee = is_member_of_committee_hash[p.id.toString()];
			}
			Store.updateStore('filtered_committees', filtered_committees);
			Store.updateStore('is_member_of_committee_hash', is_member_of_committee_hash);
        }

		let meeting_count_hash = committeeWithCount_json.meeting_count_hash;
        console.log("filtered_committees==>",is_member_of_committee_hash , filtered_committees);

		for(let i=0;i<committeeWithCount_json.tasks_list.length;i++){
			let task_json = JSON.parse(committeeWithCount_json.tasks_list[i].task_json);
			//console.log("task committees=>",task_json.object_data.headerinfo.action_json.committee_id);
			//if(committeeWithCount_json.tasks_list[i].cur_lane !== 'COMP_COMMITTEES_COMPLETE'){
				if(committeeWithCount_json.tasks_list[i].ref_id in meeting_task_ref_assoc){
					task_count_hash[task_json.object_data.headerinfo.action_json.committee_id] = task_count_hash[task_json.object_data.headerinfo.action_json.committee_id] + 1;
				}
				if(typeof incomplete_tasks_for_committee_assoc[task_json.object_data.headerinfo.action_json.committee_id] === 'undefined'){
					incomplete_tasks_for_committee_assoc[task_json.object_data.headerinfo.action_json.committee_id] = [committeeWithCount_json.tasks_list[i]]
				}else{
					incomplete_tasks_for_committee_assoc[task_json.object_data.headerinfo.action_json.committee_id].push(committeeWithCount_json.tasks_list[i]);
				}
			//}
		}

//console.log("filtered_committees=>",filtered_committees);
        if(flag !== 'loading_time'){
			let gov_committees = committeeUtils.convertListToTree(filtered_committees);
			let u_children = committeeUtils.setCommitteeCombinedGcsTopLevel(gov_committees.children);
			gov_committees.children = u_children;
			Store.updateStore('gov_committees', gov_committees);
			console.log("gov_committees==>",gov_committees);
        }

		Store.updateStore('task_count_hash', task_count_hash);
		Store.updateStore('incomplete_tasks_for_committee_assoc', incomplete_tasks_for_committee_assoc);
		Store.updateStore('committee_name_assoc', committee_name_assoc);
		Store.updateStore('committee_meeting_date_assoc', committee_meeting_date_assoc);
		Store.updateStore('meeting_count_hash', meeting_count_hash);



        if(typeof result.get_refresh !== 'undefined'){
			this.setState({
				committeelist: result.committee_with_count.committees,
				meetingCountJson: meetingCountJson,

				companyName: result.committee_with_count.committees.length > 0 ? result.committee_with_count.committees[0].companyName : '' ,
				passCommitteeTabRefreshInstruction : {},
				ready: true,
			});
        }else{
			this.setState({
				committeelist: result.committee_with_count.committees,
				meetingCountJson: meetingCountJson,

				companyName: result.committee_with_count.committees.length > 0 ? result.committee_with_count.committees[0].companyName : '' ,
				passCommitteeTabRefreshInstruction : this.props.passCommitteeTabRefreshInstruction,
				ready: true,
			});
        }

	}
	convertListToTree(list) {
		var companyIDs = {};
		list.forEach(function(x) {
			companyIDs[x['id']] = 1;
		});
	    var treeList = [];
	    var lookup = {};
	    list.forEach(function(obj) {
	        if(!(obj['parent_id'] in companyIDs)) {
	        	obj['parent_id'] = 0;
	        }
	        lookup[obj['id']] = obj;
	        obj['children'] = [];
	    });
	    list.forEach(function(obj) {
	        if (obj['parent_id'] !== 0) {
	        	lookup[obj['parent_id']]['children'].push(obj);
	        } else {
	            treeList.push(obj);
	        }
	    });

		var retTree = {"children" : treeList, "id" : 0 };
	    return retTree;
	}

    passCommitteIdForAdvancedSearch = (childData) => {
        this.props.getCommitteIdForAdvancedSearch(childData);
    }
	AddModal = (modal_name) => {
        this.props.AddModal(modal_name);
	}
	refreshCommitteeTree = () => {
        this.props.refreshCommitteeTree();
	}
    render() {
		if(!this.state.ready){
			return <div />
		}
        return (
            <div style={{ marginTop: "20px" ,marginLeft : "12px"}}>

                    {
                        (() => {
                               return (<GovmapReport showRoles={false} getCommitteIdForAdvancedSearch={this.passCommitteIdForAdvancedSearch} showInactiveCommittee={this.state.showInactiveCommittee} committeeFilterByCompany = {this.props.committeeFilterByCompany} filtered_gc_list_assoc={this.props.filtered_gc_list_assoc} company_display_option={this.props.company_display_option} AddModal={this.AddModal} refreshCommitteeTree={this.refreshCommitteeTree}/>)
                        })()
                    }
            <AlertBox alertParam = {this.state.alert_param} />
            </div>
        )

    }
}

export default CommiteeList;
