import React from 'react';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import Store from '../../Common/Store.js';
import { saveAs } from 'file-saver';

const UploadMiscAttachmentsButton = styled.button`
    background-color: #04ADA8;
    color: #ffffff; 
    border-radius: 5px;
    border: 1px solid #04ADA8;
    padding: 3px 18px;
    background-image: linear-gradient(to bottom, #04ADA8 50%, #04ADA8);
`;
const UploadMiscAttachmentsFile = styled.div`
    padding: 10px;
    background: #f9f9f9;
    margin: 13px 0;
    color: black;
`;
class UploadMiscAttachments extends React.Component{
    constructor(props){
        super(props);
		this.inputref = React.createRef();
		//this.fileSelector = this.buildFileSelector.bind(this);
        this.state = {
            editMode: false,
            selectedFile: [],
            fileRows: []
        }
    }
    handleFileSelect = (e) => {
        e.preventDefault();
        //this.fileSelector.click();
		this.inputref.current.click();
    }
    handleselectedFile = event => {
		event.stopPropagation();
		event.preventDefault();
		//console.log("files ==>"+event.target.files[0]);
		//console.log("file name==>"+event.target.files[0].name);
		let that = this;
		//console.log("Here I am");
		if (event.target.files.length > 0) {
			//console.log("in files");
			//for(let i=0;i<event.target.files.length;i++){
				let fileName = event.target.files[0].name;
				// Select the very first file from list
				var fileToLoad = event.target.files[0];

				// FileReader function for read the file.
				var fileReader = new FileReader();
				var base64;
				// Onload of file read the file content
				fileReader.onload = function(fileLoadedEvent) {
					//base64 = fileLoadedEvent.target.result;
					let fElement = {name: fileName, bin_file: window.btoa(fileLoadedEvent.target.result).replace(/\n/g,'')};
					// Print data in console
					//console.log(base64);
					//if(base64){
						const url = Store.getStoreData('api_url');
						let postData = { command: "uploadFiles", data: {  file_name : fileName , fElement : fElement } };

						let httpVerb = { method: "POST", mode: "cors", headers: { "Content-Type": "application/x-www-form-urlencoded" }, body: "jsondata=" + JSON.stringify(postData) };
						fetch(url, httpVerb)
							.then((res) => res.json())
							.then(result => {
							   console.log("LL=>"+JSON.stringify(result));
							   let fileArray = that.state.selectedFile;
								that.setState({
									selectedFile: [],
									fileRows: [],
								});
							   fileArray.push({file_name : fileName , file_id : result});
								that.setState({
									selectedFile: fileArray,
									//loaded: 0,
								},()=>{that.createFileRows();});
							});
					//}

				};
				// Convert data to base64
				//fileReader.readAsDataURL(fileToLoad);
				fileReader.readAsBinaryString(fileToLoad);
				//fileReader.readAsArrayBuffer(fileToLoad);
			//}

		}
		//const formData = new FormData();
        //formData.append({ [event.target.name]: event.target.value })
        //formData.append('file', event.target.files[0]);




		/*
		let fileArray = this.state.selectedFile;
		this.setState({
			selectedFile: [],
			fileRows: [],
		});

		fileArray.push(event.target.files[0].name);
		console.log(JSON.stringify(fileArray));

        this.setState({
            selectedFile: fileArray,
            //loaded: 0,
        },()=>{this.createFileRows();});
		*/
    }
	componentDidUpdate() {
		//console.log("dataFromParent=>"+JSON.stringify(this.props.dataFromParent));
		if(Store.getStoreData('EditMode')) {
			if(!this.state.editMode) {
				let fileArray = this.props.dataFromParent;
				this.setState({
					editMode: true,
					selectedFile: fileArray

				},()=>{this.createFileRows();});
			}
	    }
	}
	deleteAttachment = (arrayindex) => {
		//console.log("arrayindex==>"+arrayindex);
		let selectedFile = this.state.selectedFile;
		let fileJsonObj = selectedFile[arrayindex];
		let file_id = fileJsonObj.file_id;
		const url = Store.getStoreData('api_url');
		let postData = { command: "deleteFile", data: {  file_id : file_id  } };

		let httpVerb = { method: "POST", mode: "cors", headers: { "Content-Type": "application/x-www-form-urlencoded" }, body: "jsondata=" + JSON.stringify(postData) };
		fetch(url, httpVerb)
			.then((res) => res.json())
			.then(result => {
			   //console.log("file_name=>"+file_name);
			   //console.log("MM=>"+JSON.stringify(result.base64filedata.bin_file));
			   //that.downloadFileToBrowser(result.base64filedata.bin_file,result.base64filedata.name);
			   if(result.error_code==0){
					selectedFile.splice(arrayindex,1);
					this.setState({
						selectedFile: selectedFile

					},() => {

					   this.createFileRows();
					});
			   }

			});

	}

	//downloadBase64File = (contentBase64, fileName) => {
	downloadFileToBrowser = (bin_file, fileName) => {
        let dnld = bin_file.replace(/ /g, '+');
        // console.log("binary file before download:", dnld);
        var binaryString = window.atob(dnld);
        // console.log('binaryString', binaryString);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        var blob = new Blob([bytes]);
        saveAs(blob, fileName);
	}
	downloadAttachment = (file_id,file_name) => {
		//console.log("arrayindex==>"+arrayindex);
		console.log("file_id==>"+file_id);
		//console.log("file_name==>"+file_name);
		//this.state.selectedFile[i].file_id,this.state.selectedFile[i].file_name
		let that = this;

		const url = Store.getStoreData('api_url');
		let postData = { command: "downloadFile", data: {  file_id : file_id  } };

		let httpVerb = { method: "POST", mode: "cors", headers: { "Content-Type": "application/x-www-form-urlencoded" }, body: "jsondata=" + JSON.stringify(postData) };
		fetch(url, httpVerb)
			.then((res) => res.json())
			.then(result => {
			   //console.log("file_name=>"+file_name);
			   //console.log("MM=>"+JSON.stringify(result.base64filedata.bin_file));
			   that.downloadFileToBrowser(result.base64filedata.bin_file,result.base64filedata.name);

			});
	}

	createFileRows = () => {
		let table = [];
		let that = this;
		//let children = [];
		for (let i = 0; i < that.state.selectedFile.length; i++){
			//console.log("employeename==>"+this.state.committeemembers[i].employeename+"<=employeename");
			//if(typeof this.state.committeemembers[i].employeename !='undefined' && this.state.committeemembers[i].employeename!=""){
				let children = [];
				children.push(<div style={{float:"left" , width: "300px"}}><a onClick={()=>that.downloadAttachment(that.state.selectedFile[i].file_id,that.state.selectedFile[i].file_name)} style={{cursor: 'pointer'}}>{that.state.selectedFile[i].file_name}</a></div>);
				children.push(<div style={{float:"right"}}><MdClose style={{color: "#979797" , cursor: 'pointer'}} onClick={()=>that.deleteAttachment(i)}/></div>);
				//children.push(<div style={{clear:"both"}}></div>);
				//children.push(<td>{this.handleAttendeeAttendedDropDown(this.state.committeemembers[i].employeeid)}</td>);

				table.push(<tr>{children}</tr>);
			//}


		}
		that.setState({
			fileRows: table,
		},()=>{
				that.props.pCallBack(that.state.selectedFile);
			  });
        //return children;
	}

    render(){
        return(
            <div>
                <UploadMiscAttachmentsButton onClick={this.handleFileSelect}>Upload Misc. Attachments</UploadMiscAttachmentsButton>
			    <input type="file" id="file" ref={this.inputref} style={{display: "none"}} onChange={this.handleselectedFile.bind(this)} encType="multipart/form-data"/>
                <UploadMiscAttachmentsFile>
			        {this.state.fileRows}
		            {/*<div style={{float:"left"}}><p>slide_deck.ppt</p></div>
                    <div style={{float:"right"}}><MdClose style={{color: "#979797"}}/></div>
                    <div style={{clear:"both"}}></div>*/}
                </UploadMiscAttachmentsFile>
            </div>
        );
    }
}

export default UploadMiscAttachments;
