import React from 'react'
import styled from 'styled-components'
import {FaPlus} from 'react-icons/fa'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import Pagination from "../../Common/Pagination";
import Store from '../../../Common/Store';
import moment from 'moment';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import "./table_styles.css";

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const HeaderText = styled.div`
    background-color: #1E3E62;
    color: #ffffff;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 15px;
    margin-top: 12px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
`;
const TableHeaderIcon = styled.div`
    float: right;
    margin-right: 20px;
    margin-top: 15px;
    color: #c0c0c0;
`;
const QaAssignBtn = styled.button`
    background-color: #002060;
    color: #ffffff;
    padding: 10px 46px;
    border: 1px solid #002060;
    border-radius: 5px;
    float: right;
    margin: 15px;
    cursor: pointer;
`

const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin: 12px;
    cursor: pointer;
    float: left;
    margin-right: 5px;
`;

const TableContainer = styled.div`
    background-color: #ffffff;
`

class CSLTable extends React.Component
{
    state = {button_set: [], ready: false}
    constructor(props) {
        super(props);
        // // console.log("props", props);
    }

    componentDidMount() {
        this.setState({button_set: this.props.buttonSet, ready: true})
    }

    filterMethod = (filter, row, column) => {
        const id = filter.pivotId || filter.id
        // // console.log('id',id);
        return row[id] !== undefined ? String(row[id]).includes(filter.value) : true
    }

    openAssignQcModal = (event) => {
        event.preventDefault();
        this.props.toggleAssignQcModal("assignqcmodal");
    }

    exportToCSV = () => (event) => {
        event.preventDefault();
		var csv_data_final_arr = [];
        let fileName = "export";
        if('export_file_name' in this.props) {
            fileName = this.props.export_file_name;
        }
        let tableData = this.props.processData();
        console.log("tableData",tableData);
		let records = tableData.data;
		let xls_records_arr = [];
		for(let r of records){
			
		}
        /*
		let headerCSV = this.props.export_columns;
        // console.log("headerCSV",headerCSV)
        let data= tableData.data;
        let columns= tableData.columns;
        let csv_data_arr = [];
        
        csv_data_arr.unshift(headerCSV);
        for(let k = 0; k < data.length; k++) {
            let cont = {};

            Object.entries(headerCSV).forEach(([key, value]) => {
                // console.log("data[k][key]",data[k][key])
                    // cont[key] = data[k][key].replace(/,/g, ' ');
                    let d = "N/A";
                    if(typeof(data[k][key]) !== 'undefined' && data[k][key] !== null){
                        d = data[k][key]
                    }
                    d = d.toString().replace(/,/g, ' ').replace(/-/g, '-').replace(/&rsquo/g, '');
                    cont[key] = d.trim();
            })            
            csv_data_arr.push(cont);
        }
        console.log("csv_data_arr",csv_data_arr) 
        var csv_data_final_arr = [];
        for(let j = 0; j<  csv_data_arr.length; j++){
          var container = [];
          container = Object.values(csv_data_arr[j])
          if(container.length > 0); 
          csv_data_final_arr.push(container)
        }
        console.log("csv_data_final_arr",csv_data_final_arr);
		*/
        /*let csvContent = "data:text/csv;charset=utf-8," + csv_data_final_arr.map(e => e.join(", ")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", fileName + ".csv");
        document.body.appendChild(link); 
        link.click();*/
		/*const format1 = "DD-MM-YYYY HH:mm:ss"
		let tm = moment().format(format1);

		const ws = XLSX.utils.json_to_sheet(csv_data_final_arr);
		const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
		const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
		const exceldata = new Blob([excelBuffer], {type: fileType});
		FileSaver.saveAs(exceldata, fileName + '-' + tm + fileExtension);
		*/
        let wb = {SheetNames:[], Sheets:{}};
        const ws1 = XLSX.utils.json_to_sheet(csv_data_final_arr); 
        wb.SheetNames.push("data");
		wb.Sheets["data"] = ws1;

        const format1 = "DD-MM-YYYY HH:mm:ss"
        let tm = moment().format(format1);

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const exceldata = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(exceldata, fileName + '-' + tm + fileExtension);
    
    }

    goToReport = () => (event) => {
        event.preventDefault();
        this.props.goToReport();
    }

    openButtonCallback = (button_id) => {
        this.state.button_set[button_id].button_callback(this.state.button_set[button_id].stack)
    }
    exportToXLS = () => (event) => {
        event.preventDefault();
        this.props.exportxls();
    }

	render() {
        // console.log('this.props', this.props);
        if(!this.state.ready)return <div></div>
        
		let tableData = this.props.processData();
		console.log('tableData', tableData);
        let row_count = this.props.tableRows === null ? 10 : this.props.tableRows;
		return (
			<TableContainer>
                {
                    (() => {
                        if ("show_header" in this.props && this.props.show_header === false) {
                            return (<div></div>);
                        } else {
                            return (<HeaderText>{this.props.headerText}</HeaderText>);
                        }
                    })()
                }                    
                {
                    (() => {
                        if (this.props.add) {
                            return (<TableHeaderIcon><FaPlus style={{cursor: "pointer"}} onClick={this.showAddAskModal} title={this.props.addTitle} /></TableHeaderIcon>);
                        }
                    })()
                }         
                                 
                    <div style={{marginTop: '5px', width:'100%'}}>
	                    <ReactTable
	                    	PaginationComponent={Pagination}
						    data={tableData.data}
						    columns={tableData.columns}
						    defaultPageSize={parseInt(row_count)}
                            filterable={true}
                            defaultFilterMethod={(filter, row, column) => {
                                const id = filter.pivotId || filter.id
                                // // console.log('id',id);
                                return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
                            }}
						/>
                        {
                            (() => {
                                if ('export' in this.props && this.props.export === true) {                            
                                    return (<ExportButton  onClick={this.exportToXLS()}>Export</ExportButton>);                                                        
                                }
                            })()
                        }
            
                            {
                                (() => {
                                    if ('report' in this.props && this.props.report === true) {                            
                                        return (<ExportButton style={{width: 250}}  onClick={this.goToReport()}>Run Report of Current View</ExportButton>);                                                        
                                    }
                                })()
                            }
                            {/*{
                                Object.keys(this.state.button_set).map((b, i) => {
                                        if(this.state.button_set[b].is_visible)return (<QaAssignBtn key={i} onClick={() => this.openButtonCallback(b)}>{this.state.button_set[b].display}</QaAssignBtn>);
                                    })
                            }*/}
   
                        <div style={{clear: "both"}}></div>
					</div>
			</TableContainer>
			);
	}
}

export default CSLTable;