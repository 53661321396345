import React from 'react';
import moment from 'moment';
import CSLTable from './CSLTable';
import AdvanceFilter from './AdvanceFilter';
// import ReportModal from './ReportModal';
// import PdfGenerator from './PdfGenerator';
import CSLReportModal from './CSLReportModal';
import PdfGenerator from './PdfGenerator';

import Store from '../../../Common/Store';
import APICall from '../../../Common/APICall.js';
import Utils from '../../../Common/Utils.js';
import styled from 'styled-components';



const RButton = styled.button`
	background-color: #1E3E62;
    color: #ffffff;
    padding: 10px 15px;
    border: 1px solid #1E3E62;
    border-radius: 5px;
    margin-top: 10px;
    font-size: 12px;
    cursor: pointer;
`;
const InactiveOverlay = styled.div`
	display: block;
	box-sizing: border-box;
	height: calc(100vh-200px);
	width: 100%;
	background-color: #ffffff;
	position: absolute;
	z-index: 1000;
	opacity: 0.8;
	top: 0px;
`;
const Section88 = styled.div`
	width: calc(100% - 10px);
	box-sizing: border-box;
	float: left;
`;
const Section12 = styled.div`
	width: 12%;
	box-sizing: border-box;
	float: left;
`;
const FilterButton = styled.button`
    box-sizing: border-box;
    background-color:#EEF2F7;
    color: #243E57;
    border: 1px solid #243E57;
    width: 160px;
    font-size: 13px;
    height: 30px;
    border-radius: 100px / 70px;
    margin-right:3px;
    margin-top:7px;
    outline:none;
    cursor: pointer;
`;


class RegisterReporting extends React.Component
{
	state = {
				show_filter: true, 
				filters: null, 
				pill_filters: null, 
				show_report: false, 
				generate: false, 
				groupby_selections: [], 
				sortby_selected: {accessor: 'none', order: 'Descending'}
			}

	data = {};

	constructor(props) {
    	super(props);
    	this.scrollDiv = React.createRef();
  	}

  	setComponentState = () => {
  		let filters = this.state.filters === null ? this.props.filters : this.state.filters
  		let data = this.props.data
      	let table_data = this.generateTableData()
  		for(let r of table_data.raw_data) {
  			for(let id in filters) {
  				if(!(r[id] in filters[id].options)) {
  					filters[id].options[r[id]] = {name: r[id]}
  				}
  			}
  		}

        let clear_filter_done = Store.getStoreData('clear_filter_done');
  		// console.log("filters in search", filters);
		if(clear_filter_done === null || clear_filter_done === true){
			console.log("filters in search", filters);
  			for(let id in filters) {
  				//filters[id].selections = {}
  			}
		}

  		this.setState({filters, data})
  	}




  	componentDidMount() {
  		this.setComponentState()
  	}

  	componentDidUpdate(prevprops) {
  		if(prevprops === this.props)return

  		this.setComponentState()
  	}

	clearAdvancedFilter = () => {
		// window.location.reload()
		// this.props.refreshCallback()
		this.setState({filters: null});
		Store.updateStore('clear_filter_done', true);
		this.props.clearFilterMsg({clear_filter_done: true});
		
	}
	applyFilter = (filters) => {
		let applied_filters = JSON.parse(JSON.stringify(filters))
        // if ((filters).length === 0) return false;
        // console.log('applyAdvancedFilterDate filters', filters)
        // // // console.log('applyAdvancedFilterDate applied_filter', applied_filters)
        this.setState({filters: applied_filters})
        Store.updateStore('applied_filters', applied_filters);
		Store.updateStore('clear_filter_done', false);
        this.props.refreshCallback({clear_filter_done: false , applied_filters: applied_filters});
		
	}

	showOnlyAssign = (flag) =>{
	  this.props.showOnlyAssign(flag);
	}

	setShowTable = (flag, table) => {
	  this.props.setShowTable(flag, table);
	}

	toggleConfigureReport = (event) => {
		let show_configure_report = false;
		show_configure_report = show_configure_report === true ? false : true;
		this.setState({show_configure_report});
	}

	hideReport = () => {
		this.setState({show_report: false});
	}

	toggleGenerate = () => {
		let generate = this.state.generate ? false : true;
		this.setState({generate});
	}

	reportCallback = () => {
		this.setState({show_report: true})
	}

	changeSort = (sortby) => {
        let data = JSON.parse(JSON.stringify(this.state.data));
        this.data.sortby = sortby;
        this.setState({data: this.data, sortby_selected: sortby.selected});
	}

	changeGroup = (groupby) => {
        let data = JSON.parse(JSON.stringify(this.data));
        this.data.groupby = groupby;
        this.setState({data: this.data, groupby_selections: groupby.selections});
	}

    pillClickHandler = (event) => {
        event.preventDefault();
        
        let pill_id = [event.target.name]
        let pill_filters = this.state.pill_filters;
        pill_filters[pill_id].is_active = (pill_filters[pill_id].is_active + 1) % 2
        this.setState({pill_filters})
        this.props.refreshCallback({clear_filter_done: false});
		Store.updateStore('clear_filter_done', false);
    }

    filterDateScore = (date) => {
    	// // // console.log('applyAdvancedFilterDate, date', date)
    	let date_parts = date.split('T')[0].split('-')
    	return parseInt(date_parts[0]) * 10000 + parseInt(date_parts[1]) * 100 + parseInt(date_parts[2])
    }
    tableDateScore = (date) => {
    	let date_parts = date.split('/')
    	return parseInt(date_parts[2]) * 10000 + parseInt(date_parts[1]) * 100 + parseInt(date_parts[0])
    }

    filterRow = (elem) => {
    	let filters = this.state.filters;
    	let inclusion = 1
    	// // console.log('applyAdvancedFilterDate filters, elem', filters, elem)
    	for(let id in filters) {
    		if(Object.keys(filters[id].selections).length === 0) {
    			inclusion *= 1
    		} else {
    			switch(filters[id].type) {
    				case 'text' : inclusion *= elem[id].toLowerCase().includes(filters[id].selections.text.toLowerCase()) ? 1 : 0; break;
    				case 'multi' : 
    				case 'single' : inclusion *= (elem[id] in filters[id].selections ? 1 : 0); break;
    				case 'date_range' : let from_date = this.tableDateScore(filters[id].selections.from_date)
    									let to_date = this.tableDateScore(filters[id].selections.to_date)
    									let table_date = this.tableDateScore(elem[id])
    									inclusion *= (table_date >= from_date && table_date <= to_date) ? 1 : 0; break;
    			}
    		}
    	}

    	return (inclusion > 0)
    }

    getInclusion = (row_filter) => {
    	let pill_filters = this.state.pill_filters
    	// // console.log('getInclusion pill_filters, row_filter', pill_filters, row_filter)

    	let included_count = 0
    	for(let pid in pill_filters) {
    		included_count += pill_filters[pid].is_active * row_filter[pid]
    	}

    	return (included_count > 0)
    }

    generateTableData = () => {
    	let data = this.props.processTableData();
      console.log("data", data)
    	let ret = {columns: data.columns, data: [], raw_data: [], row_filters: {}} 

    	for(let elem of data.raw_data) {
			if(this.filterRow(elem)){
				/*if(this.getInclusion(data.row_filters[elem.id])) {
				  	ret.data.push(elem);
				}*/
        		ret.data.push(elem);
				ret.raw_data.push(elem);
			}    		
    	}

    	return ret
    }

    goToReport = () => {
    	this.setState({show_report: true})
    }
    exportxls = () => {
    	//console.log("I am here");
    }

	render()
	{
		console.log("RegisterReporting state,",this.state);
		console.log("RegisterReporting props,",this.props);
		if(this.state.filters === null) return <div></div>

		
		return (
			<div style={{marginLeft: "15px" , position: "relative", backgroundColor: "#F2F2F2", padding: "10px 0px"}} ref={this.scrollDiv}>
				<AdvanceFilter 
					filters={this.state.filters} 
					applyFilter={this.applyFilter} 
					clearAdvancedFilter={this.clearAdvancedFilter}
				/>
				<CSLTable export={true} report={true}  goToReport={this.goToReport} exportxls={this.props.exportxls} export_file_name={"Meeting_Details"}  
							add={false}  
							processData={this.generateTableData}
              				export_columns = {this.props.preferedColumns} 
							headerText={'Meetings'} 
							tableRows={10} 
							
				/>           

				{
					(() => {
						if(this.state.show_report) {
							let fields = this.props.fields
							let data = this.props.data
							data.data = this.generateTableData().data
							// this.data = {
							// 					data: this.generateTableData().data, 
							// 					groupby: {fields: fields, selections: this.state.groupby_selections}, 
							// 					sortby: {fields: fields, selected: this.state.sortby_selected},
							// 					table_headers: fields,
							// 					module: {display: this.props.headerText, id: this.props.moduleId}
							// 				}
							// Utils.log('widths new_data', this.data)
							return (
									<div style={{position:'absolute', top:'0px', width:'100%'}}>
										<InactiveOverlay />
										<CSLReportModal
											data={data}
											changeSort={this.changeSort}
											changeGroup={this.changeGroup}
											hideReport={this.hideReport}
											toggleGenerate={this.toggleGenerate}
											filters={this.state.filters}
											
										/>
									</div>
								);
						}
					})()
				}
				{
					(() => {
						if (this.state.generate) {
							return (
								<div style={{position:'absolute', top:'10px', width:'calc(100%-50px)', height: 'calc(100vh-90px)'}}>
									<InactiveOverlay style={{zIndex: "1010"}} />
									<PdfGenerator filters={this.state.filters}  toggleGenerate={this.toggleGenerate} contactName={Store.getStoreData('contact')} />
								</div>
							);
						}
					})()
				}
			</div>
		);
	}

	scrollToMyRef = () => this.scrollDiv.current.scrollIntoView({ behavior: 'smooth' });
}

export default RegisterReporting;



				
				// <CSLTable add={false} showHF={true} export={true} processData={this.generateTableData} 
				// 	headerText={this.props.headerText} HeaderFilter={'Only show Advice Requests assigned to me'} 
				// 	tableRows="10" refreshCallback={this.refreshCallback} showOnlyAssign = {this.props.only_assign_me} 
				// 	only_assign_me={this.props.only_assign_me} show_filter={this.props.show_filter} table="car_register" 
				// 	prefered_columns={this.props.preferedColumns} showOnlyAssign={this.showOnlyAssign} setShowTable={this.setShowTable}
				// 	report={true} goToReport={this.reportCallback} />   
