import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils.js';
import APICall from '../../Common/APICall.js';
import { MdClose, MdDelete } from 'react-icons/md';
import { Container, Row, Col } from 'react-grid-system';
import { TiArrowSortedDown } from "react-icons/ti";
import { FaEye, FaTimes } from "react-icons/fa";
import { FaPen } from "react-icons/fa";
import { FaPlus } from 'react-icons/fa';
import UploadMiscAttachments from '../../Components/ScheduleMeetingModal/UploadMiscAttachments';
import MeetingGuestTable from '../../Components/ScheduleMeetingModal/MeetingGuestTable';
import ViewVersions from '../../Components/ScheduleMeetingModal/ViewVersions';
import ViewVersionsTop from '../../Components/ScheduleMeetingModal/ViewVersionsTop';
import AddActions from './AddActions';
import CSLTable from '../Common/CSLTable';
import CSLLoader from '../Common/CSLLoader';
import CSLLoaderNonPromise from '../Common/CSLLoaderNonPromise';
import CommitteeUtils from '../Common/CommitteeUtils.js';
import AlertBox from '../Common/AlertBox.js';
import ExcelGenerator from '../EXCEL/ExcelGenerator.js';
import moment from 'moment';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const ReactModalHeader = styled.div`
    padding: 10px 15px;
    background: linear-gradient(to bottom, #04ADA8 0%, #04ADA8 100%);
    color:#fff;
`;
const ModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 40%;
    height: 690px;
    background: #ffffff;
    border: 1px solid #868484;
    z-index: 1001;
    position: absolute;
    margin: 0 20%;
    border-radius: 6px;
`;
const CloseDiv = styled.div`
    box-sizing: border-box;
    cursor:pointer;
`;
const ReactFormGroup = styled.div`
    margin-bottom: 15px;
`;

const FormSubmitButton = styled.button`
    color: #fff;
    background: linear-gradient(to top, #04ADA8 0%, #04ADA8 100%);
    box-shadow: inset 0px 1px 1px 0px #04ADA8;
    width: 116px;
    padding: 6px 16px;
    border: 1px solid #04ADA8;
    border-radius:3px;
    margin-right:10px;
`;
const FormCancelButton = styled.button`
    color: #000000;
    background: linear-gradient(to top, #FFFFFF 0%, #FFFFFF 100%);
    box-shadow: inset 0px 1px 1px 0px #FFFFFF;
    width: 116px;
    padding: 6px 16px;
    border: 1px solid grey;
    border-radius:3px;
    margin-right:10px;
`;

const AddActionButton = styled.button`
    background-color: #04ADA8;
    color: #ffffff; 
    border-radius: 5px;
    border: 1px solid #04ADA8;
    padding: 3px 18px;
    background-image: linear-gradient(to bottom, #04ADA8 50%, #04ADA8);
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #01B0A2;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 110px;
    font-size: 11px;
    height: 25px;
    border-radius: 4px;
    margin-right:2px;
`;

/*  */
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 12px;
    font-weight: 600;
    height: 80px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151;  
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: rgb(243, 247, 251);
    padding: 10px;
    border-radius: 4px;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 94%;
    position: absolute;
    top: 38px;
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 50px;
`;
const ExportBtn = styled.button`
  background-color: #213E61;
  border: 1px solid #213E61;
  border-radius: 4px;
  padding: 9px 37px;
  color: #ffffff;
  cursor: pointer;
`;
const CatBtn = styled.button`
    border-radius: 4px;
    padding: 13px 37px;
    color: #ffffff;
    cursor: pointer;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 2200px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0%;
  top: -100px;
  z-index: 1001;
  opacity: 0.9;
`;
const ViewDiv = styled.div`
    box-sizing: border-box;
    cursor:pointer;
    position:relative;
    margin-right:3px;
`;


Date.prototype.withoutTime = function () {
	var d = new Date(this);
	d.setHours(0, 0, 0, 0);
	return d;
}

class CommitteeTasksList extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			editMode: false,

			cur_lane: "",
			action_extra_json: {},
			meeting_date_hash: {},
			followup_tasks: [],
			actions: [],
			tasks_list: [],
			ready: false,
			is_loader: false,
			modal_view: null,
			download_excel: false,
			xls_data: {},
			alert_param: null,
			show_completed_tasks : false ,


		};

	}

	closeTaskModal = () => {
		//Store.updateStore('meetingDataEditMode',null);
		//Store.updateStore('MeetingOverlay', null);
		Store.updateStore('ViewCommitteeTasksListVisibility', false);
		Store.updateStore('modal_committee_tasks_View_CommitteeID', 0);
		this.props.closeModal();

	}

	componentDidMount() {

		this.processIndex();

	}

	processIndex = (result) => {
		console.log("modal_committee_tasks_View_CommitteeID=>", Store.getStoreData('modal_committee_tasks_View_CommitteeID'), Store.getStoreData('committee_meeting_date_assoc'));
		let committee_name_assoc = Store.getStoreData('committee_name_assoc');
		let committee_name = committee_name_assoc[Store.getStoreData('modal_committee_tasks_View_CommitteeID')];
		Store.updateStore('Committee_Name_For_Task', committee_name);
		let actions = [];
		//let tasks_list = result;
		let incomplete_tasks_for_committee_assoc = Store.getStoreData('incomplete_tasks_for_committee_assoc');
		let tasks_list = incomplete_tasks_for_committee_assoc[Store.getStoreData('modal_committee_tasks_View_CommitteeID')];
		console.log("committee tasks,", tasks_list);
		//console.log("committee_name committee tasks,",committee_name);
		let meeting_date_hash = {};
		let filtered_tasks = [];
		for (let i = 0; i < tasks_list.length; i++) {
			let task_json = JSON.parse(tasks_list[i].task_json);
			let action_json = task_json.object_data.headerinfo.action_json;
			action_json.cur_lane = tasks_list[i].cur_lane;
			action_json.task_id = tasks_list[i].task_id;
			//console.log("action_json==>",action_json);
			actions.push(action_json);
			meeting_date_hash[tasks_list[i].task_id] = Store.getStoreData('committee_meeting_date_assoc')[Store.getStoreData('modal_committee_tasks_View_CommitteeID')];
			filtered_tasks.push(tasks_list[i]);
		}

		this.setState({ actions: actions, meeting_date_hash: meeting_date_hash, tasks_list: filtered_tasks, ready: true });
		this.el.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
	}

	processMyActionData = () => {
		console.log(Store.getStoreData('contacts'));
		let role = Store.getStoreData('role');
		let loggedin_user_info = Store.getStoreData('loggedin_user_info');
		var keysdata = this.state.actions;
		console.log("keysdata==>", keysdata);
		let ret = { data: [], columns: [] };
		let i = 0;

		ret.columns = [{ Header: '#', accessor: 'index', minWidth: 30, headerStyle: { textAlign: 'left' } },
		{ Header: 'Assigned to', accessor: 'action_assignto', minWidth: 90, headerStyle: { textAlign: 'left' } },
		{ Header: 'Task Name', accessor: 'action_title', minWidth: 110, headerStyle: { textAlign: 'left' } },
		{
			Header: 'Due Date', accessor: 'due_date', minWidth: 100, headerStyle: { textAlign: 'left' }, sortMethod: (a, b) => {

				if (a === b) {
					return 0;
				}
				let aReverse = 0;
				let bReverse = 0;
				let adue = a.split("/");
				aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
				let bbdue = b.split("/");
				bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
				return aReverse > bReverse ? 1 : -1;

			}
		},
		{
			'Header': 'Status', Cell: row => (
				<div style={{ textAlign: "center" }}>
					{
						(() => {
							//need to restrict by role
							if (row.original.action_status === "Incomplete") {
								if (row.original.cur_lane === "COMP_COMMITTEES_ASSIGNMENT") {
									return (<MRButton style={{ float: "center", backgroundColor: "#F39C12", width: "70px" }}>Pending</MRButton>);
								} else if (row.original.cur_lane === "COMP_COMMITTEES_SAVED") {
									return (<MRButton style={{ float: "center", backgroundColor: "blue", width: "70px" }}>In Progress</MRButton>);
								} else if (row.original.cur_lane === "OVERDUE") {
									return (<MRButton style={{ float: "center", backgroundColor: "red", width: "70px" }}>Overdue</MRButton>);
								}

							} else if (row.original.action_status === "New") {
								if (row.original.cur_lane === "OVERDUE") {
									return (<MRButton style={{ float: "center", backgroundColor: "red", width: "70px" }}>Overdue</MRButton>);
								} else {
									return (<MRButton style={{ float: "center", backgroundColor: "#F39C12", width: "70px" }}>Pending</MRButton>);
								}
							} else {
								return (<MRButton style={{ float: "center", width: "70px" }}>Complete</MRButton>);
							}
						})()
					}


				</div>
			), minWidth: 30, headerStyle: { textAlign: 'center' }
		},
		{
			'Header': 'Action', Cell: row => (
				<div style={{ textAlign: "center" }}>
					{
						(() => {
								//need to restrict by role
								if (row.original.action_status === "Complete") {
									return (<FaEye style={{ float: "left", marginLeft: "60px", cursor: "pointer" }} title="view this task" onClick={() => this.viewAction({ action_title: row.original.action_title, action_description: row.original.action_description, action_assignto: row.original.action_assignto_id, due_date: row.original.unformatted_due_date, action_note_undertaken: row.original.action_note_undertaken, showModal: true, view_mode: true, task_id: row.original.task_id, roll_over_task_ref_id: row.original.roll_over_task_ref_id })} />);

								} else if (row.original.action_status === "Incomplete") {
									if(role === 'admin_manager' || role === 'governance_manager'){
										return (<FaPen style={{ float: "left", marginLeft: "60px", cursor: "pointer" }} title="edit this task" onClick={() => this.viewAction({ action_title: row.original.action_title, action_description: row.original.action_description, action_assignto: row.original.action_assignto_id, due_date: row.original.unformatted_due_date, action_note_undertaken: '', showModal: true, view_mode: false, task_id: row.original.task_id, roll_over_task_ref_id: row.original.roll_over_task_ref_id })} />);
									}else{
										if( row.original.action_assignto_id.toString() === row.original.loggedin_user_info_id.toString()){
											return (<FaPen style={{ float: "left", marginLeft: "60px", cursor: "pointer" }} title="edit this task" onClick={() => this.viewAction({ action_title: row.original.action_title, action_description: row.original.action_description, action_assignto: row.original.action_assignto_id, due_date: row.original.unformatted_due_date, action_note_undertaken: '', showModal: true, view_mode: false, task_id: row.original.task_id, roll_over_task_ref_id: row.original.roll_over_task_ref_id })} />);
										}else{
											return (<FaEye style={{ float: "left", marginLeft: "60px", cursor: "pointer" }} title="view this task" onClick={() => this.viewAction({ action_title: row.original.action_title, action_description: row.original.action_description, action_assignto: row.original.action_assignto_id, due_date: row.original.unformatted_due_date, action_note_undertaken: row.original.action_note_undertaken, showModal: true, view_mode: true, task_id: row.original.task_id, roll_over_task_ref_id: row.original.roll_over_task_ref_id })} />);
										}
									}
									
								} else {
									if (row.original.cur_lane === "COMP_COMMITTEES_SAVED") {
										return (<FaPen style={{ float: "left", marginLeft: "60px", cursor: "pointer" }} title="edit this task" onClick={() => this.viewAction({ action_title: row.original.action_title, action_description: row.original.action_description, action_assignto: row.original.action_assignto_id, due_date: row.original.unformatted_due_date, action_note_undertaken: '', showModal: true, view_mode: false, task_id: row.original.task_id, roll_over_task_ref_id: row.original.roll_over_task_ref_id })} />);
									}
								}
						})()
					}
					{
						(() => {
							if (row.original.cur_lane !== 'COMP_COMMITTEES_COMPLETE') {
								if (role === 'admin_manager' || role === 'governance_manager') {
									return (
										<div style={{ float: "right", marginRight: "50px" }}>
											<ViewDiv style={{ curser: "pointer", color: "#1A3552" }} onClick={this.deleteConfirm(row.original.task_id)} title="delete this follow-up task"><MdDelete /></ViewDiv>
										</div>
									);
								}

							}

						})()
					}

				</div>
			), minWidth: 50, headerStyle: { textAlign: 'center' }
		}
			,

		];

		keysdata.sort(function (a, b) { return new Date(a.due_date).getTime() - new Date(b.due_date).getTime() });
		for (let d of keysdata) {
			//console.log("d=>",d);
			let counter = keysdata.length - i;
			i++;
			let assignto = Store.getStoreData('contact_list')[d.action_assignto];
			let dueg_date_moment_unix = moment(d.due_date).unix();
			let due_date_obj = new Date(d.due_date);
			let due_day = due_date_obj.getDate() < 10 ? "0" + due_date_obj.getDate().toString() : due_date_obj.getDate();
			let due_month = due_date_obj.getMonth() + 1 < 10 ? `0${(due_date_obj.getMonth() + 1).toString()}` : due_date_obj.getMonth() + 1;
			let due_year = due_date_obj.getFullYear();
			let due_date_string = `${due_day}/${due_month}/${due_year}`;

			let g1 = new Date();
			let g2 = new Date(d.due_date);
			let cur_lane = d.cur_lane;

			let action_status = '';
			if ('action_status' in d) {
				action_status = d.action_status;
			} else {
				if (cur_lane !== 'COMP_COMMITTEES_COMPLETE') {
					action_status = 'Incomplete';
					if (g1.withoutTime() > g2.withoutTime()) {
						cur_lane = 'OVERDUE';
					}
				} else {
					action_status = 'Complete';
				}

			}
			if(!this.state.show_completed_tasks && cur_lane === 'COMP_COMMITTEES_COMPLETE')continue;
            let roll_over_task_ref_id = '';
            if ('roll_over_task_ref_id' in d) roll_over_task_ref_id = d.roll_over_task_ref_id;
			let elem = {
				'index': counter,
				'action_title': d.action_title,
				'action_description': d.action_description,
				'action_assignto': assignto,
				'action_status': action_status,
				'due_date': due_date_string,
				'cur_lane': cur_lane,
				'followupBinFiles': d.followupBinFiles,
				'task_id': d.task_id,
				'action_assignto_id': d.action_assignto,
				'unformatted_due_date': d.due_date,
				'action_note_undertaken': d.action_note_undertaken,
				'roll_over_task_ref_id': roll_over_task_ref_id,
				'sort_field': dueg_date_moment_unix.toString(),
                'loggedin_user_info_id': loggedin_user_info.ID,
			};
			ret.data.push(elem);
		}
		ret.data.sort(this.dueDateCompare);
		console.log("elem==>", ret.data);
		return ret;
	}
	showCompletedTasks = (event) => {
		event.persist();
		this.setState(({ show_completed_tasks }) => ({ show_completed_tasks: !this.state.show_completed_tasks}));
	}
	dueDateCompare = (a, b) => {
		return b.sort_field.localeCompare(a.sort_field);
	}
	deleteConfirm = (task_id) => (event) => {
		event.preventDefault();

		let alert_param = {
			title: 'Warning', message: 'Are you sure you want to delete this follow-up task?', ok_text: 'Yes', cancel_text: 'No', confirm: true,
			alertHandler: this.deleteProcessor, stack: { task_id: task_id }
		}
		this.setState({ alert_param: alert_param })

	}
	deleteProcessor = (result, stack) => {
		if (!result || stack.prevent) {
			this.setState({ alert_param: null })
			return
		}
		let task_id = stack.task_id;
		this.setState({ is_loader: true });
		//console.log("deleteProcessor task_id,",task_id,this.state.actions,parseInt(task_id));
		if (parseInt(task_id).toString() === 'NaN') {
			let actions = [];
			for (let a of this.state.actions) {
				if (a.task_id.toString() === task_id.toString()) continue;
				actions.push(a);
			}
			this.setState({ actions, alert_param: null, is_loader: false });
		} else {
			let postData = { command: "delete_followup_task", task_id: task_id };
			let api = new APICall();
			api.command(postData, this.processDeleteAction);
			this.setState({ alert_param: null });
		}
		//return;

	}
	processDeleteAction = (result) => {
		console.log("processDeleteAction result,", result);
		let actions = [];
		for (let a of this.state.actions) {
			if (a.task_id.toString() === result.task_id.toString()) continue;
			actions.push(a);
		}
		let updated_followup_tasks_list = [];
		let followup_tasks_list = Store.getStoreData('followup_tasks_list');
		for (let a of followup_tasks_list) {
			if (a.task_id.toString() === result.task_id.toString()) continue;
			updated_followup_tasks_list.push(a);
		}
		Store.updateStore('followup_tasks_list', updated_followup_tasks_list);
		this.props.passCommitteeTabRefreshInstruction({ 'committee_updated': true, refresh_time: new Date().getTime() });
		this.setState({ actions, is_loader: false })
	}

	viewAction = (jsonObj) => {
		//console.log("task_id in viewAction ==>",jsonObj,this.state.meeting_date_hash);
		let meeting_date_hash = this.state.meeting_date_hash;
		Store.updateStore('Meeting_Date_For_Task', meeting_date_hash[jsonObj.task_id]);
		//this.setState({modal_view: 'open_followup_task_modal' , action_extra_json : jsonObj});

		this.setState({ is_loader: true });
		//return;
		let postData = { "command": "get_task", "task_id": jsonObj.task_id };
		let api = new APICall();
		api.command(postData, this.openTaskModal);
	}
	openTaskModal = (result) => {
		console.log("result,", result);
		let loggedin_user_info = Store.getStoreData('loggedin_user_info');
		let role = Store.getStoreData('role');
		let task_json = JSON.parse(result.result.task.parenttask.task_json);
		let action_json = task_json.object_data.headerinfo.action_json;
		let task_id = result.result.task.parenttask.id;
		let bin_files = task_json.object_data.bin_files;
		let view_mode = (result.result.task.parenttask.cur_lane !== 'COMP_COMMITTEES_COMPLETE') ? false : true;
		if(role === 'team' && result.result.task.parenttask.cur_assigned_to.toString() !== loggedin_user_info.ID.toString())view_mode=true;
		let action_extra_json = { action_title: action_json.action_title, action_description: action_json.action_description, action_assignto: action_json.action_assignto, due_date: action_json.due_date, action_note_undertaken: action_json.action_note_undertaken, followupBinFiles: bin_files, showModal: true, view_mode: view_mode, task_id: task_id, cur_lane: result.result.task.parenttask.cur_lane,roll_over_task_ref_id: action_json.roll_over_task_ref_id };
		this.setState({ action_extra_json, modal_view: 'open_followup_task_modal', is_loader: false });
		//return;
	}
	actionModalOpen = (jsonObj) => {
		console.log("jsonObj,", jsonObj);
		if ('task_id' in jsonObj && 'task_status' in jsonObj) {
			let followup_tasks_list = Store.getStoreData('followup_tasks_list');
			let meeting_records = Store.getStoreData('meeting_records');
			let actions = JSON.parse(JSON.stringify(this.state.actions));
			let temp_actions = [];
			let updated_followup_tasks_list = [];
			for (let i = 0; i < actions.length; i++) {
				/*if(actions[i].task_id === jsonObj.task_id && jsonObj.task_status === 'complete'){
					//actions[i].cur_lane = 'COMP_COMMITTEES_COMPLETE';
					//actions[i].action_note_undertaken = jsonObj.action_note_undertaken;
				}else{
					temp_actions.push(actions[i]);
				}*/
				//if(actions[i].task_id === jsonObj.task_id && jsonObj.task_status === 'complete'){
				//actions[i].cur_lane = 'COMP_COMMITTEES_COMPLETE';
				//actions[i].action_note_undertaken = jsonObj.action_note_undertaken;
				//}
				if (actions[i].task_id === jsonObj.task_id && jsonObj.task_status === 'incomplete') {
					actions[i].cur_lane = 'COMP_COMMITTEES_SAVED';
					actions[i].action_title = jsonObj.subject;
					actions[i].action_assignto = jsonObj.assignedTo;
					actions[i].due_date = jsonObj.due_date;
					//actions[i].action_note_undertaken = jsonObj.action_note_undertaken;
					temp_actions.push(actions[i]);
				}
				if (actions[i].cur_lane !== 'COMP_COMMITTEES_COMPLETE' && actions[i].task_id.toString() !== jsonObj.task_id.toString()) temp_actions.push(actions[i]);
			}
			for (let ft of followup_tasks_list) {
				if (!(ft.id.toString() === jsonObj.task_id.toString() && jsonObj.task_status === 'complete')) updated_followup_tasks_list.push(ft);
			}
			Store.updateStore('followup_tasks_list', updated_followup_tasks_list);
			let meeting_record_tasks = [];
			for (let m of meeting_records.tasks_list) {
				if (m.task_id.toString() === jsonObj.task_id.toString()) {
					let task_json = JSON.parse(m.task_json);
					task_json.object_data.headerinfo.action_json.action_title = jsonObj.subject;
					task_json.object_data.headerinfo.action_json.action_assignto = jsonObj.assignedTo;
					task_json.object_data.headerinfo.action_json.due_date = jsonObj.due_date;
					m.task_json = JSON.stringify(task_json);
					m.cur_lane = (jsonObj.task_status === 'incomplete') ? 'COMP_COMMITTEES_SAVED' : 'COMP_COMMITTEES_COMPLETE';
					if (jsonObj.task_status === 'complete') m.completed_date = moment().unix();
				}
				meeting_record_tasks.push(m);
			}
			meeting_records.tasks_list = meeting_record_tasks;
			Store.updateStore('meeting_records', meeting_records);
			this.setState({ actionModalOpen: jsonObj.status, action_extra_json: {}, actions: temp_actions, modal_view: null }, () => {
				this.props.passCommitteeTabRefreshInstruction({ 'oustanding_actions_count': temp_actions.length, modal_view: null });
			});
		} else {
			this.setState({ actionModalOpen: jsonObj.status, action_extra_json: {}, modal_view: null });
		}
	}

	getDynamicTitle = () => {
		if (Store.getStoreData('ViewCommitteeTasksListVisibility') === true) {
			return (<div style={{ float: "left" }}>View outstanding tasks of committee</div>);
		}
	}

	exportToExcel = (cur_tab) => {
		let committeeUtils = new CommitteeUtils();
		let tasks = [];
		let fileName = 'committee_tasks_list';

		let tasks_data = this.processMyActionData().data;

		let dt = {
			file_name: "committee_tasks_list",
			cols: ['Task Name', 'Task Description', 'Assigned To', 'Status', 'Due Date'],
			data: [
			]
		}

		console.log("exportToExcel task_data,",tasks_data);
		//return;
		for (let i = 0; i < tasks_data.length; i++) {
			//let due_date = moment(tasks_data[i].due_date);
			let due_date = moment(new Date(tasks_data[i].unformatted_due_date));
			let day = due_date.format('DD');
			let month = due_date.format('MM');
			let year = due_date.format('YYYY');
			/*let elm ={
				'Task Title' : tasks_data[i].action_title,
				'Task Description' : committeeUtils.changeStringToAtob(tasks_data[i].action_description),
				'Assigned To' : tasks_data[i].action_assignto,
				'Status' : tasks_data[i].action_status,
				'Due Date' : `${day}-${month}-${year}`,
			};*/
			//tasks.push(elm);
			let arr = [tasks_data[i].action_title, committeeUtils.changeStringToAtob(tasks_data[i].action_description), tasks_data[i].action_assignto, tasks_data[i].action_status, `${day}/${month}/${year}`];
			dt.data.push(arr);
		}

		/*let wb = {SheetNames:[], Sheets:{}};
		const ws1 = XLSX.utils.json_to_sheet(tasks); 
		wb.SheetNames.push("TASKS");
		wb.Sheets["TASKS"] = ws1;

		const format1 = "DD-MM-YYYY HH:mm:ss"
		let tm = moment().format(format1);

		const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
		const data = new Blob([excelBuffer], {type: fileType});
		FileSaver.saveAs(data, fileName + '-' + tm + fileExtension);
		*/
		//let fileName = 'myactivitylist';


		/*const ws = XLSX.utils.json_to_sheet(activities);
		const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
		const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
		const data = new Blob([excelBuffer], {type: fileType});
		FileSaver.saveAs(data, fileName + '-' + tm + fileExtension);*/
		let format1 = "DD-MM-YYYY HH:mm:ss"
		let tm = moment().format(format1);
		let file_name = fileName + '-' + tm;
		dt.file_name = file_name;
		this.setState({ download_excel: true, is_loader: false, xls_data: dt });

	}
	toggleExcel = () => {
		this.setState({ download_excel: false });
	}





	render() {
		console.log("CommitteeTasksList state==>", this.state);
		let committee_id = Store.getStoreData('modal_committee_tasks_View_CommitteeID');

		return (
			<MRModalContainer>
				<MRModalHeader>
					<MRModalHeaderText>{this.getDynamicTitle()}</MRModalHeaderText>
					<MRModalHeaderCloseBtn onClick={this.closeTaskModal}><FaTimes /></MRModalHeaderCloseBtn>
					<div style={{ clear: "both" }}></div>
					<MRModalSubHeaderText></MRModalSubHeaderText>
					<div style={{ clear: "both" }} ref={el => { this.el = el; }}></div>
				</MRModalHeader>
				<MRModalBody>
					<div style={{ padding: "15px", height: "400px", overflowY: "scroll", backgroundColor: "#F1F6F7" }}>
						{
							(() => {
								if (this.state.is_loader) {
									return <div><CSLLoaderNonPromise /></div>
								}

							})()
						}
						{
							(() => {
								if (this.state.modal_view === 'open_followup_task_modal') {
									return <div><InactiveOverlay /><AddActions actionModalOpen={this.actionModalOpen} refreshAddActionValue={this.refreshAddActionValue} curLane={this.state.cur_lane} action_extra_json={this.state.action_extra_json} tasks_list={this.state.tasks_list} prop_task_id={this.props.prop_task_id} committeeId={committee_id} /></div>;
								}

							})()
						}
						{
							(() => {
								if (this.state.download_excel) {
									return (
										<div>
											<InactiveOverlay />
											<div style={{ position: 'fixed', top: '140px', width: 'calc(100%-100px)', height: 'calc(100vh-180px)', zIndex: 1001, border: "solid 3px", borderColor: "red" }}>


												<ExcelGenerator toggleExcel={this.toggleExcel} xls_data={this.state.xls_data} />
											</div>
										</div>
									);
								}
							})()
						}
						{/*<AddActions actionModalOpen={this.actionModalOpen} refreshAddActionValue={this.refreshAddActionValue} curLane={this.state.cur_lane} action_extra_json={this.state.action_extra_json} tasks_list={this.state.tasks_list} prop_task_id={this.props.prop_task_id} closeCommitteeTaskListModal={this.closeTaskModal}/>*/}
						<OverviewInfoBody>
							{
								(() =>{
										return(
											<div style={{float : "right" , marginRight: "5px" }}>
												 Show Completed Tasks &nbsp; <input type="checkbox" checked={this.state.show_completed_tasks} onChange={this.showCompletedTasks} value="1" />
											</div>
										);
									})()
							}
							<div style={{clear: "both", marginTop:"5px"}}></div>
							{
								(() => {
									if (this.state.actions.length > 0) {
										return (
											<CSLTable add={false} processData={this.processMyActionData} headerText={'Follow-up tasks list'} tableRows="15" refreshCallback={this.refreshCallback} />
										)
									} else {
										return (
											<div> No outstanding task .</div>
										)
									}
								})()
							}

						</OverviewInfoBody>
					</div>
					<AlertBox alertParam={this.state.alert_param} />
				</MRModalBody>
				<MRModalFooter>
					<div style={{ padding: "15px 25px 15px 0px" }}>
						<div style={{ float: "left", marginLeft: "70px" }} >  <ExportBtn onClick={this.exportToExcel} >Export</ExportBtn></div>
						<CatBtn style={{ backgroundColor: "#ffffff", border: "1px solid #F0F1F1", color: "black", float: "right", marginRight: "20px" }} onClick={this.closeTaskModal}>Cancel</CatBtn>
						<div style={{ clear: "both" }}></div>
					</div>
				</MRModalFooter>
			</MRModalContainer>
		);
	}
}
export default CommitteeTasksList;