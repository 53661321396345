import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import APICall from '../../Common/APICall.js';
import { MdClose } from 'react-icons/md';
import { FaTimes } from "react-icons/fa";
import AmendMeeting from '../Tabs/AmendMeeting';
import CSLLoader from '../Common/CSLLoader';

const ReactModalHeader = styled.div`
    padding: 10px 15px;
    background: linear-gradient(to bottom, #04ADA8 0%, #04ADA8 100%);
    color:#fff;
`;
const ModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 35%;
    height: 369px;
    background: #ffffff;
    border: 1px solid #868484;
    z-index: 1001;
    position: absolute;
    margin: 0 20%;
    border-radius: 6px;
    left: 20%;
    top: 30%;
`;
const CloseDiv = styled.div`
    box-sizing: border-box;
    cursor:pointer;
`;
const UploadAgendaDocumentFile = styled.div`
    padding: 10px;
    background: #f9f9f9;
    margin: 13px 0;
    color: black;
`;
const AnchorText = styled.a`
    text-decoration:underline;
    cursor:pointer;
	color : #04ADA8 ;
`;

/*  */
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 12px;
    font-weight: 600;
    height: 80px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151;  
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: rgb(243, 247, 251);
    padding: 10px;
    border-radius: 4px;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 50%;
    position: absolute;
    top: 38px;
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 100px;
`;





class ViewVersionsList extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            rootmeetingid: "",
            versionrows: "",
            open_viewmeeting: false,
            ready : true,
        }
        //this.handleChange = this.handleChange.bind(this);
    }
    closeThisModal = () => {
		this.props.callBackFromChild({'closeversionlistmodal' : true});

    }
    openAmendMeetingModal = (meetingid) => {
        //event.preventDefault();
		//console.log("meetingid==>"+meetingid);
        this.setState({ready: false});
		let postData = { command: "getMeetingByID" , data: {  meetingid: meetingid } };
		let api = new APICall();
		api.command(postData, this.processOpenAmendMeetingModalData);

    }
    processOpenAmendMeetingModalData = (result) => {
		//console.log("processOpenAmendMeetingModalData,",result);
		Store.updateStore('meetingDataViewMode', result);
		Store.updateStore('Meeting_view_meeting_id', result.PK_CommitteeMettingID);
		Store.updateStore('AmendMeetingOverlay', `${document.body.scrollHeight.toString()}px`);
		Store.updateStore('AmendMeetingVisibility', true);
		this.setState({open_viewmeeting: true,ready: false});

	}
	createVersionRows = (versionchildren,meetingId) => {
		//console.log("createVersionRows,",versionchildren,meetingId);
		let table = [];
		//let children = [];
		for (let i = 0; i < versionchildren.length; i++){
                if(meetingId.toString() ===  versionchildren[i].PK_CommitteeMettingID.toString()){
					table.push(<p key={versionchildren[i].PK_CommitteeMettingID} style={{color: "red"}}>Version {i+1}</p>);
                }else{
					table.push(<p key={versionchildren[i].PK_CommitteeMettingID}><AnchorText onClick={()=>this.openAmendMeetingModal(versionchildren[i].PK_CommitteeMettingID)}>Version {i+1}</AnchorText></p>);
		        }

		}
		return table;

	}
	componentDidMount_obsolate(){


			this.setState({
				//editmode: "true"
				rootmeetingid: this.props.rootMeetingId,
				currentmeetingid: this.props.currentMeetingId,

			},()=>{
				const url = Store.getStoreData('api_url');

				let meetingId = this.state.rootmeetingid === 0 ? this.state.currentmeetingid : this.state.rootmeetingid;

				//let postData = { command: "getMeetingByID", data: {  meetingid: meetingId } };
				let postData = { command: "getMeetingListByMeetingId", data: {  meetingid: meetingId } };

				let httpVerb = { method: "POST", mode: "cors", headers: { "Content-Type": "application/x-www-form-urlencoded" }, body: "jsondata=" + JSON.stringify(postData) };

				fetch(url, httpVerb)
					.then((res) => res.json())
					.then(result => {
						console.log('version list result==>',result);

						this.setState({
							versionrows: this.createVersionRows(result),

						});
					});
			});
			//Store.updateStoreData('EditActionRecord',null);


	}
	componentDidMount(){
		let meetingId = this.props.rootMeetingId === 0 ? this.props.currentMeetingId : this.props.rootMeetingId;
		this.setState({currentmeetingid: meetingId});
		this.setComponentState(meetingId);
	}
	setComponentState = (meetingId) => {
		let postData = { command: "getMeetingListByMeetingId", data: {  meetingid: meetingId } };
		let api = new APICall();
		//api.command(postData, this.processViewVersionList);
		let that = this;
		api.command(postData, function callback(result){that.processViewVersionList(result,meetingId)});
	}
    processViewVersionList = (result,meetingId) => {
		//console.log('version list result==>',result,meetingId);
		let versionrows=this.createVersionRows(result,meetingId);
		this.setState({
			versionrows

		});
    }
    closeDialog = () => {
    	this.setState({open_viewmeeting : false});
    }
    render(){
        return(
            <MRModalContainer>
				<MRModalHeader>
					<MRModalHeaderText>Versions List</MRModalHeaderText>
					<MRModalHeaderCloseBtn onClick={this.closeThisModal}><FaTimes /></MRModalHeaderCloseBtn>
					<div style={{clear: "both"}}></div>
					<MRModalSubHeaderText></MRModalSubHeaderText>
					<div style={{clear: "both"}}></div>
				</MRModalHeader>
                <MRModalBody>
                <div style={{padding: "15px",height:"327px",overflowY:"scroll"}}>
					{
						(() => {
							if(!this.state.ready) {
								return  <div><CSLLoader /></div>
							}

						})()
					}
                    {
                    	(() => {
                    		if(this.state.open_viewmeeting) {
                    			return  <AmendMeeting closeModal = {this.closeDialog} enlarge_width={true}/> ;
                    		}

                    	})()
                    }
                    <UploadAgendaDocumentFile>
                        <div style={{float:"left"}}>{this.state.versionrows}</div>
                        {/* <div style={{float:"right"}}><MdClose style={{color: "#979797"}}/></div> */}
                        <div style={{clear:"both"}}></div>
                    </UploadAgendaDocumentFile>
                </div>
                </MRModalBody>
				<MRModalFooter>
						<div style={{ textAlign: "right" , marginTop: "20px"}}>

						</div>
				</MRModalFooter>
            </MRModalContainer>
        );
    }
}

export default ViewVersionsList;
