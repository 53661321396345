import React from 'react';
import ReactSelect,{components} from 'react-select';
import {TiArrowSortedDown } from "react-icons/ti";
import { FaSearch } from "react-icons/fa";
import { Container, Row, Col } from 'react-grid-system';
import styled from 'styled-components';
import { FaPlus } from "react-icons/fa";
import { MdClose } from 'react-icons/md';
import Store from '../../Common/Store.js';
import AddCommitteeMembers from '../Tabs/AddCommitteeMembers';
import CSLTable from '../Common/CSLTable';


const ReactFormGroup = styled.div`
    margin-bottom: 15px;
`;

const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #01B0A2;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 110px;
    font-size: 11px;
    height: 25px;
    border-radius: 4px;
    margin-right:2px;
`;

const RequiredDropDown = [
    { required: "Required", value: "Required", selected: true },
    { required: "Optional", value: "Optional", selected: false },
];
const DurationDropDown = [
    {duration: "Part", value: "Part", selected: true},
    {duration: "Full", value: "Full", selected: false},
];

const AttendedDropDown = [
    { required: "Yes", value: "Yes", selected: true },
    { required: "No", value: "No", selected: false },
];

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <FaSearch/>
      </components.DropdownIndicator>
    )
  );
};

function GuestList(props){
	    let records = props.value;
		let table = [];
		for (let i = 0; i < records.length; i++){
			//console.log("employeename==>"+this.state.committeemembers[i].employeename+"<=employeename");
			if(typeof records[i].label !='undefined' && records[i].label!=""){
				let children = [];
				children.push(<td>{records[i].employeename}</td>);
				children.push(<td>Attendee</td>);
				children.push(<td>View</td>);
				children.push(<td><ReactFormGroup>{this.handleAttendeeDurationDropDown(records[i].employeeid,'')}</ReactFormGroup></td>);
				children.push(<td>{this.handleGuestAttendedDropDown(records[i].employeeid,'')}</td>);

				table.push(<tr>{children}</tr>);

				//this.state.guestmembers.push({'employeeid':jsonobj.value,'employeename':jsonobj.label , 'duration':'Part' , 'attended' : 'Yes' });
			}

			
		}
        return table;
}

class MeetingGuestTable extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            
			editMode: false,
			guestmembers: [],
			guestrows: [],
			guestmembersrequireddropdown: [],
			guestmembersattendeddropdown: [],
			requireddropdown: [],

			

        };
        
    }
	renderGuestData() {
      return this.state.guestmembers.map((guest, index) => {
         const { employeeid, employeename, required ,attended,duration } = guest //destructuring
         return (
            <tr key={employeeid}>
               <td>{employeename}</td>
               <td>Attendee</td>
		  {/*<td>View</td>*/}
               <td>{this.handleAttendeeRequiredDropDown(employeeid,required)}</td>
               <td>{this.handleGuestAttendedDropDown(employeeid,attended)}</td>
               <td style={{ align: "center" }}><MdClose style={{ color: "#979797", top: "6px", position: "relative" }} onClick={this.handleGuestMembersDeleteClick(`${index}`)} /></td>
            </tr>
         )
      })
   }

	handleGuestMembersDeleteClick_obsolate = (index) => (event) => {
        event.preventDefault();
		let tempcm = []
        tempcm = this.state.guestmembers;

		tempcm.splice(index, 1);

		let existingrows = this.state.guestrows;

		this.setState({ 
			guestrows: existingrows ,
			guestmembers: tempcm 
		});
		this.props.parentCallback(tempcm);


    }

	handleGuestMembersDeleteClick = (index,employee_id) => (event) => {
        event.preventDefault();
		let tempcm = []
        tempcm = this.state.guestmembers;
		console.log("tempcm,",tempcm);
		let deleted_obj = {};
		for(let t of tempcm){
			if(t.employeeid.toString() === employee_id.toString())deleted_obj=t;
		}

		tempcm.splice(index, 1);

		this.setState({ 
			guestmembers: tempcm 
		});
		this.props.deleteCallback({deleted_obj: deleted_obj,childData: tempcm});


    }

    attendeeEmployeeInputChange = selectedOption => {
		//event.preventDefault();
		//console.log("meetingguests val==>"+JSON.stringify(selectedOption));
		//this.state.guestmembers.push(selectedOption);
		//console.log("guestrows 1 ==>"+this.state.guestrows);
		let existingrows = this.state.guestrows;
		//console.log("existingrows 1==>"+JSON.stringify(existingrows));
		//let newguestrows = this.createGuestRows(selectedOption);
		//console.log("newguestrows==>"+JSON.stringify(newguestrows));
		//existingrows.push(newguestrows);
		//console.log("existingrows 2==>"+JSON.stringify(existingrows));
		//this.state.guestrows.push(newguestrows);
		//let updatedguestrows = this.state.guestrows;
		//console.log("guestrows 2 ==>"+updatedguestrows);
		let tempguestmembers = this.state.guestmembers;
		//tempguestmembers.push({'employeeid':selectedOption.value,'employeename':selectedOption.label , 'duration':'Part' , 'attended' : 'Yes' });
		tempguestmembers.push({'employeeid':selectedOption.value,'employeename':selectedOption.label , 'required':'Required' , 'attended' : 'Yes' , 'duration':'Part'  });
		this.setState({
            guestrows: existingrows,
            guestmembers: tempguestmembers
        },()=>{
			console.log("guestmembers 1 ==>"+JSON.stringify(this.state.guestmembers));
		    this.props.parentCallback(this.state.guestmembers);
		});
		//console.log("guestmembers 1 ==>"+JSON.stringify(this.state.guestmembers));
		//this.props.parentCallback(this.state.guestmembers);

    };

    guestDurationDropDownInputChange = (employeeId) => (event) => {
		event.preventDefault();
		//console.log("val==>"+event.target.value);
		this.state.guestmembersrequireddropdown.map((item) => {
			if(item.employeeid == employeeId){
				item.selectedvalue = event.target.value;
			}
		    for(let i=0;i<this.state.guestmembers.length;i++){
			   if(this.state.guestmembers[i].employeeid == item.employeeid){
				   this.state.guestmembers[i].duration = item.selectedvalue;
			   }
		    }
		});
        
		this.props.parentCallback(this.state.guestmembers);
		console.log("requireddropdown 2 ==>"+JSON.stringify(this.state.guestmembersrequireddropdown));

    };

	handleAttendeeDurationDropDown = (employeeId,durationvalue) => {
            return(
					<select style={{ border: "none", background: "transparent" }} onChange={this.guestDurationDropDownInputChange(employeeId)} >
						{
							DurationDropDown.map((item, index) => {
								if(item.selected){
									let json = {'employeeid' : employeeId , 'selectedvalue' : item.value};
									this.state.guestmembersrequireddropdown.push(json);
								}
								let selectedbool=false;
								if(durationvalue!=''){
									if(item.value==durationvalue){
										selectedbool=true;
									}else{
										selectedbool=false;
									}
								}else{
									selectedbool=item.selected;
								}
								return (<option key={index} value={item.value} selected={selectedbool}>{item.duration}</option>);
							})
						}

					</select>
            );

	}

    attendeeRequiredDropDownInputChange = (employeeId) => (event) => {
		event.preventDefault();
		//console.log("val==>"+event.target.value);
		this.state.requireddropdown.map((item) => {
			                            if(item.employeeid == employeeId){
											item.selectedvalue = event.target.value;

										   for(let i=0;i<this.state.guestmembers.length;i++){
											   if(this.state.guestmembers[i].employeeid == employeeId){
												   this.state.guestmembers[i].required = event.target.value;
											   }
										   }
										}

                                    });
        this.props.parentCallback(this.state.guestmembers);
		//console.log("requireddropdown 2 ==>"+JSON.stringify(this.state.requireddropdown));
		//console.log("attendeemembers 2 ==>"+JSON.stringify(this.state.attendeemembers));

    }

	handleAttendeeRequiredDropDown = (employeeId,requiredvalue) => {
            return(
					<select style={{ border: "none", background: "transparent" }} onChange={this.attendeeRequiredDropDownInputChange(employeeId)} >
						{
							RequiredDropDown.map((item, index) => {
								if(item.selected){
									let json = {'employeeid' : employeeId , 'selectedvalue' : item.value};
									this.state.requireddropdown.push(json);
								}
								let selectedbool=false;
								if(requiredvalue!=''){
									if(item.value==requiredvalue){
										selectedbool=true;
									}else{
										selectedbool=false;
									}
								}else{
									selectedbool=item.selected;
								}
								return (<option key={index} value={item.value} selected={selectedbool}>{item.required}</option>);
							})
						}

					</select>
            );

	}

    guestAttendedDropDownInputChange = (employeeId) => (event) => {
		event.preventDefault();
		//console.log("val==>"+event.target.value);
		this.state.guestmembersattendeddropdown.map((item) => {
			if(item.employeeid == employeeId){
				item.selectedvalue = event.target.value;

				for(let i=0;i<this.state.guestmembers.length;i++){
				   if(this.state.guestmembers[i].employeeid == employeeId){
					   this.state.guestmembers[i].attended = event.target.value;
				   }
				}
			}

		});
        this.props.parentCallback(this.state.guestmembers);
		//console.log("attendeedropdown 2 ==>"+JSON.stringify(this.state.attendeedropdown));

    };

	handleGuestAttendedDropDown = (employeeId,attendedvalue) => {
            return(
					<select style={{ border: "none", background: "transparent" }} onChange={this.guestAttendedDropDownInputChange(employeeId)} >
						{
							AttendedDropDown.map((item, index) => {
								if(item.selected){
									let json = {'employeeid' : employeeId , 'selectedvalue' : item.value};
									this.state.guestmembersattendeddropdown.push(json);
								}
								let selectedbool=false;
								if(attendedvalue!=''){
									if(item.value==attendedvalue){
										selectedbool=true;
									}else{
										selectedbool=false;
									}
								}else{
									selectedbool=item.selected;
								}
								return (<option key={index} value={item.value} selected={selectedbool}>{item.required}</option>);
							})
						}
						{/* <option>Required</option>
						<option>Optional</option> */}
					</select>
            );

	}

	createGuestRows = (jsonobj) => {
		let table = [];
		//for (let i = 0; i < jsonobj.length; i++){
			//console.log("employeename==>"+this.state.committeemembers[i].employeename+"<=employeename");
			if(typeof jsonobj.label !='undefined' && jsonobj.label!=""){
				let children = [];
				children.push(<td>{jsonobj.label}</td>);
				children.push(<td>Attendee</td>);
				children.push(<td>View</td>);
				children.push(<td><ReactFormGroup>{this.handleAttendeeDurationDropDown(jsonobj.value,'')}</ReactFormGroup></td>);
				children.push(<td>{this.handleGuestAttendedDropDown(jsonobj.value,'')}</td>);

				table.push(<tr >{children}</tr>);

				this.state.guestmembers.push({'employeeid':jsonobj.value,'employeename':jsonobj.label , 'duration':'Part' , 'attended' : 'Yes' });
			}

			
		//}
        return table[0];
	}

	createGuestRowsEdit = (jsonobj) => {
		//console.log("HH=>"+JSON.stringify(jsonobj));
		let table = [];
		for (let i = 0; i < jsonobj.length; i++){
			//console.log("employeename==>"+this.state.committeemembers[i].employeename+"<=employeename");
			//if(typeof jsonobj[i].label !='undefined' && jsonobj[i].label!=""){
				let children = [];
				children.push(<td>{jsonobj[i].employeename}</td>);
				children.push(<td>Attendee</td>);
				children.push(<td>View</td>);
				children.push(<td><ReactFormGroup>{this.handleAttendeeDurationDropDown(jsonobj[i].employeeid,jsonobj[i].duration)}</ReactFormGroup></td>);
				children.push(<td>{this.handleGuestAttendedDropDown(jsonobj[i].employeeid,jsonobj[i].attended)}</td>);

				table.push(<tr key={i}>{children}</tr>);

				//this.state.guestmembers.push({'employeeid':jsonobj[i].value,'employeename':jsonobj[i].label , 'duration':'Part' , 'attended' : 'Yes' });
			//}

			
		}
        return table;
	}

	componentDidMount() {
		console.log("in componentDidMount MeetingGuestTable");
		/*console.log("dataFromParent=>"+JSON.stringify(this.props.dataFromParent));*/
		if(Store.getStoreData('EditMode')) {
			//console.log("in componentDidUpdate=>");
			if(!this.state.editMode) {
				//console.log("guestMembers=>"+JSON.stringify(this.props.guestMembers));
				let newguestrows = this.createGuestRowsEdit(this.props.guestMembers);
				//console.log("newguestrows=>"+newguestrows);
				this.setState({
					editMode: true,
					guestrows: newguestrows,
					guestmembers: this.props.guestMembers
				});
			}
	    }
	}

	componentDidUpdate() {
		//console.log("dataFromParent=>"+JSON.stringify(this.props.dataFromParent));
		if(Store.getStoreData('EditMode')) {
			//console.log("in componentDidUpdate=>");
			if(!this.state.editMode) {
				//console.log("guestMembers=>"+JSON.stringify(this.props.guestMembers));
				let newguestrows = this.createGuestRowsEdit(this.props.guestMembers);
				//console.log("newguestrows=>"+newguestrows);
				this.setState({
					editMode: true,
					guestrows: newguestrows,
					guestmembers: this.props.guestMembers
				});
			}
	    }
	}

    processMeetingGuestData = () => {

        let ret = {data: [], columns: []};
        let i = 0;

		ret.columns =[{Header: 'Name', accessor: 'employee_name', width: '40%', headerStyle: {textAlign: 'left'}}, 
			          {Header: 'Role', accessor: 'role', width: '10%', headerStyle: {textAlign: 'left'}},
                      {'Header' : 'Required?', Cell: row => (
                        <div>
	                        {
	                            (() => {
									return (
										   this.handleAttendeeRequiredDropDown(row.original.employee_id,row.original.required)
										)
	                            })()
	                        }
					

                        </div>
                      ), width: '15%', headerStyle: {textAlign: 'left'}},
                      {'Header' : 'Attended?', Cell: row => (
                        <div>
	                        {
	                            (() => {
									return (
										   this.handleGuestAttendedDropDown(row.original.employee_id,row.original.attended)
										)
	                            })()
	                        }
					

                        </div>
                      ), width: '15%', headerStyle: {textAlign: 'left'}},
                      {'Header' : '', Cell: row => (
                        <div>
	                        {
	                            (() => {
									return (
                                           <MdClose style={{ color: "#979797", top: "6px", position: "relative" }} onClick={this.handleGuestMembersDeleteClick(`${row.original.index}`,`${row.original.employee_id}`)} />
										)
	                            })()
	                        }
					

                        </div>
                      ), width: '15%', headerStyle: {textAlign: 'left'}},
		             ];
        //console.log("this.state.committeemembers=>",this.state.committeemembers);

        
		let guestmembers = this.state.guestmembers;
        
		for (let i = 0; i < guestmembers.length; i++){
			//console.log("employeename==>"+this.state.committeemembers[i].employeename+"<=employeename");
			if(typeof guestmembers[i].employeename !='undefined' && guestmembers[i].employeename!=""){

				let required = ('required' in guestmembers[i]) ? guestmembers[i].required : '';
				let attended = ('attended' in guestmembers[i]) ? guestmembers[i].attended : '';
				let elem = {'index' : i, 
							'employee_id' : guestmembers[i].employeeid,
							'employee_name' : guestmembers[i].employeename,
							'role': 'Attendee' ,
							'required': required ,
							'attended': attended ,
						
						   };
				ret.data.push(elem);
			}
		}


        
        return ret;
    }
	
	
    render(){
		//console.log("guest state,", this.state);
        if(Store.getStoreData('EditMode')) {
			//console.log("dataFromParent=>"+JSON.stringify(this.props.dataFromParent));
			console.log("in editmode render=>", this.props.dataFromParent);
            return (
                <div>
					<div>
						<p style={{margin:"10px"}}><b>Meeting Guest</b></p>   
						<AddCommitteeMembers showAddIcon={false} headerText={'Meeting Guest'}/>
						<OverviewInfoBody>                                    
							 <CSLTable add={false} showPagination={false} processData={this.processMeetingGuestData} refreshCallback={this.refreshCallback} tableRows={1000}/>                                              
                             <ReactSelect options={this.props.dataFromParent} onChange={this.attendeeEmployeeInputChange} placeholder="Enter Name" components={{ DropdownIndicator }}/>
						</OverviewInfoBody>
					</div>


				</div>
            );
        } else {
            return(
				<div>
					<div>
						<p style={{margin:"10px"}}><b>Meeting Guest</b></p>   
						<AddCommitteeMembers showAddIcon={false} headerText={'Meeting Guest'}/>
						<OverviewInfoBody>                                    
							 <CSLTable add={false} showPagination={false} processData={this.processMeetingGuestData} refreshCallback={this.refreshCallback} tableRows={1000}/>
                             <ReactSelect options={this.props.dataFromParent} onChange={this.attendeeEmployeeInputChange} placeholder="Enter Name" components={{ DropdownIndicator }}/>				
						</OverviewInfoBody>
                        
					</div>

				</div>
            );
        }

    }
}

export default MeetingGuestTable; 