import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import { Container, Row, Col } from 'react-grid-system';
import DatePicker from "react-date-picker";
//import "react-datepicker/dist/react-datepicker.css";

const ReactFormGroup = styled.div`
    margin-bottom: 15px;
`;
const FormSubmitButton = styled.button`
    color: #fff;
    box-shadow: inset 0px 1px 1px 0px #1A3552;
    width: 116px;
    padding: 6px 16px;
    border: 1px solid #1A3552;
    border-radius:3px;
    background:#1A3552;
`;
const FormResetButton = styled.button`
    color: #000000;
    background: linear-gradient(to top, #FFFFFF 0%, #FFFFFF 100%);
    box-shadow: inset 0px 1px 1px 0px #FFFFFF;
    width: 116px;
    padding: 6px 16px;
    border: 1px solid grey;
    border-radius:3px;
    margin-right:20px;
`;

class AdvanceFilterBox extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            flagforsilentsearch: false,
            prevcommitteeidforsilentsearch: "",
            committeeid: "",
            searchattendees: "",
            searchorganiser: "",
            committeelistinfilter: [],
            employeelistinfilter: [],
            startDate: '',
            endDate: '',
            hideinactivecommitteemeeting: '',
            isHideInactiveCommitteeMeetingChecked: true
        }
        //this.handleChange = this.handleChange.bind(this);
    }

	handleStartDateChange = date => {
		if(date==null){
			this.setState({
				startDate: ''
			});
		}else{
			this.setState({
				startDate: date
			});
		}
	}
	handleEndDateChange = date => {
		if(date==null){
			this.setState({
				endDate: ''
			});
        }else{
			this.setState({
				endDate: date
			});

		}
	}

	handleReset = event => {
		event.preventDefault();
	    //console.log("attendee==>"+this.state.searchattendees);
	    //console.log("AdvanceFiter startDate==>"+this.state.startDate);

        this.setState({
			searchattendees: '',
			committeeid: '',
            startDate: '',
            endDate: '',
            searchorganiser: '',
		});
	}
    formatDate = (date) => {
		//console.log("date 1==>"+date);
		//console.log("date 2==>"+new Date());
		if(date !== ''){
			let day = date.getDate();
			let monthIndex = date.getMonth()+1;
			let year = date.getFullYear();

			if (day < 10) {
			  day = '0' + day;
			}
			if (monthIndex < 10) {
			  monthIndex = '0' + monthIndex;
			}

			return monthIndex + '/' + day + '/' + year;
		}else{
			return '';
		}
	}

	sendCallBack = () => {
		let include_inactive_committee = 'no';
		if (this.state.isHideInactiveCommitteeMeetingChecked === false){include_inactive_committee = 'yes';}
		if(this.state.searchattendees!=='' || this.state.committeeid!=='' || this.state.startDate!=='' || this.state.endDate!==''  || this.state.searchorganiser!==''){


            let json = {'hassearchkey':'yes','searchattendees': this.state.searchattendees,'committeeid':this.state.committeeid,'startDate':this.formatDate(this.state.startDate),'endDate':this.formatDate(this.state.endDate) , 'searchorganiser':this.state.searchorganiser , 'advancesearch' : true , 'include_inactive_committee' : include_inactive_committee};
		    this.props.filterSearchCallback(json);
		}else{
			//let json = {'hassearchkey':'no'};
			let json = {'hassearchkey':'yes' , 'include_inactive_committee' : include_inactive_committee};
			//let json = {'hassearchkey':'yes','searchattendees': this.state.searchattendees,'committeeid':this.state.committeeid,'startDate':this.state.startDate,'endDate':this.state.endDate , 'searchorganiser':this.state.searchorganiser , 'advancesearch' : true};
			this.props.filterSearchCallback(json);
		}
	}

	handleSearchSubmit = event => {
		event.preventDefault();
	    //console.log("attendee==>"+this.state.searchattendees);
	    //console.log("AdvanceFiter startDate==>"+this.formatDate(this.state.startDate));
		this.sendCallBack();
		/*let include_inactive_committee = 'no';
		if(this.state.searchattendees!=='' || this.state.committeeid!=='' || this.state.startDate!=='' || this.state.endDate!==''  || this.state.searchorganiser!==''){
			if (this.state.isHideInactiveCommitteeMeetingChecked === false){include_inactive_committee = 'yes';}

            let json = {'hassearchkey':'yes','searchattendees': this.state.searchattendees,'committeeid':this.state.committeeid,'startDate':this.formatDate(this.state.startDate),'endDate':this.formatDate(this.state.endDate) , 'searchorganiser':this.state.searchorganiser , 'advancesearch' : true , 'include_inactive_committee' : include_inactive_committee};
		    this.props.filterSearchCallback(json);
		}else{
			//let json = {'hassearchkey':'no'};
			let json = {'hassearchkey':'yes'};
			//let json = {'hassearchkey':'yes','searchattendees': this.state.searchattendees,'committeeid':this.state.committeeid,'startDate':this.state.startDate,'endDate':this.state.endDate , 'searchorganiser':this.state.searchorganiser , 'advancesearch' : true};
			this.props.filterSearchCallback(json);
		}
		*/
	}

	handleSearchFromRender = (committeeid) => {
		console.log("in handleSearchFromRender committeeid ==>"+committeeid);
		if(committeeid!=='' ){
            let json = {'hassearchkey':'yes','searchattendees': this.state.searchattendees,'committeeid':committeeid,'startDate':this.state.startDate,'endDate':this.state.endDate,'searchorganiser':this.state.searchorganiser};
		    this.props.filterSearchCallback(json);
		}else{
			let json = {'hassearchkey':'no'};
			this.props.filterSearchCallback(json);
		}
	}

    handleInputChange = event => {
        //Disabled as prevents checkboxes being clicked
        event.preventDefault();

        this.setState({ [event.target.name]: event.target.value });

    };
    handleInactiveCommitteeChange = event => {
        //Disabled as prevents checkboxes being clicked
        event.preventDefault();

        this.setState({
			isHideInactiveCommitteeMeetingChecked: !this.state.isHideInactiveCommitteeMeetingChecked
		},()=>{
			console.log(JSON.stringify(this.state));
//console.log("isHideInactiveCommitteeMeetingChecked==>"+this.state.isHideInactiveCommitteeMeetingChecked);
			if(this.state.isHideInactiveCommitteeMeetingChecked === false){
				let committeeList = [];
				//console.log("in here ==>");
				const url = Store.getStoreData('api_url');
				//this.state.committeelistinfilter = [];

				let postData = { command: "getCommitteeWithCount", data: {  employee_id : 0 , include_inactive_committee: 'yes'} };
				let httpVerb = { method: "POST", mode: "cors", headers: { "Content-Type": "application/x-www-form-urlencoded" }, body: "jsondata=" + JSON.stringify(postData) };
				fetch(url, httpVerb)
					.then((res) => res.json())
					.then((result) => {
					    console.log("AAA==>"+JSON.stringify(result));
						result.committees.map(r => {
							if(r.is_member_of_this_committee){
								committeeList.push({ PK_CommitteeID: r.PK_CommitteeID, committeename: JSON.parse(r.Json_Data).committeename , IsActive : r.IsActive })
							}
						})
                        this.setState({
							committeelistinfilter: committeeList,
							searchattendees: '',
							committeeid: '',
							startDate: '',
							endDate: '',
							searchorganiser: ''
						},()=>{
							this.sendCallBack();
						});

					 });
			}else{
				let committeeList = [];

				const url = Store.getStoreData('api_url');
				//this.state.committeelistinfilter = [];

				let postData = { command: "getCommitteeWithCount", data: {  employee_id : 0 , include_inactive_committee: 'no'} };
				let httpVerb = { method: "POST", mode: "cors", headers: { "Content-Type": "application/x-www-form-urlencoded" }, body: "jsondata=" + JSON.stringify(postData) };
				fetch(url, httpVerb)
					.then((res) => res.json())
					.then((result) => {
						result.committees.map(r => {
							if(r.is_member_of_this_committee){
								committeeList.push({ PK_CommitteeID: r.PK_CommitteeID, committeename: JSON.parse(r.Json_Data).committeename , IsActive : r.IsActive})
							}
						})
                        this.setState({
							committeelistinfilter: committeeList,
							searchattendees: '',
							committeeid: '',
							startDate: '',
							endDate: '',
							searchorganiser: ''
						},()=>{
							this.sendCallBack();
						});

					 });
			}
		});

    };
    handleCommitteeChange = event => {
        //Disabled as prevents checkboxes being clicked
        event.preventDefault();

        this.setState({
			committeeid: event.target.value,
			flagforsilentsearch: false,
            prevcommitteeidforsilentsearch: "",
		});

    };

    componentDidMount(){
        console.log("in Advanced Search Screen");
		//this.setState({
		//			flagforsilentsearch: false
		//		});

		/*
		let committeeList = [];
        const url = "http://localhost:5005/api/command"

		let postData = { command: "committeeList", data: { company_id: parseInt(process.env.REACT_APP_COMPANY_ID) } };
		let httpVerb = { method: "POST", mode: "cors", headers: { "Content-Type": "application/x-www-form-urlencoded" }, body: "jsondata=" + JSON.stringify(postData) };
		fetch(url, httpVerb)
			.then((res) => res.json())
			.then((result) => {
				result.map(r => {
					committeeList.push({ PK_CommitteeID: r.PK_CommitteeID, committeename: JSON.parse(r.Json_Data).committeename })
				})

				this.setState({ committeelistinfilter: committeeList });
		})
		*/
		console.log("in Advancesearch render==>"+JSON.stringify(this.props.passCommitteIdForAdvancedSearch));

		let committeeList = [];
		let employeeList = [];
        const url = Store.getStoreData('api_url');
		//this.state.committeelistinfilter = [];

		let postData = { command: "getCommitteeWithCount", data: {  employee_id : 0} };
		let httpVerb = { method: "POST", mode: "cors", headers: { "Content-Type": "application/x-www-form-urlencoded" }, body: "jsondata=" + JSON.stringify(postData) };
		fetch(url, httpVerb)
			.then((res) => res.json())
			.then((result) => {
				result.committees.map(r => {
					if(r.is_member_of_this_committee){
					    committeeList.push({ PK_CommitteeID: r.PK_CommitteeID, committeename: JSON.parse(r.Json_Data).committeename , IsActive : r.IsActive})
					}
				})


		     })
			.then(() => {
					let postData1 = { command: "merberList" };
					//let postData = { command: "merberList", company_id: parseInt(process.env.REACT_APP_COMPANY_ID) };

					let httpVerb1 = { method: "POST", mode: "cors", headers: { "Content-Type": "application/x-www-form-urlencoded" }, body: "jsondata=" + JSON.stringify(postData1) };
					fetch(url, httpVerb1)
						.then((res) => res.json())
						.then(result => {
							employeeList = result ;
                            //console.log("committeeList==>"+JSON.stringify(committeeList));
					        //console.log("employeeList==>"+JSON.stringify(employeeList));
							if(typeof this.props.passCommitteIdForAdvancedSearch!=='undefined' && this.props.passCommitteIdForAdvancedSearch.advancesearch){
								this.setState({
									committeelistinfilter: committeeList,
									employeelistinfilter: employeeList,
									flagforsilentsearch: true,
									prevcommitteeidforsilentsearch: this.props.passCommitteIdForAdvancedSearch.committeeid,
									committeeid: this.props.passCommitteIdForAdvancedSearch.committeeid
								},()=>{
									this.handleSearchFromRender(this.props.passCommitteIdForAdvancedSearch.committeeid);
								});
							}else{
								this.setState({
									committeelistinfilter: committeeList,
									employeelistinfilter: employeeList
								});
							}
					})


			});



    }
	componentDidUpdate(){
		console.log("in Advancesearch componentDidUpdate flagforsilentsearch==>"+this.state.flagforsilentsearch);
		console.log("in Advancesearch componentDidUpdate==>"+JSON.stringify(this.props.passCommitteIdForAdvancedSearch));
		/*
		if(this.props.passCommitteIdForAdvancedSearch.committeeid!=this.state.prevcommitteeidforsilentsearch){

			if(typeof this.props.passCommitteIdForAdvancedSearch!=='undefined' && this.props.passCommitteIdForAdvancedSearch.advancesearch){

				this.setState({
					flagforsilentsearch: true,
					prevcommitteeidforsilentsearch: this.props.passCommitteIdForAdvancedSearch.committeeid,
					committeeid: this.props.passCommitteIdForAdvancedSearch.committeeid
				},()=>{
					this.handleSearchFromRender(this.props.passCommitteIdForAdvancedSearch.committeeid);
				});
			}

		}
		*/

    }

    render(){



        return(
            <div>
                <Container>
                    <Row>
                        <Col sm={6}>
                            <ReactFormGroup>
                            <select className="react-form-control" name="committeeid" id="committeeid" onChange={this.handleInputChange} value={this.state.committeeid}>
                                <option value="">Select Committee</option>
                                {
                                    this.state.committeelistinfilter.map((c, i) => {
										if(c.IsActive){
											return (
												<option key={c.PK_CommitteeID} value={c.PK_CommitteeID}>{c.committeename}</option>
											)
										}else{
											return (
												<option key={c.PK_CommitteeID} value={c.PK_CommitteeID}>{c.committeename} (Inactive)</option>
											)
										}
                                    })
                                }
                            </select>

                            </ReactFormGroup>
                        </Col>
                        <Col sm={3}>
                            <ReactFormGroup>
                                <DatePicker className="react-form-control" style={{width:"100%"}}
                                    selected={this.state.startDate}
                                    onChange={this.handleStartDateChange}
                                    dateFormat= 'dd/MM/yyyy'
                                    placeholderText= 'From Date'
                                />
                            </ReactFormGroup>
                        </Col>
                        <Col sm={3}>
                            <ReactFormGroup>
                                <DatePicker className="react-form-control" style={{width:"100%"}}
                                    selected={this.state.endDate}
                                    onChange={this.handleEndDateChange}
                                    dateFormat= 'dd/MM/yyyy'
                                    placeholderText= 'To Date'
                                />
                            </ReactFormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={8}>
                            <Row>
                                <Col sm={4}>
                                <ReactFormGroup>
                                <select className="react-form-control" onChange={this.handleInputChange} name="searchattendees" id="searchattendees" value={this.state.searchattendees}>
                                    <option value="">Attendees</option>
                                {
                                    this.state.employeelistinfilter.map((c, i) => {
                                        return (
                                            <option key={c.employeeid} value={c.employeename}>{c.employeename}</option>
                                        )
                                    })
                                }
                                </select>
                                </ReactFormGroup>
                                </Col>
                                <Col sm={4}>
                                    <ReactFormGroup>
                                    <select className="react-form-control" onChange={this.handleInputChange} name="searchorganiser" id="searchorganiser" value={this.state.searchorganiser}>
                                        <option value="">Organiser</option>
                                {
                                    this.state.employeelistinfilter.map((c, i) => {
                                        return (
                                            <option key={c.employeeid} value={c.employeename}>{c.employeename}</option>
                                        )
                                    })
                                }
                                    </select>
                                    </ReactFormGroup>
                                </Col>
                                <Col sm={4}>

									<input type="checkbox" name="hideinactivecommitteemeeting" id="hideinactivecommitteemeeting" checked={this.state.isHideInactiveCommitteeMeetingChecked} onChange={this.handleInactiveCommitteeChange}/>
                                    Hide inactive committee meetings

                                </Col>
                            </Row>
                        </Col>

                    </Row>
                    <Row>
                        <Col sm={4}>
                            <div >
                                <FormResetButton onClick={this.handleReset}>Reset</FormResetButton>
                                <FormSubmitButton onClick={this.handleSearchSubmit}>Submit</FormSubmitButton>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default AdvanceFilterBox;
