import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import APICall from '../../Common/APICall.js';
import { MdClose } from 'react-icons/md';
import { Container, Row, Col } from 'react-grid-system';
import ScheduleMeeting from '../../Components/AmendMeeting/ScheduleMeeting';
import moment from 'moment';
import { FaTimes } from "react-icons/fa";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, thin } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import AddCommitteeMembers from './AddCommitteeMembers';
import CSLTable from '../Common/CSLTable';
import CommitteeUtils from '../Common/CommitteeUtils';
import ViewAgendaDoc from './ViewAgendaDoc';
import ViewMiscDoc from './ViewMiscDoc';
import ViewMinutesDoc from './ViewMinutesDoc';
import Dropzone from '../Common/Dropzone.js';
import Dropzone1 from '../Dropzone/Dropzone.js';
import { saveAs } from 'file-saver';
import { AttachmentButton } from '../Common/StyledComponentsDropZone.js';


const ReactModalHeader = styled.div`
    padding: 10px 15px;
    background: linear-gradient(to bottom, #04ADA8 0%, #04ADA8 100%);
    color:#fff;
`;
const ModalContainer_old = styled.div`
    display: block;
    box-sizing: border-box;
    width: 40%;
    height: 690px;
    background: #ffffff;
    border: 1px solid #868484;
    z-index: 1001;
    position: absolute;
    margin: 0 20%;
    border-radius: 6px;
`;
const CloseDiv = styled.div`
    box-sizing: border-box;
    cursor:pointer;
`;
const ReactFormGroup = styled.div`
    margin-bottom: 15px;
`;

const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;


/*  */
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 12px;
    font-weight: 600;
    height: 80px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151;  
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: rgb(243, 247, 251);
    padding: 10px;
    border-radius: 4px;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 94% !important;
    position: absolute;
    top: 38px;
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 50px;
`;

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1200px !important;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 995;
  opacity: 0.8;
`;

class AmendMeeting extends React.Component {
	state = {
		ready: false,
		view: null,
		committeename: ""
	};
	// this.fileInputRef = React.createRef();
	constructor(props) {
		super(props);


	}

	createAttendeeRows = (attendeeData) => {
		let table = [];
		for (let i = 0; i < attendeeData.length; i++) {
			let children = [];
			children.push(<td>{attendeeData[i].employeename}</td>);
			children.push(<td>{attendeeData[i].committeerolename}</td>);
			children.push(<td>{attendeeData[i].required}</td>);
			children.push(<td>{attendeeData[i].attended}</td>);
			table.push(<tr key={i}>{children}</tr>);

		}
		return table;
	}
	createGuestRows = (guestData) => {
		let table = [];
		for (let i = 0; i < guestData.length; i++) {
			let j = 5 * i;
			let children = [];
			children.push(<td>{guestData[i].employeename}</td>);
			children.push(<td>Attendee</td>);
			children.push(<td>{guestData[i].required}</td>);
			children.push(<td>{guestData[i].attended}</td>);
			table.push(<tr key={j}>{children}</tr>);
		}
		return table;
	}
	createAdditionalGuestRows = (addGuestData) => {
		let table = [];
		for (let i = 0; i < addGuestData.length; i++) {
			let j = 5 * i;
			let children = [];
			children.push(<td>{addGuestData[i].additionalguestname}</td>);
			children.push(<td>{addGuestData[i].required}</td>);
			children.push(<td>{addGuestData[i].attended}</td>);
			table.push(<tr key={j}>{children}</tr>);
		}
		return table;
	}
	createTaskRows = (taskData) => {
		let table = [];
		for (let i = 0; i < taskData.length; i++) {
			let task_json = JSON.parse(taskData[i].task_json);
			let action_json = task_json.object_data.headerinfo.action_json;
			let status = (taskData[i].cur_lane === 'COMP_COMMITTEES_COMPLETE') ? 'Complete' : 'Incomplete';
			let children = [];
			children.push(<td>{Store.getStoreData('contact_list')[action_json.action_assignto]}</td>);
			children.push(<td>{action_json.action_title}</td>);
			children.push(<td>{this.getFormattedDate(action_json.due_date)}</td>);
			children.push(<td>{status}</td>);
			table.push(<tr key={taskData[i].id}>{children}</tr>);
		}
		return table;
	}
	getFormattedDate = (input_date) => {
		var date = new Date(input_date);
		var month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
		var dt = (date.getDate() < 10) ? '0' + (date.getDate()) : (date.getDate());
		return dt + '/' + month + '/' + date.getFullYear();
	}
	getCommitteeById = (committeeid) => {
		const url = Store.getStoreData('api_url');

		let postData = { command: "getCommitteeByID", data: {  committeeid: committeeid } };

		let httpVerb = { method: "POST", mode: "cors", headers: { "Content-Type": "application/x-www-form-urlencoded" }, body: "jsondata=" + JSON.stringify(postData) };

		fetch(url, httpVerb)
			.then((res) => res.json())
			.then(result => {
				result = JSON.stringify(result);
				result = result.replace(/(--plus--)/g, "+");
				result = result.replace(/(--quote--)/g, "'");
				result = result.replace(/(--and--)/g, "&");
				result = result.replace(/'+/g, "'");
				result = result.replace(/(&rsquo;)/g, "\'");
				result = JSON.parse(result);
				//console.log("getCommitteeByID==>>"+JSON.stringify(result));
				let committeeData = JSON.parse(result.Json_Data);
				this.setState({ committeename: committeeData.committeename });
			});

	}
	closeMeetingModal = () => {
		if (this.props.prop_meeting_id > 0) {
			if (document.referrer) {
				if (document.referrer.match(/tmview/g)) {
					window.location.assign(document.referrer);
				} else {
					window.location.href = '/';
				}
				//
			} else {
				window.location.href = '/';
			}
		} else {
			Store.updateStore('meetingDataViewMode', null);
			this.props.closeModal();
		}
	}
	componentDidMount() {
		let meeting_id = Store.getStoreData('Meeting_view_meeting_id');
		let task_ref_id = Store.getStoreData('taskRefIdInMeeting');
		let meeting_records = Store.getStoreData('meeting_records');
		let employee_list = Store.getStoreData('employee_list');
		let followup_tasks_list = Store.getStoreData('followup_tasks_list');
		let employee_ids_of_members = [];
		let contact_ids_of_members = [];
		let current_meeting_tasks_list = [];
		console.log("EEEE,",meeting_records,meeting_id);
		let temp_meeting = null;
		let temp_committee = null;
		for (let mr of meeting_records.meetings) {
			if (mr.PK_CommitteeMettingID.toString() === meeting_id.toString()) {
				temp_meeting = mr;
				break;
			}
		}
		for (let p of JSON.parse(temp_meeting.Json_Data).attendeemembers) {
			employee_ids_of_members.push(parseInt(p.employeeid))
		}
		for (let p of JSON.parse(temp_meeting.Json_Data).guestmembers) {
			employee_ids_of_members.push(parseInt(p.employeeid))
		}
		for (let r of employee_list) {
			if (employee_ids_of_members.includes(parseInt(r.employeeid))) contact_ids_of_members.push(r.contact_id);
		}
		for (let mr of meeting_records.committees) {
			if (mr.PK_CommitteeID.toString() === temp_meeting.FK_CommitteeID.toString()) {
				temp_committee = mr;
				break;
			}
		}
		for (let mr of followup_tasks_list) {
			if (mr.ref_id === temp_meeting.task_ref_id) {
				current_meeting_tasks_list.push(mr)
			}
		}
		temp_meeting.tasks_list = current_meeting_tasks_list;
		//temp_meeting.contact_ids_of_members = contact_ids_of_members;
		//let postData = { command: "getMeetingByID" , data: {  meetingid: meeting_id } , task_ref_id : task_ref_id };
		//let api = new APICall();
		//api.command(postData, this.processOpenAmendMeetingModalData);
		this.processOpenAmendMeetingModalData(temp_meeting, temp_committee, contact_ids_of_members);

	}
	processOpenAmendMeetingModalData = (result, temp_committee, contact_ids_of_members) => {
		console.log("processOpenAmendMeetingModalData result=>", result, temp_committee, contact_ids_of_members);
		let loggedin_user_info = Store.getStoreData('loggedin_user_info');
		//console.log("processOpenAmendMeetingModalData loggedin_user_info=>",loggedin_user_info);
		let role = Store.getStoreData('role');
		let meeting_obj = JSON.parse(result.Json_Data);
		//let contact_ids_of_members = [];
		//for(let p of meeting_obj.contact_ids_of_members){
		//	contact_ids_of_members.push(parseInt(p.FK_ContactID));
		//}
		if (role === 'team' && !contact_ids_of_members.includes(parseInt(loggedin_user_info.ID))) {
			let alert_param = { title: 'No Access', message: 'You are not authorised to access this part. If you think this is in error, please contact your administrator.', ok_text: 'Ok', cancel_text: 'No', confirm: false, alertHandler: this.taskAlertHandler, stack: {} };
			this.setState({ alert_param: alert_param });
		} else {
			Store.updateStore('meetingDataViewMode', result);
			//let meetingDataViewMode = Store.getStoreData('meetingDataViewMode');
			//let finalData = JSON.parse(meetingDataViewMode.Json_Data);

			let committeename = JSON.parse(temp_committee.Json_Data).committeename;
			//this.getCommitteeById(finalData.committeeid);
			//this.props.AddModal('meeting_view');
			this.setState({ ready: true, committeename });
		}
	}
	taskAlertHandler = (result, stack) => {
		this.setState({ alert_param: null })
	}
	processMeetingAttendeesData = () => {
		let meetingDataViewMode = Store.getStoreData('meetingDataViewMode');
		let committeeUtils = new CommitteeUtils();
		let task_ref_id = Store.getStoreData('taskRefIdInMeeting');;
		let module_configs = Store.getStoreData('module_configs');
		let employee_assoc = Store.getStoreData('employee_assoc');

		let ret = { data: [], columns: [] };
		let i = 0;

		if (meetingDataViewMode !== null) {
			let finalData = JSON.parse(meetingDataViewMode.Json_Data);
			let committeemembers = finalData.attendeemembers;
			let employee_rsvp_assoc = committeeUtils.get_rsvp_response_assoc_for_meeting_ref(task_ref_id,committeemembers);

			ret.columns = [
				{ Header: 'Name', accessor: 'employee_name', minWidth: 200, headerStyle: { textAlign: 'left' } },
				{ Header: '', accessor: 'rsvp_comp', minWidth: 30, headerStyle: { textAlign: 'right' }, style: { textAlign: 'center' }},
				{ Header: 'Role', accessor: 'role', minWidth: 100, headerStyle: { textAlign: 'left' } },
				{ Header: 'Required?', accessor: 'required', minWidth: 100, headerStyle: { textAlign: 'left' } },
				{ Header: 'Attended?', accessor: 'attended', minWidth: 100, headerStyle: { textAlign: 'left' } },

			];
			if(!module_configs.general.track_member_responses_to_meeting_invitees)ret.columns.splice(1, 1);
			for (let i = 0; i < committeemembers.length; i++) {

				//console.log("employeename==>"+this.state.committeemembers[i].employeename+"<=employeename");
				if (typeof committeemembers[i].employeename != 'undefined' && committeemembers[i].employeename != "") {
					let employee_name = committeemembers[i].employeename;
					if(committeemembers[i].employeeid.toString() in employee_assoc && !employee_assoc[committeemembers[i].employeeid.toString()].is_active)employee_name=committeemembers[i].employeename+' (Inactive)';

					let required = ('required' in committeemembers[i]) ? committeemembers[i].required : '';
					let attended = ('attended' in committeemembers[i]) ? committeemembers[i].attended : '';
					let rsvp_response_str = (committeemembers[i].employeeid.toString() in employee_rsvp_assoc) ? employee_rsvp_assoc[committeemembers[i].employeeid.toString()] : 'NA';
					let rsvp_comp = '';
					if(rsvp_response_str === 'NA'){
						rsvp_comp=<FontAwesomeIcon icon={solid('dash')} style={{color: "#1E3E62"}} size="2x" />;
					}
					if(rsvp_response_str === 'Yes'){
						rsvp_comp=<FontAwesomeIcon icon={solid('check')} style={{color: "#5BB85C"}} size="2x" />;
					}
					if(rsvp_response_str === 'No'){
						rsvp_comp=<FontAwesomeIcon icon={solid('xmark')} style={{color: "#D9544F"}} size="2x" />;
					}
					if(rsvp_response_str === 'Maybe'){
						rsvp_comp=<FontAwesomeIcon icon={solid('question')} style={{color: "#f0b62e"}} size="2x" />;
					}
					let elem = {
						'index': i + 1,
						'employee_id': committeemembers[i].employeeid,
						'employee_name': employee_name,
						'role': committeemembers[i].committeerolename,
						'required': required,
						'attended': attended,
						'rsvp_response_str': rsvp_response_str,
						'rsvp_comp': rsvp_comp,
					};
					ret.data.push(elem);
				}
			}
		}

		return ret;
	}
	processMeetingGuestData = () => {
		let employee_assoc = Store.getStoreData('employee_assoc');

		let ret = { data: [], columns: [] };
		let i = 0;

		ret.columns = [{ Header: 'Name', accessor: 'employee_name', width: '30%', headerStyle: { textAlign: 'left' } },
		{ Header: 'Role', accessor: 'role', width: '30%', headerStyle: { textAlign: 'left' } },
		{ Header: 'Required?', accessor: 'required', width: '20%', headerStyle: { textAlign: 'left' } },
		{ Header: 'Attended?', accessor: 'attended', width: '20%', headerStyle: { textAlign: 'left' } },

		];

		let meetingDataViewMode = Store.getStoreData('meetingDataViewMode');
		if (meetingDataViewMode !== null) {
			let finalData = JSON.parse(meetingDataViewMode.Json_Data);
			let committeemembers = finalData.guestmembers;

			for (let i = 0; i < committeemembers.length; i++) {
				//console.log("employeename==>"+this.state.committeemembers[i].employeename+"<=employeename");
				if (typeof committeemembers[i].employeename != 'undefined' && committeemembers[i].employeename != "") {
					let employee_name = committeemembers[i].employeename;
					if(committeemembers[i].employeeid.toString() in employee_assoc && !employee_assoc[committeemembers[i].employeeid.toString()].is_active)employee_name=committeemembers[i].employeename+' (Inactive)';

					let required = ('required' in committeemembers[i]) ? committeemembers[i].required : '';
					let attended = ('attended' in committeemembers[i]) ? committeemembers[i].attended : '';
					let elem = {
						'index': i + 1,
						'employee_id': committeemembers[i].employeeid,
						'employee_name': employee_name,
						'role': 'Attendee',
						'required': required,
						'attended': attended,
					};
					ret.data.push(elem);
				}
			}
		}

		return ret;
	}
	processMeetingAdditionalGuestData = () => {

		let ret = { data: [], columns: [] };
		let i = 0;

		ret.columns = [{ Header: 'Name', accessor: 'employee_name', width: '30%', headerStyle: { textAlign: 'left' } },
		//{Header: 'Role', accessor: 'role', width: '30%', headerStyle: {textAlign: 'left'}},
		{ Header: 'Required?', accessor: 'required', width: '20%', headerStyle: { textAlign: 'left' } },
		{ Header: 'Attended?', accessor: 'attended', width: '20%', headerStyle: { textAlign: 'left' } },

		];
		//console.log("this.state.committeemembers=>",this.state.committeemembers);



		let meetingDataViewMode = Store.getStoreData('meetingDataViewMode');
		if (meetingDataViewMode !== null) {
			let finalData = JSON.parse(meetingDataViewMode.Json_Data);
			// console.log("this.state.committeemembers=>",finalData);
			let committeemembers = ('additionalguestmembers' in finalData) ? finalData.additionalguestmembers : [];

			for (let i = 0; i < committeemembers.length; i++) {
				//console.log("employeename==>"+this.state.committeemembers[i].employeename+"<=employeename");
				if (typeof committeemembers[i].additionalguestname != 'undefined' && committeemembers[i].additionalguestname != "") {

					let required = ('required' in committeemembers[i]) ? committeemembers[i].required : '';
					let attended = ('attended' in committeemembers[i]) ? committeemembers[i].attended : '';
					let elem = {
						'index': i + 1,
						'employee_id': committeemembers[i].employeeid,
						'employee_name': committeemembers[i].additionalguestname,
						'role': 'Advisor',
						'required': required,
						'attended': attended,

					};
					ret.data.push(elem);
				}
			}
		}




		return ret;
	}
	processTaskData = () => {

		let ret = { data: [], columns: [] };
		let i = 0;

		ret.columns = [{ Header: 'Assigned To', accessor: 'assign_to', width: '30%', headerStyle: { textAlign: 'left' } },
		{ Header: 'Title', accessor: 'title', width: '30%', headerStyle: { textAlign: 'left' } },
		{ Header: 'Due Date', accessor: 'due_date', width: '20%', headerStyle: { textAlign: 'left' } },
		{ Header: 'Status', accessor: 'status', width: '20%', headerStyle: { textAlign: 'left' } },

		];
		//console.log("this.state.committeemembers=>",this.state.committeemembers);



		let meetingDataViewMode = Store.getStoreData('meetingDataViewMode');
		if (meetingDataViewMode !== null) {
			//let finalData = JSON.parse(meetingDataViewMode.Json_Data);
			//let finalData = meetingDataViewMode;
			let taskData = meetingDataViewMode.tasks_list;


			let contact_list = Store.getStoreData('contact_list')
			for (let i = 0; i < taskData.length; i++) {
				let task_json = JSON.parse(taskData[i].task_json);
				let action_json = task_json.object_data.headerinfo.action_json;

				let status = (taskData[i].cur_lane === 'COMP_COMMITTEES_COMPLETE') ? 'Complete' : 'Incomplete';
				//if(typeof jsonobj.label !='undefined' && jsonobj.label!=""){



				let elem = {
					'index': i + 1,
					'assign_to': contact_list[action_json.action_assignto],
					'title': action_json.action_title,
					'due_date': this.getFormattedDate(action_json.due_date),
					'status': status,

				};
				ret.data.push(elem);


				//}


			}
		}




		return ret;
	}
	processOutstandingTaskData = () => {

		let ret = { data: [], columns: [] };
		let i = 0;

		ret.columns = [{ Header: 'Assigned To', accessor: 'assign_to', width: '30%', headerStyle: { textAlign: 'left' } },
		{ Header: 'Title', accessor: 'title', width: '30%', headerStyle: { textAlign: 'left' } },
		{ Header: 'Due Date', accessor: 'due_date', width: '20%', headerStyle: { textAlign: 'left' } },
			//{Header: 'Status', accessor: 'status', width: '20%', headerStyle: {textAlign: 'left'}},

		];
		let meetingDataViewMode = Store.getStoreData('meetingDataViewMode');
		let finalData = JSON.parse(meetingDataViewMode.Json_Data);
		let taskData = finalData.tasks_list_outstanding;
		let contact_list = Store.getStoreData('contact_list')
		for (let i = 0; i < taskData.length; i++) {
			let task_json = JSON.parse(taskData[i].task_json);
			let action_json = task_json.object_data.headerinfo.action_json;

			let status = (taskData[i].cur_lane === 'COMP_COMMITTEES_COMPLETE') ? 'Complete' : 'Incomplete';
			let elem = {
				'index': i + 1,
				'assign_to': contact_list[action_json.action_assignto],
				'title': action_json.action_title,
				'due_date': this.getFormattedDate(action_json.due_date),
				'status': status,

			};
			ret.data.push(elem);

		}



		return ret;
	}
	openViewMinutesModal = () => {
		Store.updateStore('ViewMinutesVisibility', true);
		this.setState({ view: 'viewminits' });
	}
	openViewMiscModal = () => {
		Store.updateStore('ViewMiscVisibility', true);
		this.setState({ view: 'viewmisc' });
	}
	showagendaModal = (view) => {
		this.setState({ view: view });

	}
	closeDialog = (view) => {
		this.setState({ view: null });
	}

	downloadFileToBrowser = (file_name, result) => {
		let bin_file = result.base64filedata.bin_file;
		let fileName = result.base64filedata.name;
		let dnld = bin_file.replace(/ /g, '+');
		// console.log("binary file before download:", dnld);
		var binaryString = window.atob(dnld);
		// console.log('binaryString', binaryString);
		var binaryLen = binaryString.length;
		var bytes = new Uint8Array(binaryLen);
		for (var i = 0; i < binaryLen; i++) {
			var ascii = binaryString.charCodeAt(i);
			bytes[i] = ascii;
		}
		var blob = new Blob([bytes]);
		saveAs(blob, fileName);
	}

	downloadAttachment = (file_id, file_name) => {
		let that = this;
		let postData = { command: "downloadFile", data: { file_id: file_id } };
		let api = new APICall();
		api.command(postData, function callback(result) { that.downloadFileToBrowser(file_name, result) });
	}

	openFileDialog(evt) {
		evt.preventDefault();
		if (this?.props?.disabled) return;
		console.log("THIS ", this)
		document.querySelector(".FileInput").click();
		// this.fileInputRef.current.click();
	}

	render() {
		let meetingDataViewMode = Store.getStoreData('meetingDataViewMode') === null ? {} : Store.getStoreData('meetingDataViewMode');
		let finalData = ('Json_Data' in meetingDataViewMode) ? JSON.parse(meetingDataViewMode.Json_Data) : [];
		let starttime = new Date(finalData.meetingstarttime);
		let additionalguestmembers = (typeof finalData.additionalguestmembers !== 'undefined') ? finalData.additionalguestmembers : [];
		let tasks_list = finalData.tasks_list;
		let container_width = '50%';
		if (typeof this.props.enlarge_width !== 'undefined') { container_width = '150%' }

		console.log("AmendMeeting meetingDataViewMode,", this.state);

		console.log("final DATA", finalData)

		let misc_curBinFiles = [];
		let misc_curBinFiles_pre = [];
		(finalData?.miscattachments || []).forEach((item) => {
			let status = ('status' in item) ? item.status : 'active';
			misc_curBinFiles_pre.push({ uid: item.file_id, name: item.file_name , status: status});
		});
		/*if(finalData.miscattachments.length > 0){
			for(let item of finalData.miscattachments){
				misc_curBinFiles_pre.push({ uid: item.file_id, name: item.file_name });
			}
		}*/
		misc_curBinFiles = misc_curBinFiles_pre.filter((thing, index, self) =>
		  index === self.findIndex((t) => (
			t.uid === thing.uid && t.name === thing.name
		  ))
		);
		for(let item of misc_curBinFiles){
			if(item.status==='deleted')continue;
			misc_curBinFiles_pre.push({ uid: item.file_id, name: item.file_name });
		}
		if (!this.state.ready) return <div>Loading...</div>;
		let attendeemembers = finalData.attendeemembers;
		let rsvp_count_assoc = {
			'NA': 0,
			'Yes': 0,
			'No': 0,
			'Maybe': 0
		};
		let rsvp_d_str = {
			'NA': {'m': 'members', 'h': 'have'},
			'Yes': {'m': 'members', 'h': 'have'},
			'No': {'m': 'members', 'h': 'have'},
			'Maybe': {'m': 'members', 'h': 'are'}
		};
		let committeeUtils = new CommitteeUtils();
		let task_ref_id = Store.getStoreData('taskRefIdInMeeting');
		let employee_rsvp_assoc = committeeUtils.get_rsvp_response_assoc_for_meeting_ref(task_ref_id,attendeemembers);
		let module_configs = Store.getStoreData('module_configs');

		for(let e in employee_rsvp_assoc){
			if(employee_rsvp_assoc[e] === 'NA')rsvp_count_assoc['NA']++;
			if(employee_rsvp_assoc[e] === 'Yes')rsvp_count_assoc['Yes']++;
			if(employee_rsvp_assoc[e] === 'No')rsvp_count_assoc['No']++;
			if(employee_rsvp_assoc[e] === 'Maybe')rsvp_count_assoc['Maybe']++;
		}
		for(let r in rsvp_count_assoc){
			if(rsvp_count_assoc[r] === 1){rsvp_d_str[r]['h'] = 'has';rsvp_d_str[r]['m'] = 'member';}
			if(rsvp_count_assoc[r] > 1)rsvp_d_str[r]['m'] = 'members';
			if(r === 'Maybe' && rsvp_count_assoc[r] === 1){rsvp_d_str[r]['h'] = 'is';rsvp_d_str[r]['m'] = 'member';}
		}
		return (
			<MRModalContainer style={{ width: container_width }}>
				<MRModalHeader>
					<MRModalHeaderText>View Meeting</MRModalHeaderText>
					<MRModalHeaderCloseBtn onClick={this.closeMeetingModal}><FaTimes /></MRModalHeaderCloseBtn>
					<div style={{ clear: "both" }}></div>
					<MRModalSubHeaderText></MRModalSubHeaderText>
					<div style={{ clear: "both" }}></div>
				</MRModalHeader>
				<MRModalBody>
					<div style={{ padding: "15px", height: "650px", overflowY: "scroll", fontSize: "13px" }}>

						{
							(() => {
								switch (this.state.view) {
									case 'viewagenda': return <div><InactiveOverlay /><ViewAgendaDoc closeDialog={this.closeDialog} /></div>; break;
									case 'viewmisc': return <div><InactiveOverlay /><ViewMiscDoc closeDialog={this.closeDialog} /></div>; break;
									case 'viewminits': return <div><InactiveOverlay /><ViewMinutesDoc closeDialog={this.closeDialog} /></div>; break;
								}

							})()
						}

						<ScheduleMeeting showagendaModal={this.showagendaModal} />

						<p style={{ marginBottom: "7px" }}><b>Committee Name</b></p>
						<ReactFormGroup>
							<select className="react-form-control" disabled="true">
								<option value={this.state.committeename}>{this.state.committeename}</option>
							</select>
						</ReactFormGroup>
						<hr style={{ border: "1px solid #dbdbdb" }}></hr>
						<p style={{ marginBottom: "10px" }}><b>Meeting Attendees</b></p>
						<div>
							<AddCommitteeMembers showAddIcon={false} headerText={'Meeting Attendees'} />
							<OverviewInfoBody>
								<CSLTable add={false} showPagination={false} processData={this.processMeetingAttendeesData} refreshCallback={this.refreshCallback} tableRows={1000}/>
								{
									(() => {
										if(module_configs.general.track_member_responses_to_meeting_invitees){
											return(
												<p style={{ marginTop: "7px", color: "rgb(12,110,228)"}}><b>{rsvp_count_assoc['Yes']} {rsvp_d_str['Yes']['m']} {rsvp_d_str['Yes']['h']} accepted, {rsvp_count_assoc['Maybe']} {rsvp_d_str['Maybe']['m']} {rsvp_d_str['Maybe']['h']} unsure, {rsvp_count_assoc['No']} {rsvp_d_str['No']['m']} {rsvp_d_str['No']['h']} declined, no response has been received from {rsvp_count_assoc['NA']} {rsvp_d_str['NA']['m']}</b></p>
											);
										}
									})()
								}
							</OverviewInfoBody>
						</div>
						<p style={{ margin: "10px 0" }}><b>Meeting Guests</b></p>
						<div>
							<AddCommitteeMembers showAddIcon={false} headerText={'Meeting Guests'} />
							<OverviewInfoBody>
								<CSLTable add={false} showPagination={false} processData={this.processMeetingGuestData} refreshCallback={this.refreshCallback} tableRows={1000}/>
							</OverviewInfoBody>
						</div>

						<p style={{ margin: "10px 0" }}><div style={{ float: "left" }}><b>Meeting Additional Guests</b></div><div style={{ float: "right", marginRight: "25px" }}></div></p>
						<div style={{ clear: "both", marginTop: "25px" }}></div>
						<div>
							<AddCommitteeMembers showAddIcon={false} headerText={'Meeting Additional Guests'} />
							<OverviewInfoBody>
								<CSLTable add={false} showPagination={false} processData={this.processMeetingAdditionalGuestData} refreshCallback={this.refreshCallback} tableRows={1000}/>
							</OverviewInfoBody>
						</div>

						<p style={{ margin: "10px 0" }}><div style={{ float: "left" }}><b>Meeting Follow-up Tasks</b></div><div style={{ float: "right", marginRight: "25px" }}><b>Minutes : </b><a id="link_viewminutesdoc" style={{ cursor: "pointer", fontWeight: "normal", color: "#04ADA8" }} onClick={this.openViewMinutesModal}>View</a></div></p>
						<div style={{ clear: "both", marginTop: "25px" }}></div>
						<div>
							<AddCommitteeMembers showAddIcon={false} headerText={'Meeting Follow-up Tasks'} />
							<OverviewInfoBody>
								<CSLTable add={false} showPagination={false} processData={this.processTaskData} refreshCallback={this.refreshCallback} tableRows={1000}/>
							</OverviewInfoBody>
						</div>

						{
							(() => {
								if (typeof finalData.tasks_list_outstanding !== 'undefined' && finalData.tasks_list_outstanding.length > 0) {
									return (
										<div>
											<p style={{ margin: "10px 0" }}><div style={{ float: "left" }}><b>Meeting Outstanding Tasks</b></div><div style={{ float: "right", marginRight: "25px" }}></div></p>
											<div style={{ clear: "both", marginTop: "25px" }}></div>
											<div>
												<AddCommitteeMembers showAddIcon={false} headerText={'Meeting Outstanding Tasks'} />
												<OverviewInfoBody>
													<CSLTable add={false} showPagination={false} processData={this.processOutstandingTaskData} refreshCallback={this.refreshCallback} />
												</OverviewInfoBody>
											</div>
										</div>
									);
								}
							})()
						}
						<div>
							<p style={{ margin: "10px 0" }}><div style={{ float: "left" }}><b>Meeting Documentation</b></div><div style={{ float: "right", marginRight: "25px" }}></div></p>
							<div style={{ clear: "both", marginTop: "25px" }}></div>
							<div style={{ width: "70%" }}>

								{
									(() => {
										if (finalData.miscattachments.length === 0) {
											return "No documentation has been uploaded for this meeting.";
										} else {
											return <Dropzone1 onFilesAdded={this.filesLoaded} initFiles={misc_curBinFiles} addnew={false} height={"200px"} onRemoveFile={this.filesRemoved} showFileButton={false} handleDownload={this.downloadAttachment} />
										}
									})()
								}
							</div>
						</div>
					</div>
				</MRModalBody>
				<MRModalFooter>
					<div style={{ textAlign: "right", marginTop: "20px" }}>

					</div>
				</MRModalFooter>
			</MRModalContainer>
		);
	}
}

export default AmendMeeting;
