import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store'
import { FaHome, FaCog, FaBook, FaElementor, FaWrench, FaRegListAlt , FaThLarge } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faGear, faSquareList} from "@fortawesome/pro-thin-svg-icons";
import { TiArrowBack } from "react-icons/ti";
import * as Style from './StyledComponents'



const Categories = styled.div`
    float: right;
    font-size: 17px;
    background-color: #ebebeb;
    color: #c4c4c4;
    width: 25px;
    height: 25px;
    margin-left: 5px;
    margin-top: 4px;
    padding-left: 4px;
    padding-top: 3px;
    border-radius: 2px;
`;
/*
			    <Categories><FaCog /></Categories>
                <Categories><FaThLarge /></Categories>
                <Categories><a href={module_config_url}><FaWrench /></a></Categories>
*/

class CSLHeader extends React.Component
{
    redirectToModuleConfig = (event) => {
        event.preventDefault();
        const url = "/moduleconfig";
        window.open(url, "cmp_mc", "width=1400, height=900");
    }
    render()
    {
		let use_site_layout = false
		if(localStorage.getItem('site_layout')!==null){
		  let site_layout = JSON.parse(localStorage.getItem('site_layout'));
		  use_site_layout = site_layout.result.use_site_layout;
		}
		if(use_site_layout) return (<div></div>)
		let module_config_url = '/moduleconfig';
		//let cur_view = Store.getStoreData('cur_view');
		if(Store.getStoreData('role') === 'admin_manager'){
			return (
				<Style.HeadContainer>
					<Style.HomeLogo><FaHome style={{color: "#282828"}} /></Style.HomeLogo>
					<Style.HomeText><span style={{fontWeight: "600" , color: "#282828"}}>{this.props.homeText}</span></Style.HomeText>
			        <Categories><a href={module_config_url} style={{cursor: "pointer", color: "#337ab7"}}><FontAwesomeIcon icon={faGear}/></a></Categories>
					<Categories title='Audit Logs'><a href={process.env.REACT_APP_AUDIT_URL} style={{cursor: "pointer", color: "#337ab7"}}><FontAwesomeIcon icon={faSquareList}/></a></Categories>

			{/*<Categories><span style={{cursor: "pointer", color: "#337ab7"}} onClick={this.redirectToModuleConfig}><FaWrench /></span></Categories>*/}
					<div style={{clear: "both"}}></div>
				</Style.HeadContainer>
			);
		}else{
			return (
				<Style.HeadContainer>
					<Style.HomeLogo><FaHome style={{color: "#282828"}}/></Style.HomeLogo>
					<Style.HomeText><span style={{fontWeight: "600", color: "#282828"}}>{this.props.homeText}</span></Style.HomeText>

					<div style={{clear: "both"}}></div>
				</Style.HeadContainer>
			);

		}

    }
}
export default CSLHeader;
