import React from 'react';
import styled from 'styled-components';
import Store from '../Common/Store.js';
import APICall from '../Common/APICall.js';
import Utils from '../Common/Utils.js';
import WelcomeBanner from './Common/WelcomeBanner';
import CSLHeader from './Common/CSLHeader';
import AlertBox from './Common/AlertBox.js';
import ReferrerHeader from './ReferrerHeader';
import PageTab from './Tabs/PageTab';
import CommiteeTab from './Tabs/CommiteeTab';
import RunExport from './RunExport';
import MeetingList from './Tabs/MeetingList';
import NewCommitteeModal from './Tabs/NewCommitteeModal';
import ScheduleMeeting from './Tabs/ScheduleMeeting';
import CommitteeDetails from './Tabs/CommitteeDetails';
import CommitteeTasksList from './Tabs/CommitteeTasksList';
import AmendMeeting from './Tabs/AmendMeeting';
import MeetingInviteesModal from './MeetingList/MeetingInviteesModal';
import MeetingTaskListModal from './MeetingList/MeetingTaskListModal';
import ViewAgendaDoc from './Tabs/ViewAgendaDoc';

import ConfirmationBox from './AddCommitteeModal/ConfirmationBox';

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1200px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 995;
  opacity: 0.8;
`;

class Index extends React.Component
{
  state = {
	cur_view: 'committee',
	view_count: 0,
	overlay_height: null,
	modal_view: null,
	passMeetingTabRefreshInstruction:{},
	passCommitteeTabRefreshInstruction:{},
	committeeidforsearch:{},
	prop_task_id: 0,
	prop_meeting_id: 0,
	ready: false,
  };

	componentDidMount (){

		let alert_param = null;
		//console.log("module_configs in Index==>",this.props.module_configs);
		if (Store.getStoreData('role') === 'no_access') {
			//let alert_param = {title: 'No Access', message: 'Access to this module is restricted, please contact your system administrator.', ok_text: 'Ok', confirm: false, alertHandler: this.alertHandler, stack: {id: 0}};

			alert_param = {title: 'No Access !!', message: 'You are not authorised to access this module. If you think this is in error, please contact your administrator.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.alertHandler, stack: {} };

		 }
		let prop_task_id = 0;
		let prop_meeting_id = 0;
		if(this.props.match){
			if(this.props.match.path = '/:taskId'){
				prop_task_id = parseInt(this.props.match.params.taskId);
			}
			if(this.props.match.path = '/meeting/:meetingId'){
				prop_meeting_id = parseInt(this.props.match.params.meetingId);
			}
		}
		if(prop_task_id > 0 || prop_meeting_id > 0) {
			let modal_view = (prop_task_id > 0) ? 'meeting_new' : '';
			this.setState({alert_param : alert_param , prop_task_id : prop_task_id , prop_meeting_id : prop_meeting_id , modal_view : modal_view , cur_view : 'meeting' , overlay_height:`${document.body.scrollHeight.toString()}px` , ready: true});
		}else{
			this.setState({alert_param : alert_param , ready: true});
		}

	}
    alertHandler = (result, stack) => {
      this.setState({alert_param: null})
      localStorage.removeItem('committees-index');
      window.location = '/';
    }

  AddModal = (cur_view) => {
  	this.setState({modal_view : cur_view, view_count: this.state.view_count + 1,overlay_height:`${document.body.scrollHeight.toString()}px` });
  }

  refreshState = (cur_view) => {
	  let passCommitteeTabRefreshInstruction = this.state.passCommitteeTabRefreshInstruction;
	  if(cur_view === 'committee') passCommitteeTabRefreshInstruction = {'committee_get_refresh' : true , trans_time : new Date().getTime()}
      this.setState({cur_view : cur_view, view_count: this.state.view_count + 1 , committeeidforsearch : {} ,passCommitteeTabRefreshInstruction : passCommitteeTabRefreshInstruction});
  }

  closeDialog = () => {
      this.setState({modal_view: null, overlay_height: null})
  }
	passMeetingTabRefreshInstruction = (childData) => {
		console.log("in Index  =>",childData);
		this.setState({passMeetingTabRefreshInstruction: childData});
	}
    passCommitteeTabRefreshInstruction = (jsonobj) => {
		this.setState({passCommitteeTabRefreshInstruction: jsonobj});
    }
    passCommitteIdForAdvancedSearch = (childData) => {

        //this.props.getCommitteIdForAdvancedSearch(childData);
		let json ={'committeeid' : childData.committeeid , 'advancesearch' : true};
		this.setState({
			committeeidforsearch: json,
			cur_view: 'meeting',
		});
    }

  render()
  {
  	let prop_task_id = 0;
  	let prop_meeting_id = 0;
  	if(this.props.match){
  		if(this.props.match.path = '/:taskId'){
  			prop_task_id = parseInt(this.props.match.params.taskId);
  		}
  		if(this.props.match.path = '/meeting/:meetingId'){
  			prop_meeting_id = parseInt(this.props.match.params.meetingId);
  		}
  	}

	let role = Store.getStoreData('role');
	if(role === 'no_access'){
		return (
							  <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>


								<AlertBox alertParam = {this.state.alert_param}/>
							  </div>
		);

	}
	console.log("Index states,",this.state,prop_meeting_id);
    if(!this.state.ready)return <div></div>;
    return (
      <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
	      {
	      	(() => {
	      		if(prop_task_id > 0){
	      			return (<ReferrerHeader />);
	      		}else{
	      			return (<CSLHeader homeText={'Governance'} />);
	      	    }
	      	})()
	      }
	      <WelcomeBanner welcome={`Welcome Back, ` + Store.getStoreData('loggedin_user_info').ContactName} />
	      {
	      	(() => {
	      		if (this.state.overlay_height !== null) {
	      			switch(this.state.modal_view) {
	      				case 'committee_new' : return  <div><InactiveOverlay/><NewCommitteeModal closeModal = {this.closeDialog} passCommitteeTabRefreshInstruction={this.passCommitteeTabRefreshInstruction}/></div> ; break;
	      				case 'meeting_new' : return  <div><InactiveOverlay/><ScheduleMeeting closeModal = {this.closeDialog} getMeetingTabRefreshInstruction={this.passMeetingTabRefreshInstruction} prop_task_id={prop_task_id} /></div> ; break;
	      				case 'committee_view' : return  <div><InactiveOverlay/><CommitteeDetails closeModal = {this.closeDialog} /></div> ; break;
	      				case 'committee_edit' : return  <div><InactiveOverlay/><NewCommitteeModal closeModal = {this.closeDialog} passCommitteeTabRefreshInstruction={this.passCommitteeTabRefreshInstruction} prop_task_id={prop_task_id}/></div> ; break;
	      				//case 'committee_tasks_list' : return  <CommitteeTasksList closeModal = {this.closeDialog}/> ; break;
	      				case 'committee_tasks_list' : return  <div><InactiveOverlay/><CommitteeTasksList closeModal = {this.closeDialog} passCommitteeTabRefreshInstruction={this.passCommitteeTabRefreshInstruction}/></div> ; break;
	      				case 'meeting_view' : return  <div><InactiveOverlay/><AmendMeeting closeModal = {this.closeDialog} prop_meeting_id={prop_meeting_id}/></div> ; break;
	      				case 'meeting_invitees' : return  <div><InactiveOverlay/><MeetingInviteesModal closeModal = {this.closeDialog}/></div> ; break;
	      				case 'meeting_taskslist' : return  <div><InactiveOverlay/><MeetingTaskListModal closeModal = {this.closeDialog}/></div> ; break;
	      			}
	      		}
	      	})()
	      }
	      <div className="carousel slide select-smr-slider-tab" data-ride="carousel" data-interval="false">
	      	<PageTab refreshParrentCallback = {this.refreshState} AddModal={this.AddModal} prop_task_id={prop_task_id}/>
	      	<div className="carousel-inner select-smr-slider-tab-inner" style={{paddingBottom: "23px" , backgroundColor : "#F5F9FF"}} >
	      	{
	      	     (() => {
	      	         switch(this.state.cur_view) {
	      	            case 'committee' : return  <div><CommiteeTab AddModal={this.AddModal} passCommitteeTabRefreshInstruction={this.state.passCommitteeTabRefreshInstruction} getCommitteIdForAdvancedSearch={this.passCommitteIdForAdvancedSearch}/> <RunExport passFlag="one"/> </div> ; break;
	      	            case 'meeting' : return  <div><MeetingList AddModal={this.AddModal} prop_task_id={prop_task_id} prop_meeting_id={prop_meeting_id} passMeetingTabRefreshInstruction={this.state.passMeetingTabRefreshInstruction} passCommitteIdForAdvancedSearch={this.state.committeeidforsearch} filterSearchJson={this.state.committeeidforsearch}/></div> ; break;
	      	            default: return <div>Sorry, you do not have permission to view this page. </div>;
	      	         }

	      	     })()
	      	 }
	      	 </div>

	      </div>
      </div>
    );
  }
}

export default Index;
