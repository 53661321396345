import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import { FaTimes } from "react-icons/fa";
import AddCommitteeMembers from './AddCommitteeMembers';
import EmployeeRoles from './EmployeeRoles';
import CSLTable from '../Common/CSLTable';




const THR = styled.tr`
    background: rgb(242,242,242);
`;
const TDR = styled.tr`
    background: rgb(249,249,249);
`;
const ViewEmployeeRoles = styled.a `
    cursor:pointer;
	color : #04ADA8 ;
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;

class CommitteePersonelTable extends React.Component{
    constructor(props){
        super(props);
    }
    state = {
    	view: null
    }
    
    closeModal = () => {
        this.setState({view: null})
    }
    formatDate = (date) => {
		//console.log("date 1==>"+date);
		//console.log("date 2==>"+new Date());
        let day = date.getDate();
        let monthIndex = date.getMonth()+1;
        let year = date.getFullYear();

		if (day < 10) {
		  day = '0' + day;
		} 
		if (monthIndex < 10) {
		  monthIndex = '0' + monthIndex;
		}

        return day + '/' + monthIndex + '/' + year;
	}
	createMemberRows1 = (members) => {
		//console.log("members==>"+JSON.stringify(members));
		let table = [];
		for (let i = 0; i < members.length; i++){
			//console.log("employeename==>"+this.state.committeemembers[i].employeename+"<=employeename");
			if(typeof members[i].employeeid !='undefined'){
				let children = [];
				//table.push(<p>{members[i].committeerolename}&nbsp;&nbsp;{members[i].employeename}</p>);
				children.push(<td style={{padding: "6px"}}>{members[i].committeerolename}</td>);
				children.push(<td style={{padding: "6px"}}>{members[i].employeename}</td>);
				children.push(<td style={{padding: "6px"}}>{this.formatDate(new Date(members[i].assigneddate))}</td>);
				children.push(<td style={{padding: "6px"}}><ViewEmployeeRoles onClick={()=>this.openEmployeeRoles(members[i].employeeid,members[i].employeename)} employeeId={members[i].employeeid}>View</ViewEmployeeRoles></td>);

				table.push(<tr>{children}</tr>);

				//this.state.guestmembers.push({'employeeid':jsonobj.value,'employeename':jsonobj.label , 'duration':'Part' , 'attended' : 'Yes' });
			}

			
		}
        return table;
	}
    openEmployeeRoles = (employeeId,employeeName) => {
		Store.updateStore('Employee_Id', employeeId);
		Store.updateStore('Employee_Name', employeeName);
        Store.updateStore('EmployeeRolesOverlay', `${document.body.scrollHeight.toString()}px`);
        Store.updateStore('EmployeeRolesVisibility', true);
        this.setState({view: 'employeerole'})
    }
    processCommitteePersonalData = () => {

        let ret = {data: [], columns: []};
        let i = 0;

		ret.columns =[{Header: 'Role', accessor: 'committeerolename', width: '30%', headerStyle: {textAlign: 'left'}}, 
			          {Header: 'Name', accessor: 'employeename', width: '30%', headerStyle: {textAlign: 'left'}},
			          {Header: 'Assigned Date', accessor: 'assigneddate', width: '20%', headerStyle: {textAlign: 'left'}},
                      {'Header' : '', Cell: row => (
                        <div>
	                        {
	                            (() => {
									return (
										   <ViewEmployeeRoles onClick={()=>this.openEmployeeRoles(row.original.employeeid,row.original.employeename)} employeeId={row.original.employeeid}>View</ViewEmployeeRoles>
										)
	                            })()
	                        }
					

                        </div>
                      ), width: '20%', headerStyle: {textAlign: 'left'}},

		             ];
		let committeeMembers = this.props.committeeMembers;
        
		for (let i = 0; i < committeeMembers.length; i++){
			//console.log("employeename==>"+this.state.committeemembers[i].employeename+"<=employeename");
			if(typeof committeeMembers[i].employeeid !='undefined'){
				let elem = {'index' : i+1, 
							'employeeid' : committeeMembers[i].employeeid,
							'committeerolename' : committeeMembers[i].committeerolename,
							'employeename' : committeeMembers[i].employeename,
							'assigneddate' : this.formatDate(new Date(committeeMembers[i].assigneddate)),


						
						   };
				ret.data.push(elem);				
			}


			
		}
		
        
        return ret;
    }
    render(){
        return(
		
			<div>
				<AddCommitteeMembers showAddIcon={false} headerText={'Committee Personnel'}/>
				{
					(() => {
						if(this.state.view === 'employeerole'){
							return <EmployeeRoles closeModal={this.closeModal}/>
						}
					})()
				}
				<OverviewInfoBody>                                    
					 <CSLTable add={false} showPagination={false} processData={this.processCommitteePersonalData} refreshCallback={this.refreshCallback}  tableRows={1000} />                                              
				</OverviewInfoBody>
			</div>
        );
    }
}

export default CommitteePersonelTable;