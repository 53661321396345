import React from 'react';
import styled from 'styled-components';
import { FaTimes, FaPlusCircle } from 'react-icons/fa';
import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils.js';
import APICall from '../../Common/APICall';
import * as Style from '../Common/StyledComponents';
import DatePicker from 'react-date-picker';
import { saveAs } from 'file-saver';
import CSLLoaderNonPromise from '../Common/CSLLoaderNonPromise';
import Dropzone from '../Common/Dropzone.js';
import '../Common/Dropzone.css';
import HtmlEditor from '../Common/HtmlEditor';
import CommitteeUtils from '../Common/CommitteeUtils';
import moment from 'moment';
import CSLTable from '../Common/CSLTable';
import AlertBox from '../Common/AlertBox.js';



const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto;
    margin-top: 30px;    
`;

const MRModalLabel = styled.div`
    color: #a0a0a0;
    font-weight: 600;    
    margin-bottom: 6px;
    margin-top: 10px;
`;
const HeaderButton = styled.div`
    float: right;
    font-size: 20px;
    cursor: pointer;
`;

const FCKTextarea = styled.textarea`
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    /*font-weight: bold;*/
	font-family: Montserrat,sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    float: right;
    cursor: pointer;
	font-family: Montserrat,sans-serif;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    padding: 0px 20px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
	font-family: Montserrat,sans-serif;
`;

const MRModalSemiHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    /*padding: 20px 20px 10px 20px;*/
	background-color: grey;
`;

const MRModalInput = styled.input`
    height: 28px;
    width: 89% !important;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
	font-family: Montserrat,sans-serif;
`;
const RegModalContainertop = styled.div`
    display: grid;
    grid-template-columns: 10% calc(90% - 15px);
    grid-gap: 15px;    
`;
const RegModalNameLabelDiv = styled.div`
    height: auto;
    width: 500px;
    padding-top: 3px;
    font-weight: bold;
    /*color: #979797;*/
    color: #000000;
`;
const ActionSelect = styled.select`    
    display: inline-block;
    box-sizing: border-box;
    height: 35px;
    /*width: 900% !important;*/
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    margin-left: 14px;
	/*font-weight: bold;*/
`;


const CSLDateCover = styled.div`
    margin-top: 0px;
    width: 900% !important;
    border-radius: 3px;
    height: 34px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0 0 4px #c4c4c4;
	font-weight: bold;
    `;

/*  */
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 12px;
    font-weight: 600;
    height: 80px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151;  
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: rgb(243, 247, 251);
    padding: 10px;
    border-radius: 4px;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #DFDFDF;
	box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: 680px;
    width: 60%;
    position: absolute;
    top: -30px;
    z-index: 1004;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 20% !important;
`;
const FormSubmitButton = styled.button`
    color: #fff;
    background: linear-gradient(to top, #04ADA8 0%, #04ADA8 100%);
    box-shadow: inset 0px 1px 1px 0px #04ADA8;
    width: 116px;
    padding: 6px 16px;
    border: 1px solid #04ADA8;
    border-radius:3px;
    margin-right:10px;
`;

const FormCancelButton = styled.button`
    color: #000000;
    background: linear-gradient(to top, #FFFFFF 0%, #FFFFFF 100%);
    box-shadow: inset 0px 1px 1px 0px #FFFFFF;
    width: 116px;
    padding: 6px 16px;
    border: 1px solid grey;
    border-radius:3px;
    margin-right:10px;
`;

let committeeUtils = new CommitteeUtils();

function GetSortOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}
class AddActions extends React.Component {
    state = {
        showModal: false,
        alert_param: null,
        is_loader: false,
        binFiles: [],
        curBinFiles: [],
        subject: "",
        actionDescription: "",
        action_status: "",
        assignedTo: 0,
        action_note_undertaken: '',
        IsActionTitleProvided: false,
        IsActionAssignedToProvided: false,
        IsActionDescriptionProvided: false,
        IsActionProvided: false,
        is_form_modified: false,
        open_data_lost_warning_modal: false,
        due_date: new Date(),
        first_request: true,
        view_mode: false,
        task_id: 0,
        committeeId: 0,
        validate_user: {},
        is_pre_complete: false,
        roll_over_task_ref_id: '',
        assign_to_multiple_users: false,
        multiple_users_array: [],
        multiple_users_select_all_checked: false,
    }

    componentDidMount() {
        this.cleanHTMLEditor();
        let task_id = ('task_id' in this.props.action_extra_json) ? this.props.action_extra_json.task_id : this.state.task_id;
        // if(task_id !== this.state.task_id){
        let view_mode = ('view_mode' in this.props.action_extra_json) ? this.props.action_extra_json.view_mode : false;
        let desc_text = committeeUtils.changeStringToAtob(this.props.action_extra_json.action_description);
        if (view_mode === true) {
            this.setState({ committeeId: this.props.committeeId, due_date: new Date(this.props.action_extra_json.due_date), subject: this.props.action_extra_json.action_title, actionDescription: desc_text, assignedTo: this.props.action_extra_json.action_assignto, curBinFiles: this.props.action_extra_json.followupBinFiles, action_note_undertaken: this.props.action_extra_json.action_note_undertaken, IsActionTitleProvided: false, IsActionAssignedToProvided: false, is_form_modified: false, showModal: true, first_request: false, view_mode: true, task_id: task_id, roll_over_task_ref_id: this.props.action_extra_json.roll_over_task_ref_id });
        } else {
            if (task_id.toString() !== '0') {
				//console.log("NNNN,", desc_text);
				if(desc_text === null || desc_text === 'null')desc_text='<p></p>';
				Store.updateStore('actionDescription',desc_text);
                this.setState({ committeeId: this.props.committeeId, due_date: new Date(this.props.action_extra_json.due_date), subject: this.props.action_extra_json.action_title, actionDescription: desc_text, assignedTo: this.props.action_extra_json.action_assignto, curBinFiles: this.props.action_extra_json.followupBinFiles, action_note_undertaken: this.props.action_extra_json.action_note_undertaken, IsActionTitleProvided: false, IsActionAssignedToProvided: false, IsActionDescriptionProvided: false , is_form_modified: false, showModal: true, first_request: false, view_mode: false, task_id: task_id, roll_over_task_ref_id: this.props.action_extra_json.roll_over_task_ref_id });
            } else {
				//console.log("NNNN,", desc_text);
                this.setState({ committeeId: this.props.committeeId, due_date: new Date(), subject: "", actionDescription: "", action_status: "", assignedTo: 0, curBinFiles: [], IsActionTitleProvided: false, IsActionAssignedToProvided: false, is_form_modified: false, showModal: true, first_request: false, view_mode: false, task_id: 0, roll_over_task_ref_id: this.props.action_extra_json.roll_over_task_ref_id });
            }
        }
        // }

    }

    processUserrestriction = (committeeId) => {
        const committees = Store.getStoreData("filtered_committees");
        const user_assignments = Store.getStoreData("user_assignments");
        let single = {};
        for (let element in committees) {
            if (parseInt(committees[element].id) === parseInt(committeeId)) {
                single = committees[element];
            }
        }
        let exist_users = {}
        if (Object.keys(single).length > 0) {
            let json_data = single.json_data;
            if ("gc_id" in json_data) {
                if (parseInt(json_data.gc_id) > 0) {
                    for (let key in user_assignments) {
                        if (user_assignments[key].companies[json_data.gc_id]) {
                            exist_users[key] = 1;
                        }
                    }
                }

                if (parseInt(json_data.grouping_id) > 0) {
                    for (let key in user_assignments) {
                        if (user_assignments[key].tags[json_data.grouping_id][json_data.grouping_value_id]) {
                            exist_users[key] = 1;
                        }
                    }
                }

            }
        }
        return exist_users;

    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            console.log("in componentDidUpdate action", this.props.committeeId);

            /*
            let task_id = ('task_id' in this.props.action_extra_json) ? this.props.action_extra_json.task_id : this.state.task_id;
            if(task_id !== this.state.task_id){

                let view_mode = ('view_mode' in this.props.action_extra_json) ? this.props.action_extra_json.view_mode : false;
                let desc_text = committeeUtils.changeStringToAtob(this.props.action_extra_json.action_description);
                if(view_mode === true){
                    this.setState({committeeId : this.props.committeeId, due_date : this.props.action_extra_json.due_date, subject: this.props.action_extra_json.action_title , actionDescription : desc_text , assignedTo : this.props.action_extra_json.action_assignto , curBinFiles : this.props.action_extra_json.followupBinFiles , action_note_undertaken : this.props.action_extra_json.action_note_undertaken , IsActionTitleProvided : false , IsActionAssignedToProvided : false , is_form_modified : false,showModal: true, first_request : false , view_mode : true , task_id : task_id}, ()=>{this.props.actionModalOpen({'status' : true}); this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});});
                }else{
                    if(task_id.toString() !== '0'){
                        this.setState({committeeId : this.props.committeeId, due_date : this.props.action_extra_json.due_date, subject: this.props.action_extra_json.action_title , actionDescription : desc_text , assignedTo : this.props.action_extra_json.action_assignto , curBinFiles : this.props.action_extra_json.followupBinFiles , action_note_undertaken : this.props.action_extra_json.action_note_undertaken , IsActionTitleProvided : false , IsActionAssignedToProvided : false , is_form_modified : false,showModal: true, first_request : false , view_mode : false , task_id : task_id}, ()=>{this.props.actionModalOpen({'status' : true}); this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});});
                    }else{
                        this.setState({committeeId : this.props.committeeId, due_date : new Date(), subject: "" , actionDescription : "" , action_status : "" , assignedTo : 0 , curBinFiles : [] , IsActionTitleProvided : false , IsActionAssignedToProvided : false , is_form_modified : false,showModal: true, first_request : false , view_mode : false, task_id : 0}, ()=>{this.props.actionModalOpen({'status' : true}); this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});});
                    }
                }
            }
            this.cleanHTMLEditor();
            */
            //}

        }

    }
    getFormattedDate = (input_date) => {
        var date = new Date(input_date);
        var month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
        var dt = (date.getDate() < 10) ? '0' + (date.getDate()) : (date.getDate());
        return dt + '/' + month + '/' + date.getFullYear();
    }

    showAddAction = (index) => (event) => {
        //console.log("in show action");
        event.preventDefault();
        this.setState({ due_date: new Date(), subject: "", actionDescription: "", action_status: "", assignedTo: 0, curBinFiles: [], IsActionTitleProvided: false, IsActionAssignedToProvided: false, is_form_modified: false, showModal: true, first_request: true, view_mode: false, task_id: 0 }, () => { this.props.actionModalOpen({ 'status': true }); this.el.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" }); });
    }
    closeAddActionModal = (event) => {
        event.preventDefault();
        if (Store.getStoreData('ViewCommitteeTasksListVisibility') === true) {
            //this.props.closeCommitteeTaskListModal();
            //Store.updateStore('ViewCommitteeTasksListVisibility' , false);
        }

        //if(this.state.is_form_modified){
        //this.props.refreshParent('openDataLostWarningModal')
        //this.setState({open_data_lost_warning_modal : true});
        //}else{
        if (this.props.prop_task_id > 0) {
            if (document.referrer) {
                if (document.referrer.match(/tmview/g)) {
                    window.location.assign(document.referrer);
                } else {
                    window.location.href = '/';
                }
                //
            } else {
                window.location.href = '/';
            }
        } else {
            this.setState({ showModal: false, first_request: true, view_mode: false, task_id: 0 }, () => { this.props.actionModalOpen({ 'status': false, 'action_popup': "close" }) });
        }
        //}
    }

    refreshState = (val) => {
        if (val === null) {
            this.setState({ open_data_lost_warning_modal: false, showModal: false }, () => { this.props.actionModalOpen({ 'status': false }) });
        } else {
            this.setState({ open_data_lost_warning_modal: false }, () => { this.el.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" }); });
        }
    }


    processcontactrole = () => {
        var contacts = Store.getStoreData('contacts')
        var userrole = Store.getStoreData('contactrole')
        let employee_contact_assoc = Store.getStoreData('employee_contact_assoc');
        let committee_with_count = Store.getStoreData('committee_with_count');
        console.log("userrole", userrole.length)
        console.log("contacts", contacts.ID)
        console.log("committeeId", this.state.committeeId);
        console.log("committee_with_count", committee_with_count);

        let is_valid_users = this.processUserrestriction(this.props.committeeId);
		let committee_obj = committee_with_count.committees.find(c => 'PK_CommitteeID' in c && c.PK_CommitteeID.toString() === this.props.committeeId.toString());
		let committee_obj_parsed = JSON.parse(committee_obj.Json_Data);
        var contactmap = [];
        for (let i = 0; i < userrole.length; i++) {
            for (let j = 0; j < contacts.length; j++) {
                if ((userrole[i].user_id == contacts[j].ID) && (userrole[i].role == 'admin_manager' || userrole[i].role == 'governance_manager')) {
                    /*if (Object.keys(is_valid_users).length === 0) {
                        contactmap.push({ ID: contacts[j].ID, name: contacts[j].ContactName })
                    } else {
                        if (is_valid_users[contacts[j].ID]) {
                            contactmap.push({ ID: contacts[j].ID, name: contacts[j].ContactName });
                        }
                    }*/
					contactmap.push({ ID: contacts[j].ID, name: contacts[j].ContactName })
                }
				if((userrole[i].user_id == contacts[j].ID) && (userrole[i].role == 'governance_manager')){
                    //if (Object.keys(is_valid_users).length === 0) {
                    //    contactmap.push({ ID: contacts[j].ID, name: contacts[j].ContactName })
                    //} else {
                        if (is_valid_users[contacts[j].ID]) {
                            contactmap.push({ ID: contacts[j].ID, name: contacts[j].ContactName });
                        }
                    //}
				}
				if((userrole[i].user_id == contacts[j].ID) && (userrole[i].role == 'team') && typeof committee_obj !== 'undefined'){
					let select_user_obj = committee_obj_parsed.committeemembers.find(c => 'employeeid' in c && contacts[j].ID.toString() in  employee_contact_assoc && 'employee_id' in employee_contact_assoc[contacts[j].ID.toString()] && c.employeeid.toString() === employee_contact_assoc[contacts[j].ID.toString()].employee_id.toString());
					if(typeof select_user_obj !== 'undefined')contactmap.push({ ID: contacts[j].ID, name: contacts[j].ContactName });
				}
            }
        }
		contactmap = contactmap.filter((li, idx, self) => self.map(itm => itm.ID+itm.name).indexOf(li.ID+li.name) === idx);//remove duplicates if any
        console.log("contactmap===>", contactmap)
        return contactmap;
    }

    submitAddActionValue = (event) => {
		event.preventDefault();
		let actionDescription = Store.getStoreData('actionDescription');
		let multiple_users_array = this.state.multiple_users_array;
		let IsActionTitleProvided = this.state.IsActionTitleProvided;
		let IsActionAssignedToProvided = this.state.IsActionAssignedToProvided;
		let IsActionDescriptionProvided = this.state.IsActionDescriptionProvided;
		console.log("submitAddActionValue,",actionDescription,this.state.actionDescription);
		if(this.state.assign_to_multiple_users){
			if(multiple_users_array.length === 0){
				let alert_param = {title: 'Alert', message: 'Please assign user(s) for this task.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.processAlert, stack: {} , continue_to_next_screen_if_yes: false };

				this.setState({alert_param: alert_param});
				return false;
			}
		}else{
			if(this.state.assignedTo.toString() === '0' || this.state.assignedTo === ""){
				IsActionAssignedToProvided = true;
			}else{
				IsActionAssignedToProvided = false;
			}
		}
        if (this.state.subject.trim() === '' ) {
            if(this.state.subject.trim() === ''){
				IsActionTitleProvided = true;
            }else{
				IsActionTitleProvided = false;
            }
            /*if(this.state.assignedTo.toString() === '0' || this.state.assignedTo === ''){
				IsActionAssignedToProvided = true;
            }else{
				IsActionAssignedToProvided = false;
            }*/
            /*if(this.state.actionDescription === '<p></p>' ||  this.state.actionDescription === '<p>.</p>' ||  actionDescription === null){
				IsActionDescriptionProvided = true;
            }else{
				IsActionDescriptionProvided = false;
            }*/
			actionDescription = (actionDescription === null) ? actionDescription = '<p></p>': actionDescription;

			this.setState({ IsActionTitleProvided ,IsActionAssignedToProvided,IsActionDescriptionProvided , actionDescription});

		} else {

            var bin_files = this.state.curBinFiles;
            let task_id = (this.state.task_id === 0) ? Utils.genKey(10) : this.state.task_id;
            //this.props.refreshAddActionValue(this.state.action_title,this.state.actionDescription,this.state.assignedTo,this.state.binFiles)
            this.props.refreshAddActionValue(this.state.subject, actionDescription, this.state.assignedTo, bin_files, this.state.action_status, this.state.due_date, this.state.action_note_undertaken, task_id,this.state.assign_to_multiple_users,multiple_users_array)
            this.setState({ subject: "", actionDescription: "", assignedTo: 0, binFiles: [], showModal: false, IsActionTitleProvided: false, IsActionAssignedToProvided: false, task_id: 0 }, () => { this.props.actionModalOpen({ 'status': false }) });

        }
    }
    completeTask = (event) => {
        event.preventDefault();
        if (this.state.action_note_undertaken.trim() === '') {
            this.setState({ IsActionProvided: true });


        } else {

            this.setState({ is_loader: true });

            setTimeout(() => {
                this.preProcessCompleteTask();
            }, 30);


        }
    }
    preProcessCompleteTask = () => {
        for (let i = 0; i < this.props.tasks_list.length; i++) {
            if (this.state.task_id.toString() === this.props.tasks_list[i].id.toString()) {
                let task_json = JSON.parse(this.props.tasks_list[i].task_json);
                let action_json = task_json.object_data.headerinfo.action_json;
                let actionDescription_text = Store.getStoreData('actionDescription');
                if (actionDescription_text === null) {
                    actionDescription_text = this.state.actionDescription;
                }
                let actionDescription_value = btoa(window.encodeURIComponent(actionDescription_text));
                task_json.object_data.headerinfo.action_json.action_description = actionDescription_value;
                task_json.object_data.headerinfo.action_json.action_note_undertaken = this.state.action_note_undertaken;
				task_json.object_data.headerinfo.action_json.actionBinFiles = this.state.curBinFiles;

                task_json.task_data = { "module_id": "committees", "last_action": "COMP_COMMITTEES_COMPLETE", "actiondate": Date.now(), 'task_type': 'COMP_COMMITTEES_COMPLETE', 'cur_lane': 'COMP_COMMITTEES_COMPLETE', completed_date: Date.now() };
                var action_data = { action: 'COMP_COMMITTEES_COMPLETE', actiondate: Date.now(), assigned_to: Store.getStoreData('loggedin_user_info').ID, cur_lane: 'COMP_COMMITTEES_COMPLETE', performed_by: Store.getStoreData('loggedin_user_info').ID };
                if (Store.getStoreData('ViewCommitteeTasksListVisibility')) {
                    action_data = { action: 'COMP_COMMITTEES_COMPLETE', actiondate: Date.now(), assigned_to: action_json.action_assignto, cur_lane: 'COMP_COMMITTEES_COMPLETE', performed_by: Store.getStoreData('loggedin_user_info').ID };
                }
                task_json.action_data = action_data;
                var task_data = {};
                task_data.task_json = task_json;
                task_data.id = this.state.task_id;
                task_data.performed_by = Store.getStoreData('loggedin_user_info').ID;
				//console.log("preProcessCompleteTask,",task_data.task_json.object_data.headerinfo.action_json);

                let postData = { "command": "updateFollowupTask", "task_id": task_data.id, "task_json": task_data.task_json, "assigned_to": task_data.assigned_to };
                let api = new APICall();
                //api.command(postData, this.processSaveCompleteAction);
                let that = this;
                api.command(postData, function callback(result) { that.processCompleteTask(task_data.id, that.state.subject, that.state.assignedTo, that.state.due_date, that.state.action_note_undertaken , actionDescription_value) });
            }
        }
    }
    processCompleteTask = (task_id, subject, assignedTo, due_date, action_note_undertaken, actionDescription_value) => {
        let that = this;

        if (this.props.prop_task_id > 0) {
            if (document.referrer) {
                if (document.referrer.match(/tmview/g)) {
                    window.location.assign(document.referrer);
                } else {
                    window.location.href = '/';
                }
                //
            } else {
                window.location.href = '/';
            }
        } else {
            this.setState({ is_loader: false, showModal: false, first_request: true, view_mode: false, task_id: 0 }, () => { this.props.actionModalOpen({ 'status': false, task_id: task_id, task_status: 'complete', subject: subject, assignedTo: assignedTo, due_date: due_date , action_note_undertaken: action_note_undertaken , actionDescription_value}) });
        }

    }
    saveTask = (event) => {
        event.preventDefault();
		console.log("KKKK,",isNaN(this.state.task_id));
		if(isNaN(this.state.task_id)){
			this.submitAddActionValue(event);
		}else{
			this.setState({ is_loader: true });

			setTimeout(() => {
				this.preProcessSaveTask();
			}, 30);
		}
		return;

    }
    preProcessSaveTask = () => {
        for (let i = 0; i < this.props.tasks_list.length; i++) {
            if (this.state.task_id.toString() === this.props.tasks_list[i].id.toString()) {
                let task_json = JSON.parse(this.props.tasks_list[i].task_json);
                let action_json = task_json.object_data.headerinfo.action_json;
                task_json.object_data.headerinfo.action_json.action_title = this.state.subject;
                let actionDescription_text = Store.getStoreData('actionDescription');
                if (actionDescription_text === null) {
                    actionDescription_text = this.state.actionDescription;
                }
                let actionDescription_value = btoa(window.encodeURIComponent(actionDescription_text));
                task_json.object_data.headerinfo.action_json.action_description = actionDescription_value;
                task_json.object_data.headerinfo.action_json.action_assignto = this.state.assignedTo;
                task_json.object_data.headerinfo.action_json.due_date = this.state.due_date;
                task_json.object_data.headerinfo.action_json.actionBinFiles = this.state.curBinFiles;
                task_json.task_data = { "module_id": "committees", "last_action": "COMP_COMMITTEES_ASSIGNMENT", "actiondate": Date.now(), 'task_type': 'COMP_COMMITTEES', 'cur_lane': 'COMP_COMMITTEES_SAVED', completed_date: Date.now() };
                var action_data = { action: 'COMP_COMMITTEES_SAVED', actiondate: Date.now(), assigned_to: this.state.assignedTo, cur_lane: 'COMP_COMMITTEES_SAVED', performed_by: Store.getStoreData('loggedin_user_info').ID };
                if (Store.getStoreData('ViewCommitteeTasksListVisibility')) {
                    action_data = { action: 'COMP_COMMITTEES_SAVED', actiondate: Date.now(), assigned_to: this.state.assignedTo, cur_lane: 'COMP_COMMITTEES_SAVED', performed_by: Store.getStoreData('loggedin_user_info').ID };
                }
                task_json.action_data = action_data;
                var task_data = {};
                task_data.task_json = task_json;
                task_data.id = this.state.task_id;
                task_data.performed_by = Store.getStoreData('loggedin_user_info').ID;

                let postData = { "command": "updateFollowupTask", "task_id": task_data.id, "task_json": task_data.task_json, "assigned_to": task_data.assigned_to };
                let api = new APICall();
                //api.command(postData, this.processSaveCompleteAction);
                let that = this;
                api.command(postData, function callback(result) { that.processSaveTask(task_data.id, that.state.subject, that.state.assignedTo, that.state.due_date, that.state.action_note_undertaken,actionDescription_value) });
            }
        }
    }
    processSaveTask = (task_id, subject, assignedTo, due_date, action_note_undertaken,actionDescription_value) => {
        let that = this;

        if (this.props.prop_task_id > 0) {
            if (document.referrer) {
                if (document.referrer.match(/tmview/g)) {
                    window.location.assign(document.referrer);
                } else {
                    window.location.href = '/';
                }
                //
            } else {
                window.location.href = '/';
            }
        } else {
            this.setState({ is_loader: false, showModal: false, first_request: true, view_mode: false, task_id: 0 }, () => { this.props.actionModalOpen({ 'status': false, task_id: task_id, task_status: 'incomplete', subject: subject, assignedTo: assignedTo, due_date: due_date, action_note_undertaken: action_note_undertaken , actionDescription_value}) });
        }

    }
    refreshPage = () => {
        window.location.reload();
    }
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value, is_form_modified: true });
    }
    onScheduleDateChange = (date) => {
        this.setState({ due_date: date });
    }
    filesLoaded = (jsonObj) => {
        let me = Store.getStoreData('loggedin_user_info');
        let files_to_show = JSON.parse(JSON.stringify(jsonObj.files));
        let curBinFiles = this.state.curBinFiles;

        let file_exists = {};
        let curBinFiles_1 = [];

        if (jsonObj.command === 'add') {
            for (let i = 0; i < files_to_show.length; i++) {
                if (typeof files_to_show[i].name !== "undefined") {
                    files_to_show[i].name = jsonObj.files[i].name.replace('&', '');
                    if (!('user_id' in files_to_show[i])) {
                        files_to_show[i].user_id = me.ID;
                        files_to_show[i].upload_date = Date.now();
                        files_to_show[i].initial_type = "testing_activity_resource";
                    } else {
                        files_to_show[i].initial_type = "testing_activity_resource";
                    }
                    if (typeof file_exists[files_to_show[i].name] === "undefined") {
                        curBinFiles.push(files_to_show[i]);
                        file_exists[files_to_show[i].name] = true;
                    }
                }

            }
            curBinFiles_1 = curBinFiles.filter((curBinFiles, index, self) => index === self.findIndex((t) => (t.name === curBinFiles.name && t.uid === curBinFiles.uid)));
            this.setState({ curBinFiles: curBinFiles_1, is_form_modified: true });

        } else {//delete
			console.log("filesRemoved jsonObj,",jsonObj)
            for (let i = 0; i < curBinFiles.length; i++) {
                if (curBinFiles[i].uid.toString() === jsonObj.deleted_uid.toString()) {
                    curBinFiles.splice(i, 1);
                }
            }
			console.log("filesRemoved jsonObj,",curBinFiles)
            this.setState({ curBinFiles: curBinFiles });
        }
    }

	filesRemoved = (jsonObj) => {

		if(jsonObj.files_to_show_size === 0){
			this.setState({curBinFiles: [] , is_form_modified : false});
		}
	}

    handleDownload = (index) => {
		console.log("curBinFiles,",this.state.curBinFiles,index);
		let cur_file = null;
		for(let p of this.state.curBinFiles){
			if(p.uid.toString() === index.toString())cur_file = p;break;
		}
        //let cur_file = this.state.curBinFiles[index];
        let dnld = cur_file.bin_file.replace(/ /g, '+');
        // console.log("binary file before download:", dnld);
        var binaryString = window.atob(dnld);
        // console.log('binaryString', binaryString);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        var blob = new Blob([bytes]);
        saveAs(blob, cur_file.name);
    }
    preCompleteTask = () => {
        this.setState({ is_pre_complete: true });
		//this.el.scrollIntoView({behavior: "smooth", block: "end"});
    }
    cleanHTMLEditor = () => {
        Store.updateStore('actionDescription', null);
    }
	assignToMultipleUsersCheckboxChange = (event) =>{
		event.persist();
		let assign_to_multiple_users = event.target.checked;
		this.setState({assign_to_multiple_users});
	}
	processUserData = () => {
		let multiple_users_array = this.state.multiple_users_array;
		let table_data = this.processcontactrole();
		table_data.sort(GetSortOrder("name"));
		//console.log("table_data,",table_data);
		let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{'Header' : <div><input type="checkbox" checked={this.state.multiple_users_select_all_checked} onClick={this.selectAllUsers}/></div>, Cell: row => (
            <div>
                <input type="checkbox" checked={row.original.checked} value={row.original.ID} onClick={this.selectUser}/>
            </div>
         ), width: 60, filterable: false, sortable: false, headerStyle: {textAlign: 'center'}, style: {textAlign: 'center'}},
        {Header: 'Name', accessor: 'name', minWidth: 100, headerStyle: {textAlign: 'left'}},
        //{Header: 'Company', accessor: 'company_name', minWidth: 100, headerStyle: {textAlign: 'left'}},
        //{Header: 'Role', accessor: 'role', minWidth: 80, headerStyle: {textAlign: 'left'}},
        //{Header: 'Email', accessor: 'email', minWidth: 100, headerStyle: {textAlign: 'left'}}
		];

		if (Store.getStoreData('parent_has_group_companies') === false) {
			ret.columns.splice(2,1);
		}
		for(let d of table_data) {
			if(d.role === 'no_access')continue;
			let checked = ('checked' in d) ? d.checked : false;
			if(multiple_users_array.includes(d.ID.toString()))checked=true;
			d.checked = checked;
			//d.ID = d.ID;
            ret.data.push(d);
        }
		console.log("table_data,",ret.data);
        return ret;
	}
   selectUser = (event) => {
	    event.preventDefault();
        let checked_id = event.target.value;
		console.log("checked_id,",checked_id);
        let multiple_users_array = this.state.multiple_users_array;
		let checked = event.target.checked;
		console.log("selectUser checked,",checked,multiple_users_array);
		//return;
		if(!checked){
			let updated_multiple_users_array = [];;
			for(let p of multiple_users_array){
				if(p.toString() === checked_id.toString())continue;
				updated_multiple_users_array.push(p);
			}
			multiple_users_array = updated_multiple_users_array;

		}else{
			multiple_users_array.push(checked_id);
		}
        this.setState({multiple_users_array});
    }
	selectAllUsers = (event) => {
		let multiple_users_array = this.state.multiple_users_array;
		let multiple_users_select_all_checked = (this.state.multiple_users_select_all_checked) ? false : true;
	    event.persist();

		if(multiple_users_select_all_checked){
			let contacts = this.processcontactrole();
			multiple_users_array = [];
			for(let t of contacts){
				if(t.role === 'no_access')continue;
				multiple_users_array.push(t.ID.toString());
			}
		}else{
			multiple_users_array = [];
		}
		this.setState({multiple_users_array,multiple_users_select_all_checked});
	}
	processAlert = (result , stack) => {
		this.setState({alert_param: null,disabled_button: false});

		if(result === false) return;
	}
    render() {
        let overview_bgcolor = (typeof this.props.overviewBgColor !== 'undefined') ? this.props.overviewBgColor : '#26ADA7';
        let plus_circle_bgcolor = (typeof this.props.overviewBgColor !== 'undefined') ? this.props.overviewBgColor : '#ffffff';
        console.log("AddActions state:", this.state);
        console.log("AddActions props:", this.props);
        console.log("raw_meetings_list:", Store.getStoreData('raw_meetings_list'));
		let meeting_date = Store.getStoreData('Meeting_Date_For_Task');
		let company_users = Store.getStoreData('company_users');
		if (typeof this.state.roll_over_task_ref_id !== 'undefined' && this.state.roll_over_task_ref_id !== ''){
			let raw_meetings_list = Store.getStoreData('raw_meetings_list');
			let a = raw_meetings_list.filter(c => c.task_ref_id === this.state.roll_over_task_ref_id);
			let c = a.sort(function(a, b) {
				return b.PK_CommitteeMettingID - a.PK_CommitteeMettingID;
			});
			meeting_date = c[0].MettingDate;
			console.log("raw_meetings_list:", c);
		}

        return (<div>
            {
                (() => {
                    if (this.state.showModal) {
                        return (<div >
                            <MRModalContainer>
                                <MRModalHeader>
                                    {
                                        (() => {
                                            if (this.state.view_mode) {
                                                return (
                                                    <MRModalHeaderText>View follow-up task</MRModalHeaderText>
                                                )
                                            } else {
                                                if (this.state.task_id.toString() !== '0') {
                                                    return (
                                                        <MRModalHeaderText>Edit follow-up task</MRModalHeaderText>
                                                    )
                                                } else {
                                                    return (
                                                        <MRModalHeaderText>Add follow-up task</MRModalHeaderText>
                                                    )
                                                }
                                            }
                                        })()
                                    }
                                    <MRModalHeaderCloseBtn onClick={this.closeAddActionModal}><FaTimes /></MRModalHeaderCloseBtn>
                                    <div style={{ clear: "both" }}></div>
                                </MRModalHeader>
                                <div style={{ clear: "both" }} ></div>
                                <MRModalSemiHeader style={{ paddingLeft: "20px", color: "#FFFFFF", fontWeight: "500" }}>
                                    {
                                        (() => {
                                            if (typeof this.state.roll_over_task_ref_id !== 'undefined' && this.state.roll_over_task_ref_id !== '') return (<div>This task has been carried over from a previous meeting record.</div>)
                                        })()
                                    }
                                    <div>Committee Name: {Store.getStoreData('Committee_Name_For_Task')}</div>
                                    <div>Meeting Date: {this.getFormattedDate(meeting_date)}</div>
                                </MRModalSemiHeader>
                                <div style={{ clear: "both" }} ></div>
                                <MRModalBody style={{maxHeight: "570px" ,overflowY: "scroll"}}>
                                    {
                                        (() => {
                                            if (this.state.is_loader) {
                                                return <div><CSLLoaderNonPromise /></div>
                                            }

                                        })()
                                    }
                                    <div style={{ clear: "both" }} ></div>
                                    {/*<RegModalContainertop>*/}
                                    {/*<RegModalNameLabelDiv>Subject</RegModalNameLabelDiv>*/}
                                    <RegModalNameLabelDiv style={{ marginLeft: "30px" }}>Task Name<label style={{ color: "#FF0000" }}>* </label></RegModalNameLabelDiv>
                                    <div style={{ clear: "both", marginTop: "8px" }}></div>
                                    {
                                        (() => {
                                            if (this.state.view_mode) {
                                                return (
                                                    <div style={{ marginLeft: "27px", width: "90%" }} >
                                                        {this.state.subject}
                                                    </div>
                                                );
                                            } else {
                                                if (this.state.task_id > 0) {
                                                    if (this.state.is_pre_complete) {
                                                        return (
                                                            <div style={{ marginLeft: "27px", width: "90%" }} >
                                                                {this.state.subject}
                                                            </div>
                                                        );
                                                    } else {
                                                        return (
                                                            <MRModalInput style={{marginLeft: "25px", color: "#000000" }} name="subject" onChange={this.handleChange} value={this.state.subject} />
                                                        );
                                                    }

                                                } else {
                                                    return (
                                                        <MRModalInput style={{ marginLeft: "25px", color: "#000000" }} name="subject" onChange={this.handleChange} value={this.state.subject} />
                                                    )
                                                }
                                            }
                                        })()
                                    }

                                    {
                                        (() => {
                                            if (this.state.IsActionTitleProvided) {
                                                return (
                                                    <div style={{ width: "200px", marginLeft: "30px", marginTop: "5px" }}>
                                                        <div style={{ clear: "both" }}></div>
                                                        <label style={{ color: "#FF0000" }}>Please enter subject</label>
                                                    </div>
                                                )
                                            }
                                        })()
                                    }
                                    <div style={{ marginTop: "20px" }}>
                                        <RegModalNameLabelDiv style={{ marginLeft: "30px" }}>Note</RegModalNameLabelDiv>
                                        <div style={{ clear: "both", marginTop: "8px" }}></div>
                                        {
                                            (() => {
                                                if (this.state.view_mode) {
                                                    return (
                                                        <div style={{ marginLeft: "27px", width: "90%" ,wordWrap: "break-word" , maxWidth: "80%" }} dangerouslySetInnerHTML={{ __html: this.state.actionDescription }}>
                                                        </div>
                                                    )
                                                } else {
                                                    if (this.state.task_id > 0) {
                                                        if (this.state.is_pre_complete) {
                                                            return (
                                                                <div style={{ marginLeft: "27px", width: "90%" ,wordWrap: "break-word" , maxWidth: "80%"}} dangerouslySetInnerHTML={{ __html: this.state.actionDescription }}>
                                                                </div>
                                                            );
                                                        } else {
                                                            //return(
                                                            {/*<FCKTextarea rows="6" style={{width: "500px" ,marginLeft: "25px" , resize: "none"}} name="actionDescription" onChange={this.handleChange} value={this.state.actionDescription}/>*/ }
                                                            //	<div style={{marginLeft: "25px"}}><HtmlEditor content={this.state.actionDescription} field_name={'actionDescription'}/></div>
                                                            //	);
                                                            return (
                                                                <div style={{ marginLeft: "27px", marginLeft: "25px", width: "calc(90%)" }}><HtmlEditor content={this.state.actionDescription} field_name={'actionDescription'} /></div>
                                                            );
                                                        }

                                                    } else {
                                                        return (
                                                            <div style={{ marginLeft: "27px", width: "calc(90%)" }}><HtmlEditor content={this.state.actionDescription} field_name={'actionDescription'} /></div>
                                                        );
                                                    }
                                                }
                                            })()
                                        }
                                    {
                                        (() => {
                                            if (this.state.IsActionDescriptionProvided) {
                                                return (
                                                    <div style={{ width: "200px", marginLeft: "30px", marginTop: "5px" }}>
                                                        <div style={{ clear: "both" }}></div>
                                                        <label style={{ color: "#FF0000" }}>Please enter note</label>
                                                    </div>
                                                )
                                            }
                                        })()
                                    }
                                    </div>
									<div style={{ clear: "both" , marginTop: "20px" }}></div>
                                    {
                                        (() => {
											if (!this.state.view_mode) {
												if (this.state.task_id === 0) {
													return(
														<div style={{width: "250px"}}>
															<RegModalNameLabelDiv style={{ marginLeft: "30px" ,float: "left" }}>Assign to Multiple Users</RegModalNameLabelDiv>
															<div style={{ float: "right" , marginTop: "-20px"}}><input type="checkbox" checked={this.state.assign_to_multiple_users} onChange={this.assignToMultipleUsersCheckboxChange}/></div>
														</div>
													);
												}

											}
                                        })()
                                    }
									<div style={{ clear: "both" , marginTop: "20px" }}></div>
                                    {
                                        (() => {
											if(this.state.assign_to_multiple_users){
												return(
													<div style={{ paddingTop: "20px" }}>
														<RegModalNameLabelDiv style={{ marginLeft: "30px" ,float: "left" }}>Users</RegModalNameLabelDiv>
														<div style={{ marginLeft: "135px" , width: "75%" }}>
															<CSLTable add={false} processData={this.processUserData} headerText={'Assign Multiple Users'} tableRows={10}/>
														</div>
													</div>
												);
											}
                                        })()
                                    }
									<div style={{ clear: "both" , marginTop: "20px" }}></div>
									{
										(() => {
											if(!this.state.assign_to_multiple_users){
												return (
													<div style={{ marginTop: "20px" }}>
														<RegModalNameLabelDiv style={{ marginLeft: "30px" }}>Assigned To<label style={{ color: "#FF0000" }}>* </label></RegModalNameLabelDiv>
														<div style={{ clear: "both", marginTop: "8px" }}></div>
														{
															(() => {
																if (this.state.view_mode) {
																	return (
																		<div style={{ marginLeft: "27px" , width: "90%" }}>
																			{Store.getStoreData('contact_list')[this.state.assignedTo]}
																		</div>
																	)
																} else {
																	if (this.state.task_id > 0) {
																		if (this.state.is_pre_complete) {
																			return (
																				<div style={{ marginLeft: "27px" , width: "90%"}}>
																					{Store.getStoreData('contact_list')[this.state.assignedTo]}
																				</div>
																			);
																		} else {
																			return (
																				<ActionSelect style={{ marginLeft: "25px" , width: "90%"}} name="assignedTo" onChange={this.handleChange} value={this.state.assignedTo}>
																					{
																						(() => {
																							let contactsdropdown = [];
																							let contacts = this.processcontactrole();
																							contacts.sort(GetSortOrder("name"));
																							contactsdropdown.push(<option key={0} value={'0'}>Select User</option>)
																							contacts.forEach((item) => {
																								let contact_name = (company_users[item.ID.toString()].IsActive===true) ? item.name: item.name+' (Inactive)';
																								contactsdropdown.push(<option key={item.ID} value={item.ID}>{contact_name}</option>)
																							})
																							return contactsdropdown;
																						})()
																					}
																				</ActionSelect>
																			);

																		}

																	} else {
																		return (
																			<ActionSelect style={{ marginLeft: "25px" , width: "90%"}} name="assignedTo" onChange={this.handleChange} value={this.state.assignedTo}>
																				{
																					(() => {
																						let contactsdropdown = [];
																						let contacts = this.processcontactrole();

																						contacts.sort(GetSortOrder("name"));
																						contactsdropdown.push(<option key={0} value={0}>Select User</option>)
																						contacts.forEach((item) => {
																							contactsdropdown.push(<option key={item.ID} value={item.ID}>{item.name}</option>)
																						})
																						return contactsdropdown;
																					})()
																				}
																			</ActionSelect>
																		)
																	}
																}
															})()
														}

														{
															(() => {
																if (this.state.IsActionAssignedToProvided) {
																	return (
																		<div style={{ width: "200px", marginLeft: "30px", marginTop: "14px" }}>
																			<div style={{ clear: "both" }}></div>
																			<label style={{ color: "#FF0000" }}>Please select a user.</label>
																		</div>
																	)
																}
															})()
														}
													</div>
												);
											}

										})()
									}
                                    <RegModalContainertop style={{ marginTop: "20px" }}>
                                        <RegModalNameLabelDiv style={{ marginLeft: "30px" }}>Due Date</RegModalNameLabelDiv>
                                        <div style={{ clear: "both", marginTop: "8px" }}></div>
                                        {
                                            (() => {
                                                if (this.state.view_mode) {
                                                    let due_date_moment = moment(this.state.due_date);
                                                    let due_date_year = due_date_moment.format('YYYY');
                                                    let due_date_month = due_date_moment.format('MM');
                                                    let due_date_day = due_date_moment.format('DD');
                                                    let due_date = `${due_date_day}/${due_date_month}/${due_date_year}`;
                                                    return (
                                                        <div style={{ marginLeft: "27px" }}>
                                                            {due_date}
                                                        </div>
                                                    );
                                                } else {
                                                    if (this.state.task_id > 0) {
                                                        if (this.state.is_pre_complete) {
                                                            let due_date_moment = moment(this.state.due_date);
                                                            let due_date_year = due_date_moment.format('YYYY');
                                                            let due_date_month = due_date_moment.format('MM');
                                                            let due_date_day = due_date_moment.format('DD');
                                                            let due_date = `${due_date_day}/${due_date_month}/${due_date_year}`;
                                                            return (
                                                                <div style={{ marginLeft: "27px" }}>
                                                                    {due_date}
                                                                </div>
                                                            );
                                                        } else {
                                                            return (
                                                                <CSLDateCover style={{ display: "inline-block", marginLeft: "27px"}}>
                                                                    <DatePicker
                                                                        onChange={this.onScheduleDateChange}
                                                                        clearIcon={null}
                                                                        calendarIcon={null}
                                                                        locale={"en-GB"}
                                                                        value={this.state.due_date}
                                                                        style={{ fontWeight: "200" }}
                                                                    />
                                                                </CSLDateCover>
                                                            )
                                                        }
                                                    } else {
                                                        return (
                                                            <CSLDateCover style={{ display: "inline-block", marginLeft: "27px"}}>
                                                                <DatePicker
                                                                    onChange={this.onScheduleDateChange}
                                                                    clearIcon={null}
                                                                    calendarIcon={null}
                                                                    locale={"en-GB"}
                                                                    value={this.state.due_date}
                                                                    style={{ fontWeight: "200" }}
                                                                />
                                                            </CSLDateCover>
                                                        )
                                                    }
                                                }
                                            })()
                                        }

                                    </RegModalContainertop>
                                    <RegModalContainertop style={{ marginTop: "20px" }}>
                                        <RegModalNameLabelDiv style={{ marginLeft: "30px" }}>Attachment(s)</RegModalNameLabelDiv>
                                        <div style={{ width: "50%" }}>
                                            <div style={{ padding: "10px" }}>
                                                <div style={{ marginLeft: "-60px" }}>
                                                    {
                                                        (() => {
                                                            let status = (this.state.view_mode) ? false : true;
                                                            return <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.curBinFiles} height={"200px"} onRemoveFile={this.filesRemoved} showFileButton={status} handleDownload={this.handleDownload} removable={status} />
                                                        })()
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </RegModalContainertop>

                                    <div style={{ clear: "both" }} ></div>
                                    <div style={{ marginTop: "20px" }}>
                                        {
                                            (() => {
                                                if (this.state.view_mode) {
                                                    return (
                                                        <div>
                                                            <RegModalNameLabelDiv style={{ marginLeft: "30px" }}>Comments</RegModalNameLabelDiv>
                                                            <div style={{ clear: "both", marginTop: "8px" }}></div>
                                                            <div style={{ marginLeft: "27px" ,wordWrap: "break-word" , maxWidth: "80%"}}>
                                                                {this.state.action_note_undertaken}
                                                            </div>
                                                        </div>

                                                    );
                                                } else {
                                                    if (this.state.task_id > 0 && this.state.is_pre_complete) {
                                                        return (
                                                            <div>
                                                                <RegModalNameLabelDiv style={{ marginLeft: "30px" }}>Please provide completion comments for this task </RegModalNameLabelDiv>
                                                                <div style={{ clear: "both", marginTop: "8px" }} ></div>
                                                                <FCKTextarea rows="6" style={{ width: "90%", marginLeft: "25px", resize: "none" }} name="action_note_undertaken" onChange={this.handleChange} value={this.state.action_note_undertaken} />
                                                            </div>
                                                        )
                                                    }
                                                }

                                            })()
                                        }

                                        {
                                            (() => {
                                                if (this.state.IsActionProvided) {
                                                    return (
                                                        <div style={{ width: "200px", marginLeft: "120px", marginTop: "5px" }}>
                                                            <div style={{ clear: "both" }}></div>
                                                            <label style={{ color: "#FF0000" }}>Please provide comments</label>
                                                        </div>
                                                    )
                                                }
                                            })()
                                        }
                                        <div style={{ clear: "both" }} ></div>
                                    </div>



                                </MRModalBody>
                                <MRModalFooter>
                                    <div style={{ textAlign: "right", marginTop: "20px" }}>
                                        {
                                            (() => {
                                                if (this.state.view_mode) {
                                                    return (<div>&nbsp;</div>)
                                                } else {
                                                    if (parseInt(this.state.task_id) > 0) {
                                                        if (this.state.is_pre_complete) {
                                                            return (
                                                                <div>
                                                                    <FormSubmitButton style={{ background: "#26ADA7", border: "1px solid #26ADA7", float: "right", width: "150px" }} onClick={this.completeTask}>Submit</FormSubmitButton>
                                                                </div>
                                                            );
                                                        } else {
                                                            return (
                                                                <div>
																	{
																		(() => {
																			if(!isNaN(this.state.task_id))return <FormSubmitButton style={{ background: "#26ADA7", border: "1px solid #26ADA7", float: "right", width: "150px" }} onClick={this.preCompleteTask}>Complete</FormSubmitButton>;
																		})()
																	}

                                                                    <FormSubmitButton style={{ background: "linear-gradient(to top, #1E3E62 0%, #1E3E62 100%)", boxShadow: "inset 0px 1px 1px 0px #1E3E62", border: "1px solid #1E3E62", float: "right", width: "150px" }} onClick={this.saveTask}>Update</FormSubmitButton>
                                                                </div>
                                                            );
                                                        }

                                                    } else {
														if(isNaN(this.state.task_id)){
															return <FormSubmitButton style={{ background: "linear-gradient(to top, #1E3E62 0%, #1E3E62 100%)", boxShadow: "inset 0px 1px 1px 0px #1E3E62", border: "1px solid #1E3E62", float: "right", width: "150px" }} onClick={this.saveTask}>Update</FormSubmitButton>
														}else{
															return <FormSubmitButton style={{ backgroundColor: "#26ADA7", border: "1px solid #26ADA7", float: "right" }} onClick={this.submitAddActionValue}>Add Task</FormSubmitButton>;
														}

                                                    }
                                                }
                                            })()
                                        }

                                        <FormCancelButton onClick={this.closeAddActionModal}>Cancel</FormCancelButton>
                                    </div>
									<div style={{clear: "both"}} ></div>
                                </MRModalFooter>
								<AlertBox alertParam = {this.state.alert_param}/>
                            </MRModalContainer>
                        </div>)
                    }

                })()
            }

        </div>
        )
    }
}
export default AddActions;
