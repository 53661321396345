import React from 'react';
import styled from 'styled-components';
import { FaTimes,FaPlusCircle} from 'react-icons/fa';
import Store from '../../Common/Store.js';
import APICall from '../../Common/APICall';
import * as Style from '../Common/StyledComponents';
import DatePicker from 'react-date-picker';
import { saveAs } from 'file-saver';
import { Container, Row, Col } from 'react-grid-system';
import { FaPlus , FaEdit } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';

const ReactFormGroup = styled.div`
    margin-bottom: 15px;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto;
    margin-top: 30px;    
`;
const ScoreContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;    
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 6px #a7a6a6;
    width: 96%;
    margin: 0px auto;
    padding: 15px;
    left: 15%;   
`

const ScoreHeaderContainer = styled.div`
    background-color: #36aba6;    
    height: auto;
    padding: 10px;
    color: #ffffff;
    border: 1px solid #36aba6;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
`;
const ScoreBodyContainer = styled.div`
    padding: 10px;
`;

const MRModalLabel = styled.div`
    color: #a0a0a0;
    font-weight: 600;    
    margin-bottom: 6px;
    margin-top: 10px;
`;
const HeaderButton = styled.div`
    float: right;
    font-size: 20px;
    cursor: pointer;
`;

const FCKTextarea = styled.textarea`
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    /*font-weight: bold;*/
	font-family: Montserrat,sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    float: right;
    cursor: pointer;
	font-family: Montserrat,sans-serif;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    padding: 0px 20px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
	font-family: Montserrat,sans-serif;
`;

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1002;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    width: 95%;   
    margin-bottom: 10px;
	margin-left: 7px;
	top: 25%;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px 20px 10px 20px;
	background-color: #04ADA8;
`;
const MRModalSemiHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    /*padding: 20px 20px 10px 20px;*/
	background-color: grey;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    /*color: #DEDEDE;*/
    color: #FFFFFF;
    font-weight: bold;
    font-size : 14px;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #FFFFFF;
    cursor: pointer;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalInput = styled.input`
    height: 28px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
	font-family: Montserrat,sans-serif;
`;
const RegModalContainertop = styled.div`
    display: grid;
    grid-template-columns: 10% calc(90% - 15px);
    grid-gap: 15px;    
`;
const RegModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 3px;
    font-weight: bold;
    /*color: #979797;*/
    color: #000000;
`;
const ActionSelect = styled.select`    
    display: inline-block;
    box-sizing: border-box;
    height: 35px;
    width: 92%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    margin-left: 14px;
	/*font-weight: bold;*/
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -10%;
  top: -80px;
  z-index: 1000;
  opacity: 0.8;
`;
const InactiveOverlay2 = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -10%;
  top: -80px;
  z-index: 1005;
  opacity: 0.8;
`;
const MRModalFooter = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    
    background-color: #EDF1F1;
    padding: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`;
const CSLDateCover = styled.div`
    margin-top: 0px;
    width: 250px;
    border-radius: 3px;
    height: 34px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0 0 4px #c4c4c4;
	font-weight: bold;
    `;

function GetSortOrder(prop) {    
    return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
}
class AddCommitteeMembers extends React.Component
{
    state = { 
        showModal: false,
        binFiles: [],
		curBinFiles: [],
        subject: "",
        actionDescription: "",
		action_status: "",
        assignedTo: 0,
        action_note_undertaken: 'dd',
		IsActionTitleProvided : false,
		IsActionAssignedToProvided : false,
		IsActionProvided : false,
		is_form_modified : false,
		open_data_lost_warning_modal : false,
		due_date : new Date(),
		first_request : true,
		view_mode : false,
		task_id : 0,
		committeeMembersPlusIconShow : true,
        committeemembers: [],
        memberList: []
    }

	componentDidMount()
    {
		//window.scrollTo(0, 0);
		//console.log("Store.getStoreData('contacts') in risk area modal==>"+JSON.stringify(Store.getStoreData('contacts')));
		
        //if(typeof this.props.action_extra_json !== 'undefined'){
			/*let selected_employee_assoc = {};
			committeemembers.forEach((value) => {
			for(let value of this.props.committeemembers){
				if('selectedemp' in value)selected_employee_assoc[value.selectedemp.toString()] = value.id;
			});
			this.setState({ committeemembers : this.props.committeemembers , memberList: this.props.memberList , committeeroles: this.props.committeeroles});
			*/
			this.initializeState();
		//}
        
    }

    componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			this.initializeState();
			//this.setState({ committeemembers : this.props.committeemembers , memberList: this.props.memberList , committeeroles: this.props.committeeroles});
		}
		
	}
	initializeState(){
		if(typeof this.props.committeemembers !== 'undefined'){
			let selected_employee_assoc = {};
			for(let value of this.props.committeemembers){
				if('selectedemp' in value)selected_employee_assoc[value.selectedemp.toString()] = value.id;
			}
			let c_members = [];
			//console.log("initializeState,",selected_employee_assoc,this.props.committeemembers);
			//if ('undefined' != typeof Store.getStoreData('modal_committee_Edit_CommitteeID') && parseInt(Store.getStoreData('modal_committee_Edit_CommitteeID')) > 0) {
			let committeemembers = JSON.parse(JSON.stringify(this.props.committeemembers));
			for(let value of committeemembers) {
				
				if('selectedemp' in value && value.selectedemp.toString() in selected_employee_assoc && selected_employee_assoc[value.selectedemp.toString()] === value.id){
					//console.log("initializeState,",value.selectedemp.toString(),value.id,selected_employee_assoc);
					let members = [];
					for(let m of value.members){
						if(m.employeeid.toString() === value.selectedemp.toString()){
							
							members.push(m);
						}
						if((m.employeeid.toString() !== value.selectedemp.toString() && !(m.employeeid.toString() in selected_employee_assoc))){
							members.push(m);
						}
					}
					members.sort(GetSortOrder("employeename"));
					//console.log("initializeState 2,",members);
					value.members = members;
				}
				if(!('selectedemp' in value)){
					let members = [];
					for(let m of value.members){
						if(!(m.employeeid.toString() in selected_employee_assoc)){
							members.push(m);
						}
					}
					members.sort(GetSortOrder("employeename"));
					//console.log("initializeState 2,",members);
					value.members = members;
				}
				c_members.push(value);

			}
			this.setState({ committeemembers : c_members , memberList: this.props.memberList , committeeroles: this.props.committeeroles});			
		}
		
	}

    
    showAddAction = (index) => (event) => {
		//console.log("in show action");
        event.preventDefault();
        this.setState({ showModal: true} , ()=>{
			//this.props.actionModalOpen({'status' : true}); 
			this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
		});
    }
    closeAddActionModal = (event) => {
		event.preventDefault();
        
        this.setState({ showModal: false });
    }

	refreshState  = (val) => {
		if(val === null){
			this.setState({open_data_lost_warning_modal : false , showModal: false} , ()=>{this.props.actionModalOpen({'status' : false})});
		}else{
			this.setState({open_data_lost_warning_modal : false } , ()=>{this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});});
		}
	}
    submit = (event) => {
		event.preventDefault();
        this.setState({ showModal: false } , ()=>{this.props.committeeMembersModalOpen({committeemembers : this.state.committeemembers})});
		//this.props.actionModalOpen({'status' : false , showModal: false , committeemembers : this.state.committeemembers })
    } 

    handleMemberChange_previous = event => {

        event.preventDefault();
		//console.log("c==>"+event.target.name);
        var c = parseInt(event.target.name.split('-')[1])
        let committeemembers = JSON.parse(JSON.stringify(this.state.committeemembers));

        let index_val;
        committeemembers.forEach((value, index) => {

            //console.log(value.id,c,parseInt(event.target.value))
            
            if (value.id === c) {
				//console.log("handleMemberChange index==>"+index)
				
                //console.log("========",value.members)
                value.members.forEach((element , index1) => {
                    if (element.employeeid === parseInt(event.target.value)) {
						index_val = index1;
                        element.selected = true
                        value.selectedemp = parseInt(event.target.value)
                    } else {
                        element.selected = false
                    }
                })
            }
        });
		//console.log("index_val==>"+index_val);
		//committeemembers[c].members.splice(index_val, 1);
		//console.log("committeemembers==>"+JSON.stringify(committeemembers));
        let membersList = [];
		committeemembers.forEach((value) => {
			if (value.id === c) {
			}else{
				value.members.forEach((element,index1) => {
					if(!element.selected && index1 === index_val){
						value.members.splice(index_val, 1);
					}
				});
				//membersList = value.members;
				
			}
		});

        this.setState({ committeemembers: committeemembers })

    }

    handleMemberChange = event => {
        event.preventDefault();
        var c = parseInt(event.target.name.split('-')[1])
        let committeemembers = JSON.parse(JSON.stringify(this.state.committeemembers));
		if(event.target.value.toString() === 'Reports To'){
			let employeeid = 0;
			for(let value of committeemembers){
				if(value.id === c){
					employeeid = value.selectedemp;
					for(let element of value.members){
						if(employeeid.toString() === element.employeeid.toString()){
							element.selected = false;
							break;
						}
					}
				}
			}
			let memberObj = {};
			for(let m of this.state.memberList){
				if(m.employeeid.toString() === employeeid.toString()){
					memberObj = m;
					break;
				}
			}
			let temp_c_members = JSON.parse(JSON.stringify(committeemembers));
			for(let value of temp_c_members){
				if(value.id !== c){
					value.members.push(memberObj);
					value.members.sort(GetSortOrder("employeename"));
				}else{
					delete value.selectedemp;
				}
			}
			committeemembers = temp_c_members;
			//console.log("handleMemberChange 3,",committeemembers,employeeid,memberObj);
		}else{
			let index_val;
			committeemembers.forEach((value, index) => {
				if (value.id === c) {
					value.members.forEach((element , index1) => {
						if (element.employeeid === parseInt(event.target.value)) {
							index_val = index1;
							element.selected = true;
							value.selectedemp = parseInt(event.target.value);
						} else {
							element.selected = false;
						}
					})
				}
			});

			let selected_employee_assoc = {};
			committeemembers.forEach((value) => {
				if('selectedemp' in value)selected_employee_assoc[value.selectedemp.toString()] = value.id;
			});
			for(let value of committeemembers) {
				if (value.id.toString() !== c.toString()) {
					let updated_v_members = [];
					for(let m of this.state.memberList){
						if(parseInt(m.employeeid) !== parseInt(event.target.value)){
							let flg =0;
							let selected = (m.employeeid.toString() in selected_employee_assoc) ? true : false;
							m.selected = selected;
							if(selected && selected_employee_assoc[m.employeeid.toString()] === value.id){
								m.selectedemp = parseInt(m.employeeid);
								updated_v_members.push(m);
								flg = 1;
							}
							if(flg === 0 && !(m.employeeid.toString() in selected_employee_assoc))updated_v_members.push(m);
						}
					}
					value.members = updated_v_members;
				}
			}
			//console.log("handleMemberChange 2,",committeemembers);			
		}

        this.setState({ committeemembers: committeemembers })

    }
    handleReportsToChange = event => {
        event.preventDefault();
        var c = parseInt(event.target.name.split('-')[1])
        let committeemembers = JSON.parse(JSON.stringify(this.state.committeemembers));


        committeemembers.forEach((value, index) => {
            if (value.id === c) {

                value.roles.forEach((element) => {

                    if (element.committeeroleid.toString() === event.target.value) {
                        element.selected = true
                        value.selectedrole = event.target.value;
                    } else {
                        element.selected = false
                    }
                })
            }
        })

        this.setState({ committeemembers: committeemembers })

    }

    handleCommitteeAddClick = event => {
        event.preventDefault()
        let tempcm = []
        tempcm = this.state.committeemembers
        console.log("handleCommitteeAddClick tempcm==>",tempcm);
        console.log("handleCommitteeAddClick membersList==>",this.state.memberList);
		//return;

        let membersList = this.state.memberList;
		let selectedMembers = [];
		let exists = {};
		let index_array = [];
		tempcm.forEach((value,index) => {
			    index_array.push(value.id);
				value.members.forEach((element,index1) => {
					exists[element.employeeid] = false;
					if(element.selected ){
						//value.members.splice(index1, 1);
						exists[element.employeeid] = true;
						//selectedMembers.push(element);
					}
				});
		});
		let maximum_index = Math.max(...index_array);

		var flags = {}, outputMembers = [];
		for(let i=0; i<membersList.length; i++) {

			if(exists[membersList[i].employeeid]) continue;
			outputMembers.push(membersList[i]);
		}
		if(outputMembers.length === 0){
			outputMembers = this.state.memberList;
		}

        tempcm.push({
            id: maximum_index + 1,
            //id: this.state.committeemembers.length + 1,

            members: outputMembers,
            roles: this.state.committeeroles
        });
		let plusIconShow = true;
		//if(outputMembers.length === 1) {plusIconShow = false;}
		if(this.state.committeemembers.length === this.state.memberList.length) {plusIconShow = false;}

        this.setState({ committeemembers: tempcm , committeeMembersPlusIconShow : plusIconShow})
    }
	handleCommitteeMembersDeleteClick = (index) => (event) => {
        event.preventDefault()
        let tempcm = []
        tempcm = this.state.committeemembers;
		let membersList = this.state.memberList;
        //console.log("index==>"+index);
        //console.log("handleCommitteeMembersDeleteClick tempcm==>",tempcm);
        //console.log("membersList==>"+JSON.stringify(membersList));
        //console.log("membersList==>"+JSON.stringify(this.state.memberList));
		let reset_employeeid = 0;
		//for(let i=0;i<tempcm.length;i++){
			if('selectedemp' in tempcm[index]){
				reset_employeeid = tempcm[index].selectedemp;
				//break;
			}
		//}
        tempcm.splice(index, 1);
		let deleted_member_obj ={};
		if(reset_employeeid.toString() !== '0'){
			for(let ml of membersList){
				if(ml.employeeid.toString() === reset_employeeid.toString()){
					deleted_member_obj = ml;
				}
			}			
		}
		//console.log("handleCommitteeMembersDeleteClick tempcm==>",index,tempcm,deleted_member_obj);

        let tempcm_new = [];
		for (let i = 0; i < tempcm.length ; i++) {
			if(Object.keys(deleted_member_obj).length > 0){
				tempcm[i].members.push(deleted_member_obj);
				tempcm[i].members.sort(GetSortOrder("employeename"));
			}
			tempcm_new.push({
				id: i + 1,
				members: tempcm[i].members,
				roles: tempcm[i].roles,
				selectedemp: tempcm[i].selectedemp,
				selectedrole: tempcm[i].selectedrole,
			})
		}

		let exists = {};
		tempcm_new.forEach((value) => {
				value.members.forEach((element,index1) => {
					exists[element.employeeid] = false;
					if(element.selected ){
						exists[element.employeeid] = true;
					}
				});
		});
		var flags = {}, outputMembers = [];
		for(let i=0; i<membersList.length; i++) {
			//console.log(membersList[i].employeeid+"<=exists==>"+exists[membersList[i].employeeid]);
			if(exists[membersList[i].employeeid]) continue;
			outputMembers.push(membersList[i]);
		}
		if(outputMembers.length === 0){
			outputMembers = this.state.memberList;
		}
		let plusIconShow = false;
		if(outputMembers.length < membersList.length) {plusIconShow = true;}

        this.setState({ committeemembers: tempcm_new , committeeMembersPlusIconShow : plusIconShow})
    }


    render() {
		//console.log("committee members state,", this.state);
        let companies_assoc = Store.getStoreData('companies_assoc')
        let user_assignments = Store.getStoreData('user_assignments')
        return(<div>
            {
                (() => {
                    if(this.state.showModal) {
                        return(<div > 
                            <MRModalContainer>
                                <MRModalHeader>
									{
										(() => {
											return(
												<MRModalHeaderText>Add Committee Members</MRModalHeaderText>
												)
										})()
									}		
                                    <MRModalHeaderCloseBtn onClick={this.closeAddActionModal}><FaTimes /></MRModalHeaderCloseBtn>
                                    <div style={{clear: "both"}}></div>
                                </MRModalHeader>

								<div style={{clear: "both"}} ></div>
                                <MRModalBody>

                                <div style={{clear: "both"}} ></div>
                                <Container>

                            {
                                this.state.committeemembers.map((cm, index) => {
                                    //console.log('cm', cm)
                                    if (index === this.state.committeemembers.length - 1) {
                                        return (
                                            <Row key={cm.id}>
                                                <Col sm={5} style={{ paddingLeft: "0px" }}>
                                                    <ReactFormGroup>
                                                        <select className="react-form-control" id={`cmbMember-${index + 1}`} name={`cmbMember-${index + 1}`} value={cm.selectedemp} onChange={this.handleMemberChange}>
                                                            <option value="Reports To">Select member</option>
                                                            {
                                                                cm.members.map(member => {
																	let is_active = ('is_active' in member) ? member.is_active: true;
																	if(is_active){
																		switch(this.props.association) {
																			case 'gc':  if(member.contact_id.toString() in user_assignments && this.props.gc_id in user_assignments[member.contact_id.toString()].companies) {
																							return (
																								<option key={member.employeeid} value={member.employeeid} >{member.employeename}</option>
																							)
																						}
																						break;
																			case 'grouping':  
																						let sel_grouping_parts = this.props.sel_grouping.split('-')
																						let grouping_id = sel_grouping_parts[0]
																						let grouping_value_id = sel_grouping_parts[1]
																						if(member.contact_id.toString() in user_assignments && grouping_id in user_assignments[member.contact_id.toString()].tags && grouping_value_id in user_assignments[member.contact_id.toString()].tags[grouping_id]) {
																							return (
																								<option key={member.employeeid} value={member.employeeid} >{member.employeename}</option>
																							)
																						}
																						break;
																			default: return (
																								<option key={member.employeeid} value={member.employeeid} >{member.employeename}</option>
																							)
																		}
																	}


                                                                    
                                                                })
                                                            }
                                                        </select>
                                                    </ReactFormGroup>
                                                </Col>
                                                <Col sm={5} style={{ paddingRight: "0px" }}>
                                                    <ReactFormGroup>
                                                        <select className="react-form-control" id={`cmbRole-${index + 1}`} name={`cmbRole-${index + 1}`} value={cm.selectedrole} onChange={this.handleReportsToChange}>
                                                            <option value="Reports To">Select Role</option>
                                                            {
                                                                cm.roles.map(role => {
                                                                    return (
                                                                        <option key={role.committeeroleid} value={role.committeeroleid} >{role.commiitteerolename}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </ReactFormGroup>
                                                </Col>
                                                <Col sm={1}>
													{this.state.committeeMembersPlusIconShow ? <FaPlus style={{ color: "#979797", top: "16px", position: "relative" }} onClick={this.handleCommitteeAddClick} /> : null}
                                                    
                                                </Col>
                                                <Col sm={1}>
													<MdClose style={{ color: "#979797", top: "16px", position: "relative" }} onClick={this.handleCommitteeMembersDeleteClick(`${index}`)} />
                                                    
                                                </Col>
                                            </Row>
                                        )
                                    }
                                    else {
                                        return (
                                            <Row key={cm.id}>
                                                <Col sm={5} style={{ paddingLeft: "0px" }}>
                                                    <ReactFormGroup>
                                                        <select className="react-form-control" id={`cmbMember-${index + 1}`} name={`cmbMember-${index + 1}`} value={cm.selectedemp} onChange={this.handleMemberChange}>
                                                            <option value="Reports To">Select member</option>
                                                            {
                                                                cm.members.map(member => {
																	let is_active = ('is_active' in member) ? member.is_active: true;
																	if(is_active){
																		switch(this.props.association) {
																			case 'gc':  if(member.contact_id.toString() in user_assignments && this.props.gc_id in user_assignments[member.contact_id.toString()].companies) {
																							return (
																								<option key={member.employeeid} value={member.employeeid} >{member.employeename}</option>
																							)
																						}
																						break;
																			case 'grouping':  
																						let sel_grouping_parts = this.props.sel_grouping.split('-')
																						let grouping_id = sel_grouping_parts[0]
																						let grouping_value_id = sel_grouping_parts[1]
																						if(member.contact_id.toString() in user_assignments && grouping_id in user_assignments[member.contact_id.toString()].tags && grouping_value_id in user_assignments[member.contact_id.toString()].tags[grouping_id]) {
																							return (
																								<option key={member.employeeid} value={member.employeeid} >{member.employeename}</option>
																							)
																						}
																						break;
																			default: return (
																								<option key={member.employeeid} value={member.employeeid} >{member.employeename}</option>
																							)
																		}
																	}


                                                                    
                                                                })
                                                                
                                                                // cm.members.map(member => {
                                                                //     return (
                                                                //         <option key={member.employeeid} value={member.employeeid} >{member.employeename}</option>
                                                                //     )
                                                                // })
                                                            }
                                                        </select>
                                                    </ReactFormGroup>
                                                </Col>
                                                <Col sm={5} style={{ paddingRight: "0px" }}>
                                                    <ReactFormGroup>
                                                        <select className="react-form-control" id={`cmbRole-${index + 1}`} name={`cmbRole-${index + 1}`} value={cm.selectedrole} onChange={this.handleReportsToChange}>
                                                            <option value="Reports To">Select Role</option>
                                                            {
                                                                cm.roles.map(role => {
                                                                    return (
                                                                        <option key={role.committeeroleid} value={role.committeeroleid}>{role.commiitteerolename}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </ReactFormGroup>
                                                </Col>
                                                <Col sm={1}>
													&nbsp;
                                                    
                                                </Col>
                                                <Col sm={1}>
													<MdClose style={{ color: "#979797", top: "16px", position: "relative" }} onClick={this.handleCommitteeMembersDeleteClick(`${index}`)} />
                                                    
                                                </Col>
                                            </Row>
                                        )
                                    }

                                })
                            }									
                                </Container>



                                <div style={{clear: "both"}} ></div>


                                <div style={{clear: "both"}} ref={el => { this.el = el; }}></div>
                                </MRModalBody>
								<MRModalFooter>
                                <div style={{height: '50px', paddingRight: "31px", marginTop: "50px"}}>   
									{
										(() => {
											return <MRModalNextBtn  style={{backgroundColor: "#26ADA7", border: "1px solid #26ADA7" }} id="add_committee_members_submit_button" onClick={this.submit}>Submit</MRModalNextBtn>;
										})()
									}													
                                    
                                    <MRModalCancelBtn style={{backgroundColor: "#ffffff", border: "1px solid #ffffff", color: "black"}} onClick={this.closeAddActionModal} id="add_committee_members_cancel_button" >Close</MRModalCancelBtn>
                                </div>
								</MRModalFooter>
                            </MRModalContainer>
                        </div>)
                    }
									
                })()
            }

            <OverviewInfoHeader>
                <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>{this.props.headerText}</div>
				{
					(() => {
						if(this.props.showAddIcon === true) {
							return <div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}}  id="add_committee_members_button" onClick={this.showAddAction(-1)} ><FaEdit  /></div>
						}
					})()
				}
                <div style={{clear: "both"}}></div>
            </OverviewInfoHeader>
            
        </div>)
    }
}
export default AddCommitteeMembers;